import * as d3 from "d3";
import { createStore } from "redux";
import { MdfDashboardActionTypes } from "../actionTypes";

export interface MdfDashboardState {
  graphLines: {
    sensors: string[];
    version: number;
    t0: number;
    t1: number;
    zoom: any;
  }[];
  graphCorrs: { sensors: string[]; version: number }[];
  graphRatio: { sensors: string[]; direction: string; version: number }[];

  flags: { [key: string]: any };
  timeseries: { [key: string]: any };

  version: number;
  flagged: { [key: string]: number[] };
}

const initialState = {
  version: 0,
  graphLines: [],
  graphCorrs: [],
  graphRatio: [],

  flags: {},
  flagged: {},
  timeseries: {},
};

const reducer = (
  state: MdfDashboardState = initialState,
  action: any
): MdfDashboardState => {
  switch (action.type) {
    case MdfDashboardActionTypes.MDF_DRAW_LINE_GRAPHS: {
      const base = {
        version: 0,
        t0: action.requestParams.t0,
        t1: action.requestParams.t1,
        zoom: d3.zoomIdentity,
      };
      const graphs = action.requestParams.linegraphs;
      const graphLines = (
        graphs.length === 1 ? [] : [{ ...base, sensors: graphs }]
      ).concat(graphs.map((sname: string) => ({ ...base, sensors: [sname] })));
      return { ...state, graphLines };
    }

    case MdfDashboardActionTypes.MDF_UPDATE_LINE_GRAPHS: {
      const { graphLines } = state;

      const { idxs } = action.requestParams;
      if (idxs.length === 0) {
        for (let i = 0; i < graphLines.length; i += 1) idxs.push(i);
      }
      for (const idx of idxs) {
        graphLines[idx].version += 1;
        graphLines[idx].t0 = action.requestParams.t0;
        graphLines[idx].t1 = action.requestParams.t1;
        graphLines[idx].zoom = action.requestParams.zoom || d3.zoomIdentity;
      }
      return { ...state, graphLines };
    }

    case MdfDashboardActionTypes.MDF_DRAW_CORR_GRAPHS: {
      return {
        ...state,
        graphCorrs: action.requestParams.corrgraphs.map(
          (sensors: string[]) => ({
            sensors,
            version: 0,
          })
        ),
      };
    }

    case MdfDashboardActionTypes.MDF_DRAW_RATIO_GRAPHS: {
      return {
        ...state,
        graphRatio: action.requestParams.plots.map((vals: string[]) => ({
          sensors: [vals[1], vals[2]],
          direction: vals[0],
          version: 0,
        })),
      };
    }

    case MdfDashboardActionTypes.MDF_UPDATE_VIEW: {
      return { ...state, version: state.version + 1 };
    }

    case MdfDashboardActionTypes.MDF_UPDATE_DATA: {
      const flags = { ...state.flags, ...action.requestParams.flags };
      const flagged = { ...state.flagged };

      const timeseries = {
        ...action.requestParams.timeseries,
        ...state.timeseries,
      };
      for (const sname of flags) {
        // NOTE: the O(N) data point flagging thing
        // convert everything into event
        // then count number of overlapping for open and close
        if (!timeseries[sname]) continue;

        let maxP = -1;
        const events = [] as any[];
        for (const e of flags[sname]) {
          events.push([e[0], e[3], 1]);
          events.push([e[1], e[3], -1]);
          maxP = Math.max(e[3], maxP);
        }
        events.sort((a: any, b: any) => a[0] - b[0] || b[2] - a[2]);
        maxP += 1;

        const flagdata = timeseries[sname][0].timeseries.map(() => -1);
        const flagbool = [] as number[];
        for (let p = 0; p < maxP; p += 1) flagbool.push(0);

        let eid = 0;
        const full = timeseries[sname][0].timeseries;
        for (let i = 0; i < full.length; i += 1) {
          const tstamp = full[i][0];
          while (eid < events.length && events[eid][0] < tstamp) {
            flagbool[events[eid][1]] += events[eid][2];
            eid += 1;
          }

          let pp = -1;
          for (let j = 0; j < maxP; j += 1) {
            if (flagbool[j] > 0) pp = j;
          }
          flagdata[i] = pp;
        }
        flagged[sname] = flagdata;
      }

      return {
        ...state,
        timeseries,
        flagged,
        flags,
      };
    }
    default:
      return state;
  }
};

export default createStore(reducer, initialState);
