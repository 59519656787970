import queryString from "query-string";
import { AlertData } from "src/networking/types";
import { AlertsActionTypes } from "src/redux/actionTypes";
import { RequestPayload } from "src/redux/utils";

export interface AlertsAction {
  type: AlertsActionTypes;
  payload: RequestPayload;
}

export function getAllAlerts(
  token: string,
  noUnresolvedBeforeTimestamp?: number
): AlertsAction {
  return {
    type: AlertsActionTypes.GET_ALL_ALERTS_REQUEST,
    payload: {
      client: "argos",
      request: {
        url: queryString.stringifyUrl({
          url: "/v1/alerts",
          query: {
            no_unresolved_before_timestamp: String(noUnresolvedBeforeTimestamp),
          },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getAlertsForSite(
  projectId: string,
  siteId: string,
  token: string,
  noUnresolvedBeforeTimestamp?: number
): AlertsAction {
  return {
    type: AlertsActionTypes.GET_ALERTS_FOR_SITE_REQUEST,
    payload: {
      client: "argos",
      request: {
        url: queryString.stringifyUrl({
          url: "/v1/alerts",
          query: {
            project_id: projectId,
            site_name: siteId,
            no_unresolved_before_timestamp: String(noUnresolvedBeforeTimestamp),
          },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getAlertsForProject(
  id: string,
  token: string,
  noUnresolvedBeforeTimestamp?: number
): AlertsAction {
  return {
    type: AlertsActionTypes.GET_ALERTS_FOR_PROJECT_REQUEST,
    payload: {
      client: "argos",
      request: {
        url: queryString.stringifyUrl({
          url: "/v1/alerts",
          query: {
            project_id: id,
            no_unresolved_before_timestamp: String(noUnresolvedBeforeTimestamp),
          },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function resolveAlerts(
  ids: string[],
  message: string,
  token: string
): AlertsAction {
  return {
    type: AlertsActionTypes.RESOLVE_ALERTS_REQUEST,
    payload: {
      client: "argos",
      request: {
        method: "post",
        url: "/v1/alerts/resolve",
        data: {
          ids,
          message,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function createAlerts(
  alertData: AlertData,
  token: string
): AlertsAction {
  return {
    type: AlertsActionTypes.CREATE_ALERTS_REQUEST,
    payload: {
      client: "argos",
      request: {
        method: "post",
        url: "/v1/alerts/",
        data: alertData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function unresolveAlerts(ids: string[], token: string): AlertsAction {
  return {
    type: AlertsActionTypes.UNRESOLVE_ALERTS_REQUEST,
    payload: {
      client: "argos",
      request: {
        method: "post",
        url: "/v1/alerts/unresolve",
        data: { ids },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function shareAlerts(ids: string[], token: string): AlertsAction {
  return {
    type: AlertsActionTypes.SHARE_ALERTS_REQUEST,
    payload: {
      client: "argos",
      request: {
        method: "post",
        url: "/v1/alerts/share",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { ids },
      },
    },
  };
}

export function snoozeAlerts(
  ids: string[],
  utc_timestamp: number,
  token: string
): AlertsAction {
  return {
    type: AlertsActionTypes.SNOOZE_ALERTS_REQUEST,
    payload: {
      client: "argos",
      request: {
        method: "post",
        url: "/v1/alerts/snooze",
        data: {
          ids,
          utc_timestamp,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}
