import { Table } from "antd";
import React from "react";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import styled from "styled-components";

interface Props {
  title: string;
  tableColumns: Record<string, unknown>[];
  data?: Record<string, unknown>[];
  loading: boolean;
  error?: Record<string, unknown>;
  scroll?: any;
  pagination?: any;
  onClick?: (record: any, idx: number | undefined) => void;
  selectedKey?: any;
  defaultPageSize?: number;
}

interface MonitoringCardComponentProps {
  hasOnClick?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MonitoringCardComponent = styled(({ hasOnClick, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <WinddeskCard {...props} />
))<MonitoringCardComponentProps>`
  & .ant-card-body {
    padding: 0;
  }

  & tbody {
    ${({ hasOnClick }) => hasOnClick && "cursor: pointer;"}
  }

  & .ant-table-cell {
    font-size: 12px !important;
  }
`;

interface TableWithSelectedRowProps {
  selectedKey?: string;
}

export const TableWithSelectedRow = styled(Table)<TableWithSelectedRowProps>`
  & [data-row-key="${({ selectedKey }) => selectedKey}"] {
    background-color: #f5f5f5;
  }
`;

const MonitoringCard: React.FC<Props> = ({
  title,
  tableColumns,
  data,
  loading,
  error,
  scroll,
  pagination,
  children,
  onClick,
  selectedKey,
  defaultPageSize,
}) => (
  <MonitoringCardComponent
    loading={loading}
    title={title}
    hasOnClick={!!onClick}
  >
    {error && Object.keys(error).length > 0 ? (
      <div>An error occured</div>
    ) : (
      <>
        <TableWithSelectedRow
          selectedKey={selectedKey}
          columns={tableColumns.map((c) => ({
            filters: [
              ...new Set((data ?? []).map((d) => d[c.dataIndex as string])),
            ].map((t) => ({
              text: String(t),
              value: String(t),
            })),
            onFilter: (v: string | number | boolean, r: Record<string, any>) =>
              // @ts-ignore dunno how to cast unknown to something with toString
              r[c.dataIndex].toString() === v,
            ...c,
          }))}
          dataSource={data}
          size="small"
          pagination={
            pagination ??
            ((data?.length ?? 0) <= 5
              ? false
              : {
                  defaultPageSize: defaultPageSize ?? 5,
                  pageSizeOptions: ["5", "10", "20", "50", "100"],
                })
          }
          scroll={scroll}
          locale={data === undefined ? { emptyText: "Pending" } : undefined}
          onRow={(record: any, idx: number | undefined) => ({
            onClick: () => onClick?.(record, idx),
          })}
        />
        {children}
      </>
    )}
  </MonitoringCardComponent>
);

export default MonitoringCard;
