import { InfoCircleOutlined } from "@ant-design/icons/lib";
import { Space, Tooltip, Typography } from "antd";
import React from "react";
import { isUrl } from "src/components/SimulationConfigs/utils";

const { Text } = Typography;

export interface FieldProps {
  tooltip?: string;
  suggestions?: string;
}

const Field: React.FC<FieldProps> = ({ tooltip, suggestions, children }) => (
  <Space direction="horizontal">
    {children}
    {tooltip && (
      <Tooltip
        placement="right"
        title={isUrl(tooltip) ? <a href={tooltip}>{tooltip}</a> : tooltip}
        overlayStyle={{ maxWidth: "500px" }}
      >
        <InfoCircleOutlined />
      </Tooltip>
    )}
    {suggestions && <Text>{suggestions}</Text>}
  </Space>
);

export default Field;
