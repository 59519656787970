import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SaveIcon } from "src/components/Common/Icons/save.svg";
import DiffAlert from "src/components/SimulationConfigs/DiffAlert";
import { getDiffs } from "src/components/SimulationConfigs/utils/diffs";
import { standardiseState } from "src/components/SimulationConfigs/utils/formValues";
import { hasValidationErrors } from "src/components/SimulationConfigs/utils/validations";
import { useSimulationSelectionState } from "src/components/Simulations/hooks";
import {
  saveConfigByWorkflowId,
  setDebug,
  setShowPrimary,
} from "src/redux/actions/configs";
import { GlobalState } from "src/redux/reducers";

import { canViewDebug } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";
import styled from "styled-components";

import "src/components/SimulationConfigs/index.css";
import "src/components/SimulationConfigs/ConfigInfo.css";

const Container = styled.div`
  background: #fff;
  border: 0;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 60px;
  left: 300px;
  z-index: 10;
  width: calc(100vw - 320px);
  height: 72px;
  box-shadow: 0 8px 16px -10px rgb(0, 0, 0, 0.1);
`;

const Title = styled.span`
  font-family: Blender Pro;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  margin-left: 18px;
  margin-right: auto;
  display: flex;
  align-items: center;
  color: #3b3b41;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const ControlsText = styled.span`
  font-family: Blender Pro;
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #3b3b41;
`;

interface Props {
  name: string;
}

export const ConfigInfo: React.FC<Props> = ({ name }) => {
  const dispatch = useDispatch();
  const showOnlyPrimaryFieldsState = useSelector(
    (state: GlobalState) => state.configs.showOnlyPrimaryFields
  );
  const onToggleShowPrimary = (checked: boolean) => {
    dispatch(setShowPrimary(checked));
  };

  const debugForms = useSelector((state: GlobalState) => state.configs.debug);
  const onToggleDebug = (checked: boolean) => {
    dispatch(setDebug(checked));
  };

  const currentSimulationId = useSimulationSelectionState()[0];
  const {
    config: formInitialState,
    configId,
    configCommitId,
  } = useSelector(
    (state: GlobalState) =>
      (currentSimulationId && state.configs.configs[currentSimulationId]) || {
        config: null,
        configId: undefined,
        configCommitId: undefined,
      }
  );

  const { getTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently().then((token: string) => {
      setAuthToken(token);
    });
  }, [getTokenSilently]);

  const formState = useSelector(
    (state: GlobalState) => state.configs.formValues
  );
  const saveConfigLoadingState = useSelector(
    (state: GlobalState) =>
      state.requests.SAVE_CONFIG_BY_WORKFLOW_ID_REQUEST.isLoading
  );

  const validationErrors = useSelector((state: GlobalState) =>
    hasValidationErrors(state.configs.validationErrors)
  );

  const handleSaveConfig = async () => {
    if (
      authToken === undefined ||
      currentSimulationId === undefined ||
      configId === undefined ||
      configCommitId === undefined
    ) {
      throw new Error("Invalid state while saving config");
    }

    if (validationErrors) {
      Modal.error({
        content: "Error when saving. Please check form for validation errors.",
      });
      return;
    }

    const standardisedFormState = standardiseState(formState);
    const standardisedFormInitialState = standardiseState(formInitialState);

    dispatch(
      saveConfigByWorkflowId(
        currentSimulationId,
        configId,
        standardisedFormState,
        getDiffs(standardisedFormState, standardisedFormInitialState),
        "message",
        configCommitId,
        authToken
      )
    );
  };

  const saveConfigByWorkflowIdState = useSelector(
    (state: GlobalState) => state.requests.SAVE_CONFIG_BY_WORKFLOW_ID_REQUEST
  );
  useEffect(() => {
    if (saveConfigByWorkflowIdState.error) {
      if (saveConfigByWorkflowIdState.error?.data.error.diffs) {
        Modal.error({
          content:
            "There has been new updates. Please check alert at the top of this page. If there are no alerts, please save config again.",
        });
      } else {
        Modal.error({
          content: `Error when saving: ${saveConfigByWorkflowIdState.error?.data.error}`,
        });
      }
    } else if (saveConfigByWorkflowIdState.isCompleted) {
      Modal.success({
        content: "Config saved!",
      });
    }
  }, [saveConfigByWorkflowIdState]);

  return (
    <>
      <Container>
        <Controls
          style={{
            marginLeft: "14px",
            marginRight: "0",
          }}
        >
          <Button
            type="primary"
            size="large"
            onClick={handleSaveConfig}
            loading={saveConfigLoadingState}
            className="trv-save-button-override"
          >
            <SaveIcon /> SAVE CHANGES
          </Button>
        </Controls>
        <Title>Subworkflow: {name}</Title>
        {authToken && canViewDebug(authToken) ? (
          <Controls>
            <ControlsText>Debug</ControlsText>
            <Switch
              checked={debugForms}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(checked) => onToggleDebug(checked)}
              style={{ marginLeft: "24px" }}
              className="traverse-switch"
            />
          </Controls>
        ) : null}
        <Controls>
          <ControlsText>Primary Fields Only</ControlsText>
          <Switch
            checked={showOnlyPrimaryFieldsState}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(checked) => onToggleShowPrimary(checked)}
            style={{ marginLeft: "24px" }}
            className="traverse-switch"
          />
        </Controls>
      </Container>
      <DiffAlert
        currentSubworkflowName={name}
        configCommitId={configCommitId}
      />
    </>
  );
};
