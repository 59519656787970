import queryString from "query-string";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TextWithIcon } from "src/components/Common";
import Sider from "src/components/Sider";
import SiderCard from "src/components/Sider/SiderCard";
import { ReactComponent as LidarIcon } from "src/components/Sider/icons/lidar.svg";
import { ReactComponent as MastIcon } from "src/components/Sider/icons/mast.svg";
import { ReactComponent as SitesIcon } from "src/components/Sider/icons/sites.svg";
import { ReactComponent as SodarIcon } from "src/components/Sider/icons/sodar.svg";
import { ReactComponent as WindTurbineIcon } from "src/components/Sider/icons/wind-turbine.svg";
import { makeOption } from "src/components/Sider/utils";
import { BasicSite } from "src/networking/types";
import { GlobalState } from "src/redux/reducers";

interface Props {
  width: string;
}

const getSiteIcon = (site: BasicSite) => {
  switch (site.type) {
    case "met_mast":
      return <MastIcon />;
    case "lidar":
      return <LidarIcon />;
    case "sodar":
      return <SodarIcon />;
    default:
      throw new Error(`Do not know what icon is for ${site.type}`);
  }
};

const MonitorSider: React.FC<Props> = ({ width }) => {
  const location = useLocation();
  const { projectId, siteName } = Object.fromEntries(
    Object.entries(queryString.parse(location.search)).map(([k, v]) => [
      k,
      typeof v === "string" ? v : undefined,
    ])
  );

  const history = useHistory();

  const projectsState = useSelector(
    (state: GlobalState) => state.projects.projects
  );
  const projectOptions = Object.values(projectsState).map((value) =>
    makeOption(
      value.id,
      <TextWithIcon>
        <WindTurbineIcon style={{ flexShrink: 0 }} />
        {value.name}
      </TextWithIcon>
    )
  );

  const selectedProject = projectId ? projectsState[projectId] : undefined;
  const siteOptions =
    selectedProject?.sites?.map((site) => ({
      value: site.name,
      label: (
        <TextWithIcon>
          {getSiteIcon(site)}
          {site.name}
        </TextWithIcon>
      ),
      description: site.name,
    })) ?? [];

  return (
    <Sider
      width={width}
      cards={[
        <SiderCard
          title="Project"
          options={projectOptions}
          value={projectId}
          handleChange={(newProjectId) => {
            history.push({
              pathname: location.pathname,
              search: queryString.stringify({
                projectId: newProjectId,
              }),
            });
          }}
          selectPlaceholder="Select Project"
        />,
        <SiderCard
          mainButtonUrl={`${location.pathname}?${queryString.stringify({
            projectId,
          })}`}
          mainButtonText="View All Sites"
          mainButtonIcon={<SitesIcon />}
          title="Site"
          options={siteOptions}
          value={siteName}
          showContents={!!projectId}
          handleChange={(newSiteName) =>
            history.push({
              pathname: location.pathname,
              search: queryString.stringify(
                newSiteName === ""
                  ? { projectId }
                  : {
                      projectId,
                      siteName: newSiteName,
                    }
              ),
            })
          }
          noContentsText="Select a project to view sites"
          useMenu
        />,
      ]}
    />
  );
};

export default MonitorSider;
