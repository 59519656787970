import { isDataTypeANumber } from "src/components/SimulationConfigs/utils";
import {
  getFieldValue,
  isNumberRange,
} from "src/components/SimulationConfigs/utils/formValues";
import {
  Accessor,
  Model,
  WorkflowConfigDataType,
  WorkflowConfigField,
  WorkflowConfigUiType,
} from "src/networking/types";

export function validate(
  config: WorkflowConfigField,
  formValues: Model,
  accessor: Accessor,
  value: unknown
): string | null {
  if (!value) {
    return null;
  }

  if (config.unique) {
    const lastNumberIndex =
      accessor.length -
      1 -
      [...accessor].reverse().findIndex((a) => typeof a === "number");
    const items = (
      getFieldValue(
        formValues,
        accessor.slice(0, lastNumberIndex),
        "array"
      ) as any[]
    )
      .map((v) =>
        getFieldValue(v, accessor.slice(lastNumberIndex + 1), "nocheck")
      )
      .filter((v) => !!v);

    if (items.indexOf(value) !== items.lastIndexOf(value)) {
      return "This value is not unique.";
    }
  }

  if (isNumberRange(value)) {
    if (value[0] !== null && value[1] !== null && value[0] > value[1]) {
      return "The range minimum must be less than or equal the range maximum.";
    }
  }

  if (
    config.ui_type === WorkflowConfigUiType.Label &&
    config.field_type === WorkflowConfigDataType.Text &&
    typeof value === "string"
  ) {
    const stringLength = value.length;

    if (
      config.min_length === null &&
      config.max_length !== null &&
      stringLength > config.max_length
    ) {
      return `${config.display_name} length must be no more than ${config.max_length}`;
    }
    if (
      config.min_length !== null &&
      config.max_length === null &&
      stringLength < config.min_length
    ) {
      return `${config.display_name} length must be no less than ${config.min_length}`;
    }
    if (
      (config.min_length !== null && stringLength < config.min_length) ||
      (config.max_length !== null && stringLength > config.max_length)
    ) {
      return `${config.display_name} length must be between ${config.min_length} and ${config.max_length}`;
    }
  }

  if (
    config.ui_type === WorkflowConfigUiType.MultiselectCheckbox &&
    Array.isArray(value)
  ) {
    const numOptionsSelected = value.length;
    if (
      (config.min_num !== null && numOptionsSelected < config.min_num) ||
      (config.max_num !== null && numOptionsSelected > config.max_num)
    ) {
      return `You can only choose up to ${config.max_num} options.`;
    }
  }

  if (isDataTypeANumber(config.field_type) && typeof value === "number") {
    let isGreaterThanMax = false;
    if (config.max_value !== null) {
      if (config.field_type === WorkflowConfigDataType.Percentage) {
        isGreaterThanMax = value > config.max_value / 100;
      } else {
        isGreaterThanMax = value > config.max_value;
      }
    }

    let isLesserThanMin = false;
    if (config.min_value !== null) {
      if (config.field_type === WorkflowConfigDataType.Percentage) {
        isLesserThanMin = value < config.min_value / 100;
      } else {
        isLesserThanMin = value < config.min_value;
      }
    }

    if (Number.isNaN(value)) {
      // noop
    } else if (config.min_value === null && isGreaterThanMax) {
      return `${config.display_name} must be no more than ${config.max_value}`;
    } else if (config.min_value !== null && isLesserThanMin) {
      return `${config.display_name} must be no less than ${config.min_value}`;
    } else if (isLesserThanMin || isGreaterThanMax) {
      return `${config.display_name} must be between ${config.min_value} and ${config.max_value}`;
    }
  }

  if (
    config.field_type === WorkflowConfigDataType.Integer &&
    typeof value === "number"
  ) {
    if (!Number.isNaN(value) && !Number.isSafeInteger(value)) {
      return `${config.display_name} must be an integer value`;
    }
  }

  return null;
}

export const hasValidationErrors = (errors: unknown): boolean => {
  if (errors === null || errors === undefined) {
    return false;
  }

  if (Array.isArray(errors)) {
    return errors.some(hasValidationErrors);
  }

  if (typeof errors === "object" && errors !== null) {
    return Object.values(errors).some(hasValidationErrors);
  }

  return true;
};
