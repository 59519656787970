import { MinusCircleOutlined } from "@ant-design/icons/lib";
import { Divider, Popconfirm } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import SimulationConfigsField from "src/components/SimulationConfigs/SimulationConfigsField";
import SimulationConfigsFieldGroupForm from "src/components/SimulationConfigs/SimulationConfigsFieldGroupForm";
import { useValuesLengthWithPrefilledLinkageUpdate } from "src/components/SimulationConfigs/hooks";
import { getAndWarnDynamicButtonConfig } from "src/components/SimulationConfigs/utils";
import { Accessor, CardData } from "src/networking/types";
import { addFormList, removeFormList } from "src/redux/actions/configs";

export interface Props {
  cardData: CardData;
  accessor: Accessor;
  hiddenConfigActionKeys: string[];
  disabledConfigActionKeys: string[];
}

const FieldGroupList: React.FC<Props> = ({
  cardData,
  accessor,
  hiddenConfigActionKeys,
  disabledConfigActionKeys,
}) => {
  const dispatch = useDispatch();
  const { common_prefix: commonPrefix, children } = cardData;
  const dynamicButtonConfig = getAndWarnDynamicButtonConfig(cardData);
  const minimumGroupSize = 1;

  const [valuesLength, hasPrefilledLinkageValue] =
    useValuesLengthWithPrefilledLinkageUpdate(accessor, cardData);

  return (
    <>
      {[...Array(valuesLength).keys()].map((index) => (
        <React.Fragment key={index}>
          <SimulationConfigsFieldGroupForm
            cardData={cardData}
            accessor={accessor.concat([index])}
            disabled={() => false}
            hiddenConfigActionKeys={hiddenConfigActionKeys}
            disabledConfigActionKeys={disabledConfigActionKeys}
          />
          {children.length > minimumGroupSize && !hasPrefilledLinkageValue && (
            <Popconfirm
              title="Confirm delete item?"
              onConfirm={() => dispatch(removeFormList(accessor, index))}
            >
              <MinusCircleOutlined />
            </Popconfirm>
          )}
          {index < children.length - 1 && <Divider />}
        </React.Fragment>
      ))}
      {dynamicButtonConfig && !hasPrefilledLinkageValue && (
        <SimulationConfigsField
          key={dynamicButtonConfig.config_name.join(".")}
          config={dynamicButtonConfig}
          accessor={accessor.concat(
            dynamicButtonConfig.config_name.slice(commonPrefix.length)
          )}
          onClick={() => {
            dispatch(addFormList(accessor));
          }}
          hiddenConfigActionKeys={hiddenConfigActionKeys}
          disabledConfigActionKeys={disabledConfigActionKeys}
        />
      )}
    </>
  );
};

export default FieldGroupList;
