import { Select } from "antd";
import React from "react";
import PlotlyPlot from "react-plotly.js";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import { makeOption } from "src/components/Sider/utils";
import {
  TIME_SERIES_INTERVAL_TO_DISPLAY,
  TimeSeriesInterval,
} from "src/networking/types";

interface Props {
  loading: boolean;
  error?: Record<string, unknown>;
  title: string;
  data?: [string, number][];
  nameSelection: [string, string][];
  name?: string;
  onChangeName: (newName: string) => void;
  periodSelection?: TimeSeriesInterval[];
  period?: TimeSeriesInterval;
  onChangePeriod: (newPeriod: TimeSeriesInterval) => void;
}

const TimeSeriesCard: React.FC<Props> = ({
  loading,
  error,
  title,
  data,
  nameSelection,
  name,
  onChangeName,
  periodSelection,
  period,
  onChangePeriod,
}) => {
  let contents;
  if (error) {
    contents = <div>An error occured</div>;
  } else if (data === undefined) {
    contents = <div>Please select a sensor / period above</div>;
  } else {
    contents = (
      <PlotlyPlot
        data={[
          {
            x: data.map((d) => new Date(d[0])),
            y: data.map((d) => d[1]),
            type: "scatter",
          },
        ]}
        layout={{
          height: 200,
          margin: { r: 0, b: 20, t: 0, l: 20 },
        }}
      />
    );
  }

  return (
    <WinddeskCard loading={loading} title={title}>
      <div>
        <Select
          placeholder="Select a sensor"
          value={name}
          options={nameSelection.map((n) => makeOption(n[0], n[1]))}
          onChange={onChangeName}
        />
        {periodSelection && (
          <Select
            placeholder="Select a period"
            value={period}
            options={periodSelection.map((p) =>
              makeOption(p, TIME_SERIES_INTERVAL_TO_DISPLAY[p])
            )}
            onChange={onChangePeriod}
          />
        )}
      </div>
      <div>{contents}</div>
    </WinddeskCard>
  );
};

export default TimeSeriesCard;
