import { Col, Row } from "antd";
import React from "react";

import SimulationConfigurationSummary from "src/components/Simulations/SimulationConfigurationSummary";
import SimulationOverview from "src/components/Simulations/SimulationOverview";
import SimulationReport from "src/components/Simulations/SimulationReport";

type Props = {
  projectId: string;
  simulationId: string;
};

const SimulationDashboard: React.FC<Props> = ({ projectId, simulationId }) => (
  <Row gutter={14}>
    <Col span={12}>
      <Row>
        <SimulationOverview />
      </Row>
      <Row>
        <SimulationConfigurationSummary
          projectId={projectId}
          simulationId={simulationId}
        />
      </Row>
    </Col>
    <Col span={12}>
      <Row>
        <SimulationReport projectId={projectId} simulationId={simulationId} />
      </Row>
    </Col>
  </Row>
);

export default SimulationDashboard;
