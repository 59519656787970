import { Tabs } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import colors from "src/colors";
import { ReactComponent as Bell } from "src/components/Monitor/bell.svg";
import { ReactComponent as Check } from "src/components/Monitor/check.svg";
import { ReactComponent as Clipboard } from "src/components/Monitor/clipboard.svg";
import { ReactComponent as Sliders } from "src/components/Monitor/sliders.svg";
import { Project, Site } from "src/networking/types";
import styled from "styled-components";

type Path = "summary" | "site-config" | "alerts" | "quality-control";

const titles: Record<Path, React.ReactNode> = {
  summary: (
    <>
      <Clipboard /> Summary
    </>
  ),
  "site-config": (
    <>
      <Sliders /> Site Config
    </>
  ),
  alerts: (
    <>
      <Bell /> Alerts
    </>
  ),
  "quality-control": (
    <>
      <Check /> Quality Control
    </>
  ),
};

const NavigationTabs = styled(Tabs)`
  & > .ant-tabs-nav {
    margin-bottom: 16px;
  }

  & .ant-tabs-tab {
    margin-left: 32px !important;
  }

  & .ant-tabs-tab:first-child {
    margin-left: 0 !important;
  }

  & .ant-tabs-tab svg > path {
    transition: all 0.3s;
  }

  & .ant-tabs-tab-active svg > path {
    fill: ${colors.primary};
  }

  & .ant-tabs-tab:not(.ant-tabs-tab-active):hover svg > path {
    fill: ${colors.hover};
  }
`;

const NavigationTabContents = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 6px;
  }
`;

interface Props {
  project?: Project;
  site?: Site;
}

const Navigation: React.FC<Props> = ({ site }) => {
  const paths: Path[] = site
    ? ["summary", "site-config", "alerts", "quality-control"]
    : ["summary"];
  const location = useLocation();
  const history = useHistory();

  return (
    <NavigationTabs
      activeKey={location.pathname.slice("/monitor/".length)}
      onChange={(key) => {
        history.push({
          pathname: `/monitor/${key}`,
          search: location.search,
        });
      }}
    >
      {paths.map((path) => (
        <Tabs.TabPane
          key={path}
          tab={<NavigationTabContents>{titles[path]}</NavigationTabContents>}
        />
      ))}
    </NavigationTabs>
  );
};

export default Navigation;
