import {
  Sensor,
  SensorConfiguration,
  SensorType,
} from "../../../networking/types";

const SensorTypePriority: { [name: string]: number } = {
  "Wind Speed": 1,
  "Wind Direction": 2,
  Humidity: 3,
  Pressure: 4,
  Temperature: 5,
  Others: 10,
};

export const getSensorTypePriority = (type: SensorType): number => {
  if (type in SensorTypePriority) {
    return SensorTypePriority[type];
  }
  return SensorTypePriority.Others;
};

export const getSensorTypePriorityComparator = (
  a: SensorType,
  b: SensorType
): number => {
  const ap = getSensorTypePriority(a);
  const bp = getSensorTypePriority(b);
  if (ap === bp) {
    return a > b ? 1 : -1;
  }
  return ap > bp ? 1 : -1;
};

export const getFilteredSensorTypeForDataCoverageHeatmap = (
  sensors?: Sensor[]
): Sensor[] | undefined =>
  sensors?.filter(({ type }) => type in SensorTypePriority);

export const getSortedSensorBySensorTypePriority = (
  sensors?: Sensor[]
): Sensor[] | undefined =>
  sensors?.sort((a, b) => getSensorTypePriorityComparator(a.type, b.type));

export const getSortedSensorConfigurationBySensorTypePriority = (
  sensorConfigs?: SensorConfiguration[]
): SensorConfiguration[] | undefined =>
  sensorConfigs?.sort((a, b) =>
    getSensorTypePriorityComparator(a.sensor_type, b.sensor_type)
  );

export const getSortedSensorConfigurationByStatus = (
  sensor_configs?: SensorConfiguration[]
): SensorConfiguration[] | undefined =>
  sensor_configs?.sort((a, b) =>
    a.status === b.status ? 0 : a.status > b.status ? 1 : -1
  );

export const getPrettySiteType = (siteType: string): string => {
  const siteTypeMapping: { [name: string]: string } = {
    met_mast: "Met Mast",
    lidar: "LiDAR",
    sodar: "SoDAR",
  };
  return siteTypeMapping[siteType];
};
