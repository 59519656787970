import React, { useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { Page } from "src/components/Page";
import MainView from "src/components/Pages/MainView";
import { canManageAllAlerts, canManageHydra } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";

export enum PageContext {
  PROJECT_AND_SITE,
  PROJECT_ONLY,
  SITE_ONLY,
}

const Dashboard: React.FC<Record<string, never>> = () => {
  const [manageAllAlertsPerm, setManageAllAlertsPerm] = useState(false);
  const [manageHydraPerm, setManageHydraPerm] = useState(false);
  const { getTokenSilently, logout } = useAuth0();

  getTokenSilently().then((token: string) => {
    setManageAllAlertsPerm(canManageAllAlerts(token));
    setManageHydraPerm(canManageHydra(token));
  });

  return (
    <Router>
      <Switch>
        {/* Ideally loop through enum to do this instead, but ran into typing issues with Object.entries usage */}
        {manageAllAlertsPerm && (
          <Route path="/alerts">
            <MainView page={Page.Alerts} />
          </Route>
        )}
        {manageHydraPerm && (
          <Route path={["/admin/:projectId", "/admin"]}>
            <MainView page={Page.Admin} />
          </Route>
        )}
        <Route path="/logout">
          {() => logout({ returnTo: window.location.origin })}
        </Route>
        <Route path="/predict">
          <MainView page={Page.Simulate} />
        </Route>
        <Route path="/monitor">
          <MainView page={Page.Monitor} />
        </Route>
        <Route path="/">
          <MainView page={Page.Monitor} />
        </Route>
        <Route path="/mdf">
          <MainView page={Page.Mdf} />
        </Route>
      </Switch>
    </Router>
  );
};

export default Dashboard;
