import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons/lib";
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  message,
} from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MonitoringCardComponent } from "src/components/Monitor/shared/MonitoringCard";
import { Alert, AlertData, getStatusFromAlert } from "src/networking/types";
import {
  createAlerts,
  resolveAlerts,
  shareAlerts,
  snoozeAlerts,
  unresolveAlerts,
} from "src/redux/actions/alerts";
import { GlobalState } from "src/redux/reducers";
import { canManageAllAlerts } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";
import styled from "styled-components";

const CHARACTER_LIMIT = 160;

export enum AlertsCardButton {
  SNOOZE = "SNOOZE",
  RESOLVE = "RESOLVE",
  UNRESOLVE = "UNRESOLVE",
  SHARE = "SHARE",
  CREATE = "CREATE",
}

interface Props {
  title: string;
  alerts: Alert[];
  loading: boolean;
  error?: Record<string, unknown>;
  tableFilters: boolean;
  startingShownColumns?: string[];
  noSelect?: boolean;
  buttonsToShow?: AlertsCardButton[];
  timeZoneOffset: number | string;
}

interface CreateFormProps {
  antdForm: FormInstance;
  onFinish: (data: AlertData) => void;
}

interface SnoozeFormProps {
  antdForm: FormInstance;
  onFinish: (snoozeTime: Moment) => void;
}

interface ResolveFormProps {
  antdForm: FormInstance;
  onFinish: (resolveMessage: string) => void;
}

const AVAILABLE_COLUMNS: Record<string, Record<string, any>> = {
  company: { title: "Company" },
  project: { title: "Project" },
  siteId: { title: "Site ID" },
  sensorId: { title: "Sensor ID" },
  sensorType: { title: "Sensor Type" },
  moduleName: { title: "Module" },
  moduleId: { title: "Module ID" },
  priority: { title: "Priority" },
  activationTime: { title: "Activation Time" },
  nature: { title: "Nature" },
  alertKey: { title: "Key" },
  message: { title: "Message", width: 400 },
  status: { title: "Status" },
  shared: { title: "Shared" },
};

const CreateForm: React.FC<CreateFormProps> = ({ antdForm, onFinish }) => (
  <Form
    form={antdForm}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={onFinish}
    initialValues={{
      shared: false,
      ignore_if_active: false,
      ignore_if_resolved: false,
      ignore_if_snoozed: false,
    }}
  >
    <Form.Item
      label="Company Name"
      labelAlign="right"
      name="company_name"
      rules={[{ required: true }]}
    >
      <Space direction="horizontal">
        <Input />
        <Tooltip title="Make sure to match this with the company the alert is for">
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    </Form.Item>
    <Form.Item
      label="Priority"
      labelAlign="right"
      name="priority"
      rules={[{ required: true }]}
    >
      <Select
        options={["LOW", "MEDIUM", "HIGH"].map((key) => ({
          value: key,
          label: key,
        }))}
      />
    </Form.Item>
    <Form.Item
      label="Nature"
      labelAlign="right"
      name="nature"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Message"
      labelAlign="right"
      name="message"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
    <Form.Item label="Company ID" labelAlign="right" name="company_id">
      <Input />
    </Form.Item>
    <Form.Item
      label="Project ID"
      labelAlign="right"
      name="project_id"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
    <Form.Item label="Project Name" labelAlign="right" name="project_name">
      <Input />
    </Form.Item>
    <Form.Item label="Site Name" labelAlign="right" name="site_name">
      <Input />
    </Form.Item>
    <Form.Item label="Sensor ID" labelAlign="right" name="sensor_id">
      <Input />
    </Form.Item>
    <Form.Item label="Sensor Type" labelAlign="right" name="sensor_type">
      <Input />
    </Form.Item>
    <Form.Item label="Module ID" labelAlign="right" name="module_id">
      <Input />
    </Form.Item>
    <Form.Item label="Module Name" labelAlign="right" name="module_name">
      <Input />
    </Form.Item>
    <Form.Item label="Data" labelAlign="right" name="data">
      <Space direction="horizontal">
        <Input />
        <Tooltip title="JSON encoded">
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    </Form.Item>
    <Form.Item
      label="Shared"
      labelAlign="right"
      name="shared"
      valuePropName="checked"
    >
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
      />
    </Form.Item>
    <Form.Item
      label="Key"
      labelAlign="right"
      name="key"
      rules={[{ required: true }]}
    >
      <Space direction="horizontal">
        <Input />
        <Tooltip title="If an alert of the same key exists, this alert will replace it. Keys from Hydra are of the form title|param1|value1|param2|value2|...">
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    </Form.Item>
    <Form.Item
      label="Ignore if active"
      labelAlign="right"
      name="ignore_if_active"
      valuePropName="checked"
    >
      <Space direction="horizontal">
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
        <Tooltip title="If checked, and alert of the same key exists and is active, it will do nothing">
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    </Form.Item>
    <Form.Item
      label="Ignore if snoozed"
      labelAlign="right"
      name="ignore_if_snoozed"
      valuePropName="checked"
    >
      <Space direction="horizontal">
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
        <Tooltip title="If checked, and alert of the same key exists and is snoozed, it will do nothing">
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    </Form.Item>
    <Form.Item
      label="Ignore if resolved"
      labelAlign="right"
      name="ignore_if_resolved"
      valuePropName="checked"
    >
      <Space direction="horizontal">
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
        <Tooltip title="If checked, and alert of the same key exists and is resolved, it will do nothing">
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    </Form.Item>
    <Form.Item wrapperCol={{ offset: 8, span: 16 }} labelAlign="right">
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
);

const SnoozeForm: React.FC<SnoozeFormProps> = ({ antdForm, onFinish }) => (
  <Form
    form={antdForm}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={({ snoozeTime }) => onFinish(snoozeTime)}
  >
    <Form.Item label="Snooze to" labelAlign="right" name="snoozeTime">
      <DatePicker showTime />
    </Form.Item>
    <Form.Item wrapperCol={{ offset: 8, span: 16 }} labelAlign="right">
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
);

const ResolveForm: React.FC<ResolveFormProps> = ({ antdForm, onFinish }) => (
  <Form
    form={antdForm}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={({ resolveMessage }) => onFinish(resolveMessage)}
  >
    <Form.Item label="Resolve message" labelAlign="right" name="resolveMessage">
      <Input />
    </Form.Item>
    <Form.Item wrapperCol={{ offset: 8, span: 16 }} labelAlign="right">
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
);

const ButtonContainer = styled.div`
  margin: 16px;

  & > * {
    margin-right: 8px;
  }
`;

const AlertsCard: React.FC<Props> = ({
  title,
  alerts,
  loading,
  error,
  tableFilters,
  startingShownColumns,
  noSelect,
  buttonsToShow,
  timeZoneOffset,
}) => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const shareAlertsState = useSelector(
    (state: GlobalState) => state.requests.SHARE_ALERTS_REQUEST
  );
  const unresolveAlertsState = useSelector(
    (state: GlobalState) => state.requests.UNRESOLVE_ALERTS_REQUEST
  );
  const resolveAlertsState = useSelector(
    (state: GlobalState) => state.requests.RESOLVE_ALERTS_REQUEST
  );
  const snoozeAlertsState = useSelector(
    (state: GlobalState) => state.requests.SNOOZE_ALERTS_REQUEST
  );

  const [selectedAlerts, setSelectedAlerts] = useState<string[]>([]);
  const [expandedTextOptions, setExpandedTextOptions] = useState<
    Record<string, string[]>
  >({});
  const [shownColumns, setShownColumns] = useState<string[]>(
    startingShownColumns === undefined
      ? Object.keys(AVAILABLE_COLUMNS)
      : startingShownColumns
  );

  const [token, setToken] = useState<string | undefined>();
  useEffect(() => {
    getTokenSilently().then((t: string) => {
      setToken(t);
    });
  }, [getTokenSilently]);

  let contents;
  let hasContents = true;

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showSnoozeForm, setShowSnoozeForm] = useState(false);
  const [showResolveForm, setShowResolveForm] = useState(false);

  const buttonsMap = {
    [AlertsCardButton.CREATE]: token && canManageAllAlerts(token) && (
      <Button
        key={AlertsCardButton.CREATE}
        style={{ textTransform: "uppercase" }}
        onClick={() => setShowCreateForm(true)}
      >
        Create Alert
      </Button>
    ),
    [AlertsCardButton.SNOOZE]: (
      <Button
        key={AlertsCardButton.SNOOZE}
        disabled={selectedAlerts.length === 0}
        style={{ textTransform: "uppercase" }}
        loading={snoozeAlertsState.isLoading}
        onClick={() => setShowSnoozeForm(true)}
      >
        Snooze Alerts
      </Button>
    ),
    [AlertsCardButton.RESOLVE]: (
      <Button
        key={AlertsCardButton.RESOLVE}
        disabled={selectedAlerts.length === 0}
        style={{ textTransform: "uppercase" }}
        loading={resolveAlertsState.isLoading}
        onClick={() => setShowResolveForm(true)}
      >
        Resolve Alerts
      </Button>
    ),
    [AlertsCardButton.UNRESOLVE]: (
      <Button
        key={AlertsCardButton.UNRESOLVE}
        disabled={selectedAlerts.length === 0}
        style={{ textTransform: "uppercase" }}
        loading={unresolveAlertsState.isLoading}
        onClick={() =>
          token && dispatch(unresolveAlerts(selectedAlerts, token))
        }
      >
        Unresolve Alerts
      </Button>
    ),
    [AlertsCardButton.SHARE]: (
      <Button
        key={AlertsCardButton.SHARE}
        disabled={selectedAlerts.length === 0}
        style={{ textTransform: "uppercase" }}
        loading={shareAlertsState.isLoading}
        onClick={() => token && dispatch(shareAlerts(selectedAlerts, token))}
      >
        Share Alerts
      </Button>
    ),
  };
  const buttons = (
    <ButtonContainer>
      {(
        buttonsToShow ?? [
          AlertsCardButton.CREATE,
          AlertsCardButton.SNOOZE,
          AlertsCardButton.RESOLVE,
          AlertsCardButton.UNRESOLVE,
          AlertsCardButton.SHARE,
        ]
      ).map((b) => buttonsMap[b])}
    </ButtonContainer>
  );

  if (error) {
    contents = <div>An error occured</div>;
    hasContents = false;
  } else {
    const data: Record<string, any>[] = alerts.map((alert) => ({
      key: alert.id,
      alertKey: alert.key,
      company: `${alert.company_name} (${alert.company_id})`,
      project: `${alert.project_name} (${alert.project_id})`,
      siteId: alert.site_name,
      sensorId: alert.sensor_id,
      sensorType: alert.sensor_type,
      moduleName: alert.module_name,
      moduleId: alert.module_id,
      priority: alert.priority,
      activationTime: moment
        .unix(alert.created_at)
        .utcOffset(timeZoneOffset)
        .format("YYYY-MM-DD HH:mm"),
      nature: alert.nature,
      message: alert.message,
      status: getStatusFromAlert(alert),
      shared: alert.shared,
    }));

    const tableColumns: Record<string, any>[] = Object.entries(
      AVAILABLE_COLUMNS
    )
      .filter(([k, _v]) => shownColumns.includes(k))
      .map(([k, v]) => ({
        ...v,
        key: k,
        dataIndex: k,
        filters: tableFilters
          ? [...new Set(alerts.map((_, i) => data[i][k]))].map((t) => ({
              text: String(t),
              value: String(t),
            }))
          : undefined,
        onFilter: tableFilters
          ? (value: string, record: any) => record[k] === value
          : undefined,
        render: (
          text: unknown,
          record: { key: string; [k: string]: string }
        ) => {
          if (text === null) {
            return text;
          }

          const stringText = String(text);
          if (stringText.length <= CHARACTER_LIMIT) {
            return stringText;
          }

          const expandOptions = expandedTextOptions[record.key];
          return expandOptions?.includes(k) ? (
            <>
              <p>{stringText} </p>
              <Button
                type="link"
                size="small"
                onClick={() =>
                  setExpandedTextOptions({
                    ...expandedTextOptions,
                    [record.key]: expandOptions
                      .slice(0, expandOptions.indexOf(k))
                      .concat(
                        expandOptions.slice(expandOptions.indexOf(k) + 1)
                      ),
                  })
                }
              >
                Less
              </Button>
            </>
          ) : (
            <>
              <p>{`${stringText.slice(0, CHARACTER_LIMIT)}...`}</p>
              <Button
                type="link"
                size="small"
                onClick={() =>
                  setExpandedTextOptions({
                    ...expandedTextOptions,
                    [record.key]: (expandOptions ?? []).concat([k]),
                  })
                }
              >
                More
              </Button>
            </>
          );
        },
      }));

    contents = (
      <>
        {!noSelect && (
          <Select
            labelInValue
            mode="multiple"
            defaultValue={Object.entries(AVAILABLE_COLUMNS).flatMap(
              ([k, { title: label }]) =>
                shownColumns.includes(k) ? [{ value: k, label, key: k }] : []
            )}
            style={{ width: "100%" }}
            placeholder="Add columns..."
            onChange={(vals) => setShownColumns(vals.map((v) => v.value))}
          >
            {Object.entries(AVAILABLE_COLUMNS)
              .filter(([k, _v]) => !shownColumns.includes(k))
              .map(([k, { title: label }]) => (
                <Select.Option key={k} value={k}>
                  {label}
                </Select.Option>
              ))}
          </Select>
        )}
        <Table
          columns={tableColumns}
          dataSource={data}
          size="small"
          pagination={{ hideOnSinglePage: true, defaultPageSize: 5 }}
          rowSelection={{
            selectedRowKeys: selectedAlerts,
            onChange: (keys, rows) =>
              setSelectedAlerts(rows.map(({ key }) => key)),
          }}
        />
      </>
    );
  }

  const [createAntdForm] = Form.useForm();
  const [snoozeAntdForm] = Form.useForm();
  const [resolveAntdForm] = Form.useForm();

  return (
    <>
      <MonitoringCardComponent loading={loading} title={title}>
        {contents}
        {hasContents && buttons}
      </MonitoringCardComponent>
      <Modal
        title="Create alert"
        visible={showCreateForm}
        footer={null}
        onCancel={() => setShowCreateForm(false)}
      >
        <CreateForm
          antdForm={snoozeAntdForm}
          onFinish={(data) => {
            if (token) {
              dispatch(createAlerts(data, token));
              setShowCreateForm(false);
              createAntdForm.resetFields();
              message.success("Alert created!");
            }
          }}
        />
      </Modal>
      <Modal
        title="Snooze alerts"
        visible={showSnoozeForm}
        footer={null}
        onCancel={() => setShowSnoozeForm(false)}
      >
        <SnoozeForm
          antdForm={snoozeAntdForm}
          onFinish={(snoozeTime) => {
            if (token) {
              dispatch(snoozeAlerts(selectedAlerts, snoozeTime.unix(), token));
              setSelectedAlerts([]);
              setShowSnoozeForm(false);
              snoozeAntdForm.resetFields();
              message.success("Alerts snoozed!");
            }
          }}
        />
      </Modal>
      <Modal
        title="Resolve alerts"
        visible={showResolveForm}
        footer={null}
        onCancel={() => setShowResolveForm(false)}
      >
        <ResolveForm
          antdForm={resolveAntdForm}
          onFinish={(resolveMessage) => {
            if (token) {
              dispatch(resolveAlerts(selectedAlerts, resolveMessage, token));
              setSelectedAlerts([]);
              setShowResolveForm(false);
              resolveAntdForm.resetFields();
              message.success("Alerts resolved!");
            }
          }}
        />
      </Modal>
    </>
  );
};

export default AlertsCard;
