import { Typography } from "antd";
import React from "react";
import MonitoringCard from "src/components/Monitor/shared/MonitoringCard";

import { Site } from "src/networking/types";
import { getSiteDataCoverage } from "src/networking/utils";
import { getPrettySiteType } from "../shared/utils";

interface Props {
  sites: Site[];
  loading: boolean;
  error?: Record<string, unknown>;
}

const OverallStatisticsCard: React.FC<Props> = ({ sites, loading, error }) => {
  const tableColumns = [
    { title: "Site ID", dataIndex: "site", key: "site" },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: getPrettySiteType,
    },
    { title: "Latitude", dataIndex: "lat", key: "lat" },
    { title: "Longitude", dataIndex: "lng", key: "lng" },
    {
      title: "Measurement Period",
      dataIndex: "measurementPeriod",
      key: "measurementPeriod",
    },
    {
      title: "Data Coverage (Wind speed) [%]",
      dataIndex: "dataCoverage",
      key: "dataCoverage",
      render: (coverage: number | undefined) =>
        coverage === undefined ? "-" : `${(coverage * 100).toFixed(2)}%`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <Typography.Text type={status === "Operating" ? "success" : "danger"}>
          {status}
        </Typography.Text>
      ),
    },
  ];

  const data = sites.map((site, idx) => ({
    key: idx,
    site: site.name,
    type: site.type,
    status: site.status,
    lat: site.position.coordinates.lat,
    lng: site.position.coordinates.lng,
    dataCoverage: getSiteDataCoverage(site),
  }));

  return (
    <MonitoringCard
      title="All Sites Summary"
      tableColumns={tableColumns}
      data={data}
      loading={loading}
      error={error}
    />
  );
};

export default OverallStatisticsCard;
