import React from "react";
import ImageCard from "src/components/Monitor/shared/ImageCard";

interface Props {
  projectId: string;
  siteName: string;
}

const SiteConfigurationImageCard: React.FC<Props> = ({
  projectId,
  siteName,
}) => (
  <ImageCard
    url={`/v1/projects/${projectId}/sites/${siteName}/site_configuration_image?workflow_type=monitor_full`}
    title="Site Configuration Image"
  />
);

export default SiteConfigurationImageCard;
