/* eslint-disable camelcase */
import { isPlainObject } from "lodash";
import { Moment } from "moment";

export type Country = string;

interface WithTime {
  created?: number;
  updated?: number;
}

interface WithId {
  id: string;
}

interface DataObject extends WithTime, WithId {}

export interface Position {
  coordinates: {
    lat: number;
    lng: number;
  };
}

export type SensorType =
  | "Wind Speed"
  | "Wind Direction"
  | "Humidity"
  | "Pressure"
  | "Temperature";

export interface DataCoverage {
  start_time: number;
  end_time: number;
}

export interface Sensor extends DataObject {
  object: "sensor";

  site: string;
  sensor_weak_name: string;
  type: SensorType;
  height: number;
  brand: string;
  model: string;
  status: string;
  data_coverage_ratio: number;
  data_coverage: DataCoverage;
  data_coverage_timeseries: [string, number][];
}

export interface BasicSite {
  name: string;
  type: "met_mast" | "lidar" | "sodar";
  position: Position;
}

export enum LocationCoordinateSystem {
  UTM = "utm",
  LAT_LONG = "lat_long",
}

export enum LocationDatum {
  WGS84 = "wgs84",
}

export interface Location {
  coordinate_system: LocationCoordinateSystem;
  datum: LocationDatum;

  // UTM
  utm_x?: number;
  utm_y?: number;
  utm_zone?: number;
  utm_zone_lettering?: string;
  utm_zone_ns?: string;

  // LATLONG
  longitude?: number;
  latitude?: number;
}

export interface Project extends DataObject {
  name: string;
  timezone: string;
  country_code: string;
  country_name: string;
  currency_code?: string;
  currency_name?: string;
  target_operational_date?: string;
  location?: Location;
  notes: string;
  data_coverage: number;
  sites: BasicSite[] | null;
  has_measurements: boolean;
}

export enum WorkflowType {
  Prospect = "prospect",
  Measure = "measure",
  MonitorDaily = "monitor_daily",
  MonitorFull = "monitor_full",
  MonitorInit = "monitor_init",
  Predict = "predict",
}

export interface Workflow extends DataObject {
  project_id: string;
  name: string;
  type: WorkflowType;
}

export enum TimeSeriesInterval {
  MIN_10 = "min_10",
  HOURLY = "hourly",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export const TIME_SERIES_INTERVAL_TO_DISPLAY = {
  [TimeSeriesInterval.MIN_10]: "10 minutes",
  [TimeSeriesInterval.HOURLY]: "Hourly",
  [TimeSeriesInterval.DAILY]: "Daily",
  [TimeSeriesInterval.WEEKLY]: "Weekly",
  [TimeSeriesInterval.MONTHLY]: "Monthly",
};

export enum WorkflowConfigUiType {
  Label = "Label",
  LabelWithPlusMinus = "Label_with_plus_minus",
  Toggle = "Toggle",
  SingleCheckbox = "Single_checkbox",
  DatePicker = "Date_picker",
  DateTimePicker = "Date_time_picker",
  FileInput = "File_input",
  MultipleFileInput = "Multiple_File_input",
  RangeNumber = "Range_Number",
  DateRange = "Date_range",
  DateTimeRange = "Date_time_range",
  MultiselectCheckbox = "Multiselect_Checkbox",
  Dropdown = "Dropdown",
  SegmentedControl = "Segmented_controls",
  Radio = "Radio",
  Table = "Table",
  Button = "Button",
  Hyperlink = "Hyperlink",
  TextArea = "TextArea",
}

export enum WorkflowConfigValueType {
  Action = "Action",
  OptionSelector = "OptionSelector",
  Range = "Range",
  Single = "Single",
  Table = "Table",
}

export enum WorkflowConfigDataType {
  Text = "Text",
  Integer = "Integer",
  Decimal = "Decimal",
  FilePath = "FilePath",
  SelectionList = "Selection of List",
  Table = "Table",
  Boolean = "Boolean",
  Date = "Date = 6",
  Percentage = "Percentage",
}

export enum WorkflowConfigLevel {
  Primary = "Primary",
  Secondary = "Secondary",
  TraverseOnly = "Traverse Only",
  NotVisible = "Not Visible",
}

export enum WorkflowConfigCardType {
  Empty = "",
  Tab = "list_tabs",
  TableColBasedFields = "table_growing_cols",
  TableRowBasedFields = "table_growing_rows",
  List = "list_vertical",
}

export enum WorkflowConfigActionType {
  Empty = "",

  SetDisable = "Set Disable",
  SetEnable = "Set Enable",

  SetHidden = "Set Hidden",
  SetVisible = "Set Visible",
}

export interface WorkflowConfigField {
  config_level: WorkflowConfigLevel;
  config_name: string[];
  config_nature: string[];
  display_name: string;
  suggestions: string;
  tooltip: string;
  url: string;

  ui_type: WorkflowConfigUiType;
  value_type: WorkflowConfigValueType;
  field_type: WorkflowConfigDataType;
  card_ui_type: WorkflowConfigCardType;

  multiple: boolean;
  unique: boolean;
  options: string | string[];
  min_length: number | null;
  max_length: number | null;
  min_value: number | null;
  max_value: number | null;
  interval: number | null;
  min_num: number | null;
  max_num: number | null;
  title_linkage: string[];
  references: string;

  prefilled_parent_linkage: string[];
  prefilled_child_linkage: string[];

  config_action: WorkflowConfigActionType;
  config_action_group_selection: string | null;
  config_action_group: string[];
}

export interface CardData {
  name: string;
  common_prefix: string[];
  children: (WorkflowConfigField | CardData)[];
}

export const isCardData = (
  card: WorkflowConfigField | CardData
): card is CardData =>
  "name" in card && "common_prefix" in card && "children" in card;

export interface PresentationConfig {
  subworkflows: CardData[];
}

export interface SiteConfiguration {
  elevation: number;
  height: number;
  datum: string;
  mast_type: string;
  logger_brand: string;
  timezone: string;
  zone: string;
}

export interface BasicSensor {
  id: string;
}

export interface Site extends WithTime {
  object: "site";

  name: string;
  type: "met_mast" | "lidar" | "sodar";
  country: Country;
  timezone: string;

  project: string;
  position: Position;
  status: "Operating" | "Inactive";

  configuration: SiteConfiguration;

  sensors: Sensor[] | undefined;
  ws_data_coverage_ratio: number;
  data_coverage_ratio: number;
  representative_sensor_deseasoned_mws: number | null;
  representative_sensor_monthly_mws: [month: string, mean: number][];
  representative_sensor_height: number;
  device_orientation_actual: number | null;
  device_orientation_device: number | null;
  start_time: string | null;
  end_time: string | null;
  window_height: number | null;
  measurement_heights_agl: number[] | null;
  logger_averaging_period: number | null;
  logger_sampling_rate: number | null;
  logger_model: string | null;
  logger_serial: string | null;
  calibration_certificate_id: string | null;
  calibration_date: string | null;
  calibration_facility: string | null;
  calibration_reference: string | null;
  flow_correction_settings: string | null;
  project_time_zone: string | null;
}

export type AlertPriority = "LOW" | "MEDIUM" | "HIGH";

export interface AlertData {
  company_name: string;
  nature: string;
  message: string;
  key: string;
  priority: AlertPriority;
  ignore_if_active: boolean;
  ignore_if_snoozed: boolean;
  ignore_if_resolved: boolean;
  shared?: boolean;
  data?: string;
  company_id?: string | null;
  project_id?: string | null;
  project_name?: string | null;
  site_name?: string | null;
  module_id?: string | null;
  module_name?: string | null;
  sensor_id?: string | null;
  sensor_type?: string | null;
}

export interface Alert {
  id: string;
  created_at: number;
  updated_at: number;
  triggered_by_id: string;
  triggered_by_name: string;
  company_id: string | null;
  company_name: string;
  project_id: string | null;
  project_name: string | null;
  site_name: string | null;
  sensor_id: string | null;
  sensor_type: string | null;
  module_id: string | null;
  module_name: string | null;
  priority: AlertPriority;
  key: string;
  nature: string;
  message: string;
  data: string;
  shared: boolean;
  resolved_by_id?: string;
  resolved_by_name?: string;
  resolve_message?: string;
  resolve_time?: number;
  snooze_until_time?: number;
}

interface ConfigurationChangeDetails {
  timestamp: number;
  difference: string;
}

export interface ConfigurationChange extends DataObject {
  site_name: string;
  details: ConfigurationChangeDetails[];
}

export interface SensorConfiguration extends DataObject {
  start_timestamp: string;
  end_timestamp: string;
  logger_offset?: number;
  logger_slope?: number;
  target_offset?: number;
  target_slope?: number;
  calibration_date?: string;
  calibration_facility?: string;
  calibration_reference?: string;
  calibration_certificate_id?: string;
  h_boom_diameter?: number;
  h_boom_length?: number;
  v_boom_diameter?: number;
  v_boom_length?: number;
  logger_boom_orientation?: number;
  target_boom_orientation?: number;
  logger_vane_mounting_angle?: number;
  target_vane_mounting_angle?: number;
  meas_net?: boolean;
  true_north?: boolean;
  applied_original_calibration?: boolean;
  time_adjusted_daylight_savings?: boolean;
  sensor_brand?: string;
  sensor_model?: string;
  sensor_serial?: string;
  sensor_id: string;
  sensor_weak_name: string;
  sensor_height: number;
  site_name: string;
  sensor_data_coverage_ratio: number;
  sensor_type: SensorType;
  status: string;
}

export interface OffsetData {
  sensor: string;
  comparison_site: string;
  comparison_sensor: string;
  offset: number;
}

export interface MissingData {
  "Sensor Name": string;
  "Sensor ID": string;
  Start: string;
  End: string;
  "Duration (days)": number;
}

export interface ExcludedData {
  "Sensor Name": string;
  "Sensor ID": string;
  Start: string;
  End: string;
  "Reason for exclusion": string;
  "Duration (days)": number;
}

export const getStatusFromAlert = (alert: Alert): string => {
  if (alert.resolved_by_id) {
    return `Resolved by ${alert.resolved_by_name} with message "${alert.resolve_message}"`;
  }

  if (alert.snooze_until_time) {
    return `Snoozed until ${new Date(alert.snooze_until_time * 1000)}`;
  }

  return `Triggered by ${alert.triggered_by_name}`;
};

export type BaseValue = string | number | boolean | Moment | null;
export type Value = Data | BaseValue;
export type Dict = [key: Value, value: Value][];
export type Dataclass = Record<string, Value>;
export type List = Value[];
export type Payload = List | Dict | Dataclass | BaseValue;

export interface Data {
  __meta__: string;
  __payload__: Payload;
  __type__: string;
  __keys__?: string[];
  __options__?: string[];
}

export interface Enum {
  keys: string[];
  value: BaseValue;
}

export interface ModelGeneric<T> {
  [key: string]: T | T[];
}

export type Model = ModelGeneric<Model> | BaseValue | Enum;

export const isData = (value: Value): value is Data =>
  value !== undefined &&
  value !== null &&
  // eslint-disable-next-line no-underscore-dangle
  (value as Data).__payload__ !== undefined;

export const isEnum = (value: Model): value is Enum =>
  value !== undefined &&
  value !== null &&
  (value as Enum).keys !== undefined &&
  Array.isArray((value as Enum).keys); // keys is an inbuilt function for javascript object, our definition of keys is an array

export interface WorkflowConfig {
  configId: number;
  configCommitId: number;
  config: ModelGeneric<Model>;
  locked: boolean;
  dynamicOptions: Record<string, string[]>;
}

export interface Document {
  id: number;
  project_id: number;
  workflow_id: number;
  author: string;
  type: string;
  traverse_document_id: string;
  title: string;
  revision: string;
  issue_time: number;
  file_name: string;
}

export interface StorageFile {
  name: string;
  size: string;
  modified: number;
  id: string;
}

export const isStorageFile = (value: unknown): value is StorageFile =>
  isPlainObject(value) &&
  typeof (value as StorageFile).name === "string" &&
  typeof (value as StorageFile).size === "string" &&
  typeof (value as StorageFile).modified === "number" &&
  typeof (value as StorageFile).id === "string";

export type Accessor = (string | number)[];

export interface Diff {
  accessor: Accessor;
  previousValue: any;
  currentValue: any;
}

export const isDiff = (value: unknown): value is Diff =>
  isPlainObject(value) &&
  Array.isArray((value as Diff).accessor) &&
  (value as Diff).accessor.every((v) =>
    ["string", "number"].includes(typeof v)
  );

export interface DiffResult {
  diffs: Diff[][];
  newest_config_commit_id: number;
}

export const isDiffResult = (value: unknown): value is DiffResult =>
  isPlainObject(value) &&
  Array.isArray((value as DiffResult).diffs) &&
  (value as DiffResult).diffs.every((ds) => ds.every(isDiff)) &&
  typeof (value as DiffResult).newest_config_commit_id === "number";
