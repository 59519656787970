// TODO
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  appendFlagData,
  getFlagData,
  getTimeSeries,
  listTimeSeries,
} from "../../redux/actions/mdf";

import {
  drawGraphCorr,
  drawGraphLine,
  drawGraphRatio,
  updateGraphLine,
  updateView,
} from "../../redux/actions/mdf_dashboard";

import { GlobalState } from "../../redux/reducers";
import MdfState from "../../redux/reducers/mdf_dashboard";
import { useAuth0 } from "../../utils/react-auth0-spa";

import MdfConfig from "./MdfConfig";
import MdfGraphLine from "./MdfGraphLine";
import MdfGraphPlot from "./MdfGraphPlot";

import "src/components/Mdf/MdfDashboard.css";

function getTstamp(date: any) {
  const diff = new Date().getTimezoneOffset() * 1000 * 60;
  return new Date(date.getTime() - diff).toISOString().slice(0, 16);
}

export const MdfViewSetup = (() => {
  function updateTstamp(tstamp0 = "", tstamp1 = "") {
    params.tstamp0 = tstamp0;
    params.tstamp1 = tstamp1;

    if (params.tstamp0Fn) params.tstamp0Fn(tstamp0);
    if (params.tstamp1Fn) params.tstamp1Fn(tstamp1);

    const dtime0 = tstamp0 ? new Date(tstamp0) : undefined;
    const dtime1 = tstamp1 ? new Date(tstamp1) : undefined;
    if (dtime0 && dtime1 && MdfViewSetup.lineXaxis) {
      MdfViewSetup.lineXaxis.domain([dtime0, dtime1]);
    } else {
      MdfViewSetup.lineXaxis = null;
    }

    MdfState.dispatch(updateGraphLine([], dtime0, dtime1));
  }

  function updateSelection() {
    if (params.selRange.length === 2) {
      params.selTstamp0(getTstamp(new Date(params.selRange[0])));
      params.selTstamp1(getTstamp(new Date(params.selRange[1])));
    } else {
      params.selTstamp0("");
      params.selTstamp1("");
    }
  }

  const params: any = {
    fps: 24,
    maxpts: 1000,

    clrCorrHlight: [0.7, 0.7, 1, 1],
    clrCorrs: [
      [0, 0, 0, 1],
      [0, 1, 0, 1],
      [1, 1, 0, 1],
      [1, 0, 0, 1],
    ],
    clrFlags: ["#0f09", "#ff09", "#f009"],
    clrSelection: "#ccc9",

    zoomall: true,
    plotflagged: false,

    w: 600,
    h: 300,
    margin: [30, 10, 70, 50],
    selRange: [] as number[],
    ratioCutoff: 1,

    plotTime: 0,
    flagbin: 0,
    tstamp0: "",
    tstamp1: "",
    tstamp0Fn: () => {},
    tstamp1Fn: () => {},
    updateTstamp,

    updateSelection,
    selTstamp0: () => {},
    selTstamp1: () => {},

    lineZoom: null as any,
    lineXaxis: null as any,
  };
  params.flagbin = params.w;
  params.plotTime = 1000 / params.fps;

  return params;
})();

const MdfTimeSlicer: React.FC = () => {
  const [tstamp0, setTstamp0] = useState(MdfViewSetup.tstamp0);
  MdfViewSetup.tstamp0Fn = setTstamp0;

  const [tstamp1, setTstamp1] = useState(MdfViewSetup.tstamp1);
  MdfViewSetup.tstamp1Fn = setTstamp1;

  function shiftSlice(diff: number) {
    if (!tstamp0 || !tstamp1) return;

    MdfViewSetup.updateTstamp(
      getTstamp(new Date(new Date(tstamp0).getTime() + diff)),
      getTstamp(new Date(new Date(tstamp1).getTime() + diff))
    );
  }

  return (
    <>
      <div>
        <label htmlFor="startTimestamp">start timestamp</label>
        <br />
        <input
          id="startTimestamp"
          type="datetime-local"
          value={tstamp0}
          onChange={(evt: any) => {
            setTstamp0(evt.target.value);
            MdfViewSetup.tstamp0 = evt.target.value;
          }}
        />
      </div>
      <div>
        <label htmlFor="endTimestamp">end timestamp</label>
        <br />
        <input
          id="endTimestamp"
          type="datetime-local"
          value={tstamp1}
          onChange={(evt: any) => {
            setTstamp1(evt.target.value);
            MdfViewSetup.tstamp1 = evt.target.value;
          }}
        />
      </div>
      <button
        type="button"
        onClick={() => MdfViewSetup.updateTstamp(tstamp0, tstamp1)}
      >
        update interval
      </button>
      <button type="button" onClick={() => MdfViewSetup.updateTstamp("", "")}>
        clear interval
      </button>
      <button
        type="button"
        onClick={() => shiftSlice(1000 * 60 * 60 * 24 * -7)}
      >
        &lt;&lt;
      </button>
      <button
        type="button"
        onClick={() => shiftSlice(1000 * 60 * 60 * 24 * -1)}
      >
        &lt;
      </button>
      <button type="button" onClick={() => shiftSlice(1000 * 60 * 60 * 24 * 1)}>
        &gt;
      </button>
      <button type="button" onClick={() => shiftSlice(1000 * 60 * 60 * 24 * 7)}>
        &gt;&gt;
      </button>
    </>
  );
};

const MdfSelector: React.FC = () => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const [tstamp0, tstamp0Fn] = useState("");
  const [tstamp1, tstamp1Fn] = useState("");

  MdfViewSetup.selTstamp0 = tstamp0Fn;
  MdfViewSetup.selTstamp1 = tstamp1Fn;

  const [errtype, errtypeFn] = useState("SENSOR_ICING");
  const [errimpt, errimptFn] = useState(0);

  return (
    <>
      <div className="mdf__bar" style={{ display: "flex" }}>
        <div>
          <label htmlFor="selectionStart">selection start</label>
          <br />
          <input
            id="selectionStart"
            type="datetime-local"
            value={tstamp0}
            onChange={(evt: any) => {
              tstamp0Fn(evt.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="selectionEnd">selection end</label>
          <br />
          <input
            id="selectionEnd"
            type="datetime-local"
            value={tstamp1}
            onChange={(evt: any) => {
              tstamp1Fn(evt.target.value);
            }}
          />
        </div>
        <button
          type="button"
          onClick={() => {
            if (tstamp0 && tstamp1) {
              MdfViewSetup.selRange = [
                new Date(
                  new Date(tstamp0).getTime() - new Date().getTimezoneOffset()
                ).getTime(),
                new Date(
                  new Date(tstamp1).getTime() - new Date().getTimezoneOffset()
                ).getTime(),
              ];
              MdfState.dispatch(updateView());
            }
          }}
        >
          update selection
        </button>
      </div>
      <div className="mdf__bar" style={{ display: "flex" }}>
        <div>
          <label htmlFor="errorType">error type</label>
          <br />
          <input
            id="errorType"
            value={errtype}
            onChange={(evt: any) => {
              errtypeFn(evt.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="severity">severity (0 lowest)</label>
          <br />
          <input
            id="severity"
            type="number"
            value={errimpt}
            onChange={(evt: any) => {
              errimptFn(evt.target.value);
            }}
          />
        </div>
        <button
          type="button"
          onClick={() => {
            const graphs = MdfState.getState().graphLines;
            if (graphs.length === 0 || !errtype || !tstamp0 || !tstamp1) return;

            getTokenSilently().then((token: string) => {
              dispatch(
                appendFlagData(
                  token,
                  graphs[0].sensors,
                  tstamp0,
                  tstamp1,
                  errimpt,
                  errtype
                )
              );
            });
            alert(`${tstamp0}\n${tstamp1}\n\n${graphs[0].sensors.join("\n")}`);
          }}
        >
          add flags for all sensors
        </button>
      </div>
    </>
  );
};

const MdfSetup: React.FC = () => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const sensors = useSelector((state: GlobalState) => state.mdf.sensors || []);
  const timeseries = useSelector(
    (state: GlobalState) => state.mdf.timeseries || {}
  );

  const [ratioDir, ratioFnDir] = useState("");
  const [ratio0Vals, ratio0Fn] = useState([] as string[]);
  const [ratioVals, ratioFn] = useState([] as string[][]);

  const [line0, line0fn] = useState("");
  const [lineVals, lineFn] = useState([] as string[]);

  const [corr1, corr1fn] = useState("");
  const [corr2, corr2fn] = useState("");
  const [corrVals, corrFn] = useState([] as string[][]);

  useEffect(() => {
    getTokenSilently().then((token: string) => {
      dispatch(listTimeSeries(token));
    });
  }, [dispatch, getTokenSilently]);

  function removeItem(list: any[], idx: number, listFn: any) {
    return (
      <button
        type="button"
        style={{ display: "inline" }}
        onClick={() => {
          list.splice(idx, 1);
          listFn([...list]);
        }}
      >
        {" "}
        ( x )
      </button>
    );
  }

  function getData(chosen: string[]) {
    const toget: string[] = [];
    for (let i = 0; i < chosen.length; i += 1) {
      if (!timeseries[chosen[i]]) toget.push(chosen[i]);
    }

    getTokenSilently().then((tkn: string) => {
      // TODO: we always want to get new flags
      // because, data is fixed, but flag is not
      // but now we just simple simple assume flag is fixed also
      if (toget.length) {
        dispatch(getFlagData(tkn, toget));
        dispatch(getTimeSeries(tkn, toget));
      }
    });
  }

  function genOptions() {
    return sensors.length === 0 ? (
      <option value="">--load some data file--</option>
    ) : (
      sensors.map((val) => <option key={val}>{val}</option>)
    );
  }

  function drawLines() {
    getData(lineVals);
    const { tstamp0 } = MdfViewSetup;
    const { tstamp1 } = MdfViewSetup;

    if (tstamp0 && tstamp1) {
      MdfState.dispatch(
        drawGraphLine(lineVals, new Date(tstamp0), new Date(tstamp1))
      );
    } else {
      MdfState.dispatch(drawGraphLine(lineVals, undefined, undefined));
    }
  }

  function drawCorrs() {
    // TODO: this is cheat spam to get corr graph
    if (corrVals.length === 0) return;

    getData(corrVals.reduce((a, b) => a.concat(b), []));
    MdfState.dispatch(drawGraphCorr(corrVals));
  }

  function drawRatio() {
    if (ratioVals.length === 0) return;
    getData(ratioVals.reduce((a: string[], b: string[]) => a.concat(b), []));
    MdfState.dispatch(drawGraphRatio(ratioVals));
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="mdf-controls mdf-controls--end">
          <span>line graphs</span>
          <br />
          <select value={line0} onChange={(e: any) => line0fn(e.target.value)}>
            {genOptions()}
          </select>
          <br />
          <button
            type="button"
            onClick={() => {
              if (line0) {
                lineFn(lineVals.concat([line0]));
              }
              drawLines();
            }}
          >
            add line graph
          </button>
        </div>
        <div className="mdf-controls mdf-controls--end">
          <span>correlation plots</span>
          <br />
          <select
            value={corr1}
            onChange={(evt: any) => corr1fn(evt.target.value)}
          >
            {genOptions()}
          </select>
          <br />
          <select
            value={corr2}
            onChange={(evt: any) => corr2fn(evt.target.value)}
          >
            {genOptions()}
          </select>
          <br />
          <button
            type="button"
            onClick={() => {
              if (corr1 && corr2) {
                corrFn(corrVals.concat([[corr1, corr2]]));
              }
              drawCorrs();
            }}
          >
            add corr plot
          </button>
        </div>
        <div
          className={`mdf-controls${
            ratioVals.length > 0 ? "" : " mdf-controls--end"
          }`}
        >
          <span>direction</span>
          <br />
          <select
            value={ratioDir}
            onChange={(evt: any) => ratioFnDir(evt.target.value)}
          >
            {genOptions()}
          </select>
          <br />
          <span>ratio plots</span>
          <br />
          <select
            value={ratio0Vals}
            multiple
            onChange={(evt: any) =>
              ratio0Fn(
                Array.from(
                  evt.target.selectedOptions,
                  (option: any) => option.value
                )
              )
            }
          >
            {genOptions()}
          </select>
          <br />
          <button
            type="button"
            onClick={() => {
              if (ratioDir !== "" && ratio0Vals.length === 2) {
                ratioFn(
                  ratioVals.concat([[ratioDir, ratio0Vals[0], ratio0Vals[1]]])
                );
              }
              drawRatio();
            }}
          >
            add ratio plot
          </button>
        </div>
      </div>
      {lineVals.length > 0 && (
        <h1>
          <br />
          line graph
        </h1>
      )}
      {lineVals.map((d: string, i: number) => (
        <span key={i}>
          {d}
          {removeItem(lineVals, i, lineFn)}
          <br />
        </span>
      ))}
      {corrVals.length > 0 && (
        <h1>
          <br />
          corr plots
        </h1>
      )}
      {corrVals.map((vals: string[], idx: number) => (
        <span key={idx}>
          - {vals[0]} VS {vals[1]}
          {removeItem(corrVals, idx, corrFn)}
          <br />
        </span>
      ))}
      {ratioVals.length > 0 && (
        <h1>
          <br />
          ratio plots
        </h1>
      )}
      {ratioVals.map((vals: string[], idx: number) => (
        <span key={idx}>
          - {vals[1]} VS {vals[2]} ({vals[0]})
          {removeItem(ratioVals, idx, ratioFn)}
          <br />
        </span>
      ))}
      <br />
      <button
        type="button"
        onClick={() => {
          drawLines();
          drawCorrs();
          drawRatio();
        }}
      >
        draw all
      </button>
    </>
  );
};

const MdfDashboard: React.FC = () => {
  const [page, setPageFn] = useState(0);

  function setPage(idx: number) {
    setPageFn(idx);

    const slicer = [0, 1, 1, 1, 0];

    // NOTE: doing this so i dun need to load stuff all over the place
    // because it will trigger reload
    const mdfSetup = document.getElementById("mdf__setup");
    if (mdfSetup) {
      mdfSetup.style.display = idx === 0 ? "block" : "none";
    }

    const mdfSlicer = document.getElementById("mdf__slicer");
    if (mdfSlicer) {
      mdfSlicer.style.display = slicer[idx] !== 0 ? "flex" : "none";
    }
  }

  return (
    <>
      <div className="mdf__bar">
        <button type="button" onClick={() => setPage(4)}>
          upload
        </button>
        <button type="button" onClick={() => setPage(0)}>
          config
        </button>
        <button type="button" onClick={() => setPage(1)}>
          line graphs
        </button>
        <button type="button" onClick={() => setPage(2)}>
          corr plot
        </button>
        <button type="button" onClick={() => setPage(3)}>
          ratio plot
        </button>
      </div>
      <br />
      <div id="mdf__setup">
        <MdfSetup />
      </div>
      <div id="mdf__slicer" className="mdf__bar" style={{ display: "none" }}>
        <MdfTimeSlicer />
      </div>
      <div id="mdf__main">
        {
          [
            <></>,
            <>
              <MdfSelector />
              <MdfGraphLine />
            </>,
            <MdfGraphPlot type="corr" key="graph_corr" />,
            <MdfGraphPlot type="ratio" key="graphRatio" />,
            <MdfConfig />,
          ][page]
        }
      </div>
    </>
  );
};

export default MdfDashboard;
