import styled from "styled-components";

export const HRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const HCenteredRow = styled(HRow)`
  align-items: center;
`;

export const TextWithIcon = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 8px;
  }
`;
