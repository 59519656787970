import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { OptionProps } from "src/components/Sider/Selector";
import SiderCard from "src/components/Sider/SiderCard";
import { makeOption } from "src/components/Sider/utils";
import {
  useDiffs,
  usePresentationConfigForSimulation,
} from "src/components/SimulationConfigs/hooks";
import { getConfigNatureForCardConfigs } from "src/components/SimulationConfigs/utils";
import { useSimulationSelectionState } from "src/components/Simulations/hooks";
import { GlobalState } from "src/redux/reducers";
import styled from "styled-components";

const StyledButtonContainer = styled.div`
  margin: 0 24px;
`;

const StyledButton = styled(Button)`
  margin: 3px;
`;

interface Props {
  simulationId?: string;
  onSelectConfiguration: (value: OptionProps["value"]) => void;
  shouldShowConfigurations: boolean;
}

interface MatchProps {
  projectId: string;
  simulationId: string;
  configurationId: string;
}

const ConfigurationSider: React.FC<Props> = ({
  simulationId,
  onSelectConfiguration,
  shouldShowConfigurations,
}) => {
  const matchConfigurationRoute = useRouteMatch<MatchProps>(
    "/predict/projects/:projectId/simulations/:simulationId/configurations/:configurationId"
  );
  const configurationId = matchConfigurationRoute?.params?.configurationId;
  const presentationConfigForWorkflow =
    usePresentationConfigForSimulation(simulationId);
  const [unselectedTags, setUnselectedTags] = useState<string[]>([]);

  const subWorkflowConfigNature: Record<string, string[]> = {};
  (presentationConfigForWorkflow ?? []).forEach((subWorkflow) => {
    subWorkflowConfigNature[subWorkflow.name] = getConfigNatureForCardConfigs(
      subWorkflow.children
    );
  });

  const allConfigNature = (presentationConfigForWorkflow ?? [])
    .reduce(
      (prev, subWorkflow) =>
        prev.concat(getConfigNatureForCardConfigs(subWorkflow.children)),
      [] as string[]
    )
    .filter((n) => n.length > 0);

  const tagsData = Array.from(new Set(allConfigNature)).sort();
  const visibleTags = new Set(
    tagsData.filter((tag) => unselectedTags.indexOf(tag) === -1)
  );

  const visibleSubWorkflow = new Set(
    Object.keys(subWorkflowConfigNature).filter((subWorkflowName) => {
      const cn = subWorkflowConfigNature[subWorkflowName];
      if (cn.length === 0) {
        return true;
      }
      const visibleCn = cn.filter((x) => visibleTags.has(x));
      return visibleCn.length > 0;
    })
  );

  function handleSelectAllTags(checked: boolean) {
    if (checked) {
      setUnselectedTags([]);
    } else {
      setUnselectedTags(tagsData);
    }
  }

  function handleToggleTag(tag: string, checked: boolean) {
    const nextUnselectedTags = !checked
      ? [...unselectedTags, tag]
      : unselectedTags.filter((t) => t !== tag);
    setUnselectedTags(nextUnselectedTags);
  }

  const { t } = useTranslation();
  const options = [...visibleSubWorkflow].map((name) =>
    makeOption(name, t(name))
  );

  const currentSimulationId = useSimulationSelectionState()[0];
  const configCommitId = useSelector((state: GlobalState) =>
    currentSimulationId === undefined
      ? undefined
      : state.configs.configs[currentSimulationId]?.configCommitId
  );

  const { diffsFromBackendAndUser } = useDiffs(configCommitId);

  return (
    <SiderCard
      title="Subworkflow"
      options={options}
      handleChange={(key) => {
        if (diffsFromBackendAndUser.length === 0) {
          onSelectConfiguration(key);
        } else {
          Modal.error({
            content:
              "There has been new updates. Please check alert at the top of this page before moving to another subworkflow.",
          });
        }
      }}
      showContents={shouldShowConfigurations}
      noContentsText="Select a project to view subworkflows"
      value={configurationId}
      useMenu
      beforeSelection={
        <StyledButtonContainer>
          <StyledButton
            size="small"
            type={unselectedTags.length === 0 ? "primary" : undefined}
            onClick={() => handleSelectAllTags(unselectedTags.length !== 0)}
            shape="round"
          >
            All
          </StyledButton>
          {tagsData.map((tag) => (
            <StyledButton
              key={tag}
              size="small"
              type={unselectedTags.indexOf(tag) === -1 ? "primary" : undefined}
              onClick={() =>
                handleToggleTag(tag, unselectedTags.indexOf(tag) !== -1)
              }
              shape="round"
            >
              {tag}
            </StyledButton>
          ))}
        </StyledButtonContainer>
      }
    />
  );
};

export default ConfigurationSider;
