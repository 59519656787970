import jwt_decode from "jwt-decode";

export const getCompanyName = (token: string): string => {
  const decoded: any = jwt_decode(token);
  return decoded["https://traverse.ai/company_name"];
};

export const canManageAllAlerts = (token: string): boolean => {
  const decoded: any = jwt_decode(token);
  return decoded.permissions.includes("manage_all:alerts");
};

export const canManageHydra = (token: string): boolean => {
  const decoded: any = jwt_decode(token);
  return decoded.permissions.includes("manage:hydra");
};

export const canCreateWorkflows = (token: string): boolean => {
  const decoded: any = jwt_decode(token);
  return decoded.permissions.includes("create:workflows");
};

export const canCreateProjects = (token: string): boolean => {
  const decoded: any = jwt_decode(token);
  return decoded.permissions.includes("create:projects");
};

export const canNotifyRunWorkflows = (token: string): boolean => {
  const decoded: any = jwt_decode(token);
  return decoded.permissions.includes("notify-run:workflows");
};

export const canUnlockWorkflows = (token: string): boolean => {
  const decoded: any = jwt_decode(token);
  return decoded.permissions.includes("unlock:workflows");
};

export const canMasq = (token: string): boolean => {
  const decoded: any = jwt_decode(token);
  return decoded.permissions.includes("masq");
};

export const canViewDebug = (token: string): boolean => {
  const decoded: any = jwt_decode(token);
  return decoded.permissions.includes("view:debug");
};

export const canUploadDocuments = (token: string): boolean => {
  const decoded: any = jwt_decode(token);
  return decoded.permissions.includes("upload:documents");
};
