// TODO
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-cycle */

import * as d3 from "d3";
import React, { useEffect } from "react";

import { updateGraphLine, updateView } from "../../redux/actions/mdf_dashboard";
import MdfState from "../../redux/reducers/mdf_dashboard";
import { MdfViewSetup } from "./MdfDashboard";
import { getTimeIndexScale } from "./utils";

const MAIN_DIVID = "mdf_graph_lines";

let zoomTransform: any;
const getZoom = (vis: any) => {
  const d3zoom = (e: any) => {
    if (MdfViewSetup.zoomall) {
      const skip = zoomTransform;
      zoomTransform = e.transform;
      if (skip) return;
    } else {
      const skip = vis.zoomTransform;
      vis.zoomTransform = e.transform;
      if (skip) return;
    }

    let xaxis2 = null as any;
    const getBounds = (zm: any) => {
      xaxis2 = zm.rescaleX(vis.xaxis0);
      return [xaxis2.invert(0), xaxis2.invert(MdfViewSetup.w)];
    };

    setTimeout(() => {
      if (!vis.xaxis0) {
        zoomTransform = undefined;
        vis.zoomTransform = undefined;
        return;
      }
      const [t0, t1] = getBounds(
        MdfViewSetup.zoomall ? zoomTransform : vis.zoomTransform
      );

      if (MdfViewSetup.zoomall) {
        // NOTE: also used for bounding the corr graphs
        MdfViewSetup.lineXaxis = xaxis2;
        MdfViewSetup.lineZoom = zoomTransform;
        MdfState.dispatch(updateGraphLine([], t0, t1, zoomTransform));
      } else {
        MdfState.dispatch(
          updateGraphLine([vis.idx], t0, t1, vis.zoomTransform)
        );
        vis.zoomTransform = undefined;
      }

      zoomTransform = undefined;
      MdfViewSetup.updateTstamp(
        t0.toISOString().slice(0, 16),
        t1.toISOString().slice(0, 16)
      );
    }, 1000 / MdfViewSetup.fps);
  };

  return d3.zoom().on("zoom", d3zoom);
};

const getSvg = (idname: string, title: string): any => {
  const D3margin = MdfViewSetup.margin;
  const svg = d3
    .select(idname)
    .append("svg")
    .attr("width", MdfViewSetup.w + D3margin[1] + D3margin[3])
    .attr("height", MdfViewSetup.h + D3margin[0] + D3margin[2]);

  svg
    .append("text")
    .text(title)
    .attr("y", D3margin[0] - 10)
    .attr("font-size", D3margin[0] - 15);
  return svg
    .append("g")
    .attr("transform", `translate(${D3margin[3]},${D3margin[0]})`);
};

const areaSelect = (vis: any) => {
  const fn = (e: any) => {
    if (e.ctrlKey) {
      MdfViewSetup.selRange = [];
    } else {
      if (!vis.xaxis2) return;
      const v = vis.xaxis2.invert(e.layerX - MdfViewSetup.margin[3]).getTime();
      if (MdfViewSetup.selRange.length === 0) MdfViewSetup.selRange = [v, v];
      MdfViewSetup.selRange[0] = Math.min(v, MdfViewSetup.selRange[0]);
      MdfViewSetup.selRange[1] = Math.max(v, MdfViewSetup.selRange[1]);
    }
    MdfViewSetup.updateSelection();
    MdfState.dispatch(updateView());
  };
  return fn;
};

const MdfGraphLine: React.FC = () => {
  const fnsUnsub: any[] = [];
  const callback = (fn: any) => {
    fnsUnsub.push(MdfState.subscribe(fn));
  };
  const clearListeners = () => {
    while (fnsUnsub.length) fnsUnsub.pop()();
  };

  let lineCurr = "";
  const redrawLine = () => {
    const graphs = MdfState.getState().graphLines;
    const lineNew = graphs.map((x: any) => x.sensors).join("---___---");
    if (lineNew === lineCurr) return;
    lineCurr = lineNew;

    clearListeners();
    const maindiv = document.getElementById(MAIN_DIVID);
    if (maindiv) {
      maindiv.innerHTML = "";
    }

    for (let idx = 0; idx < graphs.length; idx += 1) {
      callback(drawGraph(idx));
    }
  };
  const mainListener = MdfState.subscribe(redrawLine);

  useEffect(() => {
    redrawLine();

    return () => {
      mainListener();
      for (const fn of fnsUnsub) fn();
    };
  });

  return (
    <>
      <h1>line graphs</h1>
      <div id={MAIN_DIVID} />
    </>
  );
};

export default MdfGraphLine;

const drawGraph = (idx: number) => {
  const params = MdfState.getState().graphLines[idx];
  // NOTE: when isCombined is used, valnames refers to the lines to draw
  const valnames = params.sensors;
  const isCombined = params.sensors.length > 1;

  const D3margin = MdfViewSetup.margin;
  const { w } = MdfViewSetup;
  const { h } = MdfViewSetup;
  const vis = getSvg(`#${MAIN_DIVID}`, isCombined ? "combined" : valnames[0]);

  vis.idx = idx;
  vis.xaxis = vis.append("g").attr("transform", `translate(0, ${h})`);
  vis.yaxis = vis.append("g");
  vis.flags = vis.append("g");

  vis.sel = vis
    .append("rect")
    .style("fill", MdfViewSetup.clrSelection)
    .attr("height", h);

  vis.hoverX = 0;
  vis.hoverX0 = vis
    .append("rect")
    .style("fill", "#333")
    .attr("height", h)
    .attr("width", 0);
  vis.hoverY0 = vis
    .append("rect")
    .style("fill", "#333")
    .attr("height", 0)
    .attr("width", w);

  let hoverTimeout: any = null;
  const updateHov = () => {
    hoverTimeout = null;
    if (isCombined) return;
    if (!vis.yaxis2 || !vis.xaxis2) return;

    vis.hoverX0
      .attr("width", 1)
      .attr("transform", `translate(${vis.xaxis2(vis.hoverX)}, 0)`);

    const full = MdfState.getState().timeseries[valnames[0]][0].timeseries;
    const x0 = full[0][0];
    const x1 = full[full.length - 1][0];
    const y = Math.floor((full.length * (vis.hoverX - x0)) / (x1 - x0));

    if (y > 0 && y < full.length) {
      vis.hoverY0
        .attr("height", 1)
        .attr("transform", `translate(0,${vis.yaxis2(full[y][1])})`);
    }
  };
  const clearHov = () => {
    if (isCombined) return;

    // NOTE: doing this because the mouseout event sends a mousemove event as well
    // we want to delay this clearing abit so that it will be run last
    setTimeout(() => {
      vis.hoverX0.attr("width", 0);
      vis.hoverY0.attr("height", 0);
    }, 1000 / MdfViewSetup.fps);
  };

  const styleLine = (ele: any) =>
    ele
      .style("fill", "none")
      .style("stroke", "black")
      .style("stroke-width", "1px");
  vis.graph = isCombined ? vis.append("g") : styleLine(vis.append("path"));

  vis.selection = vis
    .append("rect")
    .style("fill", MdfViewSetup.clrSelection)
    .attr("height", MdfViewSetup.h)
    .attr("width", 0);

  const zoomnode = vis.append("rect");
  zoomnode
    .attr("width", w)
    .attr("height", h)
    .style("fill", "none")
    .style("pointer-events", "all")
    .call(getZoom(vis))
    .on("mousemove", (e: any) => {
      if (!vis.xaxis2) return;
      vis.hoverX = vis.xaxis2.invert(e.layerX - D3margin[3]).getTime();

      if (hoverTimeout === null) {
        hoverTimeout = setTimeout(updateHov, 1000 / MdfViewSetup.fps);
      }
    })
    .on("mouseout", clearHov)
    .on("click", areaSelect(vis));

  let flagCount = 0;
  let isReady = false;
  let currstate = -1;
  let versstate = -1;

  const update = () => {
    const state = MdfState.getState();
    const tseries = state.timeseries;

    const viewstate = state.graphLines[idx];
    if (!viewstate) return;

    if (!isReady) {
      for (const sname of valnames) {
        if (!tseries[sname]) return;
        if (!tseries[sname][0].timeseries) return;
      }
      isReady = true;
    }

    let flags = [] as any[];
    for (const sname of valnames) {
      flags = flags.concat(state.flags[sname] || []);
    }

    if (
      viewstate.version === currstate &&
      flags.length === flagCount &&
      state.version === versstate
    )
      return;

    let xaxis = null as any;
    if (!vis.xaxis0) {
      const tmp = tseries[valnames[0]][0].timeseries;
      viewstate.t0 = tmp[0][0];
      viewstate.t1 = tmp[tmp.length - 1][0];

      xaxis = d3
        .scaleTime()
        .range([0, MdfViewSetup.w])
        .domain([viewstate.t0, viewstate.t1]);
      vis.xaxis0 = xaxis;
    }

    if (MdfViewSetup.zoomall) {
      if (MdfViewSetup.lineZoom) {
        d3.zoom().transform(zoomnode, MdfViewSetup.lineZoom);
      }
      if (MdfViewSetup.lineXaxis) {
        xaxis = MdfViewSetup.lineXaxis;
      }
    } else {
      d3.zoom().transform(zoomnode, viewstate.zoom);
      xaxis = d3
        .scaleTime()
        .range([0, MdfViewSetup.w])
        .domain([viewstate.t0, viewstate.t1]);
    }

    if (!xaxis) return;
    vis.xaxis2 = xaxis;

    flagCount = flags.length;
    versstate = state.version;
    currstate = viewstate.version;

    // === DRAWING OF FLAGS === //
    if (flagCount > 0) {
      const flagCnt = MdfViewSetup.flagbin;

      const v0 = xaxis.invert(0) as any;
      const v1 = xaxis.invert(MdfViewSetup.w) as any;

      const dv = (v1 - v0) / flagCnt;
      const dd = [[], [], []] as boolean[][];

      for (let i = 0; i < flagCnt; i += 1) {
        for (const ddd of dd) ddd.push(false);
      }

      for (const f of flags) {
        if (f[3] >= dd.length) {
          console.error("unable to draw flag severity", f, viewstate.sensors);
          continue;
        }

        const vv0 = Math.floor((Math.min(Math.max(f[0], v0), v1) - v0) / dv);
        const vv1 = Math.floor((Math.min(Math.max(f[1], v0), v1) - v0) / dv);
        for (let j = vv0; j <= vv1; j += 1) {
          dd[f[3]][j] = true;
        }
      }

      const dataflag = [];
      for (let k = 0; k < flagCnt; k += 1) {
        for (let p = 0; p < dd.length; p += 1) {
          if (dd[p][k]) dataflag.push([p, k]);
        }
      }

      const fs = vis.flags.selectAll("rect").data(dataflag);
      fs.enter()
        .append("rect")
        .merge(fs)
        .attr("width", w / flagCnt)
        .attr("height", h)
        .style("fill", (d: any) => MdfViewSetup.clrFlags[d[0]])
        .attr("transform", (d: any) => `translate(${d[1] * (w / flagCnt)},0)`);
      fs.exit().remove();
    }

    // === DRAWING OF MAIN LINE === //
    if (!isCombined) {
      const scaled = tseries[valnames[0]];
      const [scale, s0, s1] = getTimeIndexScale(scaled, xaxis);
      const temp = scaled[scale].timeseries.slice(s0, s1);

      // TODO: make y domain fixed, maybe?
      const y = d3
        .scaleLinear()
        .range([h, 0])
        .domain(
          d3.extent(temp, (d: [number, number]) => d[1]) as unknown as [
            number,
            number
          ]
        );
      const line = d3
        .line()
        .x((d) => xaxis(d[0]))
        .y((d) => y(d[1]));
      vis.yaxis2 = y;
      vis.graph.data([temp]).attr("d", line);
    } else {
      const temp = [];
      for (const v of valnames) {
        // account for the possibility of different scales
        const [scale, s0, s1] = getTimeIndexScale(tseries[v], xaxis);
        temp.push(tseries[v][scale].timeseries.slice(s0, s1));
      }

      const dm = [temp[0][0][1], temp[0][0][1]];
      for (const vs of temp)
        for (const v of vs) {
          dm[0] = Math.min(v[1], dm[0]);
          dm[1] = Math.max(v[1], dm[1]);
        }
      const y = d3.scaleLinear().range([h, 0]).domain(dm);
      const line = d3
        .line()
        .x((d) => xaxis(d[0]))
        .y((d) => y(d[1]));
      vis.yaxis2 = y;

      const paths = vis.graph.selectAll("path").data(temp);
      styleLine(paths.enter().append("path").merge(paths)).attr("d", line);
      paths.exit().remove();
    }

    // === DRAWING OF SECTION AREA === //
    if (MdfViewSetup.selRange.length === 2) {
      const range = MdfViewSetup.selRange;
      const x0 = Math.min(Math.max(vis.xaxis2(range[0]), 0), MdfViewSetup.w);
      const x1 = Math.min(Math.max(vis.xaxis2(range[1]), 0), MdfViewSetup.w);

      vis.selection.attr("width", x1 - x0);
      vis.selection.attr("transform", `translate(${x0},0)`);
    } else {
      vis.selection.attr("width", 0);
    }

    vis.xaxis
      .call(d3.axisBottom(xaxis).tickFormat(d3.timeFormat("%Y-%m-%d") as any))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");
    vis.yaxis.call(d3.axisLeft(vis.yaxis2));
  };
  update();

  return update;
};
