import { HomeOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextWithIcon } from "src/components/Common";
import Selector, { OptionProps } from "src/components/Sider/Selector";
import SiderCardTitle from "src/components/Sider/SiderCardTitle";
import SiderMenu from "src/components/Sider/SiderMenu";
import styled from "styled-components";

const NoContentsText = styled.p`
  color: #8c8c8c;
  margin: 0 24px;
`;

const SiderCardComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

interface SelectionContainerProps {
  margin: boolean;
}

const SelectionContainer = styled.div<SelectionContainerProps>`
  display: flex;
  margin: 0 ${({ margin }) => (margin ? "24px" : "0")};
`;

const HomeButton = styled((props) => (
  <Button
    shape="circle"
    icon={<HomeOutlined />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))`
  margin-right: 10px;
`;

const UrlButton = styled(TextWithIcon).attrs({
  // eslint-disable-next-line react/jsx-props-no-spreading
  as: (props: ButtonProps) => <Button type="primary" {...props} />,
})`
  align-self: flex-start;
  padding: 3px 14px;
  border-radius: 2px;
  color: #fff;
  margin: 0 24px 12px;
  font-family: Blender Pro;
  font-style: normal;
  font-weight: 700;
`;

interface Props {
  title: string;
  options: OptionProps[];
  handleChange: (value: OptionProps["value"]) => void;
  value?: string;
  newText?: string;
  newForm?: (dismissModal: () => void) => React.ReactNode;
  mainButtonUrl?: string;
  mainButtonIcon?: React.ReactNode;
  mainButtonText?: string;
  homeUrl?: string;
  buttonEnabled?: boolean;
  buttonDisabledTooltip?: string;
  selectPlaceholder?: string;
  showContents?: boolean;
  selectionDataCy?: string;
  buttonDataCy?: string;
  noContentsText?: string;
  useMenu?: boolean;
  beforeSelection?: React.ReactNode;
}

const SiderCard: React.FC<Props> = ({
  title,
  options,
  handleChange,
  mainButtonUrl,
  mainButtonIcon,
  mainButtonText,
  homeUrl,
  newText,
  newForm,
  value,
  buttonEnabled,
  buttonDisabledTooltip,
  selectPlaceholder,
  selectionDataCy,
  buttonDataCy,
  noContentsText,
  useMenu,
  beforeSelection,
  showContents = true,
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const formModal = newForm ? (
    <Modal
      title={newText}
      visible={showForm}
      footer={null}
      onCancel={() => setShowForm(false)}
      maskClosable={false}
      width={600}
    >
      {newForm(() => setShowForm(false))}
    </Modal>
  ) : null;

  const selection = useMenu ? (
    <SiderMenu
      options={options}
      onClick={handleChange}
      selectedKeys={value ? [value] : []}
    />
  ) : (
    <Selector
      placeholder={selectPlaceholder}
      options={options}
      handleChange={handleChange}
      value={value}
      dropdownRender={(menu: any) => (
        <>
          {newText ? (
            buttonEnabled === false ? (
              buttonDisabledTooltip ? (
                <Tooltip title={buttonDisabledTooltip}>
                  <Button disabled>{newText}</Button>
                </Tooltip>
              ) : (
                <Button disabled>{newText}</Button>
              )
            ) : (
              <Button data-cy={buttonDataCy} onClick={() => setShowForm(true)}>
                {newText}
              </Button>
            )
          ) : null}
          {menu}
        </>
      )}
      data-cy={selectionDataCy}
    />
  );

  return (
    <>
      <SiderCardComponent>
        <SiderCardTitle title={title} />
        {showContents ? (
          <>
            {mainButtonUrl && (
              <Link to={mainButtonUrl}>
                <UrlButton>
                  {mainButtonIcon}
                  {mainButtonText}
                </UrlButton>
              </Link>
            )}
            {beforeSelection}
            <SelectionContainer margin={!useMenu}>
              {homeUrl && (
                <Link to={homeUrl}>
                  <HomeButton />
                </Link>
              )}
              {selection}
            </SelectionContainer>
          </>
        ) : (
          <NoContentsText>{noContentsText}</NoContentsText>
        )}
      </SiderCardComponent>
      {formModal}
    </>
  );
};

export default SiderCard;
