import { isDataTypeANumber } from "src/components/SimulationConfigs/utils";
import {
  Accessor,
  Enum,
  Model,
  ModelGeneric,
  WorkflowConfigField,
  WorkflowConfigUiType,
  WorkflowConfigValueType,
  isEnum,
  isStorageFile,
} from "src/networking/types";

export const isNumberRange = (
  value: unknown
): value is [min: number | null, max: number | null] =>
  Array.isArray(value) &&
  value.length === 2 &&
  (value[0] === null || typeof value[0] === "number") &&
  (value[1] === null || typeof value[1] === "number");

const checkData = (
  data: any,
  expectedDataType:
    | "boolean"
    | "string"
    | "number"
    | "stringOrNumber"
    | "numberRange"
    | "timeRange"
    | "array"
    | "fileOrFiles"
    | "nocheck"
): any => {
  switch (expectedDataType) {
    case "boolean":
      return typeof data === "boolean" ? data : undefined;
    case "string":
      return typeof data === "string" ? data : undefined;
    case "number":
      return typeof data === "number" ? data : undefined;
    case "stringOrNumber":
      return ["string", "number"].includes(typeof data) ? data : undefined;
    case "numberRange":
      return isNumberRange(data) ? data : [null, null];
    case "timeRange":
      return isNumberRange(data) ? data : null;
    case "fileOrFiles":
      return Array.isArray(data)
        ? data.filter(isStorageFile)
        : isStorageFile(data)
        ? data
        : undefined;
    case "array":
      return Array.isArray(data) ? data : [];
    case "nocheck":
    default:
      return data;
  }
};

export const getFieldValue = (
  model: Model | undefined,
  accessor: Accessor,
  expectedDataType:
    | "boolean"
    | "string"
    | "number"
    | "stringOrNumber"
    | "numberRange"
    | "timeRange"
    | "array"
    | "fileOrFiles"
    | "nocheck"
): any => {
  if (model === undefined || model === null) {
    return checkData(null, expectedDataType);
  }
  if (accessor.length === 0) {
    if (isEnum(model)) {
      return checkData((model as Enum).value, expectedDataType);
    }
    return checkData(model, expectedDataType);
  }
  return getFieldValue(
    (model as ModelGeneric<Model>)[accessor[0]] as Model,
    accessor.slice(1),
    expectedDataType
  );
};

export const standardiseState = (model: Model | Model[]): Model | Model[] => {
  if (Array.isArray(model)) {
    return model.map(standardiseState) as Model[];
  }

  if (isEnum(model)) {
    return model.value;
  }

  if (typeof model === "object" && model !== null) {
    return Object.fromEntries(
      Object.entries(model).map(([k, v]) => [k, standardiseState(v)])
    );
  }

  return model;
};

export const getFieldTypeForCheck = (
  config: WorkflowConfigField
):
  | "string"
  | "number"
  | "boolean"
  | "numberRange"
  | "timeRange"
  | "array"
  | "fileOrFiles" => {
  switch (config.value_type) {
    case WorkflowConfigValueType.Single:
      switch (config.ui_type) {
        case WorkflowConfigUiType.Label:
          return isDataTypeANumber(config.field_type) ? "number" : "string";
        case WorkflowConfigUiType.TextArea:
          return "string";
        case WorkflowConfigUiType.LabelWithPlusMinus:
          return "number";
        case WorkflowConfigUiType.Toggle:
          return "boolean";
        case WorkflowConfigUiType.SingleCheckbox:
          return "boolean";
        case WorkflowConfigUiType.DatePicker:
          return "number";
        case WorkflowConfigUiType.DateTimePicker:
          return "number";
        case WorkflowConfigUiType.FileInput:
          return "fileOrFiles";
        case WorkflowConfigUiType.MultipleFileInput:
          return "fileOrFiles";
        case WorkflowConfigUiType.RangeNumber:
          return "numberRange";
        default:
          return "string";
      }
    case WorkflowConfigValueType.Range:
      switch (config.ui_type) {
        case WorkflowConfigUiType.RangeNumber:
          return "numberRange";
        case WorkflowConfigUiType.DateRange:
          return "timeRange";
        case WorkflowConfigUiType.DateTimeRange:
          return "timeRange";
        default:
          return "numberRange";
      }
    case WorkflowConfigValueType.OptionSelector:
      switch (config.ui_type) {
        case WorkflowConfigUiType.MultiselectCheckbox:
          return "array";
        case WorkflowConfigUiType.Dropdown:
        case WorkflowConfigUiType.SegmentedControl:
        case WorkflowConfigUiType.Radio:
        default:
          return isDataTypeANumber(config.field_type) ? "number" : "string";
      }
    case WorkflowConfigValueType.Action:
      return "boolean";
    case WorkflowConfigValueType.Table:
      // probably not needed, but just in case
      return "array";
    default:
      return "string";
  }
};

export const getPrefilledLinkageValue = (
  formValues: Model | undefined,
  accessor: Accessor,
  config: WorkflowConfigField
): any => {
  const nums = accessor.filter((f) => typeof f === "number");
  if (nums.length !== 1) {
    throw new Error(
      `To use prefilled linkage, the accessor (${accessor}) must have exactly one number. Check the config "${config.config_name}".`
    );
  }

  const idx = nums[0];
  return getFieldValue(
    formValues,
    (config.prefilled_parent_linkage as Accessor)
      .concat([idx])
      .concat(config.prefilled_child_linkage),
    getFieldTypeForCheck(config)
  );
};
