import React from "react";
import { useSelector } from "react-redux";

import CardConfigVisualizer from "src/components/SimulationConfigs/CardConfigVisualizer";
import FieldGroupList from "src/components/SimulationConfigs/SimulationConfigsFieldGroup/FieldGroupList";
import FieldGroupTable from "src/components/SimulationConfigs/SimulationConfigsFieldGroup/FieldGroupTable";
import FieldGroupTabs from "src/components/SimulationConfigs/SimulationConfigsFieldGroup/FieldGroupTabs";
import SimulationConfigsFieldGroupForm from "src/components/SimulationConfigs/SimulationConfigsFieldGroupForm";

import { getAndWarnDynamicButtonConfig } from "src/components/SimulationConfigs/utils";
import {
  Accessor,
  CardData,
  WorkflowConfigCardType,
} from "src/networking/types";

import { GlobalState } from "src/redux/reducers";

export interface Props {
  cardData: CardData;
  accessor: Accessor;
  hiddenConfigActionKeys: string[];
  disabledConfigActionKeys: string[];
}

const SimulationConfigsFieldGroup: React.FC<Props> = ({
  cardData,
  accessor,
  hiddenConfigActionKeys,
  disabledConfigActionKeys,
}) => {
  const debugForms = useSelector((state: GlobalState) => state.configs.debug);

  const dynamicButtonConfig = getAndWarnDynamicButtonConfig(cardData);
  if (dynamicButtonConfig) {
    const cardUIType = dynamicButtonConfig.card_ui_type;

    if (
      dynamicButtonConfig.config_action_group.some((k) =>
        hiddenConfigActionKeys.includes(k)
      )
    ) {
      return null;
    }

    switch (cardUIType) {
      case WorkflowConfigCardType.Tab:
        return (
          <FieldGroupTabs
            cardData={cardData}
            accessor={accessor}
            hiddenConfigActionKeys={hiddenConfigActionKeys}
            disabledConfigActionKeys={disabledConfigActionKeys}
          />
        );
      case WorkflowConfigCardType.TableRowBasedFields:
      case WorkflowConfigCardType.TableColBasedFields: // TODO: add col based tables. hack first so that the table appears.
        return (
          <FieldGroupTable
            cardData={cardData}
            accessor={accessor}
            hiddenConfigActionKeys={hiddenConfigActionKeys}
            disabledConfigActionKeys={disabledConfigActionKeys}
          />
        );
      case WorkflowConfigCardType.List:
      default:
        return (
          <FieldGroupList
            cardData={cardData}
            accessor={accessor}
            hiddenConfigActionKeys={hiddenConfigActionKeys}
            disabledConfigActionKeys={disabledConfigActionKeys}
          />
        );
    }
  }

  return (
    <>
      {debugForms && (
        <CardConfigVisualizer
          cardData={cardData}
          accessor={accessor}
          hiddenConfigActionKeys={hiddenConfigActionKeys}
          disabledConfigActionKeys={disabledConfigActionKeys}
        />
      )}
      <SimulationConfigsFieldGroupForm
        cardData={cardData}
        accessor={accessor}
        hiddenConfigActionKeys={hiddenConfigActionKeys}
        disabledConfigActionKeys={disabledConfigActionKeys}
      />
    </>
  );
};

export default SimulationConfigsFieldGroup;
