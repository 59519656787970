import { Typography } from "antd";
import moment from "moment";
import React, { useMemo } from "react";
import MonitoringCard from "src/components/Monitor/shared/MonitoringCard";
import { SensorConfiguration } from "src/networking/types";
import {
  getSensorTypePriorityComparator,
  getSortedSensorConfigurationBySensorTypePriority,
  getSortedSensorConfigurationByStatus,
} from "../shared/utils";

export const ALL_COLUMNS = Object.fromEntries(
  [
    {
      title: "Start Time",
      dataIndex: "start_timestamp",
      key: "start_timestamp",
      sorter: (a: any, b: any) =>
        a.start_timestamp > b.start_timestamp ? 1 : -1,
    },
    {
      title: "End Time",
      dataIndex: "end_timestamp",
      key: "end_timestamp",
      sorter: (a: any, b: any) => (a.end_timestamp > b.end_timestamp ? 1 : -1),
    },
    {
      title: "Logger Offset",
      dataIndex: "logger_offset",
      key: "logger_offset",
    },
    { title: "Logger Slope", dataIndex: "logger_slope", key: "logger_slope" },
    {
      title: "Target Offset",
      dataIndex: "target_offset",
      key: "target_offset",
    },
    { title: "Target Slope", dataIndex: "target_slope", key: "target_slope" },
    {
      title: "Sensor Serial",
      dataIndex: "sensor_serial",
      key: "sensor_serial",
    },
    {
      title: "Calibration Date",
      dataIndex: "calibration_date",
      key: "calibration_date",
    },
    {
      title: "Calibration Facility",
      dataIndex: "calibration_facility",
      key: "calibration_facility",
    },
    {
      title: "Calibration Reference",
      dataIndex: "calibration_reference",
      key: "calibration_reference",
    },
    {
      title: "Calibration Certificate ID",
      dataIndex: "calibration_certificate_id",
      key: "calibration_certificate_id",
    },
    {
      title: "H. Boom Diameter [mm]",
      dataIndex: "h_boom_diameter",
      key: "h_boom_diameter",
    },
    {
      title: "H. Boom Length [mm]",
      dataIndex: "h_boom_length",
      key: "h_boom_length",
    },
    {
      title: "V. Boom Diameter [mm]",
      dataIndex: "v_boom_diameter",
      key: "v_boom_diameter",
    },
    {
      title: "V. Boom Length [mm]",
      dataIndex: "v_boom_length",
      key: "v_boom_length",
    },
    {
      title: "Logger Boom Orientation [deg]",
      dataIndex: "logger_boom_orientation",
      key: "logger_boom_orientation",
    },
    {
      title: "Boom Orientation [deg]",
      dataIndex: "target_boom_orientation",
      key: "target_boom_orientation",
    },
    {
      title: "Logger Vane Mounting Angle [deg]",
      dataIndex: "logger_vane_mounting_angle",
      key: "logger_vane_mounting_angle",
    },
    {
      title: "Target Vane Mounting Angle [deg]",
      dataIndex: "target_vane_mounting_angle",
      key: "target_vane_mounting_angle",
    },
    {
      title: "Calibrated by MEASNET facilities",
      dataIndex: "meas_net",
      key: "meas_net",
      render: (v?: boolean) => (v === undefined ? "-" : v ? "Yes" : "No"),
    },
    {
      title: "Data referenced to True North",
      dataIndex: "true_north",
      key: "true_north",
      render: (v?: boolean) => (v === undefined ? "-" : v ? "Yes" : "No"),
    },
    {
      title: "Applied Original Calibration",
      dataIndex: "applied_original_calibration",
      key: "applied_original_calibration",
      render: (v?: boolean) => (v === undefined ? "-" : v ? "Yes" : "No"),
    },
    {
      title: "Time Adjusted Daylight Savings",
      dataIndex: "time_adjusted_daylight_savings",
      key: "time_adjusted_daylight_savings",
      render: (v?: boolean) => (v === undefined ? "-" : v ? "Yes" : "No"),
    },
    { title: "Device Brand", dataIndex: "sensor_brand", key: "sensor_brand" },
    { title: "Device Model", dataIndex: "sensor_model", key: "sensor_model" },
    {
      title: "Serial Number",
      dataIndex: "sensor_serial",
      key: "sensor_serial",
    },
    { title: "Sensor ID", dataIndex: "sensor_id", key: "sensor_id" },
    {
      title: "Sensor ID",
      dataIndex: "sensor_weak_name",
      key: "sensor_weak_name",
      sorter: (a: any, b: any) =>
        a.sensor_weak_name > b.sensor_weak_name ? 1 : -1,
    },
    {
      title: "Measurement Height - AGL[m]",
      dataIndex: "sensor_height",
      key: "sensor_height",
    },
    { title: "Site Name", dataIndex: "site_name", key: "site_name" },
    {
      title: "Data Coverage",
      dataIndex: "sensor_data_coverage_ratio",
      key: "sensor_data_coverage_ratio",
      sorter: (a: any, b: any) =>
        a.sensor_data_coverage_ratio > b.sensor_data_coverage_ratio ? 1 : -1,
      render: (res: number | undefined) =>
        res === undefined ? "-" : `${(res * 100).toFixed(2)}%`,
    },
    {
      title: "Data Coverage",
      dataIndex: "sensor_data_coverage_nature",
      key: "sensor_data_coverage_nature",
      render: () => "Post QC Coverage",
    },
    {
      title: "Sensor Type",
      dataIndex: "sensor_type",
      key: "sensor_type",
      sorter: getSensorTypePriorityComparator,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => (a.status > b.status ? 1 : -1),
      render: (status: string) => (
        <Typography.Text type={status === "Active" ? "success" : "danger"}>
          {status}
        </Typography.Text>
      ),
    },
  ].map((c) => [c.key, c])
);

interface Props {
  title: string;
  sensorConfigs?: SensorConfiguration[];
  selectedSensorConfig?: SensorConfiguration;
  loading: boolean;
  error?: Record<string, unknown>;
  columns: string[];
  onClick?: (record: any) => void;
}

const SensorConfigurationTable: React.FC<Props> = ({
  title,
  sensorConfigs,
  selectedSensorConfig,
  loading,
  error,
  columns,
  onClick,
}) => {
  const tableColumns = columns.map((c) => ALL_COLUMNS[c]);

  const memoizedSensorConfigs = useMemo(
    () =>
      getSortedSensorConfigurationByStatus(
        getSortedSensorConfigurationBySensorTypePriority(sensorConfigs)
      )?.map((c, idx) => ({
        ...c,
        key: idx,
        start_timestamp: moment(
          c.start_timestamp,
          "YYYY-MM-DD HH:mm:ss"
        ).format("YYYY-MM-DD HH:mm"),
        end_timestamp: moment(c.end_timestamp, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DD HH:mm"
        ),
      })),
    [sensorConfigs]
  );

  return (
    <MonitoringCard
      title={title}
      tableColumns={tableColumns}
      selectedKey={
        memoizedSensorConfigs?.find(
          ({ sensor_id }) => sensor_id === selectedSensorConfig?.sensor_id
        )?.key
      }
      data={memoizedSensorConfigs}
      loading={loading}
      error={error}
      scroll={{ x: true }}
      onClick={onClick}
      defaultPageSize={20}
    />
  );
};

export default SensorConfigurationTable;
