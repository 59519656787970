import { Empty } from "antd";
import React from "react";
import { Trans } from "react-i18next";

import { WinddeskCard } from "src/components/Common/WinddeskCard";
import SimulationConfigsFieldGroup from "src/components/SimulationConfigs/SimulationConfigsFieldGroup";
import { CardData } from "src/networking/types";

interface Props {
  cardData: CardData;
}

const SimulationConfigsFieldsCollection: React.FC<Props> = ({ cardData }) => {
  const { name, children, common_prefix: commonPrefix } = cardData;

  const noConfigFields = children.length === 0;

  return (
    <WinddeskCard title={name}>
      {noConfigFields ? (
        <Empty
          description={
            <Trans i18nKey="noConfigMessage">
              No primary config for {{ name }}
            </Trans>
          }
        />
      ) : (
        <SimulationConfigsFieldGroup
          cardData={cardData}
          accessor={commonPrefix}
          hiddenConfigActionKeys={[]}
          disabledConfigActionKeys={[]}
        />
      )}
    </WinddeskCard>
  );
};
export default SimulationConfigsFieldsCollection;
