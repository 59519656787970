import { TimeSeriesInterval } from "src/networking/types";
import { SensorsActionTypes } from "src/redux/actionTypes";
import { getActionTypeForSuccess } from "src/redux/utils";

export interface SensorsState {
  timeSeries: Record<
    string,
    Record<string, Record<string, [string, number][]>>
  >;
  timeSeriesIntervals: Record<
    string,
    Record<string, Record<string, TimeSeriesInterval[]>>
  >;
}

const initialState = {
  timeSeries: {},
  timeSeriesIntervals: {},
};

const reducer = (
  state: SensorsState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action: any
): SensorsState => {
  switch (action.type) {
    case getActionTypeForSuccess(
      SensorsActionTypes.GET_TIME_SERIES_FOR_SENSOR_REQUEST
    ):
      return {
        ...state,
        timeSeries: {
          ...state.timeSeries,
          [action.meta.previousAction.metadata.projectId]: {
            ...state.timeSeries[action.meta.previousAction.metadata.projectId],
            [action.meta.previousAction.metadata.siteName]: {
              ...state.timeSeries[
                action.meta.previousAction.metadata.projectId
              ]?.[action.meta.previousAction.metadata.siteName],
              [action.meta.previousAction.metadata.sensorName]:
                action.payload.data,
            },
          },
        },
      };
    case getActionTypeForSuccess(
      SensorsActionTypes.GET_TIME_SERIES_SAMPLING_RATES_FOR_SENSOR_REQUEST
    ):
      return {
        ...state,
        timeSeriesIntervals: {
          ...state.timeSeriesIntervals,
          [action.meta.previousAction.metadata.projectId]: {
            ...state.timeSeriesIntervals[
              action.meta.previousAction.metadata.projectId
            ],
            [action.meta.previousAction.metadata.siteName]: {
              ...state.timeSeriesIntervals[
                action.meta.previousAction.metadata.projectId
              ]?.[action.meta.previousAction.metadata.siteName],
              [action.meta.previousAction.metadata.sensorName]:
                action.payload.data,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
