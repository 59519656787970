import { Card } from "antd";
import React, { ReactElement } from "react";
import styled from "styled-components";

interface DescriptionItem {
  title: string;
  subtitle: string;
  emphasis?: boolean;
}

interface Props {
  loading?: boolean;
  avatar: ReactElement;
  title: string;
  description: DescriptionItem[];
}

const InfoCardComponent = styled(Card)`
  height: 100%;

  & > .ant-card-body {
    height: 100%;
    & > .ant-card-meta {
      height: 100%;
    }
  }

  & .ant-card-meta-avatar {
    float: none;
  }

  & .ant-card-meta {
    display: flex;
    align-items: center;
  }

  & .ant-card-meta-detail {
    flex: 1;
    align-self: flex-start;
  }

  & .ant-card-meta-title {
    font-weight: 700;
    font-size: 1.3rem;
    color: #5495c4;
    margin: 0 !important;
    font-family: "Blender Pro", sans-serif;
  }

  & .ant-card-meta-description {
    display: flex;
    color: #262626;
    font-weight: 300;

    & > * {
      flex: 1 1 0;
    }
  }
`;

const InfoCardDescriptionSubtitle = styled.p`
  margin-bottom: 0;
  color: #262626;
`;

interface InfoCardDescriptionTitleProps {
  emphasis?: boolean;
}

const InfoCardDescriptionTitle = styled.p<InfoCardDescriptionTitleProps>`
  line-height: ${({ emphasis }) => (emphasis ? 1 : 1.5)};
  font-weight: 700;
  font-size: ${({ emphasis }) => (emphasis ? "2.5rem" : "1.7rem")};
  margin-bottom: 0;
  color: #595959;
`;

/* eslint-disable react/no-array-index-key */
const InfoCard: React.FC<Props> = ({ loading, avatar, title, description }) => (
  <InfoCardComponent size="small" loading={loading}>
    <Card.Meta
      avatar={avatar}
      title={title}
      description={description.map(
        ({ title: descriptionTitle, subtitle, emphasis }, idx) => (
          <div key={idx}>
            <InfoCardDescriptionTitle emphasis={emphasis}>
              {descriptionTitle}
            </InfoCardDescriptionTitle>
            <InfoCardDescriptionSubtitle>
              {subtitle}
            </InfoCardDescriptionSubtitle>
          </div>
        )
      )}
    />
  </InfoCardComponent>
);

export default InfoCard;
