import { Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WinddeskCol from "src/components/Common/WinddeskCol";
import Details from "src/components/Monitor/SiteConfig/Details";
import SensorConfigurationTable from "src/components/Monitor/SiteConfig/SensorConfigurationTable";
import SiteConfigurationTable from "src/components/Monitor/SiteConfig/SiteConfigurationTable";
import MeasurementSiteImageCard from "src/components/Monitor/shared/MeasurementSiteImageCard";
import SensorImageCard from "src/components/Monitor/shared/SensorImageCard";
import SiteConfigurationImageCard from "src/components/Monitor/shared/SiteConfigurationImageCard";
import { SensorConfiguration, Site, WorkflowType } from "src/networking/types";
import { getSensorConfigsForSite } from "src/redux/actions/sites";
import { GlobalState } from "src/redux/reducers";
import { useAuth0 } from "src/utils/react-auth0-spa";

interface Props {
  site: Site;
}

const SiteConfig: React.FC<Props> = ({ site }) => {
  const { getTokenSilently } = useAuth0();

  const dispatch = useDispatch();
  const getSensorConfigsForSiteState = useSelector(
    (state: GlobalState) => state.requests.GET_SENSOR_CONFIGS_FOR_SITE_REQUEST
  );

  const siteName = site?.name;
  const projectId = site?.project;

  const [selectedSensor, setSelectedSensor] = useState<
    SensorConfiguration | undefined
  >();
  const [showSiteDetails, setShowSiteDetails] = useState<boolean>(true);

  useEffect(() => {
    if (siteName && projectId) {
      getTokenSilently().then((token: string) => {
        dispatch(
          getSensorConfigsForSite(
            projectId,
            siteName,
            token,
            WorkflowType.MonitorFull
          )
        );
      });
    }
  }, [dispatch, siteName, projectId, getTokenSilently]);

  const sensorConfigs = getSensorConfigsForSiteState?.data;

  const onSelectSensorRow = (sensor: SensorConfiguration) => {
    setShowSiteDetails(false);
    setSelectedSensor(sensor);
  };

  return (
    <Row gutter={16}>
      <WinddeskCol span={12}>
        <SiteConfigurationTable
          site={site}
          selectedSiteName={showSiteDetails ? site.name : ""}
          onClick={setShowSiteDetails}
        />
        <SensorConfigurationTable
          title="Sensor Information"
          sensorConfigs={sensorConfigs}
          selectedSensorConfig={showSiteDetails ? undefined : selectedSensor}
          loading={getSensorConfigsForSiteState.isLoading}
          error={getSensorConfigsForSiteState.error}
          columns={
            site.type === "met_mast"
              ? [
                  "sensor_weak_name",
                  "sensor_type",
                  "sensor_height",
                  "target_boom_orientation",
                  "start_timestamp",
                  "end_timestamp",
                  "sensor_data_coverage_ratio",
                  "status",
                ]
              : [
                  "sensor_weak_name",
                  "sensor_type",
                  "start_timestamp",
                  "end_timestamp",
                  "sensor_data_coverage_ratio",
                  "status",
                ]
          }
          onClick={onSelectSensorRow}
        />
      </WinddeskCol>
      <WinddeskCol span={8}>
        <Details
          site={site}
          sensorConfig={selectedSensor}
          showSite={showSiteDetails}
        />
      </WinddeskCol>
      <WinddeskCol span={4}>
        <MeasurementSiteImageCard projectId={projectId} siteName={siteName} />
        <SiteConfigurationImageCard projectId={projectId} siteName={siteName} />
        <SensorImageCard
          projectId={projectId}
          siteName={siteName}
          sensorName={selectedSensor?.sensor_id}
        />
      </WinddeskCol>
    </Row>
  );
};

export default SiteConfig;
