import { keyBy } from "lodash";

import { Project } from "src/networking/types";
import { ProjectsActionTypes } from "src/redux/actionTypes";
import { getActionTypeForSuccess } from "src/redux/utils";

export interface ProjectsState {
  projects: Record<string, Project>;
}

const initialState = {
  projects: {},
};

const reducer = (
  state: ProjectsState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action: any
): ProjectsState => {
  switch (action.type) {
    case getActionTypeForSuccess(
      ProjectsActionTypes.CLEAR_AND_GET_PROJECTS_REQUEST
    ):
      return {
        ...state,
        projects: keyBy(action.payload.data, "id"),
      };
    case getActionTypeForSuccess(
      ProjectsActionTypes.GET_PROJECT_DETAILS_REQUEST
    ):
    case getActionTypeForSuccess(ProjectsActionTypes.CREATE_PROJECT_REQUEST):
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.data.id]: action.payload.data,
        },
      };
    case getActionTypeForSuccess(ProjectsActionTypes.UPDATE_PROJECT_REQUEST):
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.data.id]: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default reducer;
