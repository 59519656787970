import { SendOutlined, UndoOutlined } from "@ant-design/icons";
import { Modal, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colors from "src/colors";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import { useProjectSelectionState } from "src/components/Projects/hooks";
import { usePresentationConfigForSimulation } from "src/components/SimulationConfigs/hooks";
import { getConfigNatureForCardConfigs } from "src/components/SimulationConfigs/utils";
import { useSimulationSelectionState } from "src/components/Simulations/hooks";
import { CardData } from "src/networking/types";
import { notifyRunWorkflow, unlockWorkflow } from "src/redux/actions/configs";
import { GlobalState } from "src/redux/reducers";
import { canNotifyRunWorkflows, canUnlockWorkflows } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";

type Props = {
  projectId: string;
  simulationId: string;
};

const SimulationConfigurationSummary: React.FC<Props> = ({
  projectId,
  simulationId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Workflow name",
      dataIndex: "name",
      key: "name",
      render: (name: string, record: any) => (
        <Link
          to={`/predict/projects/${projectId}/simulations/${simulationId}/configurations/${record.key}`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: "Nature",
      key: "tags",
      dataIndex: "configNatures",
      render: (configNatures: string[]) => (
        <>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {configNatures.map((tag) => (
            <Tag color={colors.primary} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          ))}
        </>
      ),
    },
  ];

  const presentationConfigForWorkflow =
    usePresentationConfigForSimulation(simulationId);
  const data = (presentationConfigForWorkflow ?? []).map(
    ({ name, children }: CardData) => ({
      key: name,
      name: t(name),
      configNatures: getConfigNatureForCardConfigs(children),
    })
  );

  const currentProjectId = useProjectSelectionState()[0];
  const currentSimulationId = useSimulationSelectionState()[0];

  const isSimulationLocked = useSelector((state: GlobalState) =>
    currentSimulationId !== undefined
      ? state.configs.configs[Number(currentSimulationId)]?.locked
      : undefined
  );

  const actionState = useSelector((state: GlobalState) =>
    isSimulationLocked
      ? state.requests.NOTIFY_RUN_WORKFLOW_REQUEST
      : state.requests.UNLOCK_WORKFLOW_REQUEST
  );

  useEffect(() => {
    const clearAction = isSimulationLocked
      ? { type: "NOTIFY_RUN_WORKFLOW_REQUEST_CLEAR" }
      : { type: "UNLOCK_WORKFLOW_REQUEST_CLEAR" };
    if (actionState.error) {
      dispatch(clearAction);
      Modal.error({
        content: isSimulationLocked
          ? "Error when running workflow."
          : "Error in unsubmit.",
      });
    } else if (actionState.isCompleted) {
      dispatch(clearAction);
      Modal.success({
        content: isSimulationLocked
          ? "Workflow has been successfully submitted to run queue. Configuration will be locked in the meantime."
          : "Workflow has been successfully unsubmited.",
      });
    }
  }, [actionState, isSimulationLocked, dispatch]);

  const { getTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently().then((token: string) => {
      setAuthToken(token);
    });
  }, [getTokenSilently]);

  if (authToken === undefined || !currentProjectId || !currentSimulationId) {
    return null;
  }

  const handleSubmitJob = () => {
    getTokenSilently().then((token: string) => {
      dispatch(
        isSimulationLocked
          ? unlockWorkflow(currentProjectId, currentSimulationId, token)
          : notifyRunWorkflow(currentProjectId, currentSimulationId, token)
      );
    });
  };

  return (
    <WinddeskCard
      title="Configuration Summary"
      icon={isSimulationLocked ? <UndoOutlined /> : <SendOutlined />}
      buttonText={isSimulationLocked ? "Unsubmit Job" : "Submit Job"}
      buttonOnClick={handleSubmitJob}
      loading={actionState.isLoading}
      buttonEnabled={
        isSimulationLocked
          ? canUnlockWorkflows(authToken)
          : canNotifyRunWorkflows(authToken)
      }
      buttonDisabledTooltip="Inform company administrator to run workflow"
    >
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: true }}
        pagination={false}
      />
    </WinddeskCard>
  );
};
export default SimulationConfigurationSummary;
