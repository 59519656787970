import { Button, Popconfirm, Tabs } from "antd";
import { isEqual } from "lodash";
import "src/components/SimulationConfigs/SimulationConfigsFieldGroup/index.css";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SimulationConfigsFieldGroupForm from "src/components/SimulationConfigs/SimulationConfigsFieldGroupForm";
import { useValuesLengthWithPrefilledLinkageUpdate } from "src/components/SimulationConfigs/hooks";
import { getAndWarnDynamicButtonConfig } from "src/components/SimulationConfigs/utils";
import { getFieldValue } from "src/components/SimulationConfigs/utils/formValues";
import { Accessor, CardData } from "src/networking/types";

import { addFormList, removeFormList } from "src/redux/actions/configs";
import { GlobalState } from "src/redux/reducers";

const { TabPane } = Tabs;

interface Props {
  cardData: CardData;
  accessor: Accessor;
  hiddenConfigActionKeys: string[];
  disabledConfigActionKeys: string[];
}

const getTitleLinkageAccessor = (
  titleLinkage: string[],
  accessor: Accessor
): [Accessor, Accessor] => {
  const res = [];
  let titleLinkageIndex = 0;
  let accessorIndex = 0;

  while (accessorIndex < accessor.length) {
    if (titleLinkage[titleLinkageIndex] === accessor[accessorIndex]) {
      res.push(titleLinkage[titleLinkageIndex]);
      titleLinkageIndex += 1;
      accessorIndex += 1;
    } else if (typeof accessor[accessorIndex] === "number") {
      res.push(accessor[accessorIndex]);
      accessorIndex += 1;
    } else {
      throw new Error(
        `Cannot derive actual title linkage from supplied titleLinkage (${titleLinkage}) and accessor (${accessor}).`
      );
    }
  }

  return [res, titleLinkage.slice(titleLinkageIndex)];
};

interface InnerProps {
  cardData: CardData;
  accessor: Accessor;
  hiddenConfigActionKeys: string[];
  disabledConfigActionKeys: string[];

  // These two are only here to make antd magic work
  tab: string; // title of the tab
  [k: string]: unknown;
}

const FieldGroupTabsInner: React.FC<InnerProps> = React.memo(
  ({
    tab,
    cardData,
    accessor,
    hiddenConfigActionKeys,
    disabledConfigActionKeys,
    ...otherProps
  }) => (
    /* eslint-disable react/jsx-props-no-spreading */
    <TabPane tab={tab} {...otherProps}>
      <SimulationConfigsFieldGroupForm
        cardData={cardData}
        accessor={accessor}
        disabled={() => false}
        hiddenConfigActionKeys={hiddenConfigActionKeys}
        disabledConfigActionKeys={disabledConfigActionKeys}
      />
    </TabPane>
    /* eslint-enable react/jsx-props-no-spreading */
  ),
  isEqual
);

const FieldGroupTabs: React.FC<Props> = ({
  cardData,
  accessor,
  hiddenConfigActionKeys,
  disabledConfigActionKeys,
}) => {
  const dispatch = useDispatch();
  const [valuesLength, hasPrefilledLinkageValue] =
    useValuesLengthWithPrefilledLinkageUpdate(accessor, cardData);

  const debugForms = useSelector((state: GlobalState) => state.configs.debug);

  const [activeKey, setActiveKey] = useState<string>("0");

  const { name } = cardData;

  const dynamicListButtonConfig = getAndWarnDynamicButtonConfig(cardData);
  if (!dynamicListButtonConfig) {
    throw new Error(`No dynamic list button config found in tab ${name}`);
  }

  const tabBarExtraContent = hasPrefilledLinkageValue
    ? undefined
    : {
        left: (
          <Button
            style={{ marginRight: "1em" }}
            onClick={() => {
              setActiveKey(valuesLength.toString());
              dispatch(addFormList(accessor));
            }}
          >
            {dynamicListButtonConfig.display_name}
            {debugForms ? ` (${JSON.stringify(accessor)})` : ""}
          </Button>
        ),
        right:
          valuesLength === 0 ? (
            <Button disabled>Remove selected tab</Button>
          ) : (
            <Popconfirm
              title="Confirm delete selected tab?"
              onConfirm={() => {
                dispatch(removeFormList(accessor, Number(activeKey)));
                setActiveKey("0");
              }}
            >
              <Button>Remove selected tab</Button>
            </Popconfirm>
          ),
      };

  const titles = useSelector((state: GlobalState) => {
    if (dynamicListButtonConfig.title_linkage.length === 0) {
      return [];
    }

    const [parentAccessor, childAccessor] = getTitleLinkageAccessor(
      dynamicListButtonConfig.title_linkage,
      accessor
    );

    const parentResult = getFieldValue(
      state.configs.formValues,
      parentAccessor,
      "array"
    );
    return parentResult
      ? (parentResult as any[]).map((v) =>
          getFieldValue(v, childAccessor, "stringOrNumber")
        )
      : parentResult;
  }, shallowEqual) as string[];

  return (
    <Tabs
      onChange={setActiveKey}
      activeKey={activeKey}
      tabBarExtraContent={tabBarExtraContent}
      className="traverse-form-tab"
    >
      <>
        {[...Array(valuesLength).keys()].map((index) => (
          <FieldGroupTabsInner
            key={index}
            tab={titles[index] || "New tab"}
            cardData={cardData}
            accessor={accessor.concat([index])}
            hiddenConfigActionKeys={hiddenConfigActionKeys}
            disabledConfigActionKeys={disabledConfigActionKeys}
          />
        ))}
      </>
    </Tabs>
  );
};

export default FieldGroupTabs;
