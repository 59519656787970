import { Alert, Button, Typography } from "antd";
import { isEqual } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DiffAlertTable from "src/components/SimulationConfigs/DiffAlert/DiffAlertTable";
import { useDiffsWithCurrentSubworkflowName } from "src/components/SimulationConfigs/hooks";
import { Diff } from "src/networking/types";

import { applyDiffs as applyDiffsAction } from "src/redux/actions/configs";
import { GlobalState } from "src/redux/reducers";

const { Paragraph } = Typography;

type Props = {
  configCommitId?: number;
  currentSubworkflowName: string;
};

const DiffAlert: React.FC<Props> = ({
  currentSubworkflowName,
  configCommitId,
}) => {
  const presentationConfig = useSelector(
    (state: GlobalState) => state.configs.presentationConfig,
    isEqual
  );

  const {
    diffsFromBackend,
    overlappingDiffs,
    nonOverlappingSameSubworkflowDiffs,
    newestConfigCommitId,
    diffsFromBackendAndUser,
    differentSubworkflowDiffs,
  } = useDiffsWithCurrentSubworkflowName(
    configCommitId,
    currentSubworkflowName
  );

  const dispatch = useDispatch();
  const [showDiffs, setShowDiffs] = useState<boolean>(false);
  const [diffsToApply, setDiffsToApply] = useState<Diff[]>([]);

  if (
    newestConfigCommitId !== undefined &&
    diffsFromBackendAndUser.length === 0 &&
    diffsFromBackend?.length !== 0
  ) {
    dispatch(applyDiffsAction([], [], newestConfigCommitId));
    return null;
  }

  if (
    !presentationConfig ||
    !newestConfigCommitId ||
    diffsFromBackendAndUser.length === 0
  ) {
    return null;
  }

  const updateDiffs = () => {
    dispatch(
      applyDiffsAction(
        diffsFromBackendAndUser,
        diffsToApply.concat(differentSubworkflowDiffs),
        newestConfigCommitId
      )
    );
  };

  return (
    <>
      <Alert
        style={{ zIndex: 11 }}
        message="There has been new changes from other users"
        description={
          showDiffs ? (
            <>
              <Paragraph>
                Other users edited parts of this and other SubWorkflows while
                you were editing this page. Please follow the instructions below
                carefully to resolve your changes with other users&apos;
                changes.
              </Paragraph>
              <Paragraph>
                After you have read the instructions below carefully and taken
                the appropriate actions, you can click either of the two buttons
                below for final resolution. The &quot;Resolve&quot; button
                resolves and merges your changes with other users&apos; changes.
                The &quot;Discard All My Changes and Reset&quot; button destroys
                all your changes and resets the entire page (this is dangerous).
              </Paragraph>

              <Button
                onClick={updateDiffs}
                disabled={
                  diffsToApply.length !==
                  overlappingDiffs.length +
                    nonOverlappingSameSubworkflowDiffs.length
                }
              >
                Resolve
              </Button>
              <Button danger onClick={() => window.location.reload()}>
                Discard All My Changes and Reset
              </Button>

              <DiffAlertTable
                title={`Conflicted changes in this subworkflow (${currentSubworkflowName})`}
                description="Other users edited these fields while you were editing them. You need to resolve the conflict by checking which value you would like to adopt."
                conflictingDiffs={overlappingDiffs}
                diffsToApply={diffsToApply}
                setValue={(diff) =>
                  setDiffsToApply(
                    diffsToApply
                      .filter((d) => !isEqual(d.accessor, diff.accessor))
                      .concat([diff])
                  )
                }
              />

              <DiffAlertTable
                title={`Non conflicted changes in this subworkflow (${currentSubworkflowName})`}
                description="Other users edited these fields while you were on this page. You have NOT edited these fields and they are not conflicted. You can either accept the other users' edits or discard them by reverting to the previous value."
                diffs={nonOverlappingSameSubworkflowDiffs}
                diffsToApply={diffsToApply}
                setValue={(diff) =>
                  setDiffsToApply(
                    diffsToApply
                      .filter((d) => !isEqual(d.accessor, diff.accessor))
                      .concat([diff])
                  )
                }
              />

              <DiffAlertTable
                title="Non conflicted changes in other subworkflows"
                description="Other users edited these fields on other SubWorkflows (they are not on this page). You have NOT edited these fields and they are not conflicted. They will be automatically merged. There is no action required by you for these fields."
                diffs={differentSubworkflowDiffs}
                showSubworkflow
                diffsToApply={diffsToApply}
              />
            </>
          ) : (
            <br />
          )
        }
        type="info"
        banner
        action={
          <>
            <Button size="small" onClick={() => setShowDiffs(!showDiffs)}>
              {showDiffs ? "Hide" : "Show"} details
            </Button>
          </>
        }
      />
      <br />
    </>
  );
};

export default DiffAlert;
