import Axios from "axios";
import mockServer from "src/networking/mockServer";

// Must execute before creation of client
if (process.env.REACT_APP_MOCK === "true") mockServer();

const winddeskBaseUrl =
  process.env.REACT_APP_HYDRA_WINDDESK_BASE_URL || "http://localhost:8000";

const argosClient = Axios.create({
  baseURL: process.env.REACT_APP_ARGOS_BASE_URL || "http://localhost:8001",
  responseType: "json",
});

const hydraHttpClient = Axios.create({
  baseURL: process.env.REACT_APP_HYDRA_HTTP_BASE_URL || "http://localhost:8002",
  responseType: "json",
});
// eslint-disable-next-line
console.log(process.env.REACT_APP_HYDRA_HTTP_BASE_URL);

const hydraWinddeskClient = Axios.create({
  baseURL: winddeskBaseUrl,
  responseType: "json",
});

export { argosClient, hydraHttpClient, hydraWinddeskClient, winddeskBaseUrl };
