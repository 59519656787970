import { isEqual } from "lodash";
import {
  Accessor,
  CardData,
  PresentationConfig,
  WorkflowConfigCardType,
  WorkflowConfigDataType,
  WorkflowConfigField,
  WorkflowConfigUiType,
  WorkflowConfigValueType,
  WorkflowType,
  isCardData,
} from "src/networking/types";

export const getConfigNatureForCardConfigs = (
  configs: (WorkflowConfigField | CardData)[]
): string[] =>
  [
    ...new Set(
      configs.flatMap((cardOrField) =>
        isCardData(cardOrField)
          ? getConfigNatureForCardConfigs(cardOrField.children)
          : cardOrField.config_nature
      )
    ),
  ].filter((v) => !!v);

export const isDataTypeANumber = (type: string): boolean =>
  type === WorkflowConfigDataType.Integer ||
  type === WorkflowConfigDataType.Decimal ||
  type === WorkflowConfigDataType.Percentage;

export const isConfigButton = (c: WorkflowConfigField): boolean =>
  c.value_type === WorkflowConfigValueType.Action &&
  c.ui_type === WorkflowConfigUiType.Button;

const subWorkflowNamesInWorkflowType: { [id: string]: Set<string> } = {
  [WorkflowType.Predict]: new Set([
    "Predict_Run_Overview",
    "Predict_Run_General_Setup",
    "Predict_Run_Wind_Data_Setup",
    "Predict_Run_GIS_Data_Setup",
    "Predict_Run_Wind_Turbine_Setup",
    "Predict_Run_Electrical_Data_Setup",
    "Predict_Run_Civil_Data_Setup",
    "Predict_Run_Financial_Data_Setup",
    "Predict_Run_Wind_Resource",
    "Predict_Run_Site_Conditions",
    "Predict_Run_Wind_Farm_Configuration",
    "Predict_Run_Energy_and_Uncertainty",
    "Predict_Run_Civil_Design_and_Costing",
    "Predict_Run_Electrical_Design_and_Costing",
    "Predict_Run_Financial_Model",
    "Predict_Run_Techno_Environmental",
    "Predict_Run_Techno_Social",
  ]),
  [WorkflowType.Prospect]: new Set([
    "Predict_Run_Overview",
    "Predict_Run_General_Setup",
    "Prospect_Run_Wind_Data_Setup",
    "Predict_Run_GIS_Data_Setup",
    "Predict_Run_Wind_Turbine_Setup",
    "Predict_Run_Electrical_Data_Setup",
    "Predict_Run_Civil_Data_Setup",
    "Predict_Run_Financial_Data_Setup",
    "Prospect_Run_Wind_Resource",
    "Predict_Run_Site_Conditions",
    "Predict_Run_Wind_Farm_Configuration",
    "Prospect_Run_Energy",
    "Predict_Run_Civil_Design_and_Costing",
    "Predict_Run_Electrical_Design_and_Costing",
    "Predict_Run_Financial_Model",
    "Predict_Run_Techno_Environmental",
    "Predict_Run_Techno_Social",
  ]),
};

export const filterPresentationConfigByWorkflowType = (
  presentationConfig: CardData[],
  workflow_type: WorkflowType
): CardData[] => {
  const subWorkflowNames = subWorkflowNamesInWorkflowType[workflow_type];
  if (subWorkflowNames === undefined) {
    throw new Error(`Invalid workflow_type provided: ${workflow_type}`);
  }
  return presentationConfig.filter((pc) => subWorkflowNames.has(pc.name));
};

export const getAndWarnDynamicButtonConfig = ({
  name,
  children,
}: CardData): WorkflowConfigField | undefined => {
  const dynamicButtonConfigs = children.filter(
    (c): c is WorkflowConfigField =>
      !isCardData(c) && c.card_ui_type !== WorkflowConfigCardType.Empty
  );
  if (dynamicButtonConfigs.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(`More than one action button in ${name}`);
  }
  return dynamicButtonConfigs[0];
};

export const isUrl = (s: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(s);
    return true;
  } catch (_) {
    return false;
  }
};

export interface WorkflowConfigFieldWithNesting {
  workflowConfigField: WorkflowConfigField;
  cardNesting: string[];
}

const getWorkflowConfigFieldFromCardAndConfigName = (
  cardData: CardData,
  configName: string[],
  cardNesting: string[]
): WorkflowConfigFieldWithNesting | undefined => {
  for (const child of cardData.children) {
    if (isCardData(child)) {
      const res = getWorkflowConfigFieldFromCardAndConfigName(
        child,
        configName,
        cardNesting.concat([child.name])
      );
      if (res !== undefined) {
        return res;
      }
    } else if (isEqual(child.config_name, configName)) {
      return { workflowConfigField: child, cardNesting };
    }
  }

  return undefined;
};

// TODO optimise this function if need be
export const getWorkflowConfigFieldFromAccessor = (
  presentationConfig: PresentationConfig,
  accessor: Accessor
): WorkflowConfigFieldWithNesting | undefined => {
  const configName = accessor.filter((f): f is string => typeof f === "string");
  for (const cardData of presentationConfig.subworkflows) {
    const res = getWorkflowConfigFieldFromCardAndConfigName(
      cardData,
      configName,
      [cardData.name]
    );
    if (res !== undefined) {
      return res;
    }
  }

  return undefined;
};

export const hasPrefilledLinkageValue = (
  config: WorkflowConfigField
): boolean =>
  config.prefilled_parent_linkage.length !== 0 ||
  config.prefilled_child_linkage.length !== 0;
