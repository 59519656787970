import Maestrazgo from "src/components/Projects/publishedWindFarmAlternativesData/Maestrazgo.json";
import Demo from "src/components/Projects/publishedWindFarmAlternativesData/demo.json";

export const publishedWindFarmAlternativesData: Record<
  string,
  Record<string, any>[]
> = {
  1: Demo, // This is staging's demo project
  9: Demo, // This is AC Energy's demo project
  2: Maestrazgo, // This is Maestrazgo's real project for Forestalia
};
