import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WinddeskCol from "src/components/Common/WinddeskCol";
import { ReactComponent as BellIcon } from "src/components/Monitor/Summary/icons/bell.svg";
import { ReactComponent as CrossedBellIcon } from "src/components/Monitor/Summary/icons/crossedbell.svg";
import { ReactComponent as TimeIcon } from "src/components/Monitor/Summary/icons/time.svg";
import AlertsCard, {
  AlertsCardButton,
} from "src/components/Monitor/shared/AlertsCard";
import InfoCard from "src/components/Monitor/shared/InfoCard";
import { Site } from "src/networking/types";
import { getAlertsForSite } from "src/redux/actions/alerts";
import { GlobalState } from "src/redux/reducers";
import { useAuth0 } from "src/utils/react-auth0-spa";

interface Props {
  site: Site;
  projectTimezone: string;
}

const Alerts: React.FC<Props> = ({ site, projectTimezone }) => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const getAlertsForSiteState = useSelector(
    (state: GlobalState) => state.requests.GET_ALERTS_FOR_SITE_REQUEST
  );

  useEffect(() => {
    getTokenSilently().then((token: string) => {
      // Do not get alerts resolved from one week ago
      dispatch(
        getAlertsForSite(
          site.project,
          site.name,
          token,
          Math.floor(Date.now() / 1000) - 604800
        )
      );
    });
  }, [dispatch, site, getTokenSilently]);

  const alertsState = useSelector((state: GlobalState) => state.alerts);

  const alerts = Object.values(alertsState.alerts).filter(
    (alert) => alert.site_name === site.name
  );

  const resolvedAlertTimes = alerts.flatMap(
    ({ resolved_by_id, resolve_time, created_at }) =>
      resolved_by_id && resolve_time ? [resolve_time - created_at] : []
  );
  const averageResolveDays =
    alerts.length === 0
      ? undefined
      : resolvedAlertTimes.reduce((a, b) => a + b, 0) / alerts.length / 86400;

  return (
    <Row gutter={16}>
      <Col span={8}>
        <InfoCard
          loading={getAlertsForSiteState.isLoading}
          avatar={<BellIcon />}
          title="Active Alerts"
          description={[
            {
              title: alerts
                .filter(({ resolved_by_id }) => !resolved_by_id)
                .length.toString(),
              subtitle: "Total",
              emphasis: true,
            },
            {
              title: alerts
                .filter(({ resolved_by_id }) => !resolved_by_id)
                .filter(({ priority }) => priority === "HIGH")
                .length.toString(),
              subtitle: "Major",
            },
            {
              title: alerts
                .filter(({ resolved_by_id }) => !resolved_by_id)
                .filter(({ priority }) => priority === "MEDIUM")
                .length.toString(),
              subtitle: "Minor",
            },
            {
              title: alerts
                .filter(({ resolved_by_id }) => !resolved_by_id)
                .filter(({ priority }) => priority === "LOW")
                .length.toString(),
              subtitle: "Warnings",
            },
          ]}
        />
      </Col>
      <Col span={8}>
        <InfoCard
          loading={getAlertsForSiteState.isLoading}
          avatar={<CrossedBellIcon />}
          title="Resolved Alerts"
          description={[
            {
              title: alerts
                .filter(({ resolved_by_id }) => resolved_by_id)
                .length.toString(),
              subtitle: "Total",
              emphasis: true,
            },
            {
              title: alerts
                .filter(({ resolved_by_id }) => resolved_by_id)
                .filter(({ priority }) => priority === "HIGH")
                .length.toString(),
              subtitle: "Major",
            },
            {
              title: alerts
                .filter(({ resolved_by_id }) => resolved_by_id)
                .filter(({ priority }) => priority === "MEDIUM")
                .length.toString(),
              subtitle: "Minor",
            },
            {
              title: alerts
                .filter(({ resolved_by_id }) => resolved_by_id)
                .filter(({ priority }) => priority === "LOW")
                .length.toString(),
              subtitle: "Warnings",
            },
          ]}
        />
      </Col>
      <Col span={8}>
        <InfoCard
          loading={getAlertsForSiteState.isLoading}
          avatar={<TimeIcon />}
          title="Average Resolution Time"
          description={[
            {
              title: averageResolveDays
                ? `${averageResolveDays.toFixed(2)} days`
                : "-",
              subtitle: "",
            },
          ]}
        />
      </Col>
      <WinddeskCol span={24}>
        <AlertsCard
          title="Active Alerts"
          alerts={alerts.filter(({ resolved_by_id }) => !resolved_by_id)}
          loading={getAlertsForSiteState.isLoading}
          error={getAlertsForSiteState.error}
          tableFilters={false}
          startingShownColumns={[
            "sensorId",
            "sensorType",
            "priority",
            "nature",
            "message",
            "activationTime",
            "status",
          ]}
          noSelect
          buttonsToShow={[AlertsCardButton.SNOOZE, AlertsCardButton.RESOLVE]}
          timeZoneOffset={projectTimezone}
        />
        <AlertsCard
          title="Resolved Alerts"
          alerts={alerts.filter(({ resolved_by_id }) => !!resolved_by_id)}
          loading={getAlertsForSiteState.isLoading}
          error={getAlertsForSiteState.error}
          tableFilters={false}
          startingShownColumns={[
            "sensorId",
            "sensorType",
            "priority",
            "nature",
            "message",
            "activationTime",
            "status",
          ]}
          noSelect
          buttonsToShow={[AlertsCardButton.UNRESOLVE]}
          timeZoneOffset={projectTimezone}
        />
      </WinddeskCol>
    </Row>
  );
};

export default Alerts;
