import React from "react";
import ImageCard from "src/components/Monitor/shared/ImageCard";

interface Props {
  projectId: string;
  siteName: string;
}

const MeasurementSiteImageCard: React.FC<Props> = ({ projectId, siteName }) => (
  <ImageCard
    url={`/v1/projects/${projectId}/sites/${siteName}/site_image?workflow_type=monitor_full`}
    title="Site Image"
  />
);

export default MeasurementSiteImageCard;
