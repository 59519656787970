import { filter, isEqual } from "lodash";
import React from "react";

import { useSelector } from "react-redux";
import { WinddeskSubcard } from "src/components/Common/WinddeskSubcard";
import CardConfigVisualizer from "src/components/SimulationConfigs/CardConfigVisualizer";
import SimulationConfigsField from "src/components/SimulationConfigs/SimulationConfigsField";
import SimulationConfigsFieldGroup from "src/components/SimulationConfigs/SimulationConfigsFieldGroup";
import {
  getSubElementForCardData,
  useSubElementForCardData,
} from "src/components/SimulationConfigs/SimulationConfigsFieldGroup/utils";
import { isConfigButton } from "src/components/SimulationConfigs/utils";
import { getFieldValue } from "src/components/SimulationConfigs/utils/formValues";
import {
  Accessor,
  CardData,
  WorkflowConfigActionType,
  WorkflowConfigField,
  WorkflowConfigUiType,
  WorkflowConfigValueType,
  isCardData,
} from "src/networking/types";

import { GlobalState } from "src/redux/reducers";

interface Props {
  cardData: CardData;
  accessor: Accessor;
  hiddenConfigActionKeys: string[];
  disabledConfigActionKeys: string[];
}

interface OtherProps {
  [fieldProp: string]: (f: WorkflowConfigField) => unknown;
}

const concatAccessorWithCommonPrefix = (
  accessor: Accessor,
  commonPrefix: string[]
): Accessor => {
  const accessorWithoutFields = filter(accessor, (x) => typeof x === "string");
  if (
    !isEqual(
      accessorWithoutFields,
      commonPrefix.slice(0, accessorWithoutFields.length)
    )
  ) {
    // When this error is thrown, we should fix it promptly as this code in function does not work if this assumption does not hold
    console.error(
      "Accessor and common prefix mismatch",
      accessorWithoutFields,
      commonPrefix
    );
  }
  const postfix = commonPrefix.slice(accessorWithoutFields.length);
  if (commonPrefix.length > accessorWithoutFields.length) {
    return accessor.concat(postfix);
  }
  return accessor;
};

const SimulationConfigsFieldGroupForm: React.FC<Props | OtherProps> = (
  props
) => {
  /* eslint-disable react/destructuring-assignment */
  const cardData = props.cardData as CardData;
  const accessor = props.accessor as Accessor;
  const hiddenConfigActionKeys = props.hiddenConfigActionKeys as string[];
  const disabledConfigActionKeys = props.disabledConfigActionKeys as string[];
  /* eslint-enable react/destructuring-assignment */
  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    cardData: _cardData,
    accessor: _accessor,
    hiddenConfigActionKeys: _hiddenConfigActionKeys,
    disabledConfigActionKeys: _disabledConfigActionKeys,
    ...fieldProps
  } = props;
  /* eslint-enable @typescript-eslint/naming-convention */

  const commonPrefix = cardData.common_prefix;
  const fieldsConfig = useSubElementForCardData(cardData);
  const showOnlyPrimaryFields = useSelector(
    (state: GlobalState) => state.configs.showOnlyPrimaryFields
  );

  const debugForms = useSelector((state: GlobalState) => state.configs.debug);

  const togglesConfig = cardData.children.filter(
    (c): c is WorkflowConfigField =>
      !isCardData(c) &&
      !isConfigButton(c) &&
      c.value_type === WorkflowConfigValueType.Action &&
      c.ui_type === WorkflowConfigUiType.Toggle
  );

  const currentHiddenConfigActionKeys = useSelector(
    (state: GlobalState) =>
      hiddenConfigActionKeys.concat(
        togglesConfig.reduce((acc, curr) => {
          const fieldValue = getFieldValue(
            state.configs.formValues,
            accessor.concat(curr.config_name.slice(commonPrefix.length)),
            "boolean"
          );

          if (
            curr.config_action_group_selection &&
            ((fieldValue &&
              curr.config_action === WorkflowConfigActionType.SetHidden) ||
              (!fieldValue &&
                curr.config_action === WorkflowConfigActionType.SetVisible))
          ) {
            return acc.concat([curr.config_action_group_selection]);
          }

          return acc;
        }, [] as string[])
      ),
    isEqual
  );

  const currentDisabledConfigActionKeys = useSelector(
    (state: GlobalState) =>
      disabledConfigActionKeys.concat(
        togglesConfig.reduce((acc, curr) => {
          const fieldValue = getFieldValue(
            state.configs.formValues,
            accessor.concat(curr.config_name.slice(commonPrefix.length)),
            "boolean"
          );

          if (
            curr.config_action_group_selection &&
            ((fieldValue &&
              curr.config_action === WorkflowConfigActionType.SetDisable) ||
              (!fieldValue &&
                curr.config_action === WorkflowConfigActionType.SetEnable))
          ) {
            return acc.concat([curr.config_action_group_selection]);
          }

          return acc;
        }, [] as string[])
      ),
    isEqual
  );

  const renderSubCard = (card: CardData) => {
    if (getSubElementForCardData(card, showOnlyPrimaryFields).length === 0) {
      return null;
    }

    const cardName = debugForms
      ? `${card.name} (${JSON.stringify(accessor)})`
      : card.name;

    const newAccessor = concatAccessorWithCommonPrefix(
      accessor,
      card.common_prefix
    );

    return (
      <WinddeskSubcard
        key={`card-${card.name}-${commonPrefix}`}
        title={cardName}
      >
        {debugForms && (
          <CardConfigVisualizer
            cardData={card}
            accessor={newAccessor}
            hiddenConfigActionKeys={currentHiddenConfigActionKeys}
            disabledConfigActionKeys={currentDisabledConfigActionKeys}
          />
        )}
        <SimulationConfigsFieldGroup
          cardData={card}
          accessor={newAccessor}
          hiddenConfigActionKeys={currentHiddenConfigActionKeys}
          disabledConfigActionKeys={currentDisabledConfigActionKeys}
        />
      </WinddeskSubcard>
    );
  };

  const renderField = (field: WorkflowConfigField) => (
    /* eslint-disable react/jsx-props-no-spreading */
    <SimulationConfigsField
      key={`field-${field.display_name}-${field.config_name.join(".")}`}
      config={field}
      accessor={accessor.concat(field.config_name.slice(commonPrefix.length))}
      hiddenConfigActionKeys={currentHiddenConfigActionKeys}
      disabledConfigActionKeys={currentDisabledConfigActionKeys}
      {...Object.fromEntries(
        Object.entries(fieldProps).map(([propName, f]) => [propName, f(field)])
      )}
    />
  );
  /* eslint-enable react/jsx-props-no-spreading */
  return (
    <>
      {fieldsConfig.map((c: CardData | WorkflowConfigField) =>
        isCardData(c) ? renderSubCard(c) : renderField(c)
      )}
    </>
  );
};

export default SimulationConfigsFieldGroupForm;
