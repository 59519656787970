import { Accessor, Diff } from "src/networking/types";
import { ConfigsActionTypes } from "src/redux/actionTypes";

export interface ConfigsAction {
  type: ConfigsActionTypes;
  payload?: any;
  metadata?: any;
}

export function getConfigByWorkflowId(
  workflowId: string,
  token: string
): ConfigsAction {
  return {
    type: ConfigsActionTypes.GET_CONFIG_BY_WORKFLOW_ID_REQUEST,
    payload: {
      request: {
        url: `/v1/workflows/${workflowId}/configs/latest`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
    metadata: { workflowId },
  };
}

export function getPresentationConfig(token: string): ConfigsAction {
  return {
    type: ConfigsActionTypes.GET_PRESENTATION_CONFIG_REQUEST,
    payload: {
      request: {
        url: `/v1/presentation_configs`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getConfigFileData(
  workflowId: string,
  configName: string,
  token: string
): ConfigsAction {
  return {
    type: ConfigsActionTypes.GET_CONFIG_FILE_DATA_REQUEST,
    payload: {
      request: {
        url: `/v1/workflows/${workflowId}/configs/latest/data/${configName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function diffToLatestCommit(
  workflowId: string,
  configId: number,
  configCommitId: number,
  token: string
): ConfigsAction {
  return {
    type: ConfigsActionTypes.GET_DIFF_TO_LATEST_COMMIT_REQUEST,
    payload: {
      request: {
        url: `/v1/workflows/${workflowId}/configs/${configId}/config_commits/${configCommitId}/diff`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
    metadata: { configCommitId },
  };
}

export function saveConfigByWorkflowId(
  workflowId: string,
  configId: number,
  config: unknown,
  diff: unknown,
  message: string,
  parentConfigCommitIdCheck: number,
  token: string
): ConfigsAction {
  return {
    type: ConfigsActionTypes.SAVE_CONFIG_BY_WORKFLOW_ID_REQUEST,
    payload: {
      request: {
        method: "post",
        url: `/v1/workflows/${workflowId}/configs/${configId}/save`,
        data: {
          config,
          diff,
          message,
          parent_config_commit_id_check: parentConfigCommitIdCheck,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
    metadata: { workflowId },
  };
}

export function notifyRunWorkflow(
  projectId: string,
  workflowId: string,
  token: string
): ConfigsAction {
  return {
    type: ConfigsActionTypes.NOTIFY_RUN_WORKFLOW_REQUEST,
    payload: {
      request: {
        method: "post",
        url: `/v1/projects/${projectId}/workflows/${workflowId}/notify_run`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
    metadata: { workflowId },
  };
}

export function unlockWorkflow(
  projectId: string,
  workflowId: string,
  token: string
): ConfigsAction {
  return {
    type: ConfigsActionTypes.UNLOCK_WORKFLOW_REQUEST,
    payload: {
      request: {
        method: "post",
        url: `/v1/projects/${projectId}/workflows/${workflowId}/unlock`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
    metadata: { workflowId },
  };
}

export function setFormStateFromConfig(workflowId: string): ConfigsAction {
  return {
    type: ConfigsActionTypes.SET_FORM_STATE_FROM_CONFIG,
    payload: {
      workflowId,
    },
  };
}

export function updateFormState(diff: Diff): ConfigsAction {
  return {
    type: ConfigsActionTypes.UPDATE_FORM_STATE,
    payload: diff,
  };
}

export function applyDiffs(
  newDiffs: Diff[],
  combinedDiffs: Diff[],
  newConfigCommitId: number
): ConfigsAction {
  return {
    type: ConfigsActionTypes.APPLY_DIFFS,
    payload: { newDiffs, combinedDiffs, newConfigCommitId },
  };
}

export function resetFormState(): ConfigsAction {
  return {
    type: ConfigsActionTypes.RESET_FORM_STATE,
  };
}

export function addFormList(accessor: Accessor): ConfigsAction {
  return {
    type: ConfigsActionTypes.ADD_FORM_LIST,
    payload: {
      accessor,
    },
  };
}

export function removeFormList(
  accessor: Accessor,
  index: number
): ConfigsAction {
  return {
    type: ConfigsActionTypes.REMOVE_FORM_LIST,
    payload: {
      accessor,
      index,
    },
  };
}

export function modifyValidationErrors(
  accessor: Accessor,
  message: string | null
): ConfigsAction {
  return {
    type: ConfigsActionTypes.MODIFY_VALIDATION_ERRORS,
    payload: {
      accessor,
      message,
    },
  };
}

export function setShowPrimary(value: boolean): ConfigsAction {
  return {
    type: ConfigsActionTypes.SET_SHOW_PRIMARY,
    payload: value,
  };
}

export function setDebug(value: boolean): ConfigsAction {
  return {
    type: ConfigsActionTypes.SET_DEBUG,
    payload: value,
  };
}

export function setPromptOnLeave(value: boolean): ConfigsAction {
  return {
    type: ConfigsActionTypes.SET_PROMPT_ON_LEAVE,
    payload: value,
  };
}
