import { Divider, Layout } from "antd";
import React from "react";
import Credits from "src/components/Sider/Credits";
import styled from "styled-components";

const SiderComponent = styled(Layout.Sider)`
  background-color: white !important;
  height: calc(100vh - 60px);
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: auto;
`;

interface Props {
  cards: React.ReactNode[];
  width: string;
}

const Sider: React.FC<Props> = ({ cards, width }) => (
  <SiderComponent width={width}>
    {cards.flatMap((c, i) => (
      <React.Fragment key={i}>
        {c}
        <Divider />
      </React.Fragment>
    ))}
    <Credits />
  </SiderComponent>
);

export default Sider;
