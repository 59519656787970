import { useSelector } from "react-redux";
import { isConfigButton } from "src/components/SimulationConfigs/utils";
import {
  CardData,
  WorkflowConfigField,
  WorkflowConfigLevel,
  isCardData,
} from "src/networking/types";
import { GlobalState } from "src/redux/reducers";

export const getSubElementForCardData = (
  cardData: CardData,
  showOnlyPrimaryFields: boolean
): (CardData | WorkflowConfigField)[] => {
  const shouldDisplayField = (field: WorkflowConfigField): boolean => {
    if (isConfigButton(field)) {
      return false;
    }
    if (field.config_level === WorkflowConfigLevel.Primary) {
      return true;
    }
    return (
      field.config_level === WorkflowConfigLevel.Secondary &&
      !showOnlyPrimaryFields
    );
  };
  return cardData.children.filter((c) => {
    if (isCardData(c)) {
      return getSubElementForCardData(c, showOnlyPrimaryFields).length > 0;
    }
    return shouldDisplayField(c);
  });
};

export const useSubElementForCardData = (
  cardData: CardData
): (CardData | WorkflowConfigField)[] => {
  const showOnlyPrimaryFields = useSelector(
    (state: GlobalState) => state.configs.showOnlyPrimaryFields
  );
  return getSubElementForCardData(cardData, showOnlyPrimaryFields);
};
