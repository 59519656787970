import { Pagination } from "antd";
import React, { useState } from "react";
import MonitoringCard from "src/components/Monitor/shared/MonitoringCard";

interface Props {
  data?: any[][];
  loading: boolean;
  error?: Record<string, unknown>;
}

const SummaryStatisticsDataCard: React.FC<Props> = ({
  data,
  loading,
  error,
}) => {
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const showColumn = (idx: number) =>
    idx === 0 ||
    (idx >= (currentPage - 1) * pageSize + 1 &&
      idx < currentPage * pageSize + 1);

  const tableColumns =
    data && data.length > 0
      ? data[0].flatMap((d, i) =>
          showColumn(i)
            ? [
                {
                  title: d,
                  dataIndex: d,
                  key: d,
                  filters: undefined,
                  onFilter: undefined,
                },
              ]
            : []
        )
      : [];
  const tableData = data?.slice(1)?.map((row) => ({
    key: row[0],
    ...Object.fromEntries(
      row.flatMap((d, i) => (showColumn(i) ? [[data[0][i], d]] : []))
    ),
  }));

  return (
    <MonitoringCard
      title="Data Filtering Statistics"
      tableColumns={tableColumns}
      data={tableData}
      loading={loading}
      error={error}
      pagination={false}
    >
      <Pagination
        size="small"
        current={currentPage}
        pageSize={pageSize}
        total={data?.[0]?.length ?? 1 - 1}
        onChange={(page) => setCurrentPage(page)}
        style={{
          margin: "16px 0",
          display: "flex",
          justifyContent: "flex-end",
        }}
      />
    </MonitoringCard>
  );
};

export default SummaryStatisticsDataCard;
