import { ReactNode } from "react";
import { OptionProps } from "src/components/Sider/Selector";

export const makeOption = (
  value: string,
  label?: ReactNode,
  description?: string
): OptionProps => ({
  value,
  label: label ?? value,
  description: description ?? value,
});
