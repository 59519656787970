import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";

import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Typography,
} from "antd";
import Moment from "moment";

import React from "react";
import { getDefaultOptions } from "src/components/SimulationConfigs/SimulationConfigsField/defaults";
import SimulationConfigsFieldFileInput from "src/components/SimulationConfigs/SimulationConfigsFieldFileInput";
import { isDataTypeANumber } from "src/components/SimulationConfigs/utils";
import {
  Accessor,
  WorkflowConfigDataType,
  WorkflowConfigField,
  WorkflowConfigUiType,
  isEnum,
} from "src/networking/types";
import "src/components/SimulationConfigs/index.css";

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface GetSimulationConfigsSingleProps {
  config: WorkflowConfigField;
  disabled: boolean;
  onToggle?: (_: boolean) => void;
  value: any;
  onChangeValue: (newValue: any) => void;
}

interface GetSimulationConfigsRangeProps {
  config: WorkflowConfigField;
  disabled: boolean;
  value: any;
  onChangeValue: (newValue: any) => void;
}

interface GetSimulationConfigsOptionSelectorProps {
  config: WorkflowConfigField;
  disabled: boolean;
  options: string[];
  value: any;
  onChangeValue: (newValue: any) => void;
}

interface GetSimulationConfigsActionProps {
  config: WorkflowConfigField;
  disabled: boolean;
  accessor: Accessor;
  value: any;
  onChangeValue: (newValue: any) => void;
  onToggle?: (_: boolean) => void;
  onClick?: () => void;
  debugForms: boolean;
}

export const getSimulationConfigsSingle: React.FC<GetSimulationConfigsSingleProps> =
  ({ config, disabled, onToggle, value, onChangeValue }) => {
    switch (config.ui_type) {
      case WorkflowConfigUiType.Label:
        return config.field_type === WorkflowConfigDataType.Percentage ? (
          <InputNumber
            style={{ minWidth: 90 }}
            value={typeof value === "number" ? (value * 100).toFixed(0) : value}
            disabled={disabled}
            onChange={(v) => onChangeValue(typeof v === "number" ? v / 100 : v)}
            formatter={(v) => `${v}%`}
            parser={(v) => v?.replace("%", "") ?? ""}
            min={0}
            max={100}
          />
        ) : isDataTypeANumber(config.field_type) ? (
          <InputNumber
            style={{ minWidth: 90 }}
            value={value}
            disabled={disabled}
            onChange={onChangeValue}
          />
        ) : (
          <Input
            style={{ minWidth: 90 }}
            disabled={disabled}
            value={value}
            onChange={({ target: { value: changedValue } }) =>
              onChangeValue(changedValue)
            }
            onWheel={(e) => (e.target as HTMLElement).blur()}
          />
        );
      case WorkflowConfigUiType.TextArea:
        return (
          <TextArea
            style={{ minWidth: 400 }}
            disabled={disabled}
            value={value}
            onChange={({ target: { value: changedValue } }) =>
              onChangeValue(changedValue)
            }
          />
        );
      case WorkflowConfigUiType.LabelWithPlusMinus:
        return (
          <InputNumber
            style={{ minWidth: 90 }}
            value={value}
            disabled={disabled}
            onChange={onChangeValue}
          />
        );
      case WorkflowConfigUiType.Toggle:
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={value}
            onChange={(checked) => {
              onChangeValue(checked);
              onToggle?.(!checked);
            }}
            disabled={disabled}
          />
        );
      case WorkflowConfigUiType.SingleCheckbox:
        return (
          <Checkbox
            style={{ minWidth: 90 }}
            disabled={disabled}
            onChange={({ target: { checked } }) => onChangeValue(checked)}
          />
        );
      case WorkflowConfigUiType.DatePicker: {
        return (
          <DatePicker
            style={{ minWidth: 100 }}
            disabled={disabled}
            value={value ? Moment(value * 1000) : undefined}
            onChange={onChangeValue}
          />
        );
      }
      case WorkflowConfigUiType.DateTimePicker: {
        return (
          <DatePicker
            showTime
            style={{ minWidth: 100 }}
            disabled={disabled}
            value={value ? Moment(value * 1000) : undefined}
            onChange={onChangeValue}
          />
        );
      }
      case WorkflowConfigUiType.FileInput:
        return (
          <SimulationConfigsFieldFileInput
            disabled={disabled}
            value={value}
            onChangeValue={onChangeValue}
          />
        );
      case WorkflowConfigUiType.MultipleFileInput:
        return (
          <SimulationConfigsFieldFileInput
            disabled={disabled}
            value={value}
            onChangeValue={onChangeValue}
            multiple
          />
        );
      case WorkflowConfigUiType.RangeNumber: {
        if (
          typeof config.min_value !== "number" ||
          typeof config.max_value !== "number"
        ) {
          console.error(
            `Config ${config.display_name}[${config.config_name}] has non-number values for min_value and/or max_value.`
          );
        }

        return (
          <div className="traverse-slider-group">
            <InputNumber
              min={config.min_value ?? undefined}
              max={config.max_value ?? undefined}
              value={value[0]}
              onChange={(v) => onChangeValue([v || null, value[1]])}
            />
            <InputNumber
              min={config.min_value ?? undefined}
              max={config.max_value ?? undefined}
              value={value[1]}
              onChange={(v) => onChangeValue([value[0], v || null])}
            />
          </div>
        );
      }
      default:
        return (
          <Text type="danger">
            Unknown ui type: {config.ui_type} provided for valueType=Single
          </Text>
        );
    }
  };

export const getSimulationConfigsRange: React.FC<GetSimulationConfigsRangeProps> =
  ({ config, disabled, value, onChangeValue }) => {
    switch (config.ui_type) {
      case WorkflowConfigUiType.RangeNumber: {
        if (
          typeof config.min_value !== "number" ||
          typeof config.max_value !== "number"
        ) {
          console.error(
            `Config ${config.display_name}[${config.config_name}] has non-number values for min_value and/or max_value.`
          );
        }

        return (
          <div className="traverse-slider-group">
            <InputNumber
              min={config.min_value ?? undefined}
              max={config.max_value ?? undefined}
              value={value[0]}
              onChange={(v) => onChangeValue([v || null, value[1]])}
            />
            <InputNumber
              min={config.min_value ?? undefined}
              max={config.max_value ?? undefined}
              value={value[1]}
              onChange={(v) => onChangeValue([value[0], v || null])}
            />
          </div>
        );
      }
      case WorkflowConfigUiType.DateRange:
        return (
          <RangePicker
            style={{ minWidth: 100 }}
            disabled={disabled}
            value={
              value ? [Moment(value[0] * 1000), Moment(value[1] * 1000)] : null
            }
            onChange={onChangeValue}
          />
        );
      case WorkflowConfigUiType.DateTimeRange:
        return (
          <RangePicker
            showTime
            style={{ minWidth: 100 }}
            disabled={disabled}
            value={
              value ? [Moment(value[0] * 1000), Moment(value[1] * 1000)] : null
            }
            onChange={onChangeValue}
          />
        );
      default:
        return (
          <Text type="danger">
            Unknown ui type: {config.ui_type} provided for valueType=Range
          </Text>
        );
    }
  };

export const getSimulationConfigsOptionSelector: React.FC<GetSimulationConfigsOptionSelectorProps> =
  ({ config, disabled, value, options, onChangeValue }) => {
    const defaultOptions = getDefaultOptions(config).map((opt) => opt.key);
    const newOptions = defaultOptions.length > 0 ? defaultOptions : options;

    const newValue =
      value !== null && typeof value === "object" && !isEnum(value)
        ? Object.values(value)
        : value;

    switch (config.ui_type) {
      case WorkflowConfigUiType.MultiselectCheckbox:
        return (
          <Select
            style={{ minWidth: 120 }}
            disabled={disabled}
            mode="multiple"
            value={newValue}
            onChange={onChangeValue}
            allowClear
            options={newOptions.map((key) => ({ value: key, label: key }))}
          />
        );
      case WorkflowConfigUiType.Dropdown:
        return (
          <Select
            style={{ minWidth: 120 }}
            disabled={disabled}
            showSearch={defaultOptions.length > 6}
            notFoundContent="Not found"
            dropdownMatchSelectWidth={false}
            value={newValue}
            onChange={onChangeValue}
            options={newOptions.map((key) => ({ value: key, label: key }))}
          />
        );
      case WorkflowConfigUiType.SegmentedControl:
        return (
          <Radio.Group
            disabled={disabled}
            value={newValue}
            onChange={({ target: { value: changedValue } }) =>
              onChangeValue(changedValue)
            }
          >
            {newOptions.map((key) => (
              <Radio.Button style={{ minWidth: 60 }} value={key} key={key}>
                {key}
              </Radio.Button>
            ))}
          </Radio.Group>
        );
      case WorkflowConfigUiType.Radio:
        return (
          <Radio.Group
            name="radiogroup"
            value={newValue}
            onChange={({ target: { value: changedValue } }) =>
              onChangeValue(changedValue)
            }
            disabled={disabled}
          >
            {newOptions.map((key) => (
              <Radio style={{ minWidth: 60 }} value={key} key={key}>
                {key}
              </Radio>
            ))}
          </Radio.Group>
        );
      default:
        return (
          <Text type="danger">
            Unknown ui type: {config.ui_type} provided for
            valueType=OptionSelector
          </Text>
        );
    }
  };

export const getSimulationConfigsAction: React.FC<GetSimulationConfigsActionProps> =
  ({
    config,
    disabled,
    onToggle,
    onClick,
    accessor,
    value,
    onChangeValue,
    debugForms,
  }) => {
    switch (config.ui_type) {
      case WorkflowConfigUiType.Toggle:
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={value}
            onChange={(checked) => {
              onChangeValue(checked);
              onToggle?.(!checked);
            }}
            disabled={disabled}
          />
        );
      case WorkflowConfigUiType.Button:
        return (
          <Button icon={<PlusOutlined />} disabled={disabled} onClick={onClick}>
            {config.display_name}
            {debugForms ? ` (${JSON.stringify(accessor)})` : ""}
          </Button>
        );
      default:
        return (
          <Text type="danger">
            Unknown ui type: {config.ui_type} provided for valueType=Action
          </Text>
        );
    }
  };
