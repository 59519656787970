import queryString from "query-string";
import { Location, WorkflowType } from "src/networking/types";
import { ProjectsActionTypes } from "src/redux/actionTypes";
import { RequestPayload } from "src/redux/utils";

export interface ProjectsAction {
  type: ProjectsActionTypes;
  payload: RequestPayload;
}

export function clearAndGetProjects(
  token: string,
  workflowTypes?: WorkflowType[],
  withSites?: boolean
): ProjectsAction {
  return {
    type: ProjectsActionTypes.CLEAR_AND_GET_PROJECTS_REQUEST,
    payload: {
      client: "default",
      request: {
        url: queryString.stringifyUrl(
          {
            url: "/v1/projects",
            query: {
              workflow_types: workflowTypes,
              with_sites:
                withSites === true
                  ? "True"
                  : withSites === false
                  ? "False"
                  : undefined,
            },
          },
          { arrayFormat: "none" }
        ),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getProjectDetails(
  id: string,
  token: string,
  workflowType?: WorkflowType
): ProjectsAction {
  return {
    type: ProjectsActionTypes.GET_PROJECT_DETAILS_REQUEST,
    payload: {
      client: "default",
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${id}`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getConfigChangesForProject(
  id: string,
  token: string,
  workflowType?: WorkflowType
): ProjectsAction {
  return {
    type: ProjectsActionTypes.GET_CONFIG_CHANGES_FOR_PROJECT_REQUEST,
    payload: {
      client: "default",
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${id}/configuration_changes`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getSitesForProject(
  id: string,
  token: string,
  workflowType?: WorkflowType
): ProjectsAction {
  return {
    type: ProjectsActionTypes.GET_SITES_FOR_PROJECT_REQUEST,
    payload: {
      client: "default",
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${id}/sites`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function createProject(
  projectName: string,
  projectTimezone: string,
  projectCountryCode: string,
  token: string
): ProjectsAction {
  return {
    type: ProjectsActionTypes.CREATE_PROJECT_REQUEST,
    payload: {
      request: {
        method: "post",
        url: "/v1/projects/",
        data: {
          project_name: projectName,
          project_timezone: projectTimezone,
          project_country_code: projectCountryCode,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function updateProject(
  token: string,
  projectId: string,
  projectTimezone: string,
  projectCountryCode: string,
  projectCurrencyCode?: string,
  projectTargetOperationalDate?: number,
  projectLocation?: Location,
  projectNotes?: string
): ProjectsAction {
  return {
    type: ProjectsActionTypes.UPDATE_PROJECT_REQUEST,
    payload: {
      request: {
        method: "post",
        url: `/v1/projects/${projectId}/edit`,
        data: {
          project_timezone: projectTimezone,
          project_country_code: projectCountryCode,
          project_currency_code: projectCurrencyCode,
          project_target_operational_date: projectTargetOperationalDate,
          project_location: projectLocation,
          project_notes: projectNotes,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getPreQcSensorsForProject(
  projectId: string,
  token: string,
  workflowType?: WorkflowType
): ProjectsAction {
  return {
    type: ProjectsActionTypes.GET_PRE_QC_SENSORS_FOR_PROJECT_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/pre_qc_sensors`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getQcSensorsForProject(
  projectId: string,
  token: string,
  workflowType?: WorkflowType
): ProjectsAction {
  return {
    type: ProjectsActionTypes.GET_QC_SENSORS_FOR_PROJECT_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/qc_sensors`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}
