import { notification } from "antd";
import { Request } from "src/redux/actionTypes";

export interface RequestState {
  isCompleted: boolean;
  isLoading: boolean;
  data?: any;
  error?: Record<string, any>;
  requestParams?: { [id: string]: string };
}

export type RequestsState = Record<string, RequestState>;

const initialRequestState: RequestState = {
  isCompleted: false,
  isLoading: false,
  data: undefined,
  error: undefined,
  requestParams: undefined,
};

// The initial state for this reducer looks like:
// {
//   CLEAR_AND_GET_PROJECTS_REQUEST: {
//     isCompleted: false, isLoading: false, data: undefined, error: undefined, requestParams: undefined
//   }
//   ... so on, for other requests
// }
const initialState: RequestsState = Object.keys(Request).reduce(
  (previousValue, currentValue) => ({
    ...previousValue,
    [currentValue]: initialRequestState,
  }),
  {}
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const reducer = (state = initialState, action: any): RequestsState => {
  const actionType = action.type.split("_");
  const lastWordInActionTypeName =
    actionType.length && actionType[actionType.length - 1];

  if (lastWordInActionTypeName === "SUCCESS") {
    return {
      ...state,
      [action.type.replace("_SUCCESS", "")]: {
        isCompleted: true,
        isLoading: false,
        data: action.payload.data,
        error: undefined,
      },
    };
  }
  if (lastWordInActionTypeName === "FAIL") {
    const status = action.error?.status || action.error?.response?.status;
    const message = action.error?.data || action.error?.response?.data?.error;
    notification.open({
      message: `${action.meta.previousAction.type} request failed`,
      description: `Code: ${status}, Message: ${message}`,
      duration: 0,
    });
    return {
      ...state,
      [action.type.replace("_FAIL", "")]: {
        isCompleted: true,
        isLoading: false,
        data: undefined,
        error: action.error.response,
      },
    };
  }
  if (lastWordInActionTypeName === "REQUEST") {
    return {
      ...state,
      [action.type]: {
        isCompleted: false,
        isLoading: true,
        requestParams: action.requestParams,
        data: undefined,
        error: undefined,
      },
    };
  }
  if (lastWordInActionTypeName === "CLEAR") {
    return {
      ...state,
      [action.type.replace("_CLEAR", "")]: {
        isCompleted: false,
        isLoading: false,
        requestParams: undefined,
        data: undefined,
        error: undefined,
      },
    };
  }

  return state;
};

export default reducer;
