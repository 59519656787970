import { Switch as AntdSwitch, ConfigProvider, Spin } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import EmptyPage from "src/components/EmptyPage";
import AllProjects from "src/components/Projects/AllProjects";
import ProjectDashboard from "src/components/Projects/ProjectDashboard";
import SimulationConfigsDashboard from "src/components/SimulationConfigs/SimulationConfigsDashboard";
import SimulationDashboard from "src/components/Simulations/SimulationDashboard";
import { useSimulationSelectionState } from "src/components/Simulations/hooks";
import {
  useDispatchPresentationConfig,
  useDispatchProjects,
  useDispatchWorkflowConfig,
} from "src/networking/hooks";
import { WorkflowType } from "src/networking/types";
import {
  diffToLatestCommit,
  setFormStateFromConfig,
} from "src/redux/actions/configs";

import { GlobalState } from "src/redux/reducers";
import { canViewDebug } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";
import useConstant from "use-constant";

const NoMatch: React.FC = () => {
  const location = useLocation();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};

const SimulateMainViewContent: React.FC = () => {
  const { path } = useRouteMatch();

  const dispatch = useDispatch();

  const { getTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently().then((t: string) => {
      setToken(t);
    });
  }, [getTokenSilently]);

  const workflowTypes = useConstant(() => [
    WorkflowType.Predict,
    WorkflowType.Prospect,
  ]);
  useDispatchPresentationConfig();
  useDispatchWorkflowConfig();
  useDispatchProjects(workflowTypes, false);

  const currentSimulationId = useSimulationSelectionState()[0];
  const { configId, configCommitId } = useSelector(
    (state: GlobalState) =>
      (currentSimulationId && state.configs.configs[currentSimulationId]) || {
        configId: undefined,
        configCommitId: undefined,
      }
  );
  const configsExist = useSelector(
    (state: GlobalState) =>
      !!(currentSimulationId && state.configs.configs[currentSimulationId])
  );

  const diffIntervalId = useRef<number | undefined>(undefined);
  const [diffIntervalOn, setDiffIntervalOn] = useState<boolean>(false);

  const addDiff = useCallback(() => {
    diffIntervalId.current = window.setInterval(() => {
      if (
        currentSimulationId === undefined ||
        token === undefined ||
        configId === undefined ||
        configCommitId === undefined
      ) {
        return;
      }

      dispatch(
        diffToLatestCommit(currentSimulationId, configId, configCommitId, token)
      );
    }, 5000);
    setDiffIntervalOn(true);
  }, [configCommitId, token, configId, currentSimulationId, dispatch]);

  const removeDiff = useCallback(() => {
    clearInterval(diffIntervalId.current);
    setDiffIntervalOn(false);
  }, []);

  useEffect(() => {
    addDiff();
    return removeDiff;
  }, [addDiff, removeDiff]);

  useEffect(() => {
    if (currentSimulationId && configsExist) {
      dispatch(setFormStateFromConfig(currentSimulationId));
    }
  }, [dispatch, currentSimulationId, configsExist]);

  const isLoadingState = useSelector((state: GlobalState) => {
    const getConfigByWorkflowIdLoadingState =
      state.requests.GET_CONFIG_BY_WORKFLOW_ID_REQUEST.isLoading;
    const getPresentationConfigLoadingState =
      state.requests.GET_PRESENTATION_CONFIG_REQUEST.isLoading;
    return (
      (currentSimulationId && getConfigByWorkflowIdLoadingState) ||
      getPresentationConfigLoadingState
    );
  });
  if (isLoadingState) {
    return <Spin />;
  }

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: "'${label}' is required!",
  };

  return (
    <ConfigProvider form={{ validateMessages }}>
      {token && canViewDebug(token) ? (
        <>
          <AntdSwitch
            style={{ zIndex: 12 }}
            checked={diffIntervalOn}
            onChange={(checked) => (checked ? addDiff() : removeDiff())}
          />
          <span>Get diffs</span>
        </>
      ) : null}
      <Switch>
        <Route
          exact
          path={`${path}/projects/:projectId/simulations/:simulationId/configurations/:subWorkflowName`}
          render={({ match }) =>
            match.params.simulationId && match.params.subWorkflowName ? (
              <SimulationConfigsDashboard
                simulationId={match.params.simulationId}
                subWorkflowName={match.params.subWorkflowName}
              />
            ) : null
          }
        />
        <Route
          exact
          path={`${path}/projects/:projectId/simulations/:simulationId`}
          render={({ match }) =>
            match.params.projectId && match.params.simulationId ? (
              <SimulationDashboard
                projectId={match.params.projectId}
                simulationId={match.params.simulationId}
              />
            ) : null
          }
        />
        <Route
          exact
          path={`${path}/projects/:projectId`}
          render={({ match }) =>
            match.params.projectId ? (
              <ProjectDashboard projectId={match.params.projectId} />
            ) : null
          }
        />
        <Route
          exact
          path={`${path}/projects/`}
          render={() => <AllProjects />}
        />
        <Route
          exact
          path={`${path}/`}
          render={() => (
            <EmptyPage>Select a project on the left sidebar</EmptyPage>
          )}
        />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </ConfigProvider>
  );
};

export default SimulateMainViewContent;
