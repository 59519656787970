import { MdfActionTypes } from "src/redux/actionTypes";
import { RequestPayload } from "src/redux/utils";

export interface MdfAction {
  type: MdfActionTypes;
  payload: RequestPayload;
  requestParams?: { [id: string]: any };
}

export function listTimeSeries(token: string): MdfAction {
  return {
    type: MdfActionTypes.LIST_TIME_SERIES_REQUEST,
    payload: {
      request: {
        method: "get",
        url: "/v1/algos/list_timeseries",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function rescaleTimeSeries(token: string, csvfile: File): MdfAction {
  return {
    type: MdfActionTypes.RESCALE_TIME_SERIES_REQUEST,
    payload: {
      request: {
        method: "post",
        url: "/v1/algos/resample_timeseries",
        data: csvfile,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getTimeSeries(
  token: string,
  sensors: Array<string>
): MdfAction {
  return {
    type: MdfActionTypes.GET_SCALED_TIME_SERIES_REQUEST,
    payload: {
      request: {
        method: "get",
        url: `/v1/algos/resample_timeseries?sensor_names=${sensors.join(",")}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function appendFlagData(
  token: string,
  sensors: string[],
  t0: string,
  t1: string,
  severity: number,
  type: string
): MdfAction {
  const flags: any = {};
  for (const sname of sensors) {
    flags[sname] = [
      [new Date(t0).getTime(), new Date(t1).getTime(), type, severity],
    ];
  }

  return {
    type: MdfActionTypes.APPEND_FLAG_DATA_REQUEST,
    requestParams: { flags },
    payload: {
      request: {
        method: "post",
        url: "/v1/algos/mdf_flags",
        data: `Name,Flag_start,Flag_end,Flag_type,Flag_severity\n${sensors
          .map((s: string) => `${s},${t0},${t1},${type},${severity}`)
          .join("\n")}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function uploadFlagData(token: string, csvfile: File): MdfAction {
  return {
    type: MdfActionTypes.UPLOAD_FLAG_DATA_REQUEST,
    payload: {
      request: {
        method: "post",
        url: "/v1/algos/mdf_flags",
        data: csvfile,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getFlagData(token: string, sensors: Array<string>): MdfAction {
  return {
    type: MdfActionTypes.GET_FLAG_DATA_REQUEST,
    payload: {
      request: {
        method: "get",
        url: `/v1/algos/mdf_flags?sensor_names=${sensors.join(",")}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}
