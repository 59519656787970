import React, { useEffect, useState } from "react";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import { hydraWinddeskClient } from "src/networking/index";
import { useAuth0 } from "src/utils/react-auth0-spa";

interface Props {
  url: string;
  title: string;
  caption?: string;
}

const ImageCard: React.FC<Props> = ({ url, title, caption }) => {
  const { getTokenSilently } = useAuth0();
  const [image, setImage] = useState<string | undefined | null>();

  useEffect(() => {
    setImage(undefined);
    getTokenSilently().then((token: string) => {
      hydraWinddeskClient
        .get(url, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        })
        .then((resp) => {
          resp.data.text().then((text: string) => {
            setImage(text === "null" ? null : URL.createObjectURL(resp.data));
          });
        });
    });
  }, [url, getTokenSilently]);

  return (
    <WinddeskCard loading={image === undefined} title={title}>
      {image === null ? (
        "No image"
      ) : (
        <figure>
          <img src={image} alt={title} style={{ width: "100%" }} />
          {caption && (
            <figcaption style={{ textAlign: "center" }}>{caption}</figcaption>
          )}
        </figure>
      )}
    </WinddeskCard>
  );
};

export default ImageCard;
