import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  link?: string;
  style?: React.CSSProperties;
  className?: string;
  centered?: boolean;
}

interface NavItemComponentProps {
  centered?: boolean;
}

const NavItemComponent = styled.div<NavItemComponentProps>`
  color: inherit;
  display: flex;
  align-items: center;
  ${({ centered }) =>
    centered
      ? `
    justify-content: center;
  `
      : ""}
`;

const NavItem: React.FC<Props> = ({ link, className, centered, children }) => {
  const item = (
    <NavItemComponent centered={centered} className={className}>
      {children}
    </NavItemComponent>
  );
  return link ? <Link to={link}>{item}</Link> : item;
};

export default NavItem;
