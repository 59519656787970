import { Col, Form, Row, Space } from "antd";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";

import { ConfigInfo } from "src/components/SimulationConfigs/ConfigInfo";

import { getSubElementForCardData } from "src/components/SimulationConfigs/SimulationConfigsFieldGroup/utils";
import SimulationConfigsFieldsCollection from "src/components/SimulationConfigs/SimulationConfigsFieldsCollection";
import { usePresentationConfigForSimulation } from "src/components/SimulationConfigs/hooks";
import { CardData, isCardData } from "src/networking/types";
import { resetFormState, setPromptOnLeave } from "src/redux/actions/configs";
import { GlobalState } from "src/redux/reducers";

type Props = {
  simulationId: string;
  subWorkflowName: string;
};

const SimulationConfigsDashboard: React.FC<Props> = ({
  simulationId,
  subWorkflowName,
}) => {
  const dispatch = useDispatch();

  const anchorContainerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const presentationConfigForWorkflow =
    usePresentationConfigForSimulation(simulationId);
  const subWorkflowConfigData = (presentationConfigForWorkflow ?? []).find(
    (subworkflow: CardData) => subworkflow.name === subWorkflowName
  );
  const showOnlyPrimaryFields = useSelector(
    (state: GlobalState) => state.configs.showOnlyPrimaryFields
  );
  const promptOnLeave = useSelector(
    (state: GlobalState) => state.configs.promptOnLeave
  );

  useEffect(() => {
    const onBeforeUnload = (e: any) => {
      e.preventDefault();
      e.returnValue = "";
    };

    const onUnload = () => {
      dispatch(setPromptOnLeave(false));
      dispatch(resetFormState());
    };

    window.addEventListener("unload", onUnload);
    if (promptOnLeave) {
      window.addEventListener("beforeunload", onBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
      window.removeEventListener("unload", onUnload);
    };
  }, [dispatch, promptOnLeave]);

  const saveConfigByWorkflowIdState = useSelector(
    (state: GlobalState) => state.requests.SAVE_CONFIG_BY_WORKFLOW_ID_REQUEST
  );

  useEffect(() => {
    if (saveConfigByWorkflowIdState.isCompleted) {
      dispatch(setPromptOnLeave(false));
    }
  }, [dispatch, saveConfigByWorkflowIdState]);

  const history = useHistory();
  useEffect(
    () =>
      history.listen(() => {
        if (promptOnLeave) {
          dispatch(setPromptOnLeave(false));
          dispatch(resetFormState());
        }
      }),
    [dispatch, history, promptOnLeave]
  );

  if (subWorkflowConfigData === undefined) {
    return null;
  }
  const { children } = subWorkflowConfigData;

  return (
    <>
      <Prompt
        when={promptOnLeave}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Row gutter={26} ref={anchorContainerRef} style={{ paddingTop: "58px" }}>
        <Col span={24}>
          <ConfigInfo name={t(subWorkflowName)} />
          <Space
            direction="vertical"
            style={{
              width: "100%",
              flex: "initial",
            }}
          >
            {children
              .filter((child): child is CardData => isCardData(child))
              .map((cardData) => {
                if (
                  getSubElementForCardData(cardData, showOnlyPrimaryFields)
                    .length === 0
                ) {
                  return null;
                }
                return (
                  <Form
                    key={cardData.name}
                    colon={false}
                    layout="horizontal"
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <SimulationConfigsFieldsCollection cardData={cardData} />
                  </Form>
                );
              })}
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default SimulationConfigsDashboard;
