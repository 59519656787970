import { WorkflowConfigField } from "src/networking/types";

interface Option {
  key: string;
  value: string;
}

export function getDefaultOptions(config: WorkflowConfigField): Option[] {
  if (config.options && Array.isArray(config.options)) {
    return config.options.map((v) => ({ key: v, value: v }));
  }
  return [];
}
