import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertsCard from "src/components/Monitor/shared/AlertsCard";
import { getAllAlerts } from "src/redux/actions/alerts";
import { GlobalState } from "src/redux/reducers";
import { useAuth0 } from "src/utils/react-auth0-spa";

const Alerts: React.FC<Record<string, never>> = () => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const getAllAlertsState = useSelector(
    (state: GlobalState) => state.requests.GET_ALL_ALERTS_REQUEST
  );
  const alertsState = useSelector((state: GlobalState) => state.alerts);
  const alerts = Object.values(alertsState.alerts);

  useEffect(() => {
    getTokenSilently().then((token: string) => {
      // Do not get alerts resolved from one week ago
      dispatch(getAllAlerts(token, Math.floor(Date.now() / 1000) - 604800));
    });
  }, [dispatch, getTokenSilently]);

  return (
    <AlertsCard
      title="Alerts"
      alerts={alerts}
      loading={getAllAlertsState.isLoading}
      error={getAllAlertsState.error}
      timeZoneOffset={0}
      tableFilters
    />
  );
};

export default Alerts;
