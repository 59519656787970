import { Typography } from "antd";

import { isEqual } from "lodash";
import React, { ReactNode } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  getSimulationConfigsAction,
  getSimulationConfigsOptionSelector,
  getSimulationConfigsRange,
  getSimulationConfigsSingle,
} from "src/components/SimulationConfigs/SimulationConfigsField/SimulationConfigItems";
import TraverseFormItem from "src/components/SimulationConfigs/SimulationConfigsField/TraverseFormItem";
import { hasPrefilledLinkageValue } from "src/components/SimulationConfigs/utils";
import {
  getFieldTypeForCheck,
  getFieldValue,
} from "src/components/SimulationConfigs/utils/formValues";

import { useSimulationSelectionState } from "src/components/Simulations/hooks";
import {
  Accessor,
  BaseValue,
  WorkflowConfigField,
  WorkflowConfigValueType,
} from "src/networking/types";
import { updateFormState } from "src/redux/actions/configs";
import { GlobalState } from "src/redux/reducers";
import "src/components/SimulationConfigs/index.css";

const { Text } = Typography;

type SimulationConfigsFieldProps = {
  config: WorkflowConfigField;
  accessor: Accessor;
  noLabel?: boolean;
  noTooltip?: boolean;
  onToggle?: (_: boolean) => void;
  onClick?: () => void;
  hiddenConfigActionKeys: string[];
  disabledConfigActionKeys: string[];
};

const SimulationConfigsField: React.FC<SimulationConfigsFieldProps> =
  React.memo(
    ({
      config,
      accessor,
      onToggle,
      onClick,
      noLabel,
      noTooltip,
      hiddenConfigActionKeys,
      disabledConfigActionKeys,
    }) => {
      const configHasPrefilledLinkageValue = hasPrefilledLinkageValue(config);
      const debugForms = useSelector(
        (state: GlobalState) => state.configs.debug
      );
      const value = useSelector(
        (state: GlobalState) =>
          getFieldValue(
            state.configs.formValues,
            accessor,
            getFieldTypeForCheck(config)
          ),
        shallowEqual
      );

      let currentSimulationId: string | undefined;
      try {
        [currentSimulationId] = useSimulationSelectionState();
      } catch {
        currentSimulationId = undefined;
      }

      const options = useSelector((state: GlobalState) => {
        if (currentSimulationId && state.configs.configs[currentSimulationId]) {
          const workflowConfig = state.configs.configs[currentSimulationId];
          return workflowConfig.dynamicOptions[config.references] || [];
        }
        return [];
      }, shallowEqual);

      const dispatch = useDispatch();

      if (
        config.config_action_group.some((k) =>
          hiddenConfigActionKeys.includes(k)
        )
      ) {
        return null;
      }

      const onChangeValue = (newValue: any) =>
        dispatch(
          updateFormState({
            accessor,
            previousValue: value,
            currentValue: newValue,
          })
        );

      const disabled =
        configHasPrefilledLinkageValue ||
        config.config_action_group.some((k) =>
          disabledConfigActionKeys.includes(k)
        );

      let fieldValue: ReactNode;
      switch (config.value_type) {
        case WorkflowConfigValueType.Single:
          fieldValue = getSimulationConfigsSingle({
            config,
            disabled,
            onToggle,
            value,
            onChangeValue,
          });
          break;
        case WorkflowConfigValueType.Range:
          fieldValue = getSimulationConfigsRange({
            config,
            disabled,
            value,
            onChangeValue,
          });
          break;
        case WorkflowConfigValueType.OptionSelector:
          fieldValue = getSimulationConfigsOptionSelector({
            config,
            disabled,
            value,
            options,
            onChangeValue,
          });
          break;
        case WorkflowConfigValueType.Action:
          fieldValue = getSimulationConfigsAction({
            config,
            disabled,
            onToggle,
            onClick,
            accessor,
            debugForms,
            value,
            onChangeValue,
          });
          break;
        default:
          fieldValue = (
            <Text type="danger">Unknown value type: {config.value_type}</Text>
          );
      }
      return (
        <TraverseFormItem
          config={config}
          accessor={accessor}
          value={value as BaseValue}
          noLabel={noLabel}
          noTooltip={noTooltip}
        >
          {fieldValue}
        </TraverseFormItem>
      );
    },
    isEqual
  );

export default SimulationConfigsField;
