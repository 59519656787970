import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import NavItem from "src/components/NavHeader/NavItem";
import { defaultLogo, logos } from "src/logos";
import { getCompanyName } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";
import styled from "styled-components";

interface Props {
  width: string;
}

const BrandNavItem = styled(NavItem)<Props>`
  padding-left: 24px;
  width: ${({ width }) => width};
`;

export const Brand: React.FC<Props> = ({ width }: Props) => {
  const { getTokenSilently } = useAuth0();

  const [companyName, setCompanyName] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently().then((token: string) => {
      setCompanyName(getCompanyName(token));
    });
  }, [getTokenSilently]);

  return companyName ? (
    <BrandNavItem link="/" width={width}>
      {logos[companyName] ?? defaultLogo}
    </BrandNavItem>
  ) : (
    <Spin />
  );
};
