import { MdfActionTypes } from "../actionTypes";
import { getActionTypeForSuccess } from "../utils";

export interface MdfState {
  sensors: Array<string>;

  flags: Record<string, any>;
  timeseries: Record<string, any>;
}

const initialState = {
  flags: {},
  timeseries: {},
  sensors: [],
};

const reducer = (
  state: MdfState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action: any
): MdfState => {
  switch (action.type) {
    case getActionTypeForSuccess(MdfActionTypes.UPLOAD_FLAG_DATA_REQUEST):
    case getActionTypeForSuccess(MdfActionTypes.LIST_TIME_SERIES_REQUEST):
    case getActionTypeForSuccess(MdfActionTypes.RESCALE_TIME_SERIES_REQUEST): {
      return {
        ...state,
        sensors: action.payload.data.data,
      };
    }
    case getActionTypeForSuccess(
      MdfActionTypes.GET_SCALED_TIME_SERIES_REQUEST
    ): {
      // MdfState.dispatch(updateData(action.payload.data.data, null)); // TODO

      return {
        ...state,
        timeseries: {
          ...state.timeseries,
          ...action.payload.data.data,
        },
      };
    }
    case getActionTypeForSuccess(MdfActionTypes.GET_FLAG_DATA_REQUEST): {
      // MdfState.dispatch(updateData(null, action.payload.data.data)); // TODO

      return {
        ...state,
        flags: {
          ...state.flags,
          ...action.payload.data.data,
        },
      };
    }
    case getActionTypeForSuccess(MdfActionTypes.APPEND_FLAG_DATA_REQUEST): {
      const { flags } = state;
      const flags2 = action.meta.previousAction.requestParams.flags;

      for (const sname of flags2) {
        if (!flags[sname]) flags[sname] = [];
        flags[sname] = flags[sname].concat(flags2[sname]);
      }

      // MdfState.dispatch(updateData(null, flags)); // TODO
      return { ...state };
    }
    default:
      return state;
  }
};

export default reducer;
