import { keyBy } from "lodash";
import { Workflow } from "src/networking/types";
import { WorkflowsActionTypes } from "src/redux/actionTypes";
import { getActionTypeForSuccess } from "src/redux/utils";

export interface WorkflowsState {
  workflows: Record<string, Workflow>;
}

const initialState = {
  workflows: {},
};

const reducer = (
  state: WorkflowsState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action: any
): WorkflowsState => {
  switch (action.type) {
    case getActionTypeForSuccess(
      WorkflowsActionTypes.GET_WORKFLOWS_BY_PROJECT_ID_REQUEST
    ):
      return {
        ...state,
        workflows: {
          ...state.workflows,
          ...keyBy(action.payload.data, "id"),
        },
      };
    case getActionTypeForSuccess(WorkflowsActionTypes.CREATE_WORKFLOW_REQUEST):
      return {
        ...state,
        workflows: {
          ...state.workflows,
          [action.payload.data.id]: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default reducer;
