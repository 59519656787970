import { Spin } from "antd";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import EmptyPage from "src/components/EmptyPage";

import Alerts from "src/components/Monitor/Alerts";
import Navigation from "src/components/Monitor/Navigation";
import QualityControl from "src/components/Monitor/QualityControl";
import SiteConfig from "src/components/Monitor/SiteConfig";
import ProjectSummary from "src/components/Monitor/Summary/ProjectSummary";
import SiteSummary from "src/components/Monitor/Summary/SiteSummary";
import { WorkflowType } from "src/networking/types";
import { getProjectDetails } from "src/redux/actions/projects";
import { getSiteDetails } from "src/redux/actions/sites";
import { GlobalState } from "src/redux/reducers";
import { useAuth0 } from "src/utils/react-auth0-spa";

const getActionToDispatch = (
  projectId: string,
  siteName: string | undefined,
  token: string
) =>
  siteName
    ? getSiteDetails(projectId, siteName, token, WorkflowType.MonitorFull)
    : getProjectDetails(projectId, token, WorkflowType.MonitorFull);

interface Props {
  projectId?: string;
  siteName?: string;
}

const Monitor: React.FC<Props> = ({ projectId, siteName }) => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const projectsState = useSelector((state: GlobalState) => state.projects);
  const sitesState = useSelector((state: GlobalState) => state.sites);
  const getProjectDetailsState = useSelector(
    (state: GlobalState) => state.requests.GET_PROJECT_DETAILS_REQUEST
  );
  const getSiteDetailsState = useSelector(
    (state: GlobalState) => state.requests.GET_SITE_DETAILS_REQUEST
  );

  let { path } = useRouteMatch();
  path = path === "/" ? "" : path;

  useEffect(() => {
    if (!projectId) {
      return;
    }
    getTokenSilently().then((token: string) =>
      dispatch(getActionToDispatch(projectId, siteName, token))
    );
  }, [dispatch, projectId, siteName, getTokenSilently]);

  const location = useLocation();
  const type = siteName ? "site" : "project";

  if (!projectId) {
    return <EmptyPage>Select a project on the left sidebar</EmptyPage>;
  }

  if (
    (type === "project" && getProjectDetailsState.error) ||
    (type === "site" && getSiteDetailsState.error)
  ) {
    return <div>Error loading the project or site.</div>;
  }

  if (
    (type === "project" && !getProjectDetailsState.isCompleted) ||
    (type === "site" && !getSiteDetailsState.isCompleted)
  ) {
    return <Spin size="large" />;
  }

  const project = projectsState.projects && projectsState.projects[projectId];
  const site =
    siteName === undefined
      ? undefined
      : sitesState.sites && sitesState.sites[siteName];
  return (
    <>
      <Navigation project={project} site={site} />
      <Switch>
        <Route path={`${path}/summary`}>
          {site ? (
            <SiteSummary site={site} projectTimezone={project.timezone} />
          ) : (
            project && <ProjectSummary project={project} />
          )}
        </Route>
        {site && (
          <>
            <Route path={`${path}/site-config`}>
              <SiteConfig site={site} />
            </Route>
            <Route path={`${path}/alerts`}>
              <Alerts site={site} projectTimezone={project.timezone} />
            </Route>
            <Route path={`${path}/quality-control`}>
              <QualityControl site={site} />
            </Route>
          </>
        )}
        <Route path={`${path}/`}>
          <Redirect
            to={{
              pathname: `${path}/summary`,
              search: location.search,
            }}
          />
        </Route>
      </Switch>
    </>
  );
};

export default Monitor;
