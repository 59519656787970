import queryString from "query-string";
import { WorkflowType } from "src/networking/types";
import { SitesActionTypes } from "src/redux/actionTypes";
import { RequestPayload } from "src/redux/utils";

export interface SitesAction {
  type: SitesActionTypes;
  payload: RequestPayload;
}

export function getSiteDetails(
  projectId: string,
  siteName: string,
  token: string,
  workflowType?: WorkflowType
): SitesAction {
  return {
    type: SitesActionTypes.GET_SITE_DETAILS_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/sites/${siteName}`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getConfigChangesForSite(
  projectId: string,
  siteName: string,
  token: string
): SitesAction {
  return {
    type: SitesActionTypes.GET_CONFIG_CHANGES_FOR_SITE_REQUEST,
    payload: {
      request: {
        url: `/v1/projects/${projectId}/sites/${siteName}/configuration_changes`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getSensorConfigsForSite(
  projectId: string,
  siteName: string,
  token: string,
  workflowType?: WorkflowType
): SitesAction {
  return {
    type: SitesActionTypes.GET_SENSOR_CONFIGS_FOR_SITE_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/sites/${siteName}/sensor_configs`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getPreQcSensorsForSite(
  projectId: string,
  siteName: string,
  token: string,
  workflowType?: WorkflowType
): SitesAction {
  return {
    type: SitesActionTypes.GET_PRE_QC_SENSORS_FOR_SITE_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/sites/${siteName}/pre_qc_sensors`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getQcSensorsForSite(
  projectId: string,
  siteName: string,
  token: string,
  workflowType?: WorkflowType
): SitesAction {
  return {
    type: SitesActionTypes.GET_QC_SENSORS_FOR_SITE_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/sites/${siteName}/qc_sensors`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getTimeOffsetsForSite(
  projectId: string,
  siteName: string,
  token: string,
  workflowType?: WorkflowType
): SitesAction {
  return {
    type: SitesActionTypes.GET_TIME_OFFSETS_FOR_SITE_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/sites/${siteName}/time_offsets`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getDirectionOffsetsForSite(
  projectId: string,
  siteName: string,
  token: string,
  workflowType?: WorkflowType
): SitesAction {
  return {
    type: SitesActionTypes.GET_DIRECTION_OFFSETS_FOR_SITE_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/sites/${siteName}/direction_offsets`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getFlagsForSite(
  projectId: string,
  siteName: string,
  token: string,
  workflowType?: WorkflowType
): SitesAction {
  return {
    type: SitesActionTypes.GET_FLAGS_FOR_SITE_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/sites/${siteName}/flags`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}
