import { Sensor, Site } from "src/networking/types";

export const getDataCoverageAverage = (sensors: Sensor[]): number | undefined =>
  sensors === undefined || sensors.length === 0
    ? undefined
    : sensors.reduce((acc, curr) => acc + curr.data_coverage_ratio, 0) /
      sensors.length;

export const getSiteDataCoverage = (site: Site): number | undefined =>
  getDataCoverageAverage(site.sensors ?? []);

export const getSitesDataCoverage = (sites: Site[]): number | undefined =>
  getDataCoverageAverage(
    sites.reduce(
      (acc: Sensor[], curr: Site) => acc.concat(curr.sensors ?? []),
      []
    )
  );
