/* eslint-disable no-underscore-dangle */
import { keyBy } from "lodash";
import { Document } from "src/networking/types";
import { DocumentsActionTypes } from "src/redux/actionTypes";
import { getActionTypeForSuccess } from "src/redux/utils";

export interface DocumentsState {
  documents: Record<number, Document>;
}

const initialState = {
  documents: {},
};

const reducer = (
  state: DocumentsState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action: any
): DocumentsState => {
  switch (action.type) {
    case getActionTypeForSuccess(
      DocumentsActionTypes.GET_DOCUMENTS_BY_PROJECT_ID_REQUEST
    ):
    case getActionTypeForSuccess(
      DocumentsActionTypes.GET_DOCUMENTS_BY_WORKFLOW_ID_REQUEST
    ): {
      return {
        ...state,
        documents: { ...state.documents, ...keyBy(action.payload.data, "id") },
      };
    }
    case getActionTypeForSuccess(
      DocumentsActionTypes.UPLOAD_DOCUMENT_REQUEST
    ): {
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.payload.data.id]: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
