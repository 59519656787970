import Axios from "axios";
import MockAdapter from "axios-mock-adapter";
import presentationConfig from "src/mock-data/presentation_config.json";
import workflowConfig from "src/mock-data/workflow_config.json";
import workflows from "src/mock-data/workflows.json";

import {
  SENSOR_1_DATA,
  SENSOR_2_DATA,
  SENSOR_3_DATA,
} from "src/networking/mockChartData";

export default function mockServer(): void {
  const mock = new MockAdapter(Axios, {
    delayResponse: 1000,
    onNoMatch: "passthrough",
  });

  mock.onGet(/\/v1\/charts\?type=correlation/).reply((config) => {
    // the actual id can be grabbed from config.url
    const { url } = config;

    const sensor1 = [
      {
        id: "sensor_1",
        type: "wind_speed",
        values: SENSOR_1_DATA.map((point) => point[1]),
      },
    ];
    const sensor2 = [
      {
        id: "sensor_2",
        type: "wind_speed",
        values: SENSOR_2_DATA.map((point) => point[1]),
      },
    ];
    const sensor3 = [
      {
        id: "sensor_3",
        type: "wind_direction",
        values: SENSOR_3_DATA.map((point) => point[1]),
      },
    ];

    const sources = [
      ...(url?.includes("sensor_1") ? sensor1 : []),
      ...(url?.includes("sensor_2") ? sensor2 : []),
      ...(url?.includes("sensor_3") ? sensor3 : []),
    ];

    return [
      200,
      { object: "chart", url, data: { type: "correlation", sources } },
    ];
  });

  mock
    .onGet(/\/v1\/presentation_configs/)
    .reply(() => [200, presentationConfig]);

  mock
    .onGet(/\/v1\/workflows\/[0-9]*\/configs\/latest/)
    .reply(() => [200, workflowConfig]);

  mock.onGet(/\/v1\/projects\/[0-9]*\/workflows/).reply(() => [200, workflows]);

  mock.onGet(/\/v1\/projects/).reply(() => [
    200,
    [
      {
        object: "project",
        id: "1",
        name: "Morella Wind Farm",
        sites: [],
        created: "2021-01-01, 01:02:03",
        updated: "2021-01-03, 01:02:03",
        position: {
          coordinates: { lat: 40.619611, lng: -0.101722 },
        },
        has_measurements: true,
        scopes: ["measure", "predict"],
        stage: "has_campaign",
        data_coverage: 1.0,
        country: "JPN",
      },
    ],
  ]);

  mock
    .onGet(
      /\/v1\/workflows\/[0-9]*\/configs\/[0-9]*\/config_commits\/[0-9]*\/diff/
    )
    .reply(() => [200, []]);
}
