import { Form } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Field from "src/components/SimulationConfigs/SimulationConfigsField/Field";
import { validate } from "src/components/SimulationConfigs/utils/validations";
import {
  Accessor,
  BaseValue,
  WorkflowConfigField,
  WorkflowConfigUiType,
} from "src/networking/types";
import { modifyValidationErrors } from "src/redux/actions/configs";
import { GlobalState } from "src/redux/reducers";

import "src/components/SimulationConfigs/index.css";

type TraverseFormItemProps = {
  config: WorkflowConfigField;
  accessor: Accessor;
  value: BaseValue;
  noLabel?: boolean;
  noTooltip?: boolean;
};

const TraverseFormItem: React.FC<TraverseFormItemProps> = ({
  config,
  accessor,
  value,
  noLabel,
  noTooltip,
  children,
}) => {
  const debugForms = useSelector((state: GlobalState) => state.configs.debug);

  const label =
    config.ui_type === WorkflowConfigUiType.Button || noLabel
      ? undefined
      : config.display_name;

  const validateErrorMessage = useSelector((state: GlobalState) =>
    validate(config, state.configs.formValues, accessor, value)
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(modifyValidationErrors(accessor, validateErrorMessage));
  }, [accessor, dispatch, validateErrorMessage]);

  return (
    <Form.Item
      label={debugForms ? `${label} (${JSON.stringify(accessor)})` : label}
      labelAlign="right"
      className="traverse-form-item"
      style={{ marginBottom: "4px" }}
      validateStatus={validateErrorMessage === null ? "success" : "error"}
      help={validateErrorMessage}
      wrapperCol={noLabel ? { span: 24 } : undefined}
    >
      <Field
        tooltip={!noTooltip ? config.tooltip : undefined}
        suggestions={config.suggestions}
      >
        {children}
      </Field>
    </Form.Item>
  );
};

export default TraverseFormItem;
