import { Button, Popconfirm, Table, Tooltip } from "antd";
import { ColumnProps } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SimulationConfigsField from "src/components/SimulationConfigs/SimulationConfigsField";
import FieldGroupTableImportModal from "src/components/SimulationConfigs/SimulationConfigsFieldGroup/FieldGroupTableImportModal";
import { useSubElementForCardData } from "src/components/SimulationConfigs/SimulationConfigsFieldGroup/utils";
import { useValuesLengthWithPrefilledLinkageUpdate } from "src/components/SimulationConfigs/hooks";
import {
  Accessor,
  CardData,
  WorkflowConfigCardType,
  WorkflowConfigField,
  isCardData,
} from "src/networking/types";

import { addFormList, removeFormList } from "src/redux/actions/configs";
import { GlobalState } from "src/redux/reducers";
import { canViewDebug } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";

export interface SimulationConfigsTableProps {
  cardData: CardData;
  accessor: Accessor;
  hiddenConfigActionKeys: string[];
  disabledConfigActionKeys: string[];
}

const isInvalidField = (field: WorkflowConfigField) =>
  field.card_ui_type === WorkflowConfigCardType.TableRowBasedFields ||
  field.card_ui_type === WorkflowConfigCardType.TableColBasedFields;

const FieldTable: React.FC<SimulationConfigsTableProps> = ({
  cardData,
  accessor,
  hiddenConfigActionKeys,
  disabledConfigActionKeys,
}) => {
  const dispatch = useDispatch();
  const [valuesLength, hasPrefilledLinkageValue] =
    useValuesLengthWithPrefilledLinkageUpdate(accessor, cardData);

  const debugForms = useSelector((state: GlobalState) => state.configs.debug);
  const fieldsConfig = useSubElementForCardData(cardData);

  const { getTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently?.().then((token: string) => {
      setAuthToken(token);
    });
  }, [getTokenSilently]);

  const extractFields = (
    cards: (CardData | WorkflowConfigField)[]
  ): WorkflowConfigField[] =>
    cards.filter(
      (f): f is WorkflowConfigField => !isCardData(f) && !isInvalidField(f)
    );

  const columns = extractFields(fieldsConfig)
    .map(
      (f) =>
        ({
          title: f.tooltip ? (
            <Tooltip title={f.tooltip}>{f.display_name}</Tooltip>
          ) : (
            f.display_name
          ),
          dataIndex: f.config_name.join("."),
        } as ColumnProps<Record<string, unknown>>)
    )
    .concat({
      title: "Remove",
      dataIndex: ".remove",
      fixed: "right",
    });
  const dataSource = [...Array(valuesLength).keys()].map((index: number) => ({
    key: index,
    ...Object.fromEntries(
      extractFields(fieldsConfig)
        .map((f) => [
          f.config_name.join("."),
          <SimulationConfigsField
            config={f}
            accessor={accessor
              .concat(index)
              .concat(f.config_name.slice(cardData.common_prefix.length))}
            hiddenConfigActionKeys={hiddenConfigActionKeys}
            disabledConfigActionKeys={disabledConfigActionKeys}
            noLabel
            noTooltip
          />,
        ])
        .concat(
          hasPrefilledLinkageValue
            ? []
            : [
                [
                  ".remove",
                  <Popconfirm
                    title="Confirm delete row?"
                    onConfirm={() => dispatch(removeFormList(accessor, index))}
                  >
                    <Button>X</Button>
                  </Popconfirm>,
                ],
              ]
        )
    ),
  }));

  const [showImportForm, setShowImportForm] = useState<boolean>(false);
  const importFormFields = extractFields(fieldsConfig).map((f) => {
    const res = f.config_name.slice(cardData.common_prefix.length);
    if (res.length !== 1) {
      console.error(
        `The config name for the table (${JSON.stringify(
          f.config_name
        )}) length should be just 1 more than the common prefix (${JSON.stringify(
          cardData.common_prefix
        )} length).`
      );
    }
    return {
      name: res[0],
      field: f,
    };
  });

  const simulationStateIsUndefined = useSelector(
    (state: GlobalState) => state.configs.formValues === undefined
  );
  if (simulationStateIsUndefined) {
    return null;
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        pagination={false}
        scroll={{ x: true }}
        className="traverse-form-item-table"
      />
      {!hasPrefilledLinkageValue && (
        <Button
          onClick={() => dispatch(addFormList(accessor))}
          style={{ marginTop: "16px" }}
        >
          Add row
          {debugForms ? ` (${JSON.stringify(accessor)})` : ""}
        </Button>
      )}
      {authToken !== undefined && canViewDebug(authToken) && (
        <>
          <Button onClick={() => setShowImportForm(true)}>Import</Button>
          {showImportForm && (
            <FieldGroupTableImportModal
              tableName={cardData.name}
              fields={importFormFields}
              accessor={accessor}
              showForm={showImportForm}
              dismissForm={() => setShowImportForm(false)}
            />
          )}
        </>
      )}
    </>
  );
};
export default FieldTable;
