import React from "react";
import { WdBrandIcon } from "src/components/NavHeader/icons";
import acenergyLogo from "src/components/NavHeader/icons/acenergyLogo.png";
import forestaliaLogo from "src/components/NavHeader/icons/forestaliaLogo.png";
import styled from "styled-components";

const BrandText = styled.span`
  font-family: Blender Pro;
  font-size: 28px;
  color: #ffffff;

  margin-left: 6px;
`;

export const logos: Record<string, React.ReactNode> = {
  Forestalia: (
    <img
      src={forestaliaLogo}
      alt="fireSpot"
      style={{ width: "160px", marginRight: "18px", marginTop: "14px" }}
    />
  ),
  ACEnergy: (
    <img
      src={acenergyLogo}
      alt="fireSpot"
      style={{ width: "140px", marginRight: "0", marginTop: "10px" }}
    />
  ),
};

export const defaultLogo = (
  <>
    <WdBrandIcon />
    <BrandText>WindDesk</BrandText>
  </>
);
