import { UploadOutlined } from "@ant-design/icons/lib";
import { Button, Upload } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import { UploadChangeParam } from "antd/lib/upload";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useProjectSelectionState } from "src/components/Projects/hooks";
import { useSimulationSelectionState } from "src/components/Simulations/hooks";
import { winddeskBaseUrl } from "src/networking";
import { hydraWinddeskClient } from "src/networking/index";
import { StorageFile } from "src/networking/types";
import { useAuth0 } from "src/utils/react-auth0-spa";

interface Props {
  disabled: boolean;
  multiple?: boolean;
  value?: StorageFile | StorageFile[];
  onChangeValue: (newValue: StorageFile | StorageFile[]) => void;
}

const SimulationConfigsFieldFileInput: React.FC<Props> = ({
  disabled,
  multiple,
  value,
  onChangeValue,
}) => {
  const { getTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently().then((_token: string) => setToken(_token));
  }, [getTokenSilently]);

  let initialFiles: UploadFile<StorageFile[]>[] = [];
  if (value !== null && value !== undefined) {
    // @ts-ignore TODO
    initialFiles = (Array.isArray(value) ? value : [value]).map((f) => ({
      uid: f.id,
      status: "done",
      url: undefined,
      type: "",
      response: [f],
      size: f.size,
      name: f.name,
    }));
    if (!multiple) {
      initialFiles.slice(0);
    }
  }
  const [uploadedFiles, setUploadedFiles] =
    useState<UploadFile[]>(initialFiles);

  const currentProjectId = useProjectSelectionState()[0];
  const currentSimulationId = useSimulationSelectionState()[0];

  if (token === undefined) {
    return null;
  }

  const handleDownload = (file: UploadFile<StorageFile>) => {
    hydraWinddeskClient
      .get(
        `/v1/projects/${currentProjectId}/workflows/${currentSimulationId}/download?file=${file.uid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      )
      .then((resp) => saveAs(resp.data, file.name));
    return false;
  };

  const handleChange: (
    param: UploadChangeParam<UploadFile<StorageFile[]>>
  ) => void = ({ file, fileList }) => {
    const uploadedFileList = multiple
      ? fileList
      : fileList.length === 0
      ? []
      : [fileList[fileList.length - 1]];

    if (file.response) {
      onChangeValue(
        multiple
          ? uploadedFileList.flatMap((f) => (f.response ? [f.response] : []))
          : uploadedFileList.length === 0
          ? null
          : uploadedFileList[0].response
      );
    }
    setUploadedFiles(uploadedFileList);
  };

  const progress = {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    strokeWidth: 3,
    format: (percent?: number) =>
      percent ? `${parseFloat(percent.toFixed(2))}%` : "0%",
  };

  return (
    <>
      <Upload
        name="file"
        action={`${winddeskBaseUrl}/v1/projects/${currentProjectId}/workflows/${currentSimulationId}/upload_stream`}
        headers={{
          Authorization: `Bearer ${token}`,
        }}
        progress={progress}
        onDownload={handleDownload}
        onChange={handleChange}
        onRemove={() => {
          setUploadedFiles([]);
          return true;
        }}
        fileList={uploadedFiles}
        showUploadList={{
          showDownloadIcon: true,
        }}
        style={{ minWidth: 90 }}
        multiple={multiple}
      >
        <Button icon={<UploadOutlined />} disabled={disabled}>
          Upload
        </Button>
      </Upload>
    </>
  );
};
export default SimulationConfigsFieldFileInput;
