import { Button, Col, Row } from "antd";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WinddeskCol from "src/components/Common/WinddeskCol";
import Map from "src/components/Monitor/Summary/Map";
import OverallStatisticsCard from "src/components/Monitor/Summary/OverallStatisticsCard";
import { ReactComponent as BellIcon } from "src/components/Monitor/Summary/icons/bell.svg";
import { ReactComponent as MeterIcon } from "src/components/Monitor/Summary/icons/meter.svg";
import { ReactComponent as SiteIcon } from "src/components/Monitor/Summary/icons/site.svg";
import AlertsCard, {
  AlertsCardButton,
} from "src/components/Monitor/shared/AlertsCard";
import DataCoverageCard from "src/components/Monitor/shared/DataCoverageCard";
import InfoCard from "src/components/Monitor/shared/InfoCard";
import { hydraWinddeskClient } from "src/networking/index";
import { Project, WorkflowType } from "src/networking/types";
import {
  getDataCoverageAverage,
  getSitesDataCoverage,
} from "src/networking/utils";
import { getAlertsForProject } from "src/redux/actions/alerts";
import {
  getConfigChangesForProject,
  getPreQcSensorsForProject,
  getSitesForProject,
} from "src/redux/actions/projects";
import { GlobalState } from "src/redux/reducers";
import { canManageHydra } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";

interface Props {
  project: Project;
}

const ProjectSummary: React.FC<Props> = ({ project }) => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const getAlertsForProjectState = useSelector(
    (state: GlobalState) => state.requests.GET_ALERTS_FOR_PROJECT_REQUEST
  );
  const getSitesForProjectState = useSelector(
    (state: GlobalState) => state.requests.GET_SITES_FOR_PROJECT_REQUEST
  );
  const getPreQcSensorsForProjectState = useSelector(
    (state: GlobalState) =>
      state.requests.GET_PRE_QC_SENSORS_FOR_PROJECT_REQUEST
  );

  const projectId = project.id;

  const [token, setToken] = useState<string | undefined>();
  useEffect(() => {
    getTokenSilently().then((t: string) => {
      setToken(t);
    });
  }, [getTokenSilently]);

  useEffect(() => {
    if (token) {
      dispatch(
        getConfigChangesForProject(projectId, token, WorkflowType.MonitorFull)
      );
      // Do not get alerts resolved from one week ago
      dispatch(
        getAlertsForProject(
          projectId,
          token,
          Math.floor(Date.now() / 1000) - 604800
        )
      );
      dispatch(getSitesForProject(projectId, token, WorkflowType.MonitorFull));
      dispatch(
        getPreQcSensorsForProject(projectId, token, WorkflowType.MonitorFull)
      );
    }
  }, [dispatch, projectId, token]);

  const alertsState = useSelector((state: GlobalState) => state.alerts);
  const sitesState = useSelector((state: GlobalState) => state.sites);

  const alerts = Object.values(alertsState.alerts).filter(
    (alert) => alert.project_id === project.id
  );
  const sites = Object.values(sitesState.sites).filter(
    (site) => site.project === project.id
  );
  const dataCoverage = getSitesDataCoverage(sites);
  const preQcSensorsDataCoverage =
    getPreQcSensorsForProjectState &&
    getPreQcSensorsForProjectState.data &&
    getDataCoverageAverage(getPreQcSensorsForProjectState.data);

  const dataCoverageIndices = [
    ...new Set(
      sites?.flatMap(
        ({ sensors }) =>
          sensors?.flatMap((sensor) =>
            sensor.data_coverage_timeseries.map(([dt, _v]) => dt)
          ) ?? []
      ) ?? []
    ),
  ].sort();
  const aggregatedCoverage =
    sites?.map((site) =>
      site.sensors
        ? Object.fromEntries(
            Object.entries(
              site.sensors.reduce<Record<string, number>>(
                (acc, { data_coverage_timeseries: c }) => {
                  const res = { ...acc };
                  for (const [dt, cov] of c) {
                    if (!Object.prototype.hasOwnProperty.call(res, dt)) {
                      res[dt] = 0;
                    }
                    res[dt] += cov;
                  }
                  return res;
                },
                {}
              )
              // @ts-ignore site.sensors already checked above
            ).map(([k, v]) => [k, v / site.sensors.length])
          )
        : {}
    ) ?? [];

  return (
    <Row gutter={16}>
      <Col span={8}>
        <InfoCard
          loading={getSitesForProjectState.isLoading}
          avatar={<SiteIcon />}
          title="Sites"
          description={[
            {
              title: sites.length.toString(),
              subtitle: "Total",
              emphasis: true,
            },
            { title: sites.length.toString(), subtitle: "Active" },
            { title: "0", subtitle: "Inactive" },
          ]}
        />
      </Col>
      <Col span={8}>
        <InfoCard
          loading={getAlertsForProjectState.isLoading}
          avatar={<BellIcon />}
          title="Alerts"
          description={[
            {
              title: alerts.length.toString(),
              subtitle: "Total",
              emphasis: true,
            },
            {
              title: alerts
                .filter(({ priority }) => priority === "HIGH")
                .length.toString(),
              subtitle: "Major",
            },
            {
              title: alerts
                .filter(({ priority }) => priority === "MEDIUM")
                .length.toString(),
              subtitle: "Minor",
            },
            {
              title: alerts
                .filter(({ priority }) => priority === "LOW")
                .length.toString(),
              subtitle: "Warnings",
            },
          ]}
        />
      </Col>
      <Col span={8}>
        <InfoCard
          avatar={<MeterIcon />}
          title="Data Coverage"
          description={[
            {
              title: preQcSensorsDataCoverage
                ? `${(preQcSensorsDataCoverage * 100).toFixed(1)}%`
                : "-",
              subtitle: "Pre-QC",
            },
            {
              title: dataCoverage ? `${(dataCoverage * 100).toFixed(1)}%` : "-",
              subtitle: "Post-QC",
            },
          ]}
        />
      </Col>
      <WinddeskCol span={15}>
        <OverallStatisticsCard
          sites={sites}
          loading={getSitesForProjectState.isLoading}
          error={getSitesForProjectState.error}
        />
        <AlertsCard
          title="All Active Alerts Summary"
          alerts={alerts.filter(({ resolved_by_id }) => !resolved_by_id)}
          loading={getAlertsForProjectState.isLoading}
          error={getAlertsForProjectState.error}
          tableFilters={false}
          startingShownColumns={[
            "siteId",
            "sensorId",
            "sensorType",
            "priority",
            "nature",
            "message",
            "activationTime",
            "status",
          ]}
          noSelect
          buttonsToShow={[AlertsCardButton.SNOOZE, AlertsCardButton.RESOLVE]}
          timeZoneOffset={project.timezone}
        />
        {token && canManageHydra(token) && (
          <Button
            style={{ textTransform: "uppercase" }}
            onClick={async () => {
              if (token) {
                hydraWinddeskClient
                  .get(
                    `/v1/projects/${projectId}/mdf?workflow_type=monitor_full`,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                      responseType: "blob",
                    }
                  )
                  .then((resp) => {
                    saveAs(resp.data, "mdf.zip");
                  });
              }
            }}
          >
            Download MDF
          </Button>
        )}
      </WinddeskCol>
      <WinddeskCol span={9}>
        <Map sites={project?.sites ?? []} />
        <DataCoverageCard
          loading={!project.sites}
          x={dataCoverageIndices}
          y={sites?.map(({ name }) => name) ?? []}
          z={aggregatedCoverage.map((site) =>
            dataCoverageIndices.map((k) => site[k])
          )}
        />
      </WinddeskCol>
    </Row>
  );
};

export default ProjectSummary;
