import { Spin } from "antd";
import queryString from "query-string";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Monitor from "src/components/Monitor";
import { useDispatchProjects } from "src/networking/hooks";
import { WorkflowType } from "src/networking/types";
import { GlobalState } from "src/redux/reducers";
import useConstant from "use-constant";

/**
 * The WinddeskMainView component is responsible for determining which
 * item the user is currently viewing from the query parameters
 * `type` and `id`. It is then responsible for fetching the data
 * from the server corresponding to that item so pages can
 * render the item's details.
 */

const MonitorMainView: React.FC = () => {
  const workflowTypes = useConstant(() => [WorkflowType.MonitorFull]);
  useDispatchProjects(workflowTypes);
  const location = useLocation();
  const { projectId, siteName } = queryString.parse(location.search);

  const getProjectsState = useSelector(
    (state: GlobalState) => state.requests.CLEAR_AND_GET_PROJECTS_REQUEST
  );

  return getProjectsState.isCompleted ? (
    <Monitor
      projectId={
        Array.isArray(projectId) ? projectId[0] : projectId ?? undefined
      }
      siteName={Array.isArray(siteName) ? siteName[0] : siteName ?? undefined}
    />
  ) : (
    <Spin size="large" />
  );
};

export default MonitorMainView;
