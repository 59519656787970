export interface RequestPayload {
  client?: string;
  request: {
    url: string;
    method?: string;
    data?: Record<string, any> | string;
    headers?: Record<string, string>;
  };
}

export function getActionTypeForSuccess(type: string): string {
  return `${type}_SUCCESS`;
}

export function getActionTypeForFailure(type: string): string {
  return `${type}_FAILURE`;
}
