import { Button, Card, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";

const CardButton = styled(Button)`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
  padding: 23px 18px;
  border-radius: 0;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const WinddeskCardComponent = styled(Card)`
  & .ant-card-head-title,
  & .ant-card-extra {
    padding: 0 !important;
  }

  & .ant-card-head-title {
    font-family: "Blender Pro";
    font-size: 1.15rem;
    color: #595959;
  }

  & .traverse-form-item {
    margin-bottom: 6px !important;
  }
`;

interface Props {
  title: React.ReactNode;
  icon?: React.ReactNode;
  buttonEnabled?: boolean;
  buttonDisabledTooltip?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
  loading?: boolean;
  style?: Record<string, any>;
  headStyle?: Record<string, any>;
  bodyStyle?: Record<string, any>;
  className?: string;
}

export const WinddeskCard: React.FC<Props> = ({
  title,
  icon,
  buttonText,
  buttonOnClick,
  loading,
  children,
  buttonEnabled,
  buttonDisabledTooltip,
  style,
  headStyle,
  bodyStyle,
  className,
}) => {
  const cardButton =
    buttonText || icon ? (
      <CardButton
        type="primary"
        onClick={buttonOnClick}
        disabled={buttonEnabled === false}
      >
        <span style={{ marginRight: "10px" }}>{icon}</span>
        {buttonText}
      </CardButton>
    ) : null;

  return (
    <WinddeskCardComponent
      className={className}
      title={
        <div
          style={{
            height: "48px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
        </div>
      }
      extra={
        buttonText &&
        (buttonEnabled === false && buttonDisabledTooltip ? (
          <Tooltip title={buttonDisabledTooltip}>{cardButton}</Tooltip>
        ) : (
          cardButton
        ))
      }
      style={{
        width: "100%",
        backgroundColor: "transparent",
        ...style,
      }}
      headStyle={{
        border: "0",
        borderRadius: "3px 3px 0 0",
        boxSizing: "border-box",
        boxShadow: "none",

        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",

        padding: "0 0 0 12px",

        ...headStyle,
      }}
      bodyStyle={{
        background: "#ffffff",
        boxSizing: "border-box",
        borderTop: "0",
        overflow: "auto",
        ...bodyStyle,
      }}
      loading={loading}
      bordered={false}
      size="small"
    >
      {children}
    </WinddeskCardComponent>
  );
};
