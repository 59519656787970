import { Button, Form, Input, Select } from "antd";
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import { WinddeskSubcard } from "src/components/Common/WinddeskSubcard";
import Field from "src/components/SimulationConfigs/SimulationConfigsField/Field";
import { useSimulationState } from "src/components/Simulations/hooks";

const SimulationOverview: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const workflow = useSimulationState();

  useEffect(() => {
    form.resetFields();
  }, [form, workflow]);

  const mailToParams = {
    subject: t("Request workflow type conversion"),
    body: t(
      `For workflow name ${workflow?.name}, we would like to request for a conversion between workflow type.`
    ),
  };
  return (
    <WinddeskCard
      bodyStyle={{ marginBottom: "14px" }}
      title={t("Workflow Information")}
    >
      <WinddeskSubcard>
        <Form
          form={form}
          colon={false}
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label={t("Workflow name")}
            labelAlign="right"
            name="workflowName"
            rules={[{ required: true, validateTrigger: "onChange" }]}
          >
            <Field>
              <Input value={workflow?.name} disabled />
            </Field>
          </Form.Item>
          <Form.Item
            label={t("Workflow type")}
            labelAlign="right"
            name="workflowType"
            rules={[{ required: true, validateTrigger: "onChange" }]}
          >
            <Field>
              <Select
                value={workflow?.type}
                style={{ minWidth: 120 }}
                disabled
                options={[
                  { value: "prospect", label: t("Prospect") },
                  { value: "predict", label: t("Predict") },
                ]}
              />
            </Field>
          </Form.Item>
          <Form.Item
            label={t("Request workflow conversion")}
            labelAlign="right"
          >
            <Button
              onClick={() => {
                window.location.href = `mailto:team@traverse.ai?subject=${mailToParams.subject}&body=${mailToParams.body}`;
              }}
            >
              Request
            </Button>
          </Form.Item>
        </Form>
      </WinddeskSubcard>
    </WinddeskCard>
  );
};
export default SimulationOverview;
