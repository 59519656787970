import * as FS from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Spin } from "antd";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import "src/i18n";
import "src/index.css";
import "src/fonts/style.css";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import { multiClientMiddleware } from "redux-axios-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import App from "src/App";
import {
  argosClient,
  hydraHttpClient,
  hydraWinddeskClient,
} from "src/networking";
import rootReducer from "src/redux/reducers";
import * as serviceWorker from "src/serviceWorker";
import { isProductionEnvironment } from "src/utils";
import history from "src/utils/history";
import { Auth0Provider } from "src/utils/react-auth0-spa";

if (process.env.REACT_APP_WINDDESK_ENVIRONMENT !== undefined) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_WINDDESK_ENVIRONMENT,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

FS.init({
  orgId: "DHE0G",
  devMode: !isProductionEnvironment,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      multiClientMiddleware({
        default: { client: hydraWinddeskClient },
        argos: { client: argosClient },
        hydraHttp: { client: hydraHttpClient },
      })
    )
  )
);

const onRedirectCallback = (_appState: any) => {
  const redirectTo = window.localStorage.getItem("redirectTo");
  let url;

  if (redirectTo && redirectTo !== "/logout") {
    url = new URL(`${window.location.origin}${redirectTo}`);
    window.localStorage.removeItem("redirectTo");
  } else {
    url = window.location;
  }

  history.push({ pathname: url.pathname, search: url.search, hash: url.hash });
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* prettier-ignore */}
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || "traverse-dev.auth0.com"}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID || "qcel88YeF080p8Q3YYFbb73YMoEgzRxX"}
        redirect_uri={window.location.origin}
        audience={process.env.REACT_APP_AUTH_AUDIENCE || "https://traverse-internal"}
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
      >
        <Suspense fallback={<Spin />}>
          <App />
        </Suspense>
      </Auth0Provider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
