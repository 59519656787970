import { IWorkflowRun } from "src/entities/workflowRun";
import { AdminActionTypes } from "src/redux/actionTypes";
import { getActionTypeForSuccess } from "src/redux/utils";

export interface AdminState {
  projects: Array<any>;
  workflows: { [id: string]: { [id: string]: IWorkflowRun[] } };
}

const initialState = {
  projects: [],
  workflows: {},
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const reducer = (state: AdminState = initialState, action: any): AdminState => {
  switch (action.type) {
    case getActionTypeForSuccess(AdminActionTypes.GET_ALL_PROJECTS_REQUEST):
      return {
        ...state,
        projects: [...action.payload.data],
      };
    case getActionTypeForSuccess(AdminActionTypes.GET_ALL_WORKFLOWS_REQUEST): {
      const projectWorkflows = action.payload.data;
      projectWorkflows.sort((a: any, b: any) => b.workflow_id - a.workflow_id);
      return {
        ...state,
        workflows: {
          ...state.workflows,
          [action.meta.previousAction.requestParams.projectId]: [
            ...projectWorkflows,
          ],
        },
      };
    }
    case getActionTypeForSuccess(
      AdminActionTypes.GET_ALL_WORKFLOW_RUNS_REQUEST
    ): {
      const projectWorkflowRuns: IWorkflowRun[] = action.payload.data;
      const workflows: { [id: string]: IWorkflowRun[] } = {};
      projectWorkflowRuns.forEach((run) => {
        if (!(run.workflow_id in workflows)) {
          workflows[run.workflow_id] = [];
        }
        workflows[run.workflow_id].push(run);
      });
      return {
        ...state,
        workflows: {
          ...state.workflows,
          [action.meta.previousAction.requestParams.projectId]: workflows,
        },
      };
    }
    case getActionTypeForSuccess(AdminActionTypes.RUN_PROJECT_REQUEST): {
      const { projectId } = action.meta.previousAction;
      const projects =
        projectId in state.workflows ? state.workflows[projectId] : {};
      const run: IWorkflowRun = action.payload.data;
      return {
        ...state,
        workflows: {
          ...state.workflows,
          [action.meta.previousAction.requestParams.projectId]: {
            ...projects,
            [run.workflow_id]: {
              ...projects[run.workflow_id],
              run,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
