import { Button, Col, Descriptions, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import { useProjectState } from "src/components/Projects/hooks";
import { publishedWindFarmAlternativesData } from "src/components/Projects/publishedWindFarmAlternativesData";

const PublishedWindFarmAlternatives: React.FC = () => {
  const { t } = useTranslation();
  const project = useProjectState();
  const dataByProject =
    project?.id === undefined
      ? undefined
      : publishedWindFarmAlternativesData[project.id];

  const [selection, setSelection] = useState<number>(0);
  const selectionData = dataByProject && dataByProject[selection];

  const [mapSelections, setMapSelections] = useState<string[] | undefined>(
    undefined
  );
  useEffect(() => {
    if (mapSelections === undefined && selectionData?.map) {
      setMapSelections(Object.keys(selectionData.map));
    }
  }, [mapSelections, selectionData]);

  const [map, setMap] = useState<any>(undefined);
  useEffect(() => {
    if (selectionData && map) {
      map.setView(selectionData.initialLocation, 13);
    }
  }, [map, selectionData]);

  return (
    <WinddeskCard title={t("Published Wind Farm Alternatives")}>
      {dataByProject && selectionData ? (
        <Row gutter={14} style={{ display: "flex" }}>
          <Col span={12}>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <Select
                style={{ flex: 1 }}
                value={selection}
                onChange={setSelection}
                options={dataByProject.map(({ Name }, i) => ({
                  value: i,
                  label: Name,
                }))}
              />
              <Button
                disabled={selection === 0}
                style={{ marginLeft: "5px" }}
                onClick={() => setSelection(selection - 1)}
              >
                &lt;
              </Button>
              <Button
                disabled={selection === dataByProject.length - 1}
                style={{ marginLeft: "5px" }}
                onClick={() => setSelection(selection + 1)}
              >
                &gt;
              </Button>
            </div>
            <Descriptions column={1} colon={false} bordered size="small">
              {Object.entries(selectionData).map(([k, v]) =>
                ["map", "initialLocation"].includes(k) ? null : (
                  <Descriptions.Item label={k} key={k}>
                    {v}
                  </Descriptions.Item>
                )
              )}
            </Descriptions>
          </Col>
          <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
            <Select
              mode="multiple"
              style={{ width: "100%", marginBottom: "10px" }}
              value={mapSelections}
              onChange={setMapSelections}
              options={Object.keys(selectionData.map).map((k) => ({
                value: k,
                label: k,
              }))}
            />
            <MapContainer style={{ flex: 1 }} whenCreated={setMap}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {selectionData.map &&
                Object.entries(selectionData.map).flatMap(
                  ([key, dataObject]: any) =>
                    mapSelections?.includes(key)
                      ? Object.values(dataObject).map((geoJsonData: any) => (
                          <GeoJSON
                            key={JSON.stringify(geoJsonData)}
                            data={geoJsonData}
                          />
                        ))
                      : null
                )}
            </MapContainer>
          </Col>
        </Row>
      ) : (
        "Wind Farms will appear here when you have completed a full simulation."
      )}
    </WinddeskCard>
  );
};
export default PublishedWindFarmAlternatives;
