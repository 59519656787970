import { InfoCircleOutlined } from "@ant-design/icons/lib";
import { List, Tooltip } from "antd";
import React from "react";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import { ALL_COLUMNS } from "src/components/Monitor/SiteConfig/SensorConfigurationTable";
import { SensorConfiguration, Site } from "src/networking/types";
import styled from "styled-components";
import { getPrettySiteType } from "../shared/utils";

interface Props {
  site: Site;
  sensorConfig?: SensorConfiguration;
  showSite: boolean;
}

const DetailsItem = styled(List.Item)`
  border: 0;
  padding-top: 0 !important;
  padding-bottom: 4px !important;
`;

const Details: React.FC<Props> = ({ site, sensorConfig, showSite }) => (
  <WinddeskCard
    title={
      <div>
        Details&nbsp;
        <Tooltip title="Select a site or sensor to view more details">
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    }
  >
    {showSite || sensorConfig === undefined ? (
      <List
        size="small"
        dataSource={
          site.type === "met_mast"
            ? [
                {
                  title: "Site name",
                  description: site.name,
                },
                {
                  title: "Status",
                  description: site.status,
                },
                {
                  title: "Data Coverage",
                  description: `${(site.data_coverage_ratio * 100).toFixed(
                    2
                  )}%`,
                },
                {
                  title: "Site Type",
                  description: getPrettySiteType(site.type),
                },
                {
                  title: "Logger Brand",
                  description: site.configuration.logger_brand,
                },
                {
                  title: "Logger Model",
                  description: site.logger_model,
                },
                {
                  title: "Serial Number",
                  description: site.logger_serial,
                },
                {
                  title: "Latitude",
                  description: site.position.coordinates.lat,
                },
                {
                  title: "Longitude",
                  description: site.position.coordinates.lng,
                },
                {
                  title: "Site Elevation [m]",
                  description: site.configuration.elevation,
                },
                {
                  title: "Averaging Period [min]",
                  description: site.logger_averaging_period,
                },
                {
                  title: "Sampling Rate [Hz]",
                  description: site.logger_sampling_rate,
                },
                {
                  title: "Project Time Zone",
                  description: site.project_time_zone,
                },
                {
                  title: "Logger Time Zone",
                  description: site.configuration.timezone,
                },
              ]
            : [
                {
                  title: "Site name",
                  description: site.name,
                },
                {
                  title: "Status",
                  description: site.status,
                },
                {
                  title: "Data Coverage",
                  description: `${(site.data_coverage_ratio * 100).toFixed(
                    2
                  )}%`,
                },
                {
                  title: "Site Type",
                  description: getPrettySiteType(site.type),
                },
                {
                  title: "Device Brand",
                  description: site.configuration.logger_brand,
                },
                {
                  title: "Device Model",
                  description: site.logger_model,
                },
                {
                  title: "Serial Number",
                  description: site.logger_serial,
                },
                {
                  title: "Latitude",
                  description: site.position.coordinates.lat,
                },
                {
                  title: "Longitude",
                  description: site.position.coordinates.lng,
                },
                {
                  title: "Site Elevation [m]",
                  description: site.configuration.elevation,
                },
                {
                  title: "Window Height [m]",
                  description: site.window_height,
                },
                {
                  title: "Averaging Period [min]",
                  description: site.logger_averaging_period,
                },
                {
                  title: "Sampling Rate [Hz]",
                  description: site.logger_sampling_rate,
                },
                {
                  title: "Project Time Zone",
                  description: site.timezone,
                },
                {
                  title: "Device Time Zone",
                  description: site.configuration.timezone,
                },
                {
                  title: "Flow Correction Settings",
                  description: site.flow_correction_settings,
                },
                {
                  title: "Calibration Facility",
                  description: site.calibration_facility,
                },
                {
                  title: "Calibration Reference",
                  description: site.calibration_reference,
                },
                {
                  title: "Calibration Certificate ID",
                  description: site.calibration_certificate_id,
                },
                {
                  title: "Calibration Date",
                  description: site.calibration_date,
                },
              ]
        }
        renderItem={({ title, description }) => (
          <DetailsItem>
            <List.Item.Meta title={title} />
            <div>{description}</div>
          </DetailsItem>
        )}
      />
    ) : (
      <List
        size="small"
        dataSource={(site.type === "met_mast"
          ? ([
              "sensor_weak_name",
              "status",
              "sensor_data_coverage_ratio",
              "sensor_type",
              "sensor_height",
              "target_boom_orientation",
              "sensor_brand",
              "sensor_model",
              "sensor_serial",
              "start_timestamp",
              "end_timestamp",
              "logger_offset",
              "logger_slope",
              "target_offset",
              "target_slope",
              "h_boom_diameter",
              "h_boom_length",
              "v_boom_diameter",
              "v_boom_length",
              "meas_net",
              "calibration_facility",
              "calibration_reference",
              "calibration_certificate_id",
              "calibration_date",
            ] as (keyof SensorConfiguration)[])
          : ([
              "sensor_weak_name",
              "status",
              "sensor_data_coverage_ratio",
              "sensor_type",
              "sensor_height",
              "start_timestamp",
              "end_timestamp",
            ] as (keyof SensorConfiguration)[])
        ).map((k) => ({
          title: ALL_COLUMNS[k].title,
          description: sensorConfig[k],
          render: ALL_COLUMNS[k].render as undefined | ((r: any) => string),
        }))}
        renderItem={({ title, description, render }) => (
          <DetailsItem>
            <List.Item.Meta title={title} />
            <div>{render ? render(description) : description}</div>
          </DetailsItem>
        )}
      />
    )}
  </WinddeskCard>
);

export default Details;
