export enum ProjectsActionTypes {
  CLEAR_AND_GET_PROJECTS_REQUEST = "CLEAR_AND_GET_PROJECTS_REQUEST",
  GET_PROJECT_DETAILS_REQUEST = "GET_PROJECT_DETAILS_REQUEST",
  GET_SITES_FOR_PROJECT_REQUEST = "GET_SITES_FOR_PROJECT_REQUEST",
  GET_CONFIG_CHANGES_FOR_PROJECT_REQUEST = "GET_CONFIG_CHANGES_FOR_PROJECT_REQUEST",
  CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST",
  UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST",
  GET_PRE_QC_SENSORS_FOR_PROJECT_REQUEST = "GET_PRE_QC_SENSORS_FOR_PROJECT_REQUEST",
  GET_QC_SENSORS_FOR_PROJECT_REQUEST = "GET_QC_SENSORS_FOR_PROJECT_REQUEST",
}

export enum SitesActionTypes {
  GET_SITE_DETAILS_REQUEST = "GET_SITE_DETAILS_REQUEST",
  GET_SITE_ALERTS_REQUEST = "GET_SITE_ALERTS_REQUEST",
  GET_CONFIG_CHANGES_FOR_SITE_REQUEST = "GET_CONFIG_CHANGES_FOR_SITE_REQUEST",
  GET_TIME_OFFSETS_FOR_SITE_REQUEST = "GET_TIME_OFFSETS_FOR_SITE_REQUEST",
  GET_DIRECTION_OFFSETS_FOR_SITE_REQUEST = "GET_DIRECTION_OFFSETS_FOR_SITE_REQUEST",
  GET_FLAGS_FOR_SITE_REQUEST = "GET_FLAGS_FOR_SITE_REQUEST",
  GET_PRE_QC_SENSORS_FOR_SITE_REQUEST = "GET_PRE_QC_SENSORS_FOR_SITE_REQUEST",
  GET_QC_SENSORS_FOR_SITE_REQUEST = "GET_QC_SENSORS_FOR_SITE_REQUEST",
  GET_SENSOR_CONFIGS_FOR_SITE_REQUEST = "GET_SENSOR_CONFIGS_FOR_SITE_REQUEST",
}

export enum SensorsActionTypes {
  GET_TIME_SERIES_FOR_SENSOR_REQUEST = "GET_TIME_SERIES_FOR_SENSOR_REQUEST",
  GET_TIME_SERIES_SAMPLING_RATES_FOR_SENSOR_REQUEST = "GET_TIME_SERIES_SAMPLING_RATES_FOR_SENSOR_REQUEST",
}

export enum AlertsActionTypes {
  GET_ALL_ALERTS_REQUEST = "GET_ALL_ALERTS_REQUEST",
  GET_ALERTS_FOR_SITE_REQUEST = "GET_ALERTS_FOR_SITE_REQUEST",
  CREATE_ALERTS_REQUEST = "CREATE_ALERTS_REQUEST",
  RESOLVE_ALERTS_REQUEST = "RESOLVE_ALERTS_REQUEST",
  UNRESOLVE_ALERTS_REQUEST = "UNRESOLVE_ALERTS_REQUEST",
  SHARE_ALERTS_REQUEST = "SHARE_ALERTS_REQUEST",
  SNOOZE_ALERTS_REQUEST = "SNOOZE_ALERTS_REQUEST",
  GET_ALERTS_FOR_PROJECT_REQUEST = "GET_ALERTS_FOR_PROJECT_REQUEST",
}

export enum AdminActionTypes {
  GET_ALL_PROJECTS_REQUEST = "GET_ALL_PROJECTS_REQUEST",
  GET_ALL_WORKFLOWS_REQUEST = "GET_ALL_WORKFLOWS_REQUEST",
  GET_ALL_WORKFLOW_RUNS_REQUEST = "GET_ALL_WORKFLOW_RUNS_REQUEST",
  RUN_PROJECT_REQUEST = "RUN_PROJECT_REQUEST",
  GET_PROJECT_STATUS_REQUEST = "GET_PROJECT_STATUS_REQUEST",
  GET_PROJECT_OUTPUT_REQUEST = "GET_PROJECT_OUTPUT_REQUEST",
}

export enum ConfigsActionTypes {
  GET_CONFIG_BY_WORKFLOW_ID_REQUEST = "GET_CONFIG_BY_WORKFLOW_ID_REQUEST",
  GET_PRESENTATION_CONFIG_REQUEST = "GET_PRESENTATION_CONFIG_REQUEST",
  GET_CONFIG_FILE_DATA_REQUEST = "GET_CONFIG_FILE_DATA_REQUEST",
  GET_DIFF_TO_LATEST_COMMIT_REQUEST = "GET_DIFF_TO_LATEST_COMMIT_REQUEST",
  SAVE_CONFIG_BY_WORKFLOW_ID_REQUEST = "SAVE_CONFIG_BY_WORKFLOW_ID_REQUEST",
  NOTIFY_RUN_WORKFLOW_REQUEST = "NOTIFY_RUN_WORKFLOW_REQUEST",
  UNLOCK_WORKFLOW_REQUEST = "UNLOCK_WORKFLOW_REQUEST",

  SET_FORM_STATE_FROM_CONFIG = "SET_FORM_STATE_FROM_CONFIG",
  UPDATE_FORM_STATE = "UPDATE_FORM_STATE",
  APPLY_DIFFS = "APPLY_DIFFS",
  RESET_FORM_STATE = "RESET_FORM_STATE",
  ADD_FORM_LIST = "ADD_FORM_LIST",
  REMOVE_FORM_LIST = "REMOVE_FORM_LIST",

  MODIFY_VALIDATION_ERRORS = "MODIFY_VALIDATION_ERRORS",

  SET_SHOW_PRIMARY = "SET_SHOW_PRIMARY",
  SET_DEBUG = "SET_DEBUG",
  SET_PROMPT_ON_LEAVE = "SET_PROMPT_ON_LEAVE",
}

export enum WorkflowsActionTypes {
  GET_WORKFLOWS_BY_PROJECT_ID_REQUEST = "GET_WORKFLOWS_BY_PROJECT_ID_REQUEST",
  CREATE_WORKFLOW_REQUEST = "CREATE_WORKFLOW_REQUEST",
}

export enum DocumentsActionTypes {
  GET_DOCUMENTS_BY_PROJECT_ID_REQUEST = "GET_DOCUMENTS_BY_PROJECT_ID_REQUEST",
  GET_DOCUMENTS_BY_WORKFLOW_ID_REQUEST = "GET_DOCUMENTS_BY_WORKFLOW_ID_REQUEST",
  UPLOAD_DOCUMENT_REQUEST = "UPLOAD_DOCUMENT_REQUEST",
}

export enum ChartsActionTypes {
  GET_TIME_SERIES_CHART_DATA_FOR_SENSORS_REQUEST = "GET_TIME_SERIES_CHART_DATA_FOR_SENSORS_REQUEST",
  GET_CORRELATION_CHART_DATA_FOR_SENSORS_REQUEST = "GET_CORRELATION_CHART_DATA_FOR_SENSORS_REQUEST",
}

export enum MdfActionTypes {
  LIST_TIME_SERIES_REQUEST = "LIST_TIME_SERIES_REQUEST",
  RESCALE_TIME_SERIES_REQUEST = "RESCALE_TIME_SERIES_REQUEST",
  GET_SCALED_TIME_SERIES_REQUEST = "GET_SCALED_TIME_SERIES_REQUEST",
  UPLOAD_FLAG_DATA_REQUEST = "UPLOAD_FLAG_DATA_REQUEST",
  APPEND_FLAG_DATA_REQUEST = "APPEND_FLAG_DATA_REQUEST",
  GET_FLAG_DATA_REQUEST = "GET_FLAG_DATA_REQUEST",
}

export enum MdfDashboardActionTypes {
  MDF_UPDATE_DATA = "MDF_UPDATE_DATA",
  MDF_UPDATE_VIEW = "MDF_UPDATE_VIEW",
  MDF_DRAW_LINE_GRAPHS = "MDF_DRAW_LINE_GRAPHS",
  MDF_UPDATE_LINE_GRAPHS = "MDF_UPDATE_LINE_GRAPHS",
  MDF_DRAW_CORR_GRAPHS = "MDF_DRAW_CORR_GRAPHS",
  MDF_DRAW_RATIO_GRAPHS = "MDF_DRAW_RATIO_GRAPHS",
}

// We explicitly state which every request so that
// we populate the initial request reducer with
// the initial request state. (see reducers/requests.tsx)
export enum Request {
  GET_ALL_ALERTS_REQUEST,
  GET_ALERTS_FOR_SITE_REQUEST,
  GET_ALERTS_FOR_PROJECT_REQUEST,
  GET_QC_SENSORS_FOR_PROJECT_REQUEST,
  CREATE_ALERTS_REQUEST,
  RESOLVE_ALERTS_REQUEST,
  UNRESOLVE_ALERTS_REQUEST,
  SNOOZE_ALERTS_REQUEST,
  SHARE_ALERTS_REQUEST,
  GET_TIME_SERIES_CHART_DATA_FOR_SENSORS_REQUEST,
  GET_CORRELATION_CHART_DATA_FOR_SENSORS_REQUEST,
  GET_SITE_ALERTS_REQUEST,
  GET_SITE_DETAILS_REQUEST,
  GET_CONFIG_CHANGES_FOR_SITE_REQUEST,
  GET_TIME_OFFSETS_FOR_SITE_REQUEST,
  GET_DIRECTION_OFFSETS_FOR_SITE_REQUEST,
  GET_FLAGS_FOR_SITE_REQUEST,
  GET_TIME_SERIES_FOR_SENSOR_REQUEST,
  GET_TIME_SERIES_SAMPLING_RATES_FOR_SENSOR_REQUEST,
  GET_DATA_TIME_OFFSETS_REQUEST,
  GET_DATA_DIRECTION_OFFSETS_REQUEST,
  GET_DATA_FILTERING_REQUEST,
  GET_DATA_MISSING_REQUEST,
  GET_DATA_EXCLUDED_REQUEST,
  GET_QC_SENSORS_FOR_SITE_REQUEST,
  GET_SENSOR_CONFIGS_FOR_SITE_REQUEST,
  CLEAR_AND_GET_PROJECTS_REQUEST,
  GET_PROJECT_DETAILS_REQUEST,
  GET_CONFIG_CHANGES_FOR_PROJECT_REQUEST,
  GET_SITES_FOR_PROJECT_REQUEST,
  GET_ALL_CONFIGS_REQUEST,
  GET_CONFIG_BY_WORKFLOW_ID_REQUEST,
  GET_PRESENTATION_CONFIG_REQUEST,
  SAVE_CONFIG_BY_WORKFLOW_ID_REQUEST,
  NOTIFY_RUN_WORKFLOW_REQUEST,
  UNLOCK_WORKFLOW_REQUEST,
  GET_DOCUMENTS_BY_PROJECT_ID_REQUEST,
  GET_DOCUMENTS_BY_WORKFLOW_ID_REQUEST,
  UPLOAD_DOCUMENT_REQUEST,
  LIST_TIME_SERIES_REQUEST,
  RESCALE_TIME_SERIES_REQUEST,
  GET_SCALED_TIME_SERIES_REQUEST,
  UPLOAD_FLAG_DATA_REQUEST,
  APPEND_FLAG_DATA_REQUEST,
  GET_FLAG_DATA_REQUEST,
  MDF_UPDATE_DATA,
  MDF_UPDATE_VIEW,
  MDF_DRAW_LINE_GRAPHS,
  MDF_UPDATE_LINE_GRAPHS,
  MDF_DRAW_CORR_GRAPHS,
  MDF_DRAW_RATIO_GRAPHS,
}
