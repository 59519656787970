import { Button, Form, Input, Modal, Radio } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getFieldTypeForCheck } from "src/components/SimulationConfigs/utils/formValues";
import { Accessor, WorkflowConfigField } from "src/networking/types";
import { updateFormState } from "src/redux/actions/configs";

interface Props {
  tableName: string;
  fields: { name: string; field: WorkflowConfigField }[];
  accessor: Accessor;
  showForm: boolean;
  dismissForm: () => void;
}

const FieldGroupTableImportModal: React.FC<Props> = ({
  tableName,
  fields,
  accessor,
  showForm,
  dismissForm,
}) => {
  const dispatch = useDispatch();

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const [delimiter, setDelimiter] = useState<string | undefined>(undefined);
  const [customDelimiter, setCustomDelimiter] = useState<string>("");

  const displayedCustomDelimiter = customDelimiter
    .replaceAll(" ", "<space>")
    .replaceAll("	", "<tab>");

  return (
    <Modal
      title={`Import into table: ${tableName}`}
      visible={showForm}
      footer={null}
      onCancel={dismissForm}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={({ contents }: { contents: string }) => {
          const actualDelimiter =
            (delimiter === "others" ? customDelimiter : delimiter) ?? "";

          dispatch(
            updateFormState({
              accessor,
              previousValue: undefined,
              currentValue: contents.split("\n").map((l) => {
                const splittedLine = l.split(actualDelimiter);
                return Object.fromEntries(
                  fields.map(({ name, field }, i) => {
                    const value = splittedLine[i];

                    const fieldType = getFieldTypeForCheck(field);
                    let actualValue: any = value;
                    switch (fieldType) {
                      case "number":
                        actualValue = Number(value);
                        break;
                      case "boolean":
                        actualValue = !!value;
                        break;
                      default:
                        break;
                    }

                    return [name, actualValue];
                  })
                );
              }),
            })
          );

          dismissForm();
        }}
      >
        <Form.Item
          label="Delimiter"
          labelAlign="right"
          name="delimiter"
          rules={[{ required: true, validateTrigger: "onChange" }]}
        >
          <Radio.Group
            onChange={({ target: { value } }) => setDelimiter(value)}
          >
            <Radio style={radioStyle} value="	">
              Tab
            </Radio>
            <Radio style={radioStyle} value=",">
              Comma
            </Radio>
            <Radio style={radioStyle} value="  ">
              2 Spaces
            </Radio>
            <Radio style={radioStyle} value="    ">
              4 Spaces
            </Radio>
            <Radio style={radioStyle} value="others">
              Others
              {displayedCustomDelimiter === ""
                ? null
                : ` (${displayedCustomDelimiter})`}
              {delimiter === "others" ? (
                <>
                  <br />
                  <Input
                    onChange={({ target: { value } }) =>
                      setCustomDelimiter(value)
                    }
                  />
                </>
              ) : null}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Contents"
          labelAlign="right"
          name="contents"
          rules={[{ required: true, validateTrigger: "onChange" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }} labelAlign="right">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FieldGroupTableImportModal;
