import React from "react";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import ImageCard from "src/components/Monitor/shared/ImageCard";

interface Props {
  projectId: string;
  siteName: string;
  sensorName?: string;
}

const SensorImageCard: React.FC<Props> = ({
  projectId,
  siteName,
  sensorName,
}) => {
  if (sensorName === undefined) {
    return (
      <WinddeskCard loading={false} title="Sensor Image">
        Please select a sensor by clicking at one of the rows of the sensor
        tables on the left.
      </WinddeskCard>
    );
  }

  return (
    <ImageCard
      url={`/v1/projects/${projectId}/sites/${siteName}/sensors/${sensorName}/sensor_image?workflow_type=monitor_full`}
      title="Sensor Image"
      caption={`Sensor ${sensorName}`}
    />
  );
};

export default SensorImageCard;
