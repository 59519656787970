import { Typography } from "antd";
import React from "react";
import MonitoringCard from "src/components/Monitor/shared/MonitoringCard";

import { Site } from "src/networking/types";
import { getPrettySiteType } from "../shared/utils";

interface Props {
  site: Site;
  loading: boolean;
  error?: Record<string, unknown>;
}

const SiteSummaryCard: React.FC<Props> = ({
  site: { name, type, position, status, timezone },
  loading,
  error,
}) => {
  const tableColumns = [
    { title: "Site ID", dataIndex: "name", key: "name" },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: getPrettySiteType,
    },
    { title: "Latitude", dataIndex: "lat", key: "lat" },
    { title: "Longitude", dataIndex: "lng", key: "lng" },
    {
      title: "Time Zone",
      dataIndex: "timezone",
      key: "timezone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (siteStatus: string) => (
        <Typography.Text
          type={siteStatus === "Operating" ? "success" : "danger"}
        >
          {siteStatus}
        </Typography.Text>
      ),
    },
  ];

  const data = [
    {
      key: 0,
      name,
      type,
      status,
      lat: position.coordinates.lat,
      lng: position.coordinates.lng,
      timezone,
    },
  ];

  return (
    <MonitoringCard
      title="Site Summary"
      tableColumns={tableColumns}
      data={data}
      loading={loading}
      error={error}
    />
  );
};

export default SiteSummaryCard;
