import { keyBy } from "lodash";
import { Alert } from "src/networking/types";
import { AlertsActionTypes } from "src/redux/actionTypes";
import { getActionTypeForSuccess } from "src/redux/utils";

export interface AlertsState {
  alerts: Record<string, Alert>;
}

const initialState = {
  alerts: {},
};

const reducer = (
  state: AlertsState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action: any
): AlertsState => {
  switch (action.type) {
    case getActionTypeForSuccess(AlertsActionTypes.GET_ALL_ALERTS_REQUEST):
    case getActionTypeForSuccess(
      AlertsActionTypes.GET_ALERTS_FOR_PROJECT_REQUEST
    ):
    case getActionTypeForSuccess(AlertsActionTypes.GET_ALERTS_FOR_SITE_REQUEST):
    case getActionTypeForSuccess(AlertsActionTypes.SNOOZE_ALERTS_REQUEST):
    case getActionTypeForSuccess(AlertsActionTypes.RESOLVE_ALERTS_REQUEST):
    case getActionTypeForSuccess(AlertsActionTypes.UNRESOLVE_ALERTS_REQUEST):
    case getActionTypeForSuccess(AlertsActionTypes.SHARE_ALERTS_REQUEST):
      return {
        ...state,
        alerts: {
          ...state.alerts,
          ...keyBy(action.payload.data, "id"),
        },
      };
    case getActionTypeForSuccess(AlertsActionTypes.CREATE_ALERTS_REQUEST):
      return {
        ...state,
        alerts: {
          ...state.alerts,
          [action.payload.data.id]: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default reducer;
