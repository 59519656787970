import { WorkflowsActionTypes } from "src/redux/actionTypes";
import { RequestPayload } from "src/redux/utils";

export interface WorkflowsAction {
  type: WorkflowsActionTypes;
  payload: RequestPayload;
  metadata?: any;
}

export function getWorkflowsByProjectId(
  projectId: string,
  token: string
): WorkflowsAction {
  return {
    type: WorkflowsActionTypes.GET_WORKFLOWS_BY_PROJECT_ID_REQUEST,
    payload: {
      request: {
        url: `/v1/projects/${projectId}/workflows/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
    metadata: { projectId },
  };
}

export function createWorkflow(
  projectId: string,
  workflowName: string,
  workflowType: string,
  token: string
): WorkflowsAction {
  return {
    type: WorkflowsActionTypes.CREATE_WORKFLOW_REQUEST,
    payload: {
      request: {
        method: "post",
        url: `/v1/projects/${projectId}/workflows/`,
        data: {
          workflow_name: workflowName,
          workflow_type: workflowType,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}
