import { Layout } from "antd";
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import Alerts from "src/components/Alerts";
import MdfDashboard from "src/components/Mdf/MdfDashboard";
import { NavHeader } from "src/components/NavHeader";
import { Page } from "src/components/Page";
import MainViewSider from "src/components/Pages/MainViewSider";
import MonitorMainView from "src/components/Pages/MonitorMainView";
import SimulateMainView from "src/components/Pages/SimulateMainView";
import { Content } from "src/utils/Layout";
import styled from "styled-components";

interface Props {
  page: Page;
}

const Container = styled(Content)`
  padding: 10px 24px;
  width: 100% !important;
  height: calc(100vh - 60px);
  flex: initial;
  overflow: auto;
  background: #f7f9fc;
`;

const MainView: React.FC<Props> = ({ page }) => {
  const siderWidth = "300px";

  const history = useHistory();
  const contentRef = useRef<Element | undefined>(undefined);
  history.listen(() => contentRef.current?.scroll(0, 0));

  return (
    <Layout>
      <NavHeader brandWidth={siderWidth} />
      <Layout>
        <MainViewSider page={page} siderWidth={siderWidth} />
        <Container ref={contentRef}>
          {page === Page.Alerts ? (
            <Alerts />
          ) : page === Page.Monitor ? (
            <MonitorMainView />
          ) : page === Page.Mdf ? (
            <MdfDashboard />
          ) : (
            <SimulateMainView />
          )}
        </Container>
      </Layout>
    </Layout>
  );
};

export default MainView;
