import React from "react";
import traverseLogo from "src/components/Sider/icons/TraverseLogo2019Colored.png";
import styled from "styled-components";

const CreditsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BrandText = styled.span`
  margin-bottom: 8px;
  padding: 0;
  font-family: Blender Pro;
  font-size: 20px;
  color: #3c4257;
`;

const Credits: React.FC<Record<string, never>> = () => (
  <CreditsContainer>
    <BrandText>TRAVERSE WINDDESK</BrandText>
    <img src={traverseLogo} alt="traverse logo" style={{ width: "100px" }} />
    {process.env.REACT_APP_GIT_SHA && (
      <span style={{ marginTop: "10px" }}>
        Version {process.env.REACT_APP_GIT_SHA}
      </span>
    )}
  </CreditsContainer>
);

export default Credits;
