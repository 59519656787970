import { combineReducers } from "redux";

import admin, { AdminState } from "src/redux/reducers/admin";
import alerts, { AlertsState } from "src/redux/reducers/alert";
import configs, { ConfigsState } from "src/redux/reducers/config";
import documents, { DocumentsState } from "src/redux/reducers/document";
import mdf, { MdfState } from "src/redux/reducers/mdf";
import projects, { ProjectsState } from "src/redux/reducers/project";
import requests, { RequestsState } from "src/redux/reducers/requests";
import sensors, { SensorsState } from "src/redux/reducers/sensor";
import sites, { SitesState } from "src/redux/reducers/site";
import workflows, { WorkflowsState } from "src/redux/reducers/workflow";

export interface GlobalState {
  projects: ProjectsState;
  sites: SitesState;
  sensors: SensorsState;
  alerts: AlertsState;
  configs: ConfigsState;
  admin: AdminState;
  requests: RequestsState;
  workflows: WorkflowsState;
  documents: DocumentsState;
  mdf: MdfState;
}

export default combineReducers({
  projects,
  sites,
  sensors,
  alerts,
  configs,
  admin,
  workflows,
  requests,
  documents,
  mdf,
});
