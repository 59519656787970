import { Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WinddeskCol from "src/components/Common/WinddeskCol";
import ExcludedDataCard from "src/components/Monitor/QualityControl/ExcludedDataCard";
import MissingDataCard from "src/components/Monitor/QualityControl/MissingDataCard";
import OffsetsCard from "src/components/Monitor/QualityControl/OffsetsCard";
import SummaryStatisticsDataCard from "src/components/Monitor/QualityControl/SummaryStatisticsDataCard";
import DataCoverageCard from "src/components/Monitor/shared/DataCoverageCard";
import { OffsetData, Sensor, Site, WorkflowType } from "src/networking/types";
import {
  getDirectionOffsetsForSite,
  getFlagsForSite,
  getQcSensorsForSite,
  getTimeOffsetsForSite,
} from "src/redux/actions/sites";
import { GlobalState } from "src/redux/reducers";
import { useAuth0 } from "src/utils/react-auth0-spa";
import {
  getFilteredSensorTypeForDataCoverageHeatmap,
  getSortedSensorBySensorTypePriority,
} from "../shared/utils";

const QualityControl: React.FC<{ site: Site }> = ({ site }) => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const getTimeOffsetsForSiteState = useSelector(
    (state: GlobalState) => state.requests.GET_TIME_OFFSETS_FOR_SITE_REQUEST
  );
  const getDirectionOffsetsForSiteState = useSelector(
    (state: GlobalState) =>
      state.requests.GET_DIRECTION_OFFSETS_FOR_SITE_REQUEST
  );
  const getFlagsForSiteState = useSelector(
    (state: GlobalState) => state.requests.GET_FLAGS_FOR_SITE_REQUEST
  );
  const getQcSensorsForSiteState = useSelector(
    (state: GlobalState) => state.requests.GET_QC_SENSORS_FOR_SITE_REQUEST
  );

  useEffect(() => {
    getTokenSilently().then((token: string) => {
      dispatch(
        getFlagsForSite(
          site.project,
          site.name,
          token,
          WorkflowType.MonitorFull
        )
      );
      dispatch(
        getDirectionOffsetsForSite(
          site.project,
          site.name,
          token,
          WorkflowType.MonitorFull
        )
      );
      dispatch(
        getTimeOffsetsForSite(
          site.project,
          site.name,
          token,
          WorkflowType.MonitorFull
        )
      );
      dispatch(
        getQcSensorsForSite(
          site.project,
          site.name,
          token,
          WorkflowType.MonitorFull
        )
      );
    });
  }, [dispatch, site, getTokenSilently]);

  const timeOffsetsData = getTimeOffsetsForSiteState.data;
  const timeOffsets =
    timeOffsetsData &&
    (timeOffsetsData.within_sites_offsets ||
      timeOffsetsData.across_sites_offsets ||
      timeOffsetsData.long_term_dataset_offsets)
      ? (timeOffsetsData.within_sites_offsets ?? [])
          .concat(timeOffsetsData.across_sites_offsets ?? [])
          .concat(timeOffsetsData.long_term_dataset_offsets ?? [])
      : undefined;

  const directionOffsetsData = getDirectionOffsetsForSiteState.data;
  const directionOffsets =
    directionOffsetsData &&
    (directionOffsetsData.sensors || directionOffsetsData.sensor_pairs)
      ? (directionOffsetsData.sensors ?? []).concat(
          directionOffsetsData.sensor_pairs ?? []
        )
      : undefined;

  const filteredSiteSensors = getFilteredSensorTypeForDataCoverageHeatmap(
    getQcSensorsForSiteState.data
  );
  const sortedSiteSensors =
    getSortedSensorBySensorTypePriority(filteredSiteSensors);

  // @ts-ignore TS thinks this is unknown[]
  const dataCoverageIndices: string[] = [
    ...new Set(
      sortedSiteSensors?.flatMap((sensor: Sensor) =>
        sensor.data_coverage_timeseries.map(([dt, _v]) => dt)
      )
    ),
  ].sort();
  const aggregatedCoverage =
    sortedSiteSensors?.map(({ data_coverage_timeseries: c }: Sensor) =>
      Object.fromEntries(c)
    ) ?? [];

  return (
    <Row gutter={16}>
      <WinddeskCol span={14}>
        <SummaryStatisticsDataCard
          data={getFlagsForSiteState.data?.summary_statistics_data}
          loading={getFlagsForSiteState.isLoading}
          error={getFlagsForSiteState.error}
        />
        <DataCoverageCard
          loading={!getQcSensorsForSiteState.data}
          x={dataCoverageIndices}
          y={
            sortedSiteSensors?.map(
              ({ sensor_weak_name }: Sensor) => sensor_weak_name
            ) ?? []
          }
          z={aggregatedCoverage?.map((sensor: Record<string, number>) =>
            dataCoverageIndices.map((k) => sensor[k])
          )}
          title="Post QC Data Coverage Heatmap"
        />
      </WinddeskCol>
      <WinddeskCol span={10}>
        <MissingDataCard
          missingData={getFlagsForSiteState.data?.missing_data}
          loading={getFlagsForSiteState.isLoading}
          error={getFlagsForSiteState.error}
        />
        <ExcludedDataCard
          excludedData={getFlagsForSiteState.data?.excluded_data}
          loading={getFlagsForSiteState.isLoading}
          error={getFlagsForSiteState.error}
        />
        <OffsetsCard
          offsets={
            timeOffsets === undefined && directionOffsets === undefined
              ? undefined
              : (timeOffsets ?? [])
                  .map((o: OffsetData) => ({ ...o, type: "Time" }))
                  .concat(
                    (directionOffsets ?? []).map((o: OffsetData) => ({
                      ...o,
                      type: "Direction",
                    }))
                  )
          }
          loading={
            getTimeOffsetsForSiteState.isLoading ||
            getDirectionOffsetsForSiteState.isLoading
          }
          error={{
            ...getTimeOffsetsForSiteState.error,
            ...getDirectionOffsetsForSiteState.error,
          }}
        />
      </WinddeskCol>
    </Row>
  );
};

export default QualityControl;
