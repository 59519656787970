import React from "react";
import MonitoringCard from "src/components/Monitor/shared/MonitoringCard";

import { ExcludedData } from "src/networking/types";

interface Props {
  excludedData?: ExcludedData[];
  loading: boolean;
  error?: Record<string, unknown>;
}

const ExcludedDataCard: React.FC<Props> = ({
  excludedData,
  loading,
  error,
}) => {
  const tableColumns = [
    { title: "Sensor ID", dataIndex: "sensorId", key: "sensorId" },
    { title: "Start", dataIndex: "start", key: "start" },
    { title: "End", dataIndex: "end", key: "end" },
    { title: "Duration (days)", dataIndex: "duration", key: "duration" },
  ];

  const data: Record<string, any>[] | undefined = excludedData?.map(
    (datum, idx) => ({
      key: idx,
      sensorId: datum["Sensor ID"],
      start: datum.Start,
      end: datum.End,
      duration: datum["Duration (days)"],
    })
  );

  return (
    <MonitoringCard
      title="Excluded Data Information"
      tableColumns={tableColumns}
      data={data}
      loading={loading}
      error={error}
    />
  );
};

export default ExcludedDataCard;
