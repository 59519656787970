import { Breadcrumb, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useDispatchWorkflowsByProject } from "src/networking/hooks";
import { GlobalState } from "src/redux/reducers";
import styled from "styled-components";

const Container = styled.div`
  margin-left: 16px;
  display: flex;
`;

const TraverseBreadcrumb = styled(Breadcrumb)`
  display: flex;
  align-items: center;

  & a {
    font-family: Blender Pro;
    font-size: 24px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.6);
  }

  & > span:last-child a {
    color: #ffffff;
  }

  & a:hover {
    color: #ffffff;
  }

  & .ant-breadcrumb-separator {
    font-family: Blender Pro;
    font-size: 24px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.6);
  }
`;

type BreadcrumbItemData = {
  title: React.ReactNode;
  url: string;
  id: string;
};

export const WinddeskBreadcrumb: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const projectsState = useSelector(
    (state: GlobalState) => state.projects.projects
  );

  useDispatchWorkflowsByProject();
  const workflowsState = useSelector(
    (state: GlobalState) => state.workflows.workflows
  );

  const pathComponentToTitleMapping: Record<
    string,
    (id: string) => React.ReactNode
  > = {
    projects: (id: string) => projectsState[id]?.name,
    simulations: (id: string) => workflowsState[id]?.name,
    configurations: (id: string) => t(id),
  };

  const breadCrumbItems: BreadcrumbItemData[] = [];
  for (let idx = 1; idx < pathSnippets.length; idx += 2) {
    // starts from idx=1 as the url is expected to be /predict/projects/xx/simulation/yy etc
    const url = `/${pathSnippets.slice(0, idx + 2).join("/")}`;
    const currentPath = pathSnippets[idx];
    const id = pathSnippets[idx + 1];

    if (id !== undefined) {
      const title =
        currentPath in pathComponentToTitleMapping &&
        pathComponentToTitleMapping[currentPath](id);
      breadCrumbItems.push({ url, title, id });
    }
  }

  const isLoading = breadCrumbItems.some((item) => item.title === undefined);

  return (
    <Container>
      {isLoading ? (
        <Spin />
      ) : (
        <TraverseBreadcrumb>
          {breadCrumbItems.map((item) => (
            <Breadcrumb.Item key={item.id}>
              <Link to={item.url}>{item.title}</Link>
            </Breadcrumb.Item>
          ))}
        </TraverseBreadcrumb>
      )}
    </Container>
  );
};
