import { isEqual, keyBy, partition } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  filterPresentationConfigByWorkflowType,
  getWorkflowConfigFieldFromAccessor,
  hasPrefilledLinkageValue,
} from "src/components/SimulationConfigs/utils";

import {
  applyDiffs,
  getDiffs,
  getOverlappingDiffs,
} from "src/components/SimulationConfigs/utils/diffs";
import { getFieldValue } from "src/components/SimulationConfigs/utils/formValues";
import { useSimulationSelectionState } from "src/components/Simulations/hooks";
import {
  Accessor,
  CardData,
  Diff,
  PresentationConfig,
  WorkflowConfigField,
  isCardData,
  isDiff,
  isDiffResult,
} from "src/networking/types";
import { updateFormState } from "src/redux/actions/configs";

import { GlobalState } from "src/redux/reducers";

export const usePresentationConfigForSimulation = (
  simulationId?: string
): CardData[] | undefined => {
  const simulationState = useSelector(
    (state: GlobalState) => state.workflows.workflows
  );
  const presentationConfigState = useSelector(
    (state: GlobalState) => state.configs.presentationConfig
  );
  const [presentationConfigForWorkflow, setPresentationConfigForWorkflow] =
    useState<CardData[] | undefined>(undefined);
  useEffect(() => {
    if (
      simulationState !== undefined &&
      simulationId !== undefined &&
      simulationState[simulationId] !== undefined
    ) {
      setPresentationConfigForWorkflow(
        filterPresentationConfigByWorkflowType(
          presentationConfigState?.subworkflows ?? [],
          simulationState[simulationId].type
        )
      );
    }
  }, [simulationState, presentationConfigState, simulationId]);
  return presentationConfigForWorkflow;
};

export const useValuesLengthWithPrefilledLinkageUpdate = (
  accessor: Accessor,
  cardData: CardData
): [valuesLength: number, hasPrefilledLinkageValue: boolean] => {
  const values = useSelector(
    (state: GlobalState) =>
      getFieldValue(state.configs.formValues, accessor, "array"),
    isEqual
  );

  const [valuesLength, configNameOfPrefilledLinkage, prefilledLinkageValues] =
    useSelector((state: GlobalState) => {
      const fieldsWithPrefilledLinkage = cardData.children.filter(
        (f): f is WorkflowConfigField =>
          !isCardData(f) && hasPrefilledLinkageValue(f)
      );
      if (fieldsWithPrefilledLinkage.length > 1) {
        throw new Error(
          `The card ${
            cardData.name
          } has more than one fields with prefilled linkage: ${JSON.stringify(
            fieldsWithPrefilledLinkage.map((f) => f.config_name)
          )}`
        );
      }

      if (fieldsWithPrefilledLinkage.length === 1) {
        const fieldWithPrefilledLinkage = fieldsWithPrefilledLinkage[0];
        const prefilledLinkageValuesInner = getFieldValue(
          state.configs.formValues,
          fieldWithPrefilledLinkage.prefilled_parent_linkage,
          "array"
        ).map((v: any) =>
          getFieldValue(
            v,
            fieldWithPrefilledLinkage.prefilled_child_linkage,
            "stringOrNumber"
          )
        );
        return [
          prefilledLinkageValuesInner.length,
          fieldWithPrefilledLinkage.config_name,
          prefilledLinkageValuesInner,
        ];
      }

      const valuesInner = getFieldValue(
        state.configs.formValues,
        accessor,
        "array"
      );
      return [valuesInner ? Object.keys(valuesInner).length : 0, undefined, []];
    }, isEqual);

  const dispatch = useDispatch();
  useEffect(() => {
    if (configNameOfPrefilledLinkage !== undefined) {
      const previousValues = keyBy(values, (v) =>
        getFieldValue(
          v,
          configNameOfPrefilledLinkage.slice(accessor.length),
          "stringOrNumber"
        )
      );
      for (const [i, v] of prefilledLinkageValues.entries()) {
        if (Object.prototype.hasOwnProperty.call(previousValues, v)) {
          if (!isEqual(values[i], previousValues[v])) {
            dispatch(
              updateFormState({
                accessor: accessor.concat([i]),
                previousValue: values[i],
                currentValue: previousValues[v],
              })
            );
          }
        } else {
          dispatch(
            updateFormState({
              accessor: accessor
                .concat([i])
                .concat(configNameOfPrefilledLinkage.slice(accessor.length)),
              previousValue: values[i],
              currentValue: v,
            })
          );
        }
      }
    }
  }, [
    accessor,
    configNameOfPrefilledLinkage,
    dispatch,
    prefilledLinkageValues,
    values,
  ]);

  return [valuesLength, configNameOfPrefilledLinkage !== undefined];
};

interface UseDiffsResult {
  presentationConfig?: PresentationConfig;
  newestConfigCommitId?: number;
  diffsFromBackend?: Diff[][];
  combinedDiffsFromBackend: Diff[];
  diffsFromUser: Diff[];
  diffsFromBackendAndUser: Diff[];
  overlappingDiffs: [diffFromLeft: Diff, diffFromRight: Diff][];
  nonOverlappingDiffs: [
    remainingDiffsFromLeft: Diff[],
    remainingDiffsFromRight: Diff[]
  ];
}

export const useDiffs = (
  configCommitId: number | undefined
): UseDiffsResult => {
  const {
    diffs: diffsFromBackend,
    newest_config_commit_id: newestConfigCommitId,
  } = useSelector((state: GlobalState) => {
    const { configCommitDiffs } = state.configs;
    const workflowConfigSavingFailRequestDiffResult =
      state.requests.SAVE_CONFIG_BY_WORKFLOW_ID_REQUEST.error?.data.error;

    return isDiffResult(workflowConfigSavingFailRequestDiffResult)
      ? workflowConfigSavingFailRequestDiffResult
      : configCommitId && configCommitDiffs[configCommitId]
      ? configCommitDiffs[configCommitId]
      : { diffs: undefined, newest_config_commit_id: undefined };
  });

  const presentationConfig = useSelector(
    (state: GlobalState) => state.configs.presentationConfig,
    isEqual
  );

  let combinedDiffsFromBackend: Diff[];
  if (
    !Array.isArray(diffsFromBackend) ||
    diffsFromBackend.length === 0 ||
    !diffsFromBackend.every((ds) => Array.isArray(ds))
  ) {
    combinedDiffsFromBackend = [];
  } else {
    combinedDiffsFromBackend = diffsFromBackend.flat();
    if (combinedDiffsFromBackend.some((d) => !isDiff(d))) {
      combinedDiffsFromBackend = [];
    }
  }

  const currentSimulationId = useSimulationSelectionState()[0];
  const [diffsFromBackendAndUser, diffsFromUser] = useSelector(
    (state: GlobalState) => {
      if (!currentSimulationId) {
        return [[], []];
      }

      const previousConfig = state.configs.configs[currentSimulationId]?.config;
      if (!previousConfig) {
        return [[], []];
      }

      const latestConfig = applyDiffs(previousConfig, combinedDiffsFromBackend);

      return [
        getDiffs(latestConfig, previousConfig),
        getDiffs(state.configs.formValues, previousConfig),
      ];
    },
    isEqual
  );

  const { overlappingDiffs, nonOverlappingDiffs } = getOverlappingDiffs(
    diffsFromBackendAndUser,
    diffsFromUser
  );

  return {
    presentationConfig,
    newestConfigCommitId,
    diffsFromBackend,
    combinedDiffsFromBackend,
    diffsFromUser,
    diffsFromBackendAndUser,
    overlappingDiffs,
    nonOverlappingDiffs,
  };
};

interface UseDiffsWithCurrentSubworkflowNameResult {
  newestConfigCommitId?: number;
  diffsFromBackend?: Diff[][];
  combinedDiffsFromBackend: Diff[];
  diffsFromUser: Diff[];
  diffsFromBackendAndUser: Diff[];
  overlappingDiffs: [diffFromLeft: Diff, diffFromRight: Diff][];
  nonOverlappingDiffs: [
    remainingDiffsFromLeft: Diff[],
    remainingDiffsFromRight: Diff[]
  ];
  nonOverlappingSameSubworkflowDiffs: Diff[];
  differentSubworkflowDiffs: Diff[];
}

export const useDiffsWithCurrentSubworkflowName = (
  configCommitId: number | undefined,
  currentSubworkflowName: string
): UseDiffsWithCurrentSubworkflowNameResult => {
  const { t } = useTranslation();

  const {
    presentationConfig,
    newestConfigCommitId,
    diffsFromBackend,
    combinedDiffsFromBackend,
    diffsFromUser,
    diffsFromBackendAndUser,
    overlappingDiffs,
    nonOverlappingDiffs,
  } = useDiffs(configCommitId);

  const [nonOverlappingSameSubworkflowDiffs, differentSubworkflowDiffs] =
    partition(nonOverlappingDiffs[0], ({ accessor }) => {
      if (!presentationConfig) {
        return true;
      }
      const subworkflow = getWorkflowConfigFieldFromAccessor(
        presentationConfig,
        accessor
      )?.cardNesting?.[0];
      return subworkflow ? t(subworkflow) === currentSubworkflowName : false;
    });

  return {
    newestConfigCommitId,
    diffsFromBackend,
    combinedDiffsFromBackend,
    diffsFromUser,
    diffsFromBackendAndUser,
    overlappingDiffs,
    nonOverlappingDiffs,
    nonOverlappingSameSubworkflowDiffs,
    differentSubworkflowDiffs,
  };
};
