import { Typography } from "antd";
import React from "react";
import MonitoringCard from "src/components/Monitor/shared/MonitoringCard";
import { Site } from "src/networking/types";
import { getPrettySiteType } from "../shared/utils";

interface Props {
  site: Site;
  selectedSiteName?: string;
  onClick?: (record: any) => void;
}

const SiteConfigurationTable: React.FC<Props> = ({
  site,
  selectedSiteName,
  onClick,
}) => {
  const tableColumns = [
    { title: "Site Name", dataIndex: "name", key: "name" },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: getPrettySiteType,
    },
    { title: "Latitude", dataIndex: "lat", key: "lat" },
    { title: "Longitude", dataIndex: "lng", key: "lng" },
    {
      title: "Data Coverage (Wind speed)",
      dataIndex: "windSpeed",
      key: "windSpeed",
      render: (res: number | undefined) =>
        res === undefined ? "-" : `${(res * 100).toFixed(2)}%`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <Typography.Text type={status === "Operating" ? "success" : "danger"}>
          {status}
        </Typography.Text>
      ),
    },
  ];

  const data = [
    {
      key: site.name,
      name: site.name,
      type: site.type,
      status: site.status,
      lat: site.position.coordinates.lat,
      lng: site.position.coordinates.lng,
      windSpeed: site.ws_data_coverage_ratio,
    },
  ];

  return (
    <MonitoringCard
      title="Site Summary"
      tableColumns={tableColumns}
      selectedKey={selectedSiteName}
      data={data}
      loading={false}
      onClick={onClick}
    />
  );
};

export default SiteConfigurationTable;
