import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { MatchProps, matchUrls } from "src/components/Common/routes";
import { useProjectSelectionState } from "src/components/Projects/hooks";
import { Workflow } from "src/networking/types";
import { GlobalState } from "src/redux/reducers";

export const useSimulationSelectionState = (): [
  string | undefined,
  Dispatch<SetStateAction<string | undefined>>
] => {
  const selectedProject = useProjectSelectionState()[0];
  const [selectedSimulation, setSelectedSimulation] = useState<
    string | undefined
  >();

  const simulationMatch =
    useRouteMatch<MatchProps>(matchUrls.simulation)?.params || {};
  const { simulationId } = simulationMatch;

  const workflowsState = useSelector(
    (state: GlobalState) => state.workflows.workflows
  );

  useEffect(() => {
    const workflowOptions = selectedProject
      ? Object.entries(workflowsState).filter(
          ([_, value]) => value.project_id === selectedProject
        )
      : [];

    if (
      simulationId &&
      workflowOptions.find(([_, option]) => option.id === simulationId)
    )
      setSelectedSimulation(simulationId);
  }, [selectedProject, simulationId, workflowsState]);

  return [selectedSimulation, setSelectedSimulation];
};
export const useSimulationState = (): Workflow | undefined => {
  const simulationState = useSelector(
    (state: GlobalState) => state.workflows.workflows
  );
  const selectedSimulation = useSimulationSelectionState()[0];
  return selectedSimulation && selectedSimulation in simulationState
    ? simulationState[selectedSimulation]
    : undefined;
};
