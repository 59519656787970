export const SENSOR_1_DATA = [
  ["2016-02-01 08:10:00", 4.708],
  ["2016-02-01 08:20:00", 4.693],
  ["2016-02-01 08:30:00", 5.717],
  ["2016-02-01 08:40:00", 5.207],
  ["2016-02-01 08:50:00", 5.477],
  ["2016-02-01 09:00:00", 7.937],
  ["2016-02-01 09:10:00", 7.625],
  ["2016-02-01 09:20:00", 5.987],
  ["2016-02-01 09:30:00", 5.713],
  ["2016-02-01 09:40:00", 4.171],
  ["2016-02-01 09:50:00", 3.788],
  ["2016-02-01 10:00:00", 2.915],
  ["2016-02-01 10:10:00", 2.208],
  ["2016-02-01 10:20:00", 3.672],
  ["2016-02-01 10:30:00", 4.621],
  ["2016-02-01 10:40:00", 4.432],
  ["2016-02-01 10:50:00", 2.524],
  ["2016-02-01 11:00:00", 2.628],
  ["2016-02-01 11:10:00", 4.444],
  ["2016-02-01 11:20:00", 4.672],
  ["2016-02-01 11:30:00", 3.132],
  ["2016-02-01 11:40:00", 2.021],
  ["2016-02-01 11:50:00", 2.253],
  ["2016-02-01 12:00:00", 2.744],
  ["2016-02-01 12:10:00", 3.8],
  ["2016-02-01 12:20:00", 4.104],
  ["2016-02-01 12:30:00", 8.132],
  ["2016-02-01 12:40:00", 9.483],
  ["2016-02-01 12:50:00", 9.749],
  ["2016-02-01 13:00:00", 7.062],
  ["2016-02-01 13:10:00", 4.243],
  ["2016-02-01 13:20:00", 4.681],
  ["2016-02-01 13:30:00", 4.481],
  ["2016-02-01 13:40:00", 5.488],
  ["2016-02-01 13:50:00", 5.545],
  ["2016-02-01 14:00:00", 5.449],
  ["2016-02-01 14:10:00", 5.556],
  ["2016-02-01 14:20:00", 5.863],
  ["2016-02-01 14:30:00", 5.739],
  ["2016-02-01 14:40:00", 5.791],
  ["2016-02-01 14:50:00", 4.606],
  ["2016-02-01 15:00:00", 5.837],
  ["2016-02-01 15:10:00", 5.57],
  ["2016-02-01 15:20:00", 4.118],
  ["2016-02-01 15:30:00", 4.06],
  ["2016-02-01 15:40:00", 4.841],
  ["2016-02-01 15:50:00", 4.249],
  ["2016-02-01 16:00:00", 3.866],
  ["2016-02-01 16:10:00", 4.518],
  ["2016-02-01 16:20:00", 4.533],
  ["2016-02-01 16:30:00", 4.513],
  ["2016-02-01 16:40:00", 4.32],
  ["2016-02-01 16:50:00", 3.16],
  ["2016-02-01 17:00:00", 3.981],
  ["2016-02-01 17:10:00", 4.139],
  ["2016-02-01 17:20:00", 4.147],
  ["2016-02-01 17:30:00", 4.073],
  ["2016-02-01 17:40:00", 4.104],
  ["2016-02-01 17:50:00", 3.293],
  ["2016-02-01 18:00:00", 3.146],
  ["2016-02-01 18:10:00", 2.857],
  ["2016-02-01 18:20:00", 2.571],
  ["2016-02-01 18:30:00", 3.013],
  ["2016-02-01 18:40:00", 4.152],
  ["2016-02-01 18:50:00", 4.794],
  ["2016-02-01 19:00:00", 5.354],
  ["2016-02-01 19:10:00", 5.221],
  ["2016-02-01 19:20:00", 5.16],
  ["2016-02-01 19:30:00", 6.073],
  ["2016-02-01 19:40:00", 6.576],
  ["2016-02-01 19:50:00", 5.669],
  ["2016-02-01 20:00:00", 5.358],
  ["2016-02-01 20:10:00", 5.041],
  ["2016-02-01 20:20:00", 5.137],
  ["2016-02-01 20:30:00", 5.134],
  ["2016-02-01 20:40:00", 5.378],
  ["2016-02-01 20:50:00", 5.867],
  ["2016-02-01 21:00:00", 5.795],
  ["2016-02-01 21:10:00", 6.512],
  ["2016-02-01 21:20:00", 7.221],
  ["2016-02-01 21:30:00", 6.658],
  ["2016-02-01 21:40:00", 6.038],
  ["2016-02-01 21:50:00", 6.113],
  ["2016-02-01 22:00:00", 5.999],
  ["2016-02-01 22:10:00", 5.879],
  ["2016-02-01 22:20:00", 5.837],
  ["2016-02-01 22:30:00", 6.332],
  ["2016-02-01 22:40:00", 6.781],
  ["2016-02-01 22:50:00", 6.611],
  ["2016-02-01 23:00:00", 6.529],
  ["2016-02-01 23:10:00", 5.995],
  ["2016-02-01 23:20:00", 6.728],
  ["2016-02-01 23:30:00", 6.508],
  ["2016-02-01 23:40:00", 7.085],
  ["2016-02-01 23:50:00", 6.598],
  ["2016-02-02 00:00:00", 6.826],
  ["2016-02-02 00:10:00", 6.474],
  ["2016-02-02 00:20:00", 6.737],
  ["2016-02-02 00:30:00", 7.772],
  ["2016-02-02 00:40:00", 7.691],
  ["2016-02-02 00:50:00", 7.67],
  ["2016-02-02 01:00:00", 6.755],
  ["2016-02-02 01:10:00", 6.411],
  ["2016-02-02 01:20:00", 7.021],
  ["2016-02-02 01:30:00", 7.141],
  ["2016-02-02 01:40:00", 5.743],
  ["2016-02-02 01:50:00", 5.735],
  ["2016-02-02 02:00:00", 6.357],
  ["2016-02-02 02:10:00", 6.728],
  ["2016-02-02 02:20:00", 6.415],
  ["2016-02-02 02:30:00", 6.812],
  ["2016-02-02 02:40:00", 7.864],
  ["2016-02-02 02:50:00", 7.179],
  ["2016-02-02 03:00:00", 5.746],
  ["2016-02-02 03:10:00", 4.951],
  ["2016-02-02 03:20:00", 5.906],
  ["2016-02-02 03:30:00", 5.502],
  ["2016-02-02 03:40:00", 5.743],
  ["2016-02-02 03:50:00", 5.669],
  ["2016-02-02 04:00:00", 5.799],
  ["2016-02-02 04:10:00", 5.732],
  ["2016-02-02 04:20:00", 5.177],
  ["2016-02-02 04:30:00", 4.478],
  ["2016-02-02 04:40:00", 4.068],
  ["2016-02-02 04:50:00", 3.826],
  ["2016-02-02 05:00:00", 4.047],
  ["2016-02-02 05:10:00", 2.922],
  ["2016-02-02 05:20:00", 2.866],
  ["2016-02-02 05:30:00", 3.089],
  ["2016-02-02 05:40:00", 3.262],
  ["2016-02-02 05:50:00", 3.382],
  ["2016-02-02 06:00:00", 2.538],
  ["2016-02-02 06:10:00", 1.978],
  ["2016-02-02 06:20:00", 1.257],
  ["2016-02-02 06:30:00", 1.174],
  ["2016-02-02 06:40:00", 1.441],
  ["2016-02-02 06:50:00", 1.073],
  ["2016-02-02 07:00:00", 1.011],
  ["2016-02-02 07:10:00", 1.104],
  ["2016-02-02 07:20:00", 1.678],
  ["2016-02-02 07:30:00", 1.801],
  ["2016-02-02 07:40:00", 1.718],
  ["2016-02-02 07:50:00", 1.03],
  ["2016-02-02 08:00:00", 1.242],
  ["2016-02-02 08:10:00", 1.223],
  ["2016-02-02 08:20:00", 2.222],
  ["2016-02-02 08:30:00", 3.512],
  ["2016-02-02 08:40:00", 2.973],
  ["2016-02-02 08:50:00", 3.335],
  ["2016-02-02 09:00:00", 2.358],
  ["2016-02-02 09:10:00", 1.94],
  ["2016-02-02 09:20:00", 2.624],
  ["2016-02-02 09:30:00", 3.285],
  ["2016-02-02 09:40:00", 3.252],
  ["2016-02-02 09:50:00", 2.578],
  ["2016-02-02 10:00:00", 2.14],
  ["2016-02-02 10:10:00", 2.466],
  ["2016-02-02 10:20:00", 2.104],
  ["2016-02-02 10:30:00", 1.291],
  ["2016-02-02 10:40:00", 0.905],
  ["2016-02-02 10:50:00", 1.862],
  ["2016-02-02 11:00:00", 2.192],
  ["2016-02-02 11:10:00", 2.462],
  ["2016-02-02 11:20:00", 2.554],
  ["2016-02-02 11:30:00", 2.607],
  ["2016-02-02 11:40:00", 3.024],
  ["2016-02-02 11:50:00", 3.144],
  ["2016-02-02 12:00:00", 3.719],
  ["2016-02-02 12:10:00", 3.523],
  ["2016-02-02 12:20:00", 3.236],
  ["2016-02-02 12:30:00", 3.258],
  ["2016-02-02 12:40:00", 3.258],
  ["2016-02-02 12:50:00", 3.793],
  ["2016-02-02 13:00:00", 4.213],
  ["2016-02-02 13:10:00", 5.75],
  ["2016-02-02 13:20:00", 5.632],
  ["2016-02-02 13:30:00", 6.089],
  ["2016-02-02 13:40:00", 5.647],
  ["2016-02-02 13:50:00", 6.572],
  ["2016-02-02 14:00:00", 6.889],
  ["2016-02-02 14:10:00", 7.021],
  ["2016-02-02 14:20:00", 12.78],
  ["2016-02-02 14:30:00", 11.68],
  ["2016-02-02 14:40:00", 12.4],
  ["2016-02-02 14:50:00", 13.37],
  ["2016-02-02 15:00:00", 10.89],
  ["2016-02-02 15:10:00", 10.33],
  ["2016-02-02 15:20:00", 11.56],
  ["2016-02-02 15:30:00", 11.12],
  ["2016-02-02 15:40:00", 9.743],
  ["2016-02-02 15:50:00", 7.462],
  ["2016-02-02 16:00:00", 7.254],
  ["2016-02-02 16:10:00", 5.848],
  ["2016-02-02 16:20:00", 4.838],
  ["2016-02-02 16:30:00", 4.147],
  ["2016-02-02 16:40:00", 3.688],
  ["2016-02-02 16:50:00", 4.125],
  ["2016-02-02 17:00:00", 4.076],
  ["2016-02-02 17:10:00", 3.535],
  ["2016-02-02 17:20:00", 3.21],
  ["2016-02-02 17:30:00", 3.162],
  ["2016-02-02 17:40:00", 3.521],
  ["2016-02-02 17:50:00", 4.213],
  ["2016-02-02 18:00:00", 4.687],
  ["2016-02-02 18:10:00", 4.91],
  ["2016-02-02 18:20:00", 4.696],
  ["2016-02-02 18:30:00", 4.776],
  ["2016-02-02 18:40:00", 4.504],
  ["2016-02-02 18:50:00", 4.577],
  ["2016-02-02 19:00:00", 4.441],
  ["2016-02-02 19:10:00", 3.648],
  ["2016-02-02 19:20:00", 4.001],
  ["2016-02-02 19:30:00", 3.745],
  ["2016-02-02 19:40:00", 3.512],
  ["2016-02-02 19:50:00", 2.7],
  ["2016-02-02 20:00:00", 3.623],
  ["2016-02-02 20:10:00", 2.92],
  ["2016-02-02 20:20:00", 2.133],
  ["2016-02-02 20:30:00", 2.468],
  ["2016-02-02 20:40:00", 2.641],
  ["2016-02-02 20:50:00", 2.62],
  ["2016-02-02 21:00:00", 3.665],
  ["2016-02-02 21:10:00", 3.702],
  ["2016-02-02 21:20:00", 2.759],
  ["2016-02-02 21:30:00", 2.414],
  ["2016-02-02 21:40:00", 1.491],
  ["2016-02-02 21:50:00", 2.079],
  ["2016-02-02 22:00:00", 3.218],
  ["2016-02-02 22:10:00", 2.675],
  ["2016-02-02 22:20:00", 2.631],
  ["2016-02-02 22:30:00", 2.546],
  ["2016-02-02 22:40:00", 1.844],
  ["2016-02-02 22:50:00", 1.481],
  ["2016-02-02 23:00:00", 8.36],
  ["2016-02-02 23:10:00", 7.772],
  ["2016-02-02 23:20:00", 6.03],
  ["2016-02-02 23:30:00", 5.337],
  ["2016-02-02 23:40:00", 5.617],
  ["2016-02-02 23:50:00", 4.831],
  ["2016-02-03 00:00:00", 5.442],
  ["2016-02-03 00:10:00", 5.983],
  ["2016-02-03 00:20:00", 5.445],
  ["2016-02-03 00:30:00", 6.242],
  ["2016-02-03 00:40:00", 5.967],
  ["2016-02-03 00:50:00", 6.014],
  ["2016-02-03 01:00:00", 6.181],
  ["2016-02-03 01:10:00", 5.588],
  ["2016-02-03 01:20:00", 4.736],
  ["2016-02-03 01:30:00", 4.654],
  ["2016-02-03 01:40:00", 4.298],
  ["2016-02-03 01:50:00", 3.812],
  ["2016-02-03 02:00:00", 5.003],
  ["2016-02-03 02:10:00", 5.68],
  ["2016-02-03 02:20:00", 6.25],
  ["2016-02-03 02:30:00", 6.287],
  ["2016-02-03 02:40:00", 5.534],
  ["2016-02-03 02:50:00", 5.347],
  ["2016-02-03 03:00:00", 5.72],
  ["2016-02-03 03:10:00", 4.869],
  ["2016-02-03 03:20:00", 4.367],
  ["2016-02-03 03:30:00", 5.18],
  ["2016-02-03 03:40:00", 6.03],
  ["2016-02-03 03:50:00", 6.258],
  ["2016-02-03 04:00:00", 6.311],
  ["2016-02-03 04:10:00", 6.585],
  ["2016-02-03 04:20:00", 6.598],
  ["2016-02-03 04:30:00", 7.491],
  ["2016-02-03 04:40:00", 6.521],
  ["2016-02-03 04:50:00", 6.491],
  ["2016-02-03 05:00:00", 6.344],
  ["2016-02-03 05:10:00", 5.86],
  ["2016-02-03 05:20:00", 5.702],
  ["2016-02-03 05:30:00", 5.698],
  ["2016-02-03 05:40:00", 5.713],
  ["2016-02-03 05:50:00", 5.548],
  ["2016-02-03 06:00:00", 6.47],
  ["2016-02-03 06:10:00", 6.01],
  ["2016-02-03 06:20:00", 6.085],
  ["2016-02-03 06:30:00", 6.137],
  ["2016-02-03 06:40:00", 4.819],
  ["2016-02-03 06:50:00", 4.427],
  ["2016-02-03 07:00:00", 3.453],
  ["2016-02-03 07:10:00", 5.003],
  ["2016-02-03 07:20:00", 5.197],
  ["2016-02-03 07:30:00", 5.459],
  ["2016-02-03 07:40:00", 5.527],
  ["2016-02-03 07:50:00", 5.643],
  ["2016-02-03 08:00:00", 4.878],
  ["2016-02-03 08:10:00", 4.574],
  ["2016-02-03 08:20:00", 4.803],
  ["2016-02-03 08:30:00", 4.367],
  ["2016-02-03 08:40:00", 4.81],
  ["2016-02-03 08:50:00", 4.197],
  ["2016-02-03 09:00:00", 4.866],
  ["2016-02-03 09:10:00", 5.117],
  ["2016-02-03 09:20:00", 4.813],
  ["2016-02-03 09:30:00", 4.163],
  ["2016-02-03 09:40:00", 4.176],
  ["2016-02-03 09:50:00", 4.042],
  ["2016-02-03 10:00:00", 3.873],
  ["2016-02-03 10:10:00", 4.176],
  ["2016-02-03 10:20:00", 4.427],
  ["2016-02-03 10:30:00", 4.633],
  ["2016-02-03 10:40:00", 4.089],
  ["2016-02-03 10:50:00", 4.553],
  ["2016-02-03 11:00:00", 3.616],
  ["2016-02-03 11:10:00", 3.586],
  ["2016-02-03 11:20:00", 4.243],
  ["2016-02-03 11:30:00", 4.945],
  ["2016-02-03 11:40:00", 5.791],
  ["2016-02-03 11:50:00", 5.299],
  ["2016-02-03 12:00:00", 5.78],
  ["2016-02-03 12:10:00", 4.856],
  ["2016-02-03 12:20:00", 5.333],
  ["2016-02-03 12:30:00", 5.358],
  ["2016-02-03 12:40:00", 4.373],
  ["2016-02-03 12:50:00", 4.205],
  ["2016-02-03 13:00:00", 4.045],
  ["2016-02-03 13:10:00", 4.042],
  ["2016-02-03 13:20:00", 4.068],
  ["2016-02-03 13:30:00", 3.371],
  ["2016-02-03 13:40:00", 3.575],
  ["2016-02-03 13:50:00", 3.658],
  ["2016-02-03 14:00:00", 2.994],
  ["2016-02-03 14:10:00", 3.302],
  ["2016-02-03 14:20:00", 3.566],
  ["2016-02-03 14:30:00", 3.177],
  ["2016-02-03 14:40:00", 3.21],
  ["2016-02-03 14:50:00", 3.63],
  ["2016-02-03 15:00:00", 3.933],
  ["2016-02-03 15:10:00", 5.231],
  ["2016-02-03 15:20:00", 4.381],
  ["2016-02-03 15:30:00", 3.214],
  ["2016-02-03 15:40:00", 3.009],
  ["2016-02-03 15:50:00", 3.14],
  ["2016-02-03 16:00:00", 3.269],
  ["2016-02-03 16:10:00", 4.147],
  ["2016-02-03 16:20:00", 3.446],
  ["2016-02-03 16:30:00", 2.895],
  ["2016-02-03 16:40:00", 2.719],
  ["2016-02-03 16:50:00", 2.947],
  ["2016-02-03 17:00:00", 3.749],
  ["2016-02-03 17:10:00", 2.93],
  ["2016-02-03 17:20:00", 3.44],
  ["2016-02-03 17:30:00", 3.74],
  ["2016-02-03 17:40:00", 3.234],
  ["2016-02-03 17:50:00", 3.197],
  ["2016-02-03 18:00:00", 3.479],
  ["2016-02-03 18:10:00", 2.785],
  ["2016-02-03 18:20:00", 2.127],
  ["2016-02-03 18:30:00", 3.883],
  ["2016-02-03 18:40:00", 4.136],
  ["2016-02-03 18:50:00", 3.983],
  ["2016-02-03 19:00:00", 3.003],
  ["2016-02-03 19:10:00", 2.191],
  ["2016-02-03 19:20:00", 2.361],
  ["2016-02-03 19:30:00", 2.019],
  ["2016-02-03 19:40:00", 2.171],
  ["2016-02-03 19:50:00", 2.1],
  ["2016-02-03 20:00:00", 1.68],
  ["2016-02-03 20:10:00", 2.233],
  ["2016-02-03 20:20:00", 2.348],
  ["2016-02-03 20:30:00", 2.145],
  ["2016-02-03 20:40:00", 1.739],
  ["2016-02-03 20:50:00", 1.546],
  ["2016-02-03 21:00:00", 1.251],
  ["2016-02-03 21:10:00", 0.601],
  ["2016-02-03 21:20:00", 0.433],
  ["2016-02-03 21:30:00", 1.305],
  ["2016-02-03 21:40:00", 1.654],
  ["2016-02-03 21:50:00", 1.14],
  ["2016-02-03 22:00:00", 0.858],
  ["2016-02-03 22:10:00", 1.318],
  ["2016-02-03 22:20:00", 1.233],
  ["2016-02-03 22:30:00", 1.472],
  ["2016-02-03 22:40:00", 1.931],
  ["2016-02-03 22:50:00", 1.946],
  ["2016-02-03 23:00:00", 2.396],
  ["2016-02-03 23:10:00", 2.827],
  ["2016-02-03 23:20:00", 2.478],
  ["2016-02-03 23:30:00", 2.657],
  ["2016-02-03 23:40:00", 2.54],
  ["2016-02-03 23:50:00", 2.611],
  ["2016-02-04 00:00:00", 2.45],
  ["2016-02-04 00:10:00", 2.376],
  ["2016-02-04 00:20:00", 2.281],
  ["2016-02-04 00:30:00", 2.058],
  ["2016-02-04 00:40:00", 1.921],
  ["2016-02-04 00:50:00", 1.968],
  ["2016-02-04 01:00:00", 1.898],
  ["2016-02-04 01:10:00", 2.735],
  ["2016-02-04 01:20:00", 3.026],
  ["2016-02-04 01:30:00", 3.093],
  ["2016-02-04 01:40:00", 3.02],
  ["2016-02-04 01:50:00", 2.811],
  ["2016-02-04 02:00:00", 2.719],
  ["2016-02-04 02:10:00", 2.456],
  ["2016-02-04 02:20:00", 2.309],
  ["2016-02-04 02:30:00", 2.296],
  ["2016-02-04 02:40:00", 2.306],
  ["2016-02-04 02:50:00", 1.635],
  ["2016-02-04 03:00:00", 1.381],
  ["2016-02-04 03:10:00", 1.715],
  ["2016-02-04 03:20:00", 1.678],
  ["2016-02-04 03:30:00", 1.972],
  ["2016-02-04 03:40:00", 2.54],
  ["2016-02-04 03:50:00", 3.072],
  ["2016-02-04 04:00:00", 2.754],
  ["2016-02-04 04:10:00", 2.698],
  ["2016-02-04 04:20:00", 2.379],
  ["2016-02-04 04:30:00", 2.377],
  ["2016-02-04 04:40:00", 2.794],
  ["2016-02-04 04:50:00", 3.416],
  ["2016-02-04 05:00:00", 2.417],
  ["2016-02-04 05:10:00", 0.724],
  ["2016-02-04 05:20:00", 0.895],
  ["2016-02-04 05:30:00", 1.654],
  ["2016-02-04 05:40:00", 2.175],
  ["2016-02-04 05:50:00", 2.567],
  ["2016-02-04 06:00:00", 2.367],
  ["2016-02-04 06:10:00", 2.48],
  ["2016-02-04 06:20:00", 3.015],
  ["2016-02-04 06:30:00", 2.947],
  ["2016-02-04 06:40:00", 2.353],
  ["2016-02-04 06:50:00", 2.78],
  ["2016-02-04 07:00:00", 2.462],
  ["2016-02-04 07:10:00", 2.597],
  ["2016-02-04 07:20:00", 2.311],
  ["2016-02-04 07:30:00", 1.968],
  ["2016-02-04 07:40:00", 1.72],
  ["2016-02-04 07:50:00", 1.789],
  ["2016-02-04 08:00:00", 1.143],
  ["2016-02-04 08:10:00", 1.297],
  ["2016-02-04 08:20:00", 1.473],
  ["2016-02-04 08:30:00", 1.484],
  ["2016-02-04 08:40:00", 1.86],
  ["2016-02-04 08:50:00", 1.616],
  ["2016-02-04 09:00:00", 1.271],
  ["2016-02-04 09:10:00", 1.735],
  ["2016-02-04 09:20:00", 1.777],
  ["2016-02-04 09:30:00", 3.63],
  ["2016-02-04 09:40:00", 3.201],
  ["2016-02-04 09:50:00", 3.16],
  ["2016-02-04 10:00:00", 3.21],
  ["2016-02-04 10:10:00", 2.759],
  ["2016-02-04 10:20:00", 3.759],
  ["2016-02-04 10:30:00", 3.31],
  ["2016-02-04 10:40:00", 3.868],
  ["2016-02-04 10:50:00", 3.142],
  ["2016-02-04 11:00:00", 2.637],
  ["2016-02-04 11:10:00", 2.827],
  ["2016-02-04 11:20:00", 3.085],
  ["2016-02-04 11:30:00", 2.615],
  ["2016-02-04 11:40:00", 2.735],
  ["2016-02-04 11:50:00", 3.573],
  ["2016-02-04 12:00:00", 2.829],
  ["2016-02-04 12:10:00", 2.714],
  ["2016-02-04 12:20:00", 2.758],
  ["2016-02-04 12:30:00", 2.882],
  ["2016-02-04 12:40:00", 2.69],
  ["2016-02-04 12:50:00", 2.737],
  ["2016-02-04 13:00:00", 3.795],
  ["2016-02-04 13:10:00", 2.712],
  ["2016-02-04 13:20:00", 2.286],
  ["2016-02-04 13:30:00", 2.399],
  ["2016-02-04 13:40:00", 3.795],
  ["2016-02-04 13:50:00", 4.024],
  ["2016-02-04 14:00:00", 3.639],
  ["2016-02-04 14:10:00", 3.47],
  ["2016-02-04 14:20:00", 3.851],
  ["2016-02-04 14:30:00", 3.602],
  ["2016-02-04 14:40:00", 3.258],
  ["2016-02-04 14:50:00", 2.916],
  ["2016-02-04 15:00:00", 3.598],
  ["2016-02-04 15:10:00", 3.612],
  ["2016-02-04 15:20:00", 3.747],
  ["2016-02-04 15:30:00", 4.516],
  ["2016-02-04 15:40:00", 4.131],
  ["2016-02-04 15:50:00", 4.779],
  ["2016-02-04 16:00:00", 3.89],
  ["2016-02-04 16:10:00", 3.358],
  ["2016-02-04 16:20:00", 3.363],
  ["2016-02-04 16:30:00", 4.165],
  ["2016-02-04 16:40:00", 3.236],
  ["2016-02-04 16:50:00", 2.845],
  ["2016-02-04 17:00:00", 2.87],
  ["2016-02-04 17:10:00", 3.401],
  ["2016-02-04 17:20:00", 3.759],
  ["2016-02-04 17:30:00", 4.224],
  ["2016-02-04 17:40:00", 3.193],
  ["2016-02-04 17:50:00", 2.789],
  ["2016-02-04 18:00:00", 2.579],
  ["2016-02-04 18:10:00", 2.744],
  ["2016-02-04 18:20:00", 3.395],
  ["2016-02-04 18:30:00", 3.169],
  ["2016-02-04 18:40:00", 3.238],
  ["2016-02-04 18:50:00", 3.106],
  ["2016-02-04 19:00:00", 2.472],
  ["2016-02-04 19:10:00", 2.735],
  ["2016-02-04 19:20:00", 2.642],
  ["2016-02-04 19:30:00", 2.632],
  ["2016-02-04 19:40:00", 3.481],
  ["2016-02-04 19:50:00", 4.524],
  ["2016-02-04 20:00:00", 7.66],
  ["2016-02-04 20:10:00", 6.038],
  ["2016-02-04 20:20:00", 7.782],
  ["2016-02-04 20:30:00", 9.672],
  ["2016-02-04 20:40:00", 6.373],
  ["2016-02-04 20:50:00", 3.602],
  ["2016-02-04 21:00:00", 4.149],
  ["2016-02-04 21:10:00", 3.728],
  ["2016-02-04 21:20:00", 3.591],
  ["2016-02-04 21:30:00", 3.212],
  ["2016-02-04 21:40:00", 3.704],
  ["2016-02-04 21:50:00", 3.707],
  ["2016-02-04 22:00:00", 4.125],
  ["2016-02-04 22:10:00", 4.081],
  ["2016-02-04 22:20:00", 4.24],
  ["2016-02-04 22:30:00", 4.032],
  ["2016-02-04 22:40:00", 3.752],
  ["2016-02-04 22:50:00", 3.559],
  ["2016-02-04 23:00:00", 4.168],
  ["2016-02-04 23:10:00", 4.598],
  ["2016-02-04 23:20:00", 4.281],
  ["2016-02-04 23:30:00", 4.76],
  ["2016-02-04 23:40:00", 5.204],
  ["2016-02-04 23:50:00", 4.958],
  ["2016-02-05 00:00:00", 4.645],
  ["2016-02-05 00:10:00", 4.427],
  ["2016-02-05 00:20:00", 4.192],
  ["2016-02-05 00:30:00", 3.618],
  ["2016-02-05 00:40:00", 4.014],
  ["2016-02-05 00:50:00", 4.136],
  ["2016-02-05 01:00:00", 4.287],
  ["2016-02-05 01:10:00", 3.875],
  ["2016-02-05 01:20:00", 4.415],
  ["2016-02-05 01:30:00", 4.516],
  ["2016-02-05 01:40:00", 4.203],
  ["2016-02-05 01:50:00", 4.536],
  ["2016-02-05 02:00:00", 4.574],
  ["2016-02-05 02:10:00", 4.788],
  ["2016-02-05 02:20:00", 4.872],
  ["2016-02-05 02:30:00", 5.104],
  ["2016-02-05 02:40:00", 4.888],
  ["2016-02-05 02:50:00", 5.051],
  ["2016-02-05 03:00:00", 4.754],
  ["2016-02-05 03:10:00", 5.114],
  ["2016-02-05 03:20:00", 4.986],
  ["2016-02-05 03:30:00", 4.07],
  ["2016-02-05 03:40:00", 4.365],
  ["2016-02-05 03:50:00", 4.176],
  ["2016-02-05 04:00:00", 3.983],
  ["2016-02-05 04:10:00", 4.073],
  ["2016-02-05 04:20:00", 3.943],
  ["2016-02-05 04:30:00", 3.011],
  ["2016-02-05 04:40:00", 3.457],
  ["2016-02-05 04:50:00", 2.133],
  ["2016-02-05 05:00:00", 2.754],
  ["2016-02-05 05:10:00", 3.875],
  ["2016-02-05 05:20:00", 4.907],
  ["2016-02-05 05:30:00", 4.568],
  ["2016-02-05 05:40:00", 4.681],
  ["2016-02-05 05:50:00", 4.714],
  ["2016-02-05 06:00:00", 3.829],
  ["2016-02-05 06:10:00", 4.268],
  ["2016-02-05 06:20:00", 5.643],
  ["2016-02-05 06:30:00", 6.957],
  ["2016-02-05 06:40:00", 5.463],
  ["2016-02-05 06:50:00", 4.926],
  ["2016-02-05 07:00:00", 4.492],
  ["2016-02-05 07:10:00", 4.171],
  ["2016-02-05 07:20:00", 3.973],
  ["2016-02-05 07:30:00", 3.457],
  ["2016-02-05 07:40:00", 3.679],
  ["2016-02-05 07:50:00", 4.897],
  ["2016-02-05 08:00:00", 4.996],
  ["2016-02-05 08:10:00", 4.524],
  ["2016-02-05 08:20:00", 4.568],
  ["2016-02-05 08:30:00", 4.935],
  ["2016-02-05 08:40:00", 5.068],
  ["2016-02-05 08:50:00", 6.061],
  ["2016-02-05 09:00:00", 5.147],
  ["2016-02-05 09:10:00", 5.841],
  ["2016-02-05 09:20:00", 5.081],
  ["2016-02-05 09:30:00", 5.137],
  ["2016-02-05 09:40:00", 4.589],
  ["2016-02-05 09:50:00", 4.393],
  ["2016-02-05 10:00:00", 4.331],
  ["2016-02-05 10:10:00", 3.928],
  ["2016-02-05 10:20:00", 4.648],
  ["2016-02-05 10:30:00", 5.048],
  ["2016-02-05 10:40:00", 5.15],
  ["2016-02-05 10:50:00", 4.872],
  ["2016-02-05 11:00:00", 5.691],
  ["2016-02-05 11:10:00", 4.945],
  ["2016-02-05 11:20:00", 5.17],
  ["2016-02-05 11:30:00", 5.097],
  ["2016-02-05 11:40:00", 4.203],
  ["2016-02-05 11:50:00", 4.841],
  ["2016-02-05 12:00:00", 4.104],
  ["2016-02-05 12:10:00", 4.498],
  ["2016-02-05 12:20:00", 3.923],
  ["2016-02-05 12:30:00", 4.133],
  ["2016-02-05 12:40:00", 3.918],
  ["2016-02-05 12:50:00", 3.935],
  ["2016-02-05 13:00:00", 4.586],
  ["2016-02-05 13:10:00", 4.91],
  ["2016-02-05 13:20:00", 4.384],
];

export const SENSOR_2_DATA = [
  ["2016-02-01 08:10:00", 5.08464],
  ["2016-02-01 08:20:00", 5.06844],
  ["2016-02-01 08:30:00", 6.11719],
  ["2016-02-01 08:40:00", 5.51942],
  ["2016-02-01 08:50:00", 5.75085],
  ["2016-02-01 09:00:00", 8.33385],
  ["2016-02-01 09:10:00", 8.0825],
  ["2016-02-01 09:20:00", 6.46596],
  ["2016-02-01 09:30:00", 6.11291],
  ["2016-02-01 09:40:00", 4.46297],
  ["2016-02-01 09:50:00", 4.01528],
  ["2016-02-01 10:00:00", 3.0899],
  ["2016-02-01 10:10:00", 2.34048],
  ["2016-02-01 10:20:00", 3.81888],
  ["2016-02-01 10:30:00", 4.99068],
  ["2016-02-01 10:40:00", 4.6536],
  ["2016-02-01 10:50:00", 2.70068],
  ["2016-02-01 11:00:00", 2.78568],
  ["2016-02-01 11:10:00", 4.62176],
  ["2016-02-01 11:20:00", 4.95232],
  ["2016-02-01 11:30:00", 3.25728],
  ["2016-02-01 11:40:00", 2.14226],
  ["2016-02-01 11:50:00", 2.41071],
  ["2016-02-01 12:00:00", 2.85376],
  ["2016-02-01 12:10:00", 4.104],
  ["2016-02-01 12:20:00", 4.35024],
  ["2016-02-01 12:30:00", 8.70124],
  ["2016-02-01 12:40:00", 10.05198],
  ["2016-02-01 12:50:00", 10.13896],
  ["2016-02-01 13:00:00", 7.55634],
  ["2016-02-01 13:10:00", 4.58244],
  ["2016-02-01 13:20:00", 4.86824],
  ["2016-02-01 13:30:00", 4.66024],
  ["2016-02-01 13:40:00", 5.92704],
  ["2016-02-01 13:50:00", 5.82225],
  ["2016-02-01 14:00:00", 5.88492],
  ["2016-02-01 14:10:00", 6.00048],
  ["2016-02-01 14:20:00", 6.27341],
  ["2016-02-01 14:30:00", 6.19812],
  ["2016-02-01 14:40:00", 6.08055],
  ["2016-02-01 14:50:00", 4.79024],
  ["2016-02-01 15:00:00", 6.12885],
  ["2016-02-01 15:10:00", 5.9042],
  ["2016-02-01 15:20:00", 4.3239],
  ["2016-02-01 15:30:00", 4.3848],
  ["2016-02-01 15:40:00", 5.03464],
  ["2016-02-01 15:50:00", 4.50394],
  ["2016-02-01 16:00:00", 4.17528],
  ["2016-02-01 16:10:00", 4.78908],
  ["2016-02-01 16:20:00", 4.89564],
  ["2016-02-01 16:30:00", 4.69352],
  ["2016-02-01 16:40:00", 4.6224],
  ["2016-02-01 16:50:00", 3.318],
  ["2016-02-01 17:00:00", 4.29948],
  ["2016-02-01 17:10:00", 4.42873],
  ["2016-02-01 17:20:00", 4.43729],
  ["2016-02-01 17:30:00", 4.39884],
  ["2016-02-01 17:40:00", 4.39128],
  ["2016-02-01 17:50:00", 3.49058],
  ["2016-02-01 18:00:00", 3.33476],
  ["2016-02-01 18:10:00", 2.97128],
  ["2016-02-01 18:20:00", 2.75097],
  ["2016-02-01 18:30:00", 3.22391],
  ["2016-02-01 18:40:00", 4.44264],
  ["2016-02-01 18:50:00", 4.98576],
  ["2016-02-01 19:00:00", 5.6217],
  ["2016-02-01 19:10:00", 5.48205],
  ["2016-02-01 19:20:00", 5.4696],
  ["2016-02-01 19:30:00", 6.43738],
  ["2016-02-01 19:40:00", 6.83904],
  ["2016-02-01 19:50:00", 6.00914],
  ["2016-02-01 20:00:00", 5.78664],
  ["2016-02-01 20:10:00", 5.44428],
  ["2016-02-01 20:20:00", 5.54796],
  ["2016-02-01 20:30:00", 5.49338],
  ["2016-02-01 20:40:00", 5.70068],
  ["2016-02-01 20:50:00", 6.33636],
  ["2016-02-01 21:00:00", 6.20065],
  ["2016-02-01 21:10:00", 6.77248],
  ["2016-02-01 21:20:00", 7.79868],
  ["2016-02-01 21:30:00", 7.05748],
  ["2016-02-01 21:40:00", 6.27952],
  ["2016-02-01 21:50:00", 6.35752],
  ["2016-02-01 22:00:00", 6.41893],
  ["2016-02-01 22:10:00", 6.17295],
  ["2016-02-01 22:20:00", 6.18722],
  ["2016-02-01 22:30:00", 6.71192],
  ["2016-02-01 22:40:00", 7.18786],
  ["2016-02-01 22:50:00", 7.00766],
  ["2016-02-01 23:00:00", 6.98603],
  ["2016-02-01 23:10:00", 6.41465],
  ["2016-02-01 23:20:00", 7.0644],
  ["2016-02-01 23:30:00", 7.02864],
  ["2016-02-01 23:40:00", 7.6518],
  ["2016-02-01 23:50:00", 7.05986],
  ["2016-02-02 00:00:00", 7.1673],
  ["2016-02-02 00:10:00", 6.92718],
  ["2016-02-02 00:20:00", 7.07385],
  ["2016-02-02 00:30:00", 8.39376],
  ["2016-02-02 00:40:00", 8.22937],
  ["2016-02-02 00:50:00", 8.0535],
  ["2016-02-02 01:00:00", 7.22785],
  ["2016-02-02 01:10:00", 6.73155],
  ["2016-02-02 01:20:00", 7.30184],
  ["2016-02-02 01:30:00", 7.56946],
  ["2016-02-02 01:40:00", 6.14501],
  ["2016-02-02 01:50:00", 6.02175],
  ["2016-02-02 02:00:00", 6.86556],
  ["2016-02-02 02:10:00", 7.0644],
  ["2016-02-02 02:20:00", 6.7999],
  ["2016-02-02 02:30:00", 7.35696],
  ["2016-02-02 02:40:00", 8.17856],
  ["2016-02-02 02:50:00", 7.60974],
  ["2016-02-02 03:00:00", 6.14822],
  ["2016-02-02 03:10:00", 5.19855],
  ["2016-02-02 03:20:00", 6.37848],
  ["2016-02-02 03:30:00", 5.72208],
  ["2016-02-02 03:40:00", 6.20244],
  ["2016-02-02 03:50:00", 5.95245],
  ["2016-02-02 04:00:00", 6.20493],
  ["2016-02-02 04:10:00", 5.96128],
  ["2016-02-02 04:20:00", 5.53939],
  ["2016-02-02 04:30:00", 4.83624],
  ["2016-02-02 04:40:00", 4.2714],
  ["2016-02-02 04:50:00", 4.0173],
  ["2016-02-02 05:00:00", 4.24935],
  ["2016-02-02 05:10:00", 3.0681],
  ["2016-02-02 05:20:00", 3.0093],
  ["2016-02-02 05:30:00", 3.24345],
  ["2016-02-02 05:40:00", 3.49034],
  ["2016-02-02 05:50:00", 3.65256],
  ["2016-02-02 06:00:00", 2.63952],
  ["2016-02-02 06:10:00", 2.09668],
  ["2016-02-02 06:20:00", 1.35756],
  ["2016-02-02 06:30:00", 1.25618],
  ["2016-02-02 06:40:00", 1.52746],
  ["2016-02-02 06:50:00", 1.12665],
  ["2016-02-02 07:00:00", 1.07166],
  ["2016-02-02 07:10:00", 1.19232],
  ["2016-02-02 07:20:00", 1.81224],
  ["2016-02-02 07:30:00", 1.87304],
  ["2016-02-02 07:40:00", 1.8039],
  ["2016-02-02 07:50:00", 1.1021],
  ["2016-02-02 08:00:00", 1.34136],
  ["2016-02-02 08:10:00", 1.28415],
  ["2016-02-02 08:20:00", 2.35532],
  ["2016-02-02 08:30:00", 3.79296],
  ["2016-02-02 08:40:00", 3.12165],
  ["2016-02-02 08:50:00", 3.56845],
  ["2016-02-02 09:00:00", 2.52306],
  ["2016-02-02 09:10:00", 2.0176],
  ["2016-02-02 09:20:00", 2.78144],
  ["2016-02-02 09:30:00", 3.51495],
  ["2016-02-02 09:40:00", 3.38208],
  ["2016-02-02 09:50:00", 2.75846],
  ["2016-02-02 10:00:00", 2.2898],
  ["2016-02-02 10:10:00", 2.63862],
  ["2016-02-02 10:20:00", 2.23024],
  ["2016-02-02 10:30:00", 1.34264],
  ["2016-02-02 10:40:00", 0.9593],
  ["2016-02-02 10:50:00", 1.93648],
  ["2016-02-02 11:00:00", 2.34544],
  ["2016-02-02 11:10:00", 2.63434],
  ["2016-02-02 11:20:00", 2.65616],
  ["2016-02-02 11:30:00", 2.81556],
  ["2016-02-02 11:40:00", 3.26592],
  ["2016-02-02 11:50:00", 3.36408],
  ["2016-02-02 12:00:00", 3.86776],
  ["2016-02-02 12:10:00", 3.73438],
  ["2016-02-02 12:20:00", 3.46252],
  ["2016-02-02 12:30:00", 3.38832],
  ["2016-02-02 12:40:00", 3.48606],
  ["2016-02-02 12:50:00", 3.98265],
  ["2016-02-02 13:00:00", 4.50791],
  ["2016-02-02 13:10:00", 6.095],
  ["2016-02-02 13:20:00", 6.02624],
  ["2016-02-02 13:30:00", 6.57612],
  ["2016-02-02 13:40:00", 5.98582],
  ["2016-02-02 13:50:00", 7.03204],
  ["2016-02-02 14:00:00", 7.37123],
  ["2016-02-02 14:10:00", 7.58268],
  ["2016-02-02 14:20:00", 13.29744],
  ["2016-02-02 14:30:00", 12.50723],
  ["2016-02-02 14:40:00", 13.40172],
  ["2016-02-02 14:50:00", 14.44716],
  ["2016-02-02 15:00:00", 11.65872],
  ["2016-02-02 15:10:00", 11.16072],
  ["2016-02-02 15:20:00", 12.1443],
  ["2016-02-02 15:30:00", 11.56688],
  ["2016-02-02 15:40:00", 10.42501],
  ["2016-02-02 15:50:00", 7.76048],
  ["2016-02-02 16:00:00", 7.54416],
  ["2016-02-02 16:10:00", 6.31584],
  ["2016-02-02 16:20:00", 5.22504],
  ["2016-02-02 16:30:00", 4.47876],
  ["2016-02-02 16:40:00", 3.8724],
  ["2016-02-02 16:50:00", 4.41375],
  ["2016-02-02 17:00:00", 4.2798],
  ["2016-02-02 17:10:00", 3.8178],
  ["2016-02-02 17:20:00", 3.4668],
  ["2016-02-02 17:30:00", 3.38334],
  ["2016-02-02 17:40:00", 3.69705],
  ["2016-02-02 17:50:00", 4.46578],
  ["2016-02-02 18:00:00", 5.01509],
  ["2016-02-02 18:10:00", 5.1555],
  ["2016-02-02 18:20:00", 4.88384],
  ["2016-02-02 18:30:00", 4.96704],
  ["2016-02-02 18:40:00", 4.86432],
  ["2016-02-02 18:50:00", 4.85162],
  ["2016-02-02 19:00:00", 4.75187],
  ["2016-02-02 19:10:00", 3.93984],
  ["2016-02-02 19:20:00", 4.20105],
  ["2016-02-02 19:30:00", 3.9697],
  ["2016-02-02 19:40:00", 3.6876],
  ["2016-02-02 19:50:00", 2.862],
  ["2016-02-02 20:00:00", 3.87661],
  ["2016-02-02 20:10:00", 3.0952],
  ["2016-02-02 20:20:00", 2.28231],
  ["2016-02-02 20:30:00", 2.64076],
  ["2016-02-02 20:40:00", 2.77305],
  ["2016-02-02 20:50:00", 2.7772],
  ["2016-02-02 21:00:00", 3.8116],
  ["2016-02-02 21:10:00", 3.85008],
  ["2016-02-02 21:20:00", 2.92454],
  ["2016-02-02 21:30:00", 2.5347],
  ["2016-02-02 21:40:00", 1.55064],
  ["2016-02-02 21:50:00", 2.22453],
  ["2016-02-02 22:00:00", 3.3789],
  ["2016-02-02 22:10:00", 2.8355],
  ["2016-02-02 22:20:00", 2.73624],
  ["2016-02-02 22:30:00", 2.6733],
  ["2016-02-02 22:40:00", 1.97308],
  ["2016-02-02 22:50:00", 1.56986],
  ["2016-02-02 23:00:00", 8.8616],
  ["2016-02-02 23:10:00", 8.23832],
  ["2016-02-02 23:20:00", 6.4521],
  ["2016-02-02 23:30:00", 5.55048],
  ["2016-02-02 23:40:00", 5.89785],
  ["2016-02-02 23:50:00", 5.02424],
  ["2016-02-03 00:00:00", 5.76852],
  ["2016-02-03 00:10:00", 6.46164],
  ["2016-02-03 00:20:00", 5.8806],
  ["2016-02-03 00:30:00", 6.61652],
  ["2016-02-03 00:40:00", 6.32502],
  ["2016-02-03 00:50:00", 6.43498],
  ["2016-02-03 01:00:00", 6.49005],
  ["2016-02-03 01:10:00", 5.92328],
  ["2016-02-03 01:20:00", 5.06752],
  ["2016-02-03 01:30:00", 4.93324],
  ["2016-02-03 01:40:00", 4.59886],
  ["2016-02-03 01:50:00", 4.0026],
  ["2016-02-03 02:00:00", 5.25315],
  ["2016-02-03 02:10:00", 5.964],
  ["2016-02-03 02:20:00", 6.5625],
  ["2016-02-03 02:30:00", 6.60135],
  ["2016-02-03 02:40:00", 5.8107],
  ["2016-02-03 02:50:00", 5.72129],
  ["2016-02-03 03:00:00", 6.0632],
  ["2016-02-03 03:10:00", 5.06376],
  ["2016-02-03 03:20:00", 4.71636],
  ["2016-02-03 03:30:00", 5.5944],
  ["2016-02-03 03:40:00", 6.3315],
  ["2016-02-03 03:50:00", 6.5709],
  ["2016-02-03 04:00:00", 6.81588],
  ["2016-02-03 04:10:00", 6.91425],
  ["2016-02-03 04:20:00", 6.99388],
  ["2016-02-03 04:30:00", 8.01537],
  ["2016-02-03 04:40:00", 6.84705],
  ["2016-02-03 04:50:00", 6.75064],
  ["2016-02-03 05:00:00", 6.78808],
  ["2016-02-03 05:10:00", 6.153],
  ["2016-02-03 05:20:00", 5.9871],
  ["2016-02-03 05:30:00", 5.92592],
  ["2016-02-03 05:40:00", 6.17004],
  ["2016-02-03 05:50:00", 5.99184],
  ["2016-02-03 06:00:00", 6.9876],
  ["2016-02-03 06:10:00", 6.2504],
  ["2016-02-03 06:20:00", 6.38925],
  ["2016-02-03 06:30:00", 6.62796],
  ["2016-02-03 06:40:00", 5.10814],
  ["2016-02-03 06:50:00", 4.64835],
  ["2016-02-03 07:00:00", 3.59112],
  ["2016-02-03 07:10:00", 5.25315],
  ["2016-02-03 07:20:00", 5.40488],
  ["2016-02-03 07:30:00", 5.67736],
  ["2016-02-03 07:40:00", 5.80335],
  ["2016-02-03 07:50:00", 5.86872],
  ["2016-02-03 08:00:00", 5.07312],
  ["2016-02-03 08:10:00", 4.84844],
  ["2016-02-03 08:20:00", 5.18724],
  ["2016-02-03 08:30:00", 4.62902],
  ["2016-02-03 08:40:00", 5.0986],
  ["2016-02-03 08:50:00", 4.53276],
  ["2016-02-03 09:00:00", 5.06064],
  ["2016-02-03 09:10:00", 5.52636],
  ["2016-02-03 09:20:00", 5.05365],
  ["2016-02-03 09:30:00", 4.32952],
  ["2016-02-03 09:40:00", 4.42656],
  ["2016-02-03 09:50:00", 4.32494],
  ["2016-02-03 10:00:00", 4.10538],
  ["2016-02-03 10:10:00", 4.42656],
  ["2016-02-03 10:20:00", 4.78116],
  ["2016-02-03 10:30:00", 5.00364],
  ["2016-02-03 10:40:00", 4.37523],
  ["2016-02-03 10:50:00", 4.78065],
  ["2016-02-03 11:00:00", 3.76064],
  ["2016-02-03 11:10:00", 3.7653],
  ["2016-02-03 11:20:00", 4.54001],
  ["2016-02-03 11:30:00", 5.29115],
  ["2016-02-03 11:40:00", 6.25428],
  ["2016-02-03 11:50:00", 5.56395],
  ["2016-02-03 12:00:00", 6.0112],
  ["2016-02-03 12:10:00", 5.19592],
  ["2016-02-03 12:20:00", 5.65298],
  ["2016-02-03 12:30:00", 5.73306],
  ["2016-02-03 12:40:00", 4.59165],
  ["2016-02-03 12:50:00", 4.49935],
  ["2016-02-03 13:00:00", 4.2068],
  ["2016-02-03 13:10:00", 4.32494],
  ["2016-02-03 13:20:00", 4.23072],
  ["2016-02-03 13:30:00", 3.64068],
  ["2016-02-03 13:40:00", 3.861],
  ["2016-02-03 13:50:00", 3.91406],
  ["2016-02-03 14:00:00", 3.11376],
  ["2016-02-03 14:10:00", 3.50012],
  ["2016-02-03 14:20:00", 3.70864],
  ["2016-02-03 14:30:00", 3.43116],
  ["2016-02-03 14:40:00", 3.3705],
  ["2016-02-03 14:50:00", 3.8115],
  ["2016-02-03 15:00:00", 4.12965],
  ["2016-02-03 15:10:00", 5.44024],
  ["2016-02-03 15:20:00", 4.60005],
  ["2016-02-03 15:30:00", 3.34256],
  ["2016-02-03 15:40:00", 3.18954],
  ["2016-02-03 15:50:00", 3.3598],
  ["2016-02-03 16:00:00", 3.46514],
  ["2016-02-03 16:10:00", 4.39582],
  ["2016-02-03 16:20:00", 3.6183],
  ["2016-02-03 16:30:00", 3.03975],
  ["2016-02-03 16:40:00", 2.88214],
  ["2016-02-03 16:50:00", 3.12382],
  ["2016-02-03 17:00:00", 4.01143],
  ["2016-02-03 17:10:00", 3.074],
  ["2016-02-03 17:20:00", 3.7152],
  ["2016-02-03 17:30:00", 3.8896],
  ["2016-02-03 17:40:00", 3.46038],
  ["2016-02-03 17:50:00", 3.32488],
  ["2016-02-03 18:00:00", 3.75732],
  ["2016-02-03 18:10:00", 2.9521],
  ["2016-02-03 18:20:00", 2.21208],
  ["2016-02-03 18:30:00", 4.19364],
  ["2016-02-03 18:40:00", 4.30144],
  ["2016-02-03 18:50:00", 4.22198],
  ["2016-02-03 19:00:00", 3.18318],
  ["2016-02-03 19:10:00", 2.34437],
  ["2016-02-03 19:20:00", 2.54988],
  ["2016-02-03 19:30:00", 2.16033],
  ["2016-02-03 19:40:00", 2.32297],
  ["2016-02-03 19:50:00", 0.14],
  ["2016-02-03 20:00:00", 1.7472],
  ["2016-02-03 20:10:00", 2.36698],
  ["2016-02-03 20:20:00", 2.53584],
  ["2016-02-03 20:30:00", 2.3166],
  ["2016-02-03 20:40:00", 1.82595],
  ["2016-02-03 20:50:00", 1.6233],
  ["2016-02-03 21:00:00", 1.31355],
  ["2016-02-03 21:10:00", 0.63105],
  ["2016-02-03 21:20:00", 0.45465],
  ["2016-02-03 21:30:00", 1.3572],
  ["2016-02-03 21:40:00", 1.78632],
  ["2016-02-03 21:50:00", 1.1856],
  ["2016-02-03 22:00:00", 0.91806],
  ["2016-02-03 22:10:00", 1.3839],
  ["2016-02-03 22:20:00", 1.33164],
  ["2016-02-03 22:30:00", 1.56032],
  ["2016-02-03 22:40:00", 2.04686],
  ["2016-02-03 22:50:00", 2.0433],
  ["2016-02-03 23:00:00", 2.56372],
  ["2016-02-03 23:10:00", 2.96835],
  ["2016-02-03 23:20:00", 2.57712],
  ["2016-02-03 23:30:00", 2.81642],
  ["2016-02-03 23:40:00", 2.6416],
  ["2016-02-03 23:50:00", 2.81988],
  ["2016-02-04 00:00:00", 2.6215],
  ["2016-02-04 00:10:00", 2.47104],
  ["2016-02-04 00:20:00", 2.46348],
  ["2016-02-04 00:30:00", 2.1609],
  ["2016-02-04 00:40:00", 1.99784],
  ["2016-02-04 00:50:00", 2.0664],
  ["2016-02-04 01:00:00", 1.97392],
  ["2016-02-04 01:10:00", 2.8991],
  ["2016-02-04 01:20:00", 3.23782],
  ["2016-02-04 01:30:00", 3.34044],
  ["2016-02-04 01:40:00", 3.2314],
  ["2016-02-04 01:50:00", 2.95155],
  ["2016-02-04 02:00:00", 2.85495],
  ["2016-02-04 02:10:00", 2.55424],
  ["2016-02-04 02:20:00", 2.40136],
  ["2016-02-04 02:30:00", 2.38784],
  ["2016-02-04 02:40:00", 2.39824],
  ["2016-02-04 02:50:00", 1.71675],
  ["2016-02-04 03:00:00", 1.43624],
  ["2016-02-04 03:10:00", 1.7836],
  ["2016-02-04 03:20:00", 1.81224],
  ["2016-02-04 03:30:00", 2.05088],
  ["2016-02-04 03:40:00", 2.6416],
  ["2016-02-04 03:50:00", 3.31776],
  ["2016-02-04 04:00:00", 2.94678],
  ["2016-02-04 04:10:00", 2.8329],
  ["2016-02-04 04:20:00", 2.52174],
  ["2016-02-04 04:30:00", 2.54339],
  ["2016-02-04 04:40:00", 2.9337],
  ["2016-02-04 04:50:00", 3.68928],
  ["2016-02-04 05:00:00", 2.58619],
  ["2016-02-04 05:10:00", 0.7602],
  ["2016-02-04 05:20:00", 0.95765],
  ["2016-02-04 05:30:00", 1.76978],
  ["2016-02-04 05:40:00", 2.32725],
  ["2016-02-04 05:50:00", 2.77236],
  ["2016-02-04 06:00:00", 2.53269],
  ["2016-02-04 06:10:00", 2.604],
  ["2016-02-04 06:20:00", 3.1959],
  ["2016-02-04 06:30:00", 3.06488],
  ["2016-02-04 06:40:00", 2.51771],
  ["2016-02-04 06:50:00", 3.0024],
  ["2016-02-04 07:00:00", 2.5851],
  ["2016-02-04 07:10:00", 2.72685],
  ["2016-02-04 07:20:00", 2.47277],
  ["2016-02-04 07:30:00", 2.08608],
  ["2016-02-04 07:40:00", 1.7888],
  ["2016-02-04 07:50:00", 1.91423],
  ["2016-02-04 08:00:00", 1.20015],
  ["2016-02-04 08:10:00", 1.38779],
  ["2016-02-04 08:20:00", 1.53192],
  ["2016-02-04 08:30:00", 1.60272],
  ["2016-02-04 08:40:00", 1.9716],
  ["2016-02-04 08:50:00", 1.72912],
  ["2016-02-04 09:00:00", 1.33455],
  ["2016-02-04 09:10:00", 1.85645],
  ["2016-02-04 09:20:00", 1.86585],
  ["2016-02-04 09:30:00", 3.78],
  ["2016-02-04 09:40:00", 3.45708],
  ["2016-02-04 09:50:00", 3.2864],
  ["2016-02-04 10:00:00", 3.3384],
  ["2016-02-04 10:10:00", 2.97972],
  ["2016-02-04 10:20:00", 4.05972],
  ["2016-02-04 10:30:00", 3.4755],
  ["2016-02-04 10:40:00", 4.0614],
  ["2016-02-04 10:50:00", 3.36194],
  ["2016-02-04 11:00:00", 2.74248],
  ["2016-02-04 11:10:00", 2.96835],
  ["2016-02-04 11:20:00", 3.23925],
  ["2016-02-04 11:30:00", 2.8242],
  ["2016-02-04 11:40:00", 2.9538],
  ["2016-02-04 11:50:00", 3.78738],
  ["2016-02-04 12:00:00", 3.05532],
  ["2016-02-04 12:10:00", 2.8497],
  ["2016-02-04 12:20:00", 2.95106],
  ["2016-02-04 12:30:00", 3.08374],
  ["2016-02-04 12:40:00", 2.8783],
  ["2016-02-04 12:50:00", 2.84648],
  ["2016-02-04 13:00:00", 4.0986],
  ["2016-02-04 13:10:00", 2.87472],
  ["2016-02-04 13:20:00", 2.44602],
  ["2016-02-04 13:30:00", 2.56693],
  ["2016-02-04 13:40:00", 4.06065],
  ["2016-02-04 13:50:00", 4.34592],
  ["2016-02-04 14:00:00", 3.85734],
  ["2016-02-04 14:10:00", 3.6088],
  ["2016-02-04 14:20:00", 4.00504],
  ["2016-02-04 14:30:00", 3.85414],
  ["2016-02-04 14:40:00", 3.38832],
  ["2016-02-04 14:50:00", 3.0618],
  ["2016-02-04 15:00:00", 3.7779],
  ["2016-02-04 15:10:00", 3.86484],
  ["2016-02-04 15:20:00", 4.04676],
  ["2016-02-04 15:30:00", 4.69664],
  ["2016-02-04 15:40:00", 4.42017],
  ["2016-02-04 15:50:00", 5.11353],
  ["2016-02-04 16:00:00", 4.0845],
  ["2016-02-04 16:10:00", 3.49232],
  ["2016-02-04 16:20:00", 3.63204],
  ["2016-02-04 16:30:00", 4.4982],
  ["2016-02-04 16:40:00", 3.3978],
  ["2016-02-04 16:50:00", 3.0157],
  ["2016-02-04 17:00:00", 3.0996],
  ["2016-02-04 17:10:00", 3.63907],
  ["2016-02-04 17:20:00", 3.94695],
  ["2016-02-04 17:30:00", 4.39296],
  ["2016-02-04 17:40:00", 3.38458],
  ["2016-02-04 17:50:00", 2.98423],
  ["2016-02-04 18:00:00", 2.73374],
  ["2016-02-04 18:10:00", 2.85376],
  ["2016-02-04 18:20:00", 3.6666],
  ["2016-02-04 18:30:00", 3.42252],
  ["2016-02-04 18:40:00", 3.43228],
  ["2016-02-04 18:50:00", 3.2613],
  ["2016-02-04 19:00:00", 2.5956],
  ["2016-02-04 19:10:00", 2.8991],
  ["2016-02-04 19:20:00", 2.82694],
  ["2016-02-04 19:30:00", 2.78992],
  ["2016-02-04 19:40:00", 3.65505],
  ["2016-02-04 19:50:00", 4.70496],
  ["2016-02-04 20:00:00", 7.9664],
  ["2016-02-04 20:10:00", 6.27952],
  ["2016-02-04 20:20:00", 8.40456],
  ["2016-02-04 20:30:00", 10.1556],
  ["2016-02-04 20:40:00", 6.62792],
  ["2016-02-04 20:50:00", 3.7821],
  ["2016-02-04 21:00:00", 4.48092],
  ["2016-02-04 21:10:00", 3.87712],
  ["2016-02-04 21:20:00", 3.84237],
  ["2016-02-04 21:30:00", 3.40472],
  ["2016-02-04 21:40:00", 3.96328],
  ["2016-02-04 21:50:00", 4.00356],
  ["2016-02-04 22:00:00", 4.3725],
  ["2016-02-04 22:10:00", 4.32586],
  ["2016-02-04 22:20:00", 4.452],
  ["2016-02-04 22:30:00", 4.27392],
  ["2016-02-04 22:40:00", 3.90208],
  ["2016-02-04 22:50:00", 3.70136],
  ["2016-02-04 23:00:00", 4.33472],
  ["2016-02-04 23:10:00", 4.96584],
  ["2016-02-04 23:20:00", 4.45224],
  ["2016-02-04 23:30:00", 5.0456],
  ["2016-02-04 23:40:00", 5.56828],
  ["2016-02-04 23:50:00", 5.35464],
  ["2016-02-05 00:00:00", 4.87725],
  ["2016-02-05 00:10:00", 4.78116],
  ["2016-02-05 00:20:00", 4.48544],
  ["2016-02-05 00:30:00", 3.76272],
  ["2016-02-05 00:40:00", 4.33512],
  ["2016-02-05 00:50:00", 4.42552],
  ["2016-02-05 01:00:00", 4.58709],
  ["2016-02-05 01:10:00", 4.1075],
  ["2016-02-05 01:20:00", 4.7682],
  ["2016-02-05 01:30:00", 4.69664],
  ["2016-02-05 01:40:00", 4.53924],
  ["2016-02-05 01:50:00", 4.80816],
  ["2016-02-05 02:00:00", 4.93992],
  ["2016-02-05 02:10:00", 5.07528],
  ["2016-02-05 02:20:00", 5.1156],
  ["2016-02-05 02:30:00", 5.41024],
  ["2016-02-05 02:40:00", 5.23016],
  ["2016-02-05 02:50:00", 5.35406],
  ["2016-02-05 03:00:00", 5.03924],
  ["2016-02-05 03:10:00", 5.52312],
  ["2016-02-05 03:20:00", 5.2353],
  ["2016-02-05 03:30:00", 4.3956],
  ["2016-02-05 03:40:00", 4.6269],
  ["2016-02-05 03:50:00", 4.34304],
  ["2016-02-05 04:00:00", 4.18215],
  ["2016-02-05 04:10:00", 4.23592],
  ["2016-02-05 04:20:00", 4.14015],
  ["2016-02-05 04:30:00", 3.19166],
  ["2016-02-05 04:40:00", 3.69899],
  ["2016-02-05 04:50:00", 2.23965],
  ["2016-02-05 05:00:00", 2.94678],
  ["2016-02-05 05:10:00", 4.1075],
  ["2016-02-05 05:20:00", 5.25049],
  ["2016-02-05 05:30:00", 4.75072],
  ["2016-02-05 05:40:00", 4.96186],
  ["2016-02-05 05:50:00", 4.99684],
  ["2016-02-05 06:00:00", 3.98216],
  ["2016-02-05 06:10:00", 4.56676],
  ["2016-02-05 06:20:00", 6.03801],
  ["2016-02-05 06:30:00", 7.44399],
  ["2016-02-05 06:40:00", 5.73615],
  ["2016-02-05 06:50:00", 5.12304],
  ["2016-02-05 07:00:00", 4.80644],
  ["2016-02-05 07:10:00", 4.33784],
  ["2016-02-05 07:20:00", 4.17165],
  ["2016-02-05 07:30:00", 3.59528],
  ["2016-02-05 07:40:00", 3.82616],
  ["2016-02-05 07:50:00", 5.09288],
  ["2016-02-05 08:00:00", 5.2458],
  ["2016-02-05 08:10:00", 4.79544],
  ["2016-02-05 08:20:00", 4.75072],
  ["2016-02-05 08:30:00", 5.3298],
  ["2016-02-05 08:40:00", 5.37208],
  ["2016-02-05 08:50:00", 6.36405],
  ["2016-02-05 09:00:00", 5.55876],
  ["2016-02-05 09:10:00", 6.24987],
  ["2016-02-05 09:20:00", 5.28424],
  ["2016-02-05 09:30:00", 5.44522],
  ["2016-02-05 09:40:00", 4.77256],
  ["2016-02-05 09:50:00", 4.74444],
  ["2016-02-05 10:00:00", 4.63417],
  ["2016-02-05 10:10:00", 4.1244],
  ["2016-02-05 10:20:00", 4.97336],
  ["2016-02-05 10:30:00", 5.24992],
  ["2016-02-05 10:40:00", 5.457],
  ["2016-02-05 10:50:00", 5.16432],
  ["2016-02-05 11:00:00", 5.97555],
  ["2016-02-05 11:10:00", 5.2417],
  ["2016-02-05 11:20:00", 5.4285],
  ["2016-02-05 11:30:00", 5.40282],
  ["2016-02-05 11:40:00", 4.41315],
  ["2016-02-05 11:50:00", 5.13146],
  ["2016-02-05 12:00:00", 4.39128],
  ["2016-02-05 12:10:00", 4.85784],
  ["2016-02-05 12:20:00", 4.19761],
  ["2016-02-05 12:30:00", 4.46364],
  ["2016-02-05 12:40:00", 4.07472],
  ["2016-02-05 12:50:00", 4.13175],
  ["2016-02-05 13:00:00", 4.76944],
  ["2016-02-05 13:10:00", 5.2537],
  ["2016-02-05 13:20:00", 4.73472],
];

export const SENSOR_3_DATA = [
  ["2016-02-01 08:10:00", 304.53],
  ["2016-02-01 08:20:00", 291.43],
  ["2016-02-01 08:30:00", 288.33],
  ["2016-02-01 08:40:00", 296.03],
  ["2016-02-01 08:50:00", 286.53],
  ["2016-02-01 09:00:00", 279.13],
  ["2016-02-01 09:10:00", 245.03],
  ["2016-02-01 09:20:00", 225.93],
  ["2016-02-01 09:30:00", 226.43],
  ["2016-02-01 09:40:00", 223.53],
  ["2016-02-01 09:50:00", 222.93],
  ["2016-02-01 10:00:00", 223.93],
  ["2016-02-01 10:10:00", 238.33],
  ["2016-02-01 10:20:00", 284.23],
  ["2016-02-01 10:30:00", 276.33],
  ["2016-02-01 10:40:00", 262.13],
  ["2016-02-01 10:50:00", 255.63],
  ["2016-02-01 11:00:00", 295.83],
  ["2016-02-01 11:10:00", 306.13],
  ["2016-02-01 11:20:00", 310.53],
  ["2016-02-01 11:30:00", 304.93],
  ["2016-02-01 11:40:00", 297.23],
  ["2016-02-01 11:50:00", 276.43],
  ["2016-02-01 12:00:00", 309.53],
  ["2016-02-01 12:10:00", 322.93],
  ["2016-02-01 12:20:00", 294.03],
  ["2016-02-01 12:30:00", 290.03],
  ["2016-02-01 12:40:00", 293.83],
  ["2016-02-01 12:50:00", 292.43],
  ["2016-02-01 13:00:00", 311.33],
  ["2016-02-01 13:10:00", 349.23],
  ["2016-02-01 13:20:00", 328.63],
  ["2016-02-01 13:30:00", 332.93],
  ["2016-02-01 13:40:00", 319.73],
  ["2016-02-01 13:50:00", 300.43],
  ["2016-02-01 14:00:00", 294.83],
  ["2016-02-01 14:10:00", 293.23],
  ["2016-02-01 14:20:00", 294.03],
  ["2016-02-01 14:30:00", 299.83],
  ["2016-02-01 14:40:00", 314.93],
  ["2016-02-01 14:50:00", 339.53],
  ["2016-02-01 15:00:00", 332.73],
  ["2016-02-01 15:10:00", 343.23],
  ["2016-02-01 15:20:00", 323.63],
  ["2016-02-01 15:30:00", 343.63],
  ["2016-02-01 15:40:00", 330.93],
  ["2016-02-01 15:50:00", 334.23],
  ["2016-02-01 16:00:00", 346.73],
  ["2016-02-01 16:10:00", 350.93],
  ["2016-02-01 16:20:00", 333.83],
  ["2016-02-01 16:30:00", 333.13],
  ["2016-02-01 16:40:00", 339.53],
  ["2016-02-01 16:50:00", 328.23],
  ["2016-02-01 17:00:00", 306.53],
  ["2016-02-01 17:10:00", 312.23],
  ["2016-02-01 17:20:00", 320.53],
  ["2016-02-01 17:30:00", 325.53],
  ["2016-02-01 17:40:00", 331.33],
  ["2016-02-01 17:50:00", 328.23],
  ["2016-02-01 18:00:00", 322.53],
  ["2016-02-01 18:10:00", 343.43],
  ["2016-02-01 18:20:00", 339.03],
  ["2016-02-01 18:30:00", 326.43],
  ["2016-02-01 18:40:00", 307.63],
  ["2016-02-01 18:50:00", 321.03],
  ["2016-02-01 19:00:00", 319.93],
  ["2016-02-01 19:10:00", 322.03],
  ["2016-02-01 19:20:00", 321.23],
  ["2016-02-01 19:30:00", 318.13],
  ["2016-02-01 19:40:00", 313.23],
  ["2016-02-01 19:50:00", 315.83],
  ["2016-02-01 20:00:00", 320.53],
  ["2016-02-01 20:10:00", 317.73],
  ["2016-02-01 20:20:00", 308.03],
  ["2016-02-01 20:30:00", 309.93],
  ["2016-02-01 20:40:00", 315.63],
  ["2016-02-01 20:50:00", 322.33],
  ["2016-02-01 21:00:00", 326.63],
  ["2016-02-01 21:10:00", 334.93],
  ["2016-02-01 21:20:00", 318.63],
  ["2016-02-01 21:30:00", 320.33],
  ["2016-02-01 21:40:00", 321.83],
  ["2016-02-01 21:50:00", 324.93],
  ["2016-02-01 22:00:00", 331.13],
  ["2016-02-01 22:10:00", 321.83],
  ["2016-02-01 22:20:00", 322.93],
  ["2016-02-01 22:30:00", 321.83],
  ["2016-02-01 22:40:00", 317.93],
  ["2016-02-01 22:50:00", 321.63],
  ["2016-02-01 23:00:00", 320.33],
  ["2016-02-01 23:10:00", 322.33],
  ["2016-02-01 23:20:00", 313.23],
  ["2016-02-01 23:30:00", 308.23],
  ["2016-02-01 23:40:00", 305.93],
  ["2016-02-01 23:50:00", 303.53],
  ["2016-02-02 00:00:00", 307.23],
  ["2016-02-02 00:10:00", 313.53],
  ["2016-02-02 00:20:00", 314.13],
  ["2016-02-02 00:30:00", 311.63],
  ["2016-02-02 00:40:00", 312.03],
  ["2016-02-02 00:50:00", 314.53],
  ["2016-02-02 01:00:00", 320.13],
  ["2016-02-02 01:10:00", 319.93],
  ["2016-02-02 01:20:00", 318.83],
  ["2016-02-02 01:30:00", 317.13],
  ["2016-02-02 01:40:00", 305.73],
  ["2016-02-02 01:50:00", 303.93],
  ["2016-02-02 02:00:00", 302.83],
  ["2016-02-02 02:10:00", 299.43],
  ["2016-02-02 02:20:00", 300.63],
  ["2016-02-02 02:30:00", 301.63],
  ["2016-02-02 02:40:00", 302.83],
  ["2016-02-02 02:50:00", 317.93],
  ["2016-02-02 03:00:00", 331.83],
  ["2016-02-02 03:10:00", 343.23],
  ["2016-02-02 03:20:00", 351.83],
  ["2016-02-02 03:30:00", 350.73],
  ["2016-02-02 03:40:00", 2.13],
  ["2016-02-02 03:50:00", 357.63],
  ["2016-02-02 04:00:00", 355.73],
  ["2016-02-02 04:10:00", 350.43],
  ["2016-02-02 04:20:00", 342.93],
  ["2016-02-02 04:30:00", 326.23],
  ["2016-02-02 04:40:00", 330.03],
  ["2016-02-02 04:50:00", 335.83],
  ["2016-02-02 05:00:00", 325.83],
  ["2016-02-02 05:10:00", 314.93],
  ["2016-02-02 05:20:00", 357.43],
  ["2016-02-02 05:30:00", 39.63],
  ["2016-02-02 05:40:00", 44.03],
  ["2016-02-02 05:50:00", 39.63],
  ["2016-02-02 06:00:00", 8.43],
  ["2016-02-02 06:10:00", 268.33],
  ["2016-02-02 06:20:00", 298.83],
  ["2016-02-02 06:30:00", 294.03],
  ["2016-02-02 06:40:00", 270.23],
  ["2016-02-02 06:50:00", 232.43],
  ["2016-02-02 07:00:00", 243.03],
  ["2016-02-02 07:10:00", 5.33],
  ["2016-02-02 07:20:00", 22.33],
  ["2016-02-02 07:30:00", 35.63],
  ["2016-02-02 07:40:00", 31.93],
  ["2016-02-02 07:50:00", 336.33],
  ["2016-02-02 08:00:00", 349.73],
  ["2016-02-02 08:10:00", 335.83],
  ["2016-02-02 08:20:00", 0.93],
  ["2016-02-02 08:30:00", 352.33],
  ["2016-02-02 08:40:00", 328.63],
  ["2016-02-02 08:50:00", 331.13],
  ["2016-02-02 09:00:00", 324.43],
  ["2016-02-02 09:10:00", 325.53],
  ["2016-02-02 09:20:00", 312.63],
  ["2016-02-02 09:30:00", 295.23],
  ["2016-02-02 09:40:00", 289.63],
  ["2016-02-02 09:50:00", 293.83],
  ["2016-02-02 10:00:00", 316.23],
  ["2016-02-02 10:10:00", 301.83],
  ["2016-02-02 10:20:00", 303.93],
  ["2016-02-02 10:30:00", 230.73],
  ["2016-02-02 10:40:00", 3.13],
  ["2016-02-02 10:50:00", 313.73],
  ["2016-02-02 11:00:00", 306.83],
  ["2016-02-02 11:10:00", 286.33],
  ["2016-02-02 11:20:00", 287.13],
  ["2016-02-02 11:30:00", 317.53],
  ["2016-02-02 11:40:00", 303.53],
  ["2016-02-02 11:50:00", 294.23],
  ["2016-02-02 12:00:00", 300.63],
  ["2016-02-02 12:10:00", 295.43],
  ["2016-02-02 12:20:00", 300.03],
  ["2016-02-02 12:30:00", 281.93],
  ["2016-02-02 12:40:00", 281.23],
  ["2016-02-02 12:50:00", 276.13],
  ["2016-02-02 13:00:00", 268.73],
  ["2016-02-02 13:10:00", 267.43],
  ["2016-02-02 13:20:00", 256.83],
  ["2016-02-02 13:30:00", 253.03],
  ["2016-02-02 13:40:00", 252.53],
  ["2016-02-02 13:50:00", 258.73],
  ["2016-02-02 14:00:00", 272.43],
  ["2016-02-02 14:10:00", 277.93],
  ["2016-02-02 14:20:00", 288.13],
  ["2016-02-02 14:30:00", 273.13],
  ["2016-02-02 14:40:00", 270.23],
  ["2016-02-02 14:50:00", 273.13],
  ["2016-02-02 15:00:00", 265.63],
  ["2016-02-02 15:10:00", 271.33],
  ["2016-02-02 15:20:00", 283.63],
  ["2016-02-02 15:30:00", 284.83],
  ["2016-02-02 15:40:00", 288.93],
  ["2016-02-02 15:50:00", 293.63],
  ["2016-02-02 16:00:00", 298.43],
  ["2016-02-02 16:10:00", 283.13],
  ["2016-02-02 16:20:00", 286.73],
  ["2016-02-02 16:30:00", 273.53],
  ["2016-02-02 16:40:00", 266.03],
  ["2016-02-02 16:50:00", 261.43],
  ["2016-02-02 17:00:00", 256.73],
  ["2016-02-02 17:10:00", 268.93],
  ["2016-02-02 17:20:00", 251.53],
  ["2016-02-02 17:30:00", 258.03],
  ["2016-02-02 17:40:00", 257.93],
  ["2016-02-02 17:50:00", 259.83],
  ["2016-02-02 18:00:00", 254.83],
  ["2016-02-02 18:10:00", 257.93],
  ["2016-02-02 18:20:00", 260.13],
  ["2016-02-02 18:30:00", 255.13],
  ["2016-02-02 18:40:00", 255.63],
  ["2016-02-02 18:50:00", 249.13],
  ["2016-02-02 19:00:00", 240.53],
  ["2016-02-02 19:10:00", 230.13],
  ["2016-02-02 19:20:00", 232.23],
  ["2016-02-02 19:30:00", 241.03],
  ["2016-02-02 19:40:00", 242.03],
  ["2016-02-02 19:50:00", 234.33],
  ["2016-02-02 20:00:00", 257.73],
  ["2016-02-02 20:10:00", 262.33],
  ["2016-02-02 20:20:00", 248.13],
  ["2016-02-02 20:30:00", 244.13],
  ["2016-02-02 20:40:00", 248.53],
  ["2016-02-02 20:50:00", 213.63],
  ["2016-02-02 21:00:00", 220.83],
  ["2016-02-02 21:10:00", 239.43],
  ["2016-02-02 21:20:00", 247.13],
  ["2016-02-02 21:30:00", 278.13],
  ["2016-02-02 21:40:00", 257.33],
  ["2016-02-02 21:50:00", 249.63],
  ["2016-02-02 22:00:00", 280.43],
  ["2016-02-02 22:10:00", 220.93],
  ["2016-02-02 22:20:00", 205.13],
  ["2016-02-02 22:30:00", 231.13],
  ["2016-02-02 22:40:00", 245.53],
  ["2016-02-02 22:50:00", 275.93],
  ["2016-02-02 23:00:00", 279.33],
  ["2016-02-02 23:10:00", 278.93],
  ["2016-02-02 23:20:00", 276.33],
  ["2016-02-02 23:30:00", 266.93],
  ["2016-02-02 23:40:00", 275.73],
  ["2016-02-02 23:50:00", 279.63],
  ["2016-02-03 00:00:00", 287.53],
  ["2016-02-03 00:10:00", 295.23],
  ["2016-02-03 00:20:00", 299.83],
  ["2016-02-03 00:30:00", 303.93],
  ["2016-02-03 00:40:00", 310.93],
  ["2016-02-03 00:50:00", 310.93],
  ["2016-02-03 01:00:00", 312.03],
  ["2016-02-03 01:10:00", 314.53],
  ["2016-02-03 01:20:00", 321.23],
  ["2016-02-03 01:30:00", 317.33],
  ["2016-02-03 01:40:00", 313.23],
  ["2016-02-03 01:50:00", 314.13],
  ["2016-02-03 02:00:00", 317.53],
  ["2016-02-03 02:10:00", 317.33],
  ["2016-02-03 02:20:00", 312.43],
  ["2016-02-03 02:30:00", 316.43],
  ["2016-02-03 02:40:00", 326.23],
  ["2016-02-03 02:50:00", 329.53],
  ["2016-02-03 03:00:00", 319.03],
  ["2016-02-03 03:10:00", 320.73],
  ["2016-02-03 03:20:00", 339.73],
  ["2016-02-03 03:30:00", 334.73],
  ["2016-02-03 03:40:00", 327.73],
  ["2016-02-03 03:50:00", 321.23],
  ["2016-02-03 04:00:00", 321.23],
  ["2016-02-03 04:10:00", 312.63],
  ["2016-02-03 04:20:00", 308.63],
  ["2016-02-03 04:30:00", 305.53],
  ["2016-02-03 04:40:00", 307.83],
  ["2016-02-03 04:50:00", 306.83],
  ["2016-02-03 05:00:00", 307.83],
  ["2016-02-03 05:10:00", 315.43],
  ["2016-02-03 05:20:00", 319.93],
  ["2016-02-03 05:30:00", 319.73],
  ["2016-02-03 05:40:00", 324.73],
  ["2016-02-03 05:50:00", 322.33],
  ["2016-02-03 06:00:00", 321.83],
  ["2016-02-03 06:10:00", 327.13],
  ["2016-02-03 06:20:00", 325.33],
  ["2016-02-03 06:30:00", 321.63],
  ["2016-02-03 06:40:00", 321.63],
  ["2016-02-03 06:50:00", 330.93],
  ["2016-02-03 07:00:00", 325.33],
  ["2016-02-03 07:10:00", 324.93],
  ["2016-02-03 07:20:00", 340.23],
  ["2016-02-03 07:30:00", 331.33],
  ["2016-02-03 07:40:00", 331.53],
  ["2016-02-03 07:50:00", 322.33],
  ["2016-02-03 08:00:00", 330.03],
  ["2016-02-03 08:10:00", 343.93],
  ["2016-02-03 08:20:00", 343.23],
  ["2016-02-03 08:30:00", 346.03],
  ["2016-02-03 08:40:00", 334.93],
  ["2016-02-03 08:50:00", 337.63],
  ["2016-02-03 09:00:00", 339.73],
  ["2016-02-03 09:10:00", 329.33],
  ["2016-02-03 09:20:00", 321.43],
  ["2016-02-03 09:30:00", 322.73],
  ["2016-02-03 09:40:00", 310.73],
  ["2016-02-03 09:50:00", 317.13],
  ["2016-02-03 10:00:00", 324.43],
  ["2016-02-03 10:10:00", 318.43],
  ["2016-02-03 10:20:00", 317.93],
  ["2016-02-03 10:30:00", 311.13],
  ["2016-02-03 10:40:00", 312.63],
  ["2016-02-03 10:50:00", 306.13],
  ["2016-02-03 11:00:00", 300.63],
  ["2016-02-03 11:10:00", 312.03],
  ["2016-02-03 11:20:00", 302.83],
  ["2016-02-03 11:30:00", 297.23],
  ["2016-02-03 11:40:00", 294.03],
  ["2016-02-03 11:50:00", 295.23],
  ["2016-02-03 12:00:00", 292.03],
  ["2016-02-03 12:10:00", 293.03],
  ["2016-02-03 12:20:00", 289.43],
  ["2016-02-03 12:30:00", 289.63],
  ["2016-02-03 12:40:00", 296.63],
  ["2016-02-03 12:50:00", 293.43],
  ["2016-02-03 13:00:00", 290.83],
  ["2016-02-03 13:10:00", 286.53],
  ["2016-02-03 13:20:00", 291.83],
  ["2016-02-03 13:30:00", 282.33],
  ["2016-02-03 13:40:00", 268.93],
  ["2016-02-03 13:50:00", 268.23],
  ["2016-02-03 14:00:00", 273.83],
  ["2016-02-03 14:10:00", 295.03],
  ["2016-02-03 14:20:00", 284.83],
  ["2016-02-03 14:30:00", 288.33],
  ["2016-02-03 14:40:00", 279.43],
  ["2016-02-03 14:50:00", 282.93],
  ["2016-02-03 15:00:00", 264.43],
  ["2016-02-03 15:10:00", 260.83],
  ["2016-02-03 15:20:00", 256.83],
  ["2016-02-03 15:30:00", 251.53],
  ["2016-02-03 15:40:00", 248.33],
  ["2016-02-03 15:50:00", 227.43],
  ["2016-02-03 16:00:00", 233.73],
  ["2016-02-03 16:10:00", 229.13],
  ["2016-02-03 16:20:00", 235.93],
  ["2016-02-03 16:30:00", 237.63],
  ["2016-02-03 16:40:00", 233.83],
  ["2016-02-03 16:50:00", 233.23],
  ["2016-02-03 17:00:00", 234.13],
  ["2016-02-03 17:10:00", 238.13],
  ["2016-02-03 17:20:00", 238.63],
  ["2016-02-03 17:30:00", 250.53],
  ["2016-02-03 17:40:00", 255.33],
  ["2016-02-03 17:50:00", 240.73],
  ["2016-02-03 18:00:00", 214.23],
  ["2016-02-03 18:10:00", 216.53],
  ["2016-02-03 18:20:00", 217.13],
  ["2016-02-03 18:30:00", 166.13],
  ["2016-02-03 18:40:00", 167.83],
  ["2016-02-03 18:50:00", 166.03],
  ["2016-02-03 19:00:00", 171.33],
  ["2016-02-03 19:10:00", 197.93],
  ["2016-02-03 19:20:00", 195.53],
  ["2016-02-03 19:30:00", 203.33],
  ["2016-02-03 19:40:00", 193.33],
  ["2016-02-03 19:50:00", 182.33],
  ["2016-02-03 20:00:00", 200.53],
  ["2016-02-03 20:10:00", 189.23],
  ["2016-02-03 20:20:00", 177.13],
  ["2016-02-03 20:30:00", 201.33],
  ["2016-02-03 20:40:00", 204.43],
  ["2016-02-03 20:50:00", 196.33],
  ["2016-02-03 21:00:00", 180.13],
  ["2016-02-03 21:10:00", 247.33],
  ["2016-02-03 21:20:00", 238.43],
  ["2016-02-03 21:30:00", 254.83],
  ["2016-02-03 21:40:00", 277.23],
  ["2016-02-03 21:50:00", 280.03],
  ["2016-02-03 22:00:00", 280.03],
  ["2016-02-03 22:10:00", 280.03],
  ["2016-02-03 22:20:00", 280.03],
  ["2016-02-03 22:30:00", 283.23],
  ["2016-02-03 22:40:00", 303.53],
  ["2016-02-03 22:50:00", 305.53],
  ["2016-02-03 23:00:00", 305.53],
  ["2016-02-03 23:10:00", 319.73],
  ["2016-02-03 23:20:00", 332.03],
  ["2016-02-03 23:30:00", 359.83],
  ["2016-02-03 23:40:00", 350.23],
  ["2016-02-03 23:50:00", 341.33],
  ["2016-02-04 00:00:00", 331.83],
  ["2016-02-04 00:10:00", 336.33],
  ["2016-02-04 00:20:00", 321.43],
  ["2016-02-04 00:30:00", 324.23],
  ["2016-02-04 00:40:00", 330.03],
  ["2016-02-04 00:50:00", 334.73],
  ["2016-02-04 01:00:00", 321.63],
  ["2016-02-04 01:10:00", 320.33],
  ["2016-02-04 01:20:00", 330.43],
  ["2016-02-04 01:30:00", 330.43],
  ["2016-02-04 01:40:00", 318.63],
  ["2016-02-04 01:50:00", 314.73],
  ["2016-02-04 02:00:00", 302.43],
  ["2016-02-04 02:10:00", 304.33],
  ["2016-02-04 02:20:00", 299.23],
  ["2016-02-04 02:30:00", 294.23],
  ["2016-02-04 02:40:00", 292.43],
  ["2016-02-04 02:50:00", 297.23],
  ["2016-02-04 03:00:00", 294.03],
  ["2016-02-04 03:10:00", 294.03],
  ["2016-02-04 03:20:00", 281.53],
  ["2016-02-04 03:30:00", 266.93],
  ["2016-02-04 03:40:00", 272.63],
  ["2016-02-04 03:50:00", 279.63],
  ["2016-02-04 04:00:00", 284.63],
  ["2016-02-04 04:10:00", 296.63],
  ["2016-02-04 04:20:00", 294.63],
  ["2016-02-04 04:30:00", 279.83],
  ["2016-02-04 04:40:00", 285.73],
  ["2016-02-04 04:50:00", 279.43],
  ["2016-02-04 05:00:00", 260.33],
  ["2016-02-04 05:10:00", 239.63],
  ["2016-02-04 05:20:00", 194.93],
  ["2016-02-04 05:30:00", 10.03],
  ["2016-02-04 05:40:00", 327.13],
  ["2016-02-04 05:50:00", 319.93],
  ["2016-02-04 06:00:00", 319.23],
  ["2016-02-04 06:10:00", 330.03],
  ["2016-02-04 06:20:00", 332.73],
  ["2016-02-04 06:30:00", 348.53],
  ["2016-02-04 06:40:00", 352.83],
  ["2016-02-04 06:50:00", 330.03],
  ["2016-02-04 07:00:00", 325.83],
  ["2016-02-04 07:10:00", 320.73],
  ["2016-02-04 07:20:00", 321.23],
  ["2016-02-04 07:30:00", 324.93],
  ["2016-02-04 07:40:00", 343.93],
  ["2016-02-04 07:50:00", 333.13],
  ["2016-02-04 08:00:00", 340.23],
  ["2016-02-04 08:10:00", 11.23],
  ["2016-02-04 08:20:00", 292.63],
  ["2016-02-04 08:30:00", 290.23],
  ["2016-02-04 08:40:00", 288.73],
  ["2016-02-04 08:50:00", 279.33],
  ["2016-02-04 09:00:00", 279.33],
  ["2016-02-04 09:10:00", 273.53],
  ["2016-02-04 09:20:00", 279.33],
  ["2016-02-04 09:30:00", 291.83],
  ["2016-02-04 09:40:00", 269.43],
  ["2016-02-04 09:50:00", 247.33],
  ["2016-02-04 10:00:00", 206.53],
  ["2016-02-04 10:10:00", 219.73],
  ["2016-02-04 10:20:00", 258.93],
  ["2016-02-04 10:30:00", 266.93],
  ["2016-02-04 10:40:00", 278.33],
  ["2016-02-04 10:50:00", 276.43],
  ["2016-02-04 11:00:00", 288.13],
  ["2016-02-04 11:10:00", 330.63],
  ["2016-02-04 11:20:00", 354.23],
  ["2016-02-04 11:30:00", 359.53],
  ["2016-02-04 11:40:00", 0.83],
  ["2016-02-04 11:50:00", 357.63],
  ["2016-02-04 12:00:00", 357.13],
  ["2016-02-04 12:10:00", 9.33],
  ["2016-02-04 12:20:00", 340.83],
  ["2016-02-04 12:30:00", 304.93],
  ["2016-02-04 12:40:00", 288.13],
  ["2016-02-04 12:50:00", 297.23],
  ["2016-02-04 13:00:00", 295.63],
  ["2016-02-04 13:10:00", 296.83],
  ["2016-02-04 13:20:00", 280.43],
  ["2016-02-04 13:30:00", 287.53],
  ["2016-02-04 13:40:00", 294.03],
  ["2016-02-04 13:50:00", 301.63],
  ["2016-02-04 14:00:00", 300.43],
  ["2016-02-04 14:10:00", 294.03],
  ["2016-02-04 14:20:00", 299.03],
  ["2016-02-04 14:30:00", 288.13],
  ["2016-02-04 14:40:00", 288.33],
  ["2016-02-04 14:50:00", 282.73],
  ["2016-02-04 15:00:00", 286.93],
  ["2016-02-04 15:10:00", 290.03],
  ["2016-02-04 15:20:00", 293.43],
  ["2016-02-04 15:30:00", 290.03],
  ["2016-02-04 15:40:00", 285.23],
  ["2016-02-04 15:50:00", 288.73],
  ["2016-02-04 16:00:00", 281.33],
  ["2016-02-04 16:10:00", 281.03],
  ["2016-02-04 16:20:00", 279.13],
  ["2016-02-04 16:30:00", 263.03],
  ["2016-02-04 16:40:00", 268.53],
  ["2016-02-04 16:50:00", 256.83],
  ["2016-02-04 17:00:00", 261.03],
  ["2016-02-04 17:10:00", 252.73],
  ["2016-02-04 17:20:00", 260.73],
  ["2016-02-04 17:30:00", 267.83],
  ["2016-02-04 17:40:00", 267.63],
  ["2016-02-04 17:50:00", 258.73],
  ["2016-02-04 18:00:00", 249.33],
  ["2016-02-04 18:10:00", 262.43],
  ["2016-02-04 18:20:00", 265.53],
  ["2016-02-04 18:30:00", 275.53],
  ["2016-02-04 18:40:00", 274.03],
  ["2016-02-04 18:50:00", 259.43],
  ["2016-02-04 19:00:00", 261.03],
  ["2016-02-04 19:10:00", 275.73],
  ["2016-02-04 19:20:00", 283.83],
  ["2016-02-04 19:30:00", 283.83],
  ["2016-02-04 19:40:00", 280.63],
  ["2016-02-04 19:50:00", 276.33],
  ["2016-02-04 20:00:00", 279.33],
  ["2016-02-04 20:10:00", 297.43],
  ["2016-02-04 20:20:00", 309.53],
  ["2016-02-04 20:30:00", 315.63],
  ["2016-02-04 20:40:00", 324.03],
  ["2016-02-04 20:50:00", 352.33],
  ["2016-02-04 21:00:00", 2.03],
  ["2016-02-04 21:10:00", 352.13],
  ["2016-02-04 21:20:00", 349.23],
  ["2016-02-04 21:30:00", 346.23],
  ["2016-02-04 21:40:00", 343.63],
  ["2016-02-04 21:50:00", 344.63],
  ["2016-02-04 22:00:00", 344.83],
  ["2016-02-04 22:10:00", 349.73],
  ["2016-02-04 22:20:00", 351.63],
  ["2016-02-04 22:30:00", 336.03],
  ["2016-02-04 22:40:00", 332.03],
  ["2016-02-04 22:50:00", 348.53],
  ["2016-02-04 23:00:00", 341.53],
  ["2016-02-04 23:10:00", 319.23],
  ["2016-02-04 23:20:00", 312.83],
  ["2016-02-04 23:30:00", 312.43],
  ["2016-02-04 23:40:00", 305.93],
  ["2016-02-04 23:50:00", 308.23],
  ["2016-02-05 00:00:00", 312.03],
  ["2016-02-05 00:10:00", 315.83],
  ["2016-02-05 00:20:00", 319.43],
  ["2016-02-05 00:30:00", 326.43],
  ["2016-02-05 00:40:00", 332.23],
  ["2016-02-05 00:50:00", 339.03],
  ["2016-02-05 01:00:00", 336.53],
  ["2016-02-05 01:10:00", 327.33],
  ["2016-02-05 01:20:00", 326.93],
  ["2016-02-05 01:30:00", 319.43],
  ["2016-02-05 01:40:00", 315.43],
  ["2016-02-05 01:50:00", 312.63],
  ["2016-02-05 02:00:00", 307.83],
  ["2016-02-05 02:10:00", 308.43],
  ["2016-02-05 02:20:00", 307.43],
  ["2016-02-05 02:30:00", 304.13],
  ["2016-02-05 02:40:00", 305.93],
  ["2016-02-05 02:50:00", 309.53],
  ["2016-02-05 03:00:00", 305.33],
  ["2016-02-05 03:10:00", 305.53],
  ["2016-02-05 03:20:00", 299.43],
  ["2016-02-05 03:30:00", 299.83],
  ["2016-02-05 03:40:00", 301.63],
  ["2016-02-05 03:50:00", 297.83],
  ["2016-02-05 04:00:00", 301.43],
  ["2016-02-05 04:10:00", 304.53],
  ["2016-02-05 04:20:00", 307.43],
  ["2016-02-05 04:30:00", 319.23],
  ["2016-02-05 04:40:00", 295.03],
  ["2016-02-05 04:50:00", 228.23],
  ["2016-02-05 05:00:00", 222.33],
  ["2016-02-05 05:10:00", 242.73],
  ["2016-02-05 05:20:00", 269.13],
  ["2016-02-05 05:30:00", 278.73],
  ["2016-02-05 05:40:00", 288.13],
  ["2016-02-05 05:50:00", 294.43],
  ["2016-02-05 06:00:00", 296.03],
  ["2016-02-05 06:10:00", 280.63],
  ["2016-02-05 06:20:00", 270.73],
  ["2016-02-05 06:30:00", 272.63],
  ["2016-02-05 06:40:00", 288.93],
  ["2016-02-05 06:50:00", 299.83],
  ["2016-02-05 07:00:00", 304.53],
  ["2016-02-05 07:10:00", 301.63],
  ["2016-02-05 07:20:00", 299.03],
  ["2016-02-05 07:30:00", 287.73],
  ["2016-02-05 07:40:00", 295.23],
  ["2016-02-05 07:50:00", 292.83],
  ["2016-02-05 08:00:00", 297.43],
  ["2016-02-05 08:10:00", 294.63],
  ["2016-02-05 08:20:00", 297.83],
  ["2016-02-05 08:30:00", 296.23],
  ["2016-02-05 08:40:00", 294.63],
  ["2016-02-05 08:50:00", 297.03],
  ["2016-02-05 09:00:00", 298.83],
  ["2016-02-05 09:10:00", 302.63],
  ["2016-02-05 09:20:00", 301.03],
  ["2016-02-05 09:30:00", 300.23],
  ["2016-02-05 09:40:00", 302.43],
  ["2016-02-05 09:50:00", 309.73],
  ["2016-02-05 10:00:00", 301.63],
  ["2016-02-05 10:10:00", 304.33],
  ["2016-02-05 10:20:00", 307.83],
  ["2016-02-05 10:30:00", 305.53],
  ["2016-02-05 10:40:00", 305.53],
  ["2016-02-05 10:50:00", 302.43],
  ["2016-02-05 11:00:00", 305.13],
  ["2016-02-05 11:10:00", 299.03],
  ["2016-02-05 11:20:00", 303.93],
  ["2016-02-05 11:30:00", 294.43],
  ["2016-02-05 11:40:00", 299.23],
  ["2016-02-05 11:50:00", 290.43],
  ["2016-02-05 12:00:00", 299.03],
  ["2016-02-05 12:10:00", 301.23],
  ["2016-02-05 12:20:00", 291.03],
  ["2016-02-05 12:30:00", 295.03],
  ["2016-02-05 12:40:00", 295.23],
  ["2016-02-05 12:50:00", 291.63],
  ["2016-02-05 13:00:00", 296.83],
  ["2016-02-05 13:10:00", 289.83],
  ["2016-02-05 13:20:00", 284.23],
];
