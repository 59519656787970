import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useSimulationSelectionState } from "src/components/Simulations/hooks";
import { WorkflowType } from "src/networking/types";
import {
  getConfigByWorkflowId,
  getPresentationConfig,
} from "src/redux/actions/configs";
import { clearAndGetProjects } from "src/redux/actions/projects";
import { getWorkflowsByProjectId } from "src/redux/actions/workflows";
import { GlobalState } from "src/redux/reducers";
import { useAuth0 } from "src/utils/react-auth0-spa";

interface MatchProps {
  projectId?: string;
  simulationId?: string;
}

export const useDispatchPresentationConfig = (): void => {
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const getPresentationConfigState = useSelector(
    (state: GlobalState) => state.requests.GET_PRESENTATION_CONFIG_REQUEST
  );

  useEffect(() => {
    if (
      getPresentationConfigState.isCompleted ||
      getPresentationConfigState.isLoading
    ) {
      return;
    }
    getTokenSilently().then((token: string) => {
      dispatch(getPresentationConfig(token));
    });
  }, [dispatch, getTokenSilently, getPresentationConfigState]);
};

export const useDispatchProjects = (
  workflowTypes?: WorkflowType[],
  withSites?: boolean
): void => {
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    getTokenSilently().then((token: string) => {
      dispatch(clearAndGetProjects(token, workflowTypes, withSites));
    });
  }, [dispatch, getTokenSilently, workflowTypes, withSites]);
};

export const useDispatchWorkflowsByProject = (): void => {
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const currentProjectId = useRouteMatch<MatchProps>(
    "/predict/projects/:projectId"
  )?.params.projectId;

  useEffect(() => {
    if (!currentProjectId) {
      return;
    }
    getTokenSilently().then((token: string) => {
      dispatch(getWorkflowsByProjectId(currentProjectId, token));
    });
  }, [dispatch, currentProjectId, getTokenSilently]);
};

export const useDispatchWorkflowConfig = (): void => {
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const currentSimulationId = useSimulationSelectionState()[0];

  useEffect(() => {
    if (!currentSimulationId) {
      return;
    }
    getTokenSilently().then((token: string) => {
      dispatch(getConfigByWorkflowId(currentSimulationId, token));
    });
  }, [dispatch, currentSimulationId, getTokenSilently]);
};
