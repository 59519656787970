import React from "react";
import MonitoringCard from "src/components/Monitor/shared/MonitoringCard";

import { OffsetData } from "src/networking/types";

interface Props {
  offsets?: (OffsetData & { type: "string" })[];
  loading: boolean;
  error?: Record<string, unknown>;
}

const OffsetsCard: React.FC<Props> = ({ offsets, loading, error }) => {
  const tableColumns = [
    { title: "Sensor", dataIndex: "sensor", key: "sensor" },
    {
      title: "Comparison Site",
      dataIndex: "comparisonSite",
      key: "comparisonSite",
    },
    {
      title: "Comparison Sensor",
      dataIndex: "comparisonSensor",
      key: "comparisonSensor",
    },
    { title: "Type", dataIndex: "type", key: "type" },
    {
      title: "Offset",
      dataIndex: "offset",
      key: "offset",
      render: (o: number) => o.toFixed(2),
    },
  ];

  const data = offsets?.map((datum, idx) => {
    const {
      sensor,
      comparison_site: comparisonSite,
      comparison_sensor: comparisonSensor,
      offset,
      type,
    } = datum;
    return {
      key: idx,
      sensor,
      comparisonSite,
      comparisonSensor,
      offset,
      type,
    };
  });

  return (
    <MonitoringCard
      title="Time / Direction Offsets"
      tableColumns={tableColumns}
      data={data}
      loading={loading}
      error={error}
    />
  );
};

export default OffsetsCard;
