// This file is from https://github.com/ant-design/ant-design/blob/master/components/layout/layout.tsx
// We monkey patch this to add React.forwardRef to generator and Basic, so that
// we can get the refs from them and do stuff like setting scroll.
import { ConfigContext } from "antd/lib/config-provider";
import classNames from "classnames";
import * as React from "react";

export interface GeneratorProps {
  suffixCls: string;
  tagName: "header" | "footer" | "main" | "section";
  displayName: string;
}
export interface BasicProps extends React.HTMLAttributes<HTMLDivElement> {
  prefixCls?: string;
  hasSider?: boolean;
}

interface BasicPropsWithTagName extends BasicProps {
  tagName: "header" | "footer" | "main" | "section";
}

function generator({ suffixCls, tagName, displayName }: GeneratorProps) {
  return (BasicComponent: any) => {
    const Adapter = React.forwardRef<unknown, BasicProps>((props, ref) => {
      const { getPrefixCls } = React.useContext(ConfigContext);
      const { prefixCls: customizePrefixCls } = props;
      const prefixCls = getPrefixCls(suffixCls, customizePrefixCls);

      /* eslint-disable react/jsx-props-no-spreading */
      return (
        <BasicComponent
          ref={ref}
          prefixCls={prefixCls}
          tagName={tagName}
          {...props}
        />
      );
      /* eslint-enable react/jsx-props-no-spreading */
    });
    Adapter.displayName = displayName;
    return Adapter;
  };
}

const Basic = React.forwardRef((props: BasicPropsWithTagName, ref) => {
  const { prefixCls, className, children, tagName, ...others } = props;
  const classString = classNames(prefixCls, className);
  return React.createElement(
    tagName,
    { ref, className: classString, ...others },
    children
  );
});

const Header = generator({
  suffixCls: "layout-header",
  tagName: "header",
  displayName: "Header",
})(Basic);

const Footer = generator({
  suffixCls: "layout-footer",
  tagName: "footer",
  displayName: "Footer",
})(Basic);

const Content = generator({
  suffixCls: "layout-content",
  tagName: "main",
  displayName: "Content",
})(Basic);

export { Header, Footer, Content };
