import React from "react";
import JSONTree from "react-json-tree";
import { useSelector } from "react-redux";
import { GlobalState } from "src/redux/reducers";

interface Props {
  [k: string]: unknown;
}

const CardConfigVisualizer: React.FC<Props> = (props) => {
  const debugForms = useSelector((state: GlobalState) => state.configs.debug);

  if (!debugForms) {
    // eslint-disable-next-line no-console
    console.error(
      "This component[CardConfigVisualizer] should only be used in debug mode."
    );
  }

  return <JSONTree data={props} />;
};

export default CardConfigVisualizer;
