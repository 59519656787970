import React, { useMemo } from "react";
import PlotlyPlot from "react-plotly.js";
import { WinddeskCard } from "src/components/Common/WinddeskCard";

interface Props {
  loading: boolean;
  error?: Record<string, unknown>;
  x: string[];
  y: string[];
  z: number[][];
  title?: string;
}

const DataCoverageCard: React.FC<Props> = ({
  loading,
  error,
  x,
  y,
  z,
  title,
}) => {
  const memoizedX = useMemo(() => x.map((d) => new Date(d)), [x]);
  let contents;
  if (error) {
    contents = <div>An error occured</div>;
  } else {
    contents = (
      <PlotlyPlot
        data={[
          {
            x: memoizedX,
            y,
            z,
            type: "heatmap",
            colorscale: "Greens",
            reversescale: true,
          },
        ]}
        layout={{ height: 400, margin: { r: 0, b: 30, t: 0 } }}
        config={{ modeBarButtons: [["toImage", "resetScale2d"]] }}
      />
    );
  }

  return (
    <WinddeskCard loading={loading} title={title ?? "Data Coverage Heatmap"}>
      {contents}
    </WinddeskCard>
  );
};

export default DataCoverageCard;
