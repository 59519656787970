import * as FS from "@fullstory/browser";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Dashboard from "src/components/Dashboard";
import "antd/dist/antd.less";
import { isProductionEnvironment } from "src/utils";
import { canMasq } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";

const App: React.FC<Record<string, never>> = () => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    getTokenSilently,
    user,
    logout,
  } = useAuth0();
  const { pathname } = useLocation();

  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently?.()?.then((token: string) => {
      setAuthToken(token);
    });
  }, [getTokenSilently]);

  useEffect(() => {
    if (isProductionEnvironment) {
      if (user) {
        FS.identify(user.sub, {
          displayName: user.name,
          email: user.email,
        });
      } else {
        FS.anonymize();
      }
    }
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect({});
    return null;
  }

  if (pathname === "/masq" && authToken && canMasq(authToken)) {
    loginWithRedirect({ company_name: prompt("Company ID to masq into:") });
  }

  if (pathname === "/logout") {
    logout({ returnTo: window.location.origin });
  }

  return <Dashboard />;
};

export default App;
