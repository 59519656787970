// TODO
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MdfDashboardActionTypes } from "src/redux/actionTypes";

export interface MdfDashboardAction {
  type: MdfDashboardActionTypes;
  requestParams?: { [id: string]: any };
}

export function drawGraphLine(
  linegraphs: string[],
  t0: any,
  t1: any
): MdfDashboardAction {
  return {
    type: MdfDashboardActionTypes.MDF_DRAW_LINE_GRAPHS,
    requestParams: { linegraphs, t0, t1 },
  };
}

export function updateGraphLine(
  idxs: number[],
  t0: any,
  t1: any,
  zoom: any = null
): MdfDashboardAction {
  return {
    type: MdfDashboardActionTypes.MDF_UPDATE_LINE_GRAPHS,
    requestParams: { idxs, t0, t1, zoom },
  };
}

export function drawGraphCorr(corrgraphs: string[][]): MdfDashboardAction {
  return {
    type: MdfDashboardActionTypes.MDF_DRAW_CORR_GRAPHS,
    requestParams: { corrgraphs },
  };
}

export function drawGraphRatio(plots: string[][]): MdfDashboardAction {
  return {
    type: MdfDashboardActionTypes.MDF_DRAW_RATIO_GRAPHS,
    requestParams: { plots },
  };
}

export function updateData(timeseries: any, flags: any): MdfDashboardAction {
  return {
    type: MdfDashboardActionTypes.MDF_UPDATE_DATA,
    requestParams: { timeseries, flags },
  };
}

export function updateView(): MdfDashboardAction {
  return { type: MdfDashboardActionTypes.MDF_UPDATE_VIEW };
}
