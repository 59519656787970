import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TextWithIcon } from "src/components/Common";
import { matchUrls } from "src/components/Common/routes";
import { useProjectSelectionState } from "src/components/Projects/hooks";
import Sider from "src/components/Sider";
import ConfigurationSider from "src/components/Sider/ConfigurationSider";
import ProjectSider from "src/components/Sider/ProjectSider";
import SimulationSider from "src/components/Sider/SimulationSider";
import { ReactComponent as SitesIcon } from "src/components/Sider/icons/sites-gray.svg";
import { ReactComponent as WindTurbineIcon } from "src/components/Sider/icons/wind-turbine.svg";
import { makeOption } from "src/components/Sider/utils";
import { useSimulationSelectionState } from "src/components/Simulations/hooks";
import { useDispatchWorkflowsByProject } from "src/networking/hooks";
import { GlobalState } from "src/redux/reducers";

interface Props {
  width: string;
}

const PredictProspectSider: React.FC<Props> = ({ width }) => {
  const history = useHistory();

  const [selectedProject, setSelectedProject] = useProjectSelectionState();
  const [selectedSimulation, setSelectedSimulation] =
    useSimulationSelectionState();
  useDispatchWorkflowsByProject();

  const projectsState = useSelector(
    (state: GlobalState) => state.projects.projects
  );
  const projectOptions = Object.values(projectsState).map((value) =>
    makeOption(
      value.id,
      <TextWithIcon>
        <WindTurbineIcon style={{ flexShrink: 0 }} />
        {value.name}
      </TextWithIcon>,
      value.name
    )
  );
  const workflowsState = useSelector(
    (state: GlobalState) => state.workflows.workflows
  );

  const workflowOptions = selectedProject
    ? Object.entries(workflowsState)
        .filter(([_, value]) => value.project_id === selectedProject)
        .map(([_, value]) =>
          makeOption(
            value.id,
            <TextWithIcon>
              <SitesIcon />
              {value.name}
            </TextWithIcon>,
            value.name
          )
        )
    : [];

  return (
    <Sider
      width={width}
      cards={[
        <ProjectSider
          options={projectOptions}
          selectedProjectId={selectedProject}
          onSelectedProject={(p) => {
            setSelectedProject(p);
            setSelectedSimulation(undefined);
          }}
          selectedProjectUrl={
            selectedProject &&
            matchUrls.project.replace(":projectId", selectedProject)
          }
        />,
        <SimulationSider
          options={workflowOptions}
          projectId={selectedProject}
          onSelectedSimulation={(simulationId) =>
            setSelectedSimulation(simulationId)
          }
          selectedSimulationId={selectedSimulation}
          selectedSimulationUrl={
            selectedProject &&
            selectedSimulation &&
            matchUrls.simulation
              .replace(":projectId", selectedProject)
              .replace(":simulationId", selectedSimulation)
          }
        />,
        <ConfigurationSider
          simulationId={selectedSimulation}
          onSelectConfiguration={(val) =>
            selectedProject &&
            selectedSimulation &&
            history.push(
              `/predict/projects/${selectedProject}/simulations/${selectedSimulation}/configurations/${val}`
            )
          }
          shouldShowConfigurations={!!selectedProject && !!selectedSimulation}
        />,
      ]}
    />
  );
};

export default PredictProspectSider;
