import React from "react";
import { Page } from "src/components/Page";
import MonitorSider from "src/components/Sider/MonitorSider";
import PredictProspectSider from "src/components/Sider/PredictProspectSider";

interface Props {
  page: Page;
  siderWidth: string;
}

const MainViewSider: React.FC<Props> = ({ page, siderWidth }) => {
  if (![Page.Monitor, Page.Simulate].includes(page)) {
    return null;
  }

  return page === Page.Monitor ? (
    <MonitorSider width={siderWidth} />
  ) : (
    <PredictProspectSider width={siderWidth} />
  );
};

export default MainViewSider;
