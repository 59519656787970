import { Radio, Table, Typography } from "antd";
import { isEqual } from "lodash";

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getWorkflowConfigFieldFromAccessor } from "src/components/SimulationConfigs/utils";
import { Diff } from "src/networking/types";
import { GlobalState } from "src/redux/reducers";

const { Title, Paragraph } = Typography;

const radioBlockStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

type DiffAlertTableProps = {
  title: string;
  description: string;
  diffs?: Diff[];
  conflictingDiffs?: [Diff, Diff][];
  showSubworkflow?: boolean;
  diffsToApply: Diff[];
  setValue?: (diff: Diff) => void;
};

const DiffAlertTable: React.FC<DiffAlertTableProps> = ({
  title,
  description,
  diffs,
  conflictingDiffs,
  showSubworkflow,
  diffsToApply,
  setValue,
}) => {
  const presentationConfig = useSelector(
    (state: GlobalState) => state.configs.presentationConfig,
    isEqual
  );

  const { t } = useTranslation();

  if (
    !presentationConfig ||
    ((diffs === undefined || diffs.length === 0) &&
      (conflictingDiffs === undefined || conflictingDiffs.length === 0))
  ) {
    return null;
  }

  const columns = (
    showSubworkflow
      ? [{ title: "Subworkflow", key: "subworkflow", dataIndex: "subworkflow" }]
      : []
  )
    .concat([
      { title: "Card", key: "card", dataIndex: "card" },
      { title: "Subcard", key: "subcard", dataIndex: "subcard" },
      { title: "Field", key: "field", dataIndex: "field" },
      {
        title: "Previous value",
        key: "previousValue",
        dataIndex: "previousValue",
      },
    ])
    .concat(
      conflictingDiffs
        ? [{ title: "Your value", key: "yourValue", dataIndex: "yourValue" }]
        : []
    )
    .concat([
      {
        title: "Other user's value",
        key: "otherValue",
        dataIndex: "otherValue",
      },
    ])
    .concat(
      setValue
        ? [
            {
              title: "Action",
              key: "action",
              dataIndex: "action",
            },
          ]
        : []
    );

  const diffsDataSource = diffs?.flatMap(
    ({ accessor, previousValue, currentValue }) => {
      const field = getWorkflowConfigFieldFromAccessor(
        presentationConfig,
        accessor
      );
      if (!field) {
        console.error(
          `When showing diffs, I can't locate accessor ${accessor} in workflow config fields`
        );
        return [];
      }

      const radioValue = diffsToApply.find((d) =>
        isEqual(d.accessor, accessor)
      )?.currentValue;
      if (radioValue === undefined) {
        setValue?.({
          accessor,
          previousValue,
          currentValue,
        });
      }

      return [
        {
          key: accessor,
          subworkflow: t(field.cardNesting[0]),
          card: field.cardNesting[1],
          subcard: field.cardNesting[2],
          field: field.workflowConfigField.display_name,
          previousValue,
          otherValue: currentValue,
          action: (
            <Radio.Group
              value={radioValue}
              onChange={({ target: { value } }) => {
                setValue?.({
                  accessor,
                  previousValue,
                  currentValue: value,
                });
              }}
            >
              <Radio style={radioBlockStyle} value={previousValue}>
                Use previous value ({previousValue})
              </Radio>
              <Radio style={radioBlockStyle} value={currentValue}>
                Use other user&apos;s value ({currentValue})
              </Radio>
            </Radio.Group>
          ),
        },
      ];
    }
  );

  const conflictingDiffsDataSource = conflictingDiffs?.flatMap(
    ([
      { accessor, previousValue, currentValue },
      { currentValue: yourValue },
    ]) => {
      const field = getWorkflowConfigFieldFromAccessor(
        presentationConfig,
        accessor
      );
      if (!field) {
        // eslint-disable-next-line no-console
        console.error(
          `When showing diffs, I can't locate accessor ${accessor} in workflow config fields`
        );
        return [];
      }

      return [
        {
          key: accessor,
          subworkflow: t(field.cardNesting[0]),
          card: field.cardNesting[1],
          subcard: field.cardNesting[2],
          field: field.workflowConfigField.display_name,
          previousValue,
          yourValue,
          otherValue: currentValue,
          action: (
            <Radio.Group
              value={
                diffsToApply.find((d) => isEqual(d.accessor, accessor))
                  ?.currentValue
              }
              onChange={({ target: { value } }) => {
                setValue?.({
                  accessor,
                  previousValue,
                  currentValue: value,
                });
              }}
            >
              <Radio style={radioBlockStyle} value={previousValue}>
                Use previous value ({previousValue})
              </Radio>
              <Radio style={radioBlockStyle} value={currentValue}>
                Use other user&apos;s value ({currentValue})
              </Radio>
              <Radio style={radioBlockStyle} value={yourValue}>
                Use your value ({yourValue})
              </Radio>
            </Radio.Group>
          ),
        },
      ];
    }
  );

  return (
    <>
      <Title level={3}>{title}</Title>
      <Paragraph>{description}</Paragraph>
      <Table
        dataSource={diffsDataSource || conflictingDiffsDataSource}
        columns={columns}
        pagination={false}
        size="small"
      />
    </>
  );
};

export default DiffAlertTable;
