import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import moment, { Moment } from "moment";
// This is used by antd. We are only importing this for typing
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from "rc-field-form/es/interface";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Omit, useDispatch, useSelector } from "react-redux";

import { WinddeskCard } from "src/components/Common/WinddeskCard";
import { WinddeskSubcard } from "src/components/Common/WinddeskSubcard";
import {
  countryCodes,
  currencyCodes,
  timezones,
} from "src/components/Projects/constants";
import { useProjectState } from "src/components/Projects/hooks";
import Field from "src/components/SimulationConfigs/SimulationConfigsField/Field";
import {
  LocationCoordinateSystem,
  LocationDatum,
  Project,
} from "src/networking/types";
import { updateProject } from "src/redux/actions/projects";
import { GlobalState } from "src/redux/reducers";
import { useAuth0 } from "src/utils/react-auth0-spa";

export interface FormattedFormItemProps {
  name: string;
  label: string;
  tooltip?: string;
  className: string;
  style: React.CSSProperties;
  rules?: Rule[];
}

const FormattedFormItem: React.FC<FormattedFormItemProps> = ({
  name,
  label,
  tooltip,
  className,
  style,
  rules,
  children,
}) => (
  <Form.Item label={label} labelAlign="right" style={{ marginBottom: 0 }}>
    <Field tooltip={tooltip}>
      <Form.Item name={name} rules={rules} className={className} style={style}>
        {children}
      </Form.Item>
    </Field>
  </Form.Item>
);

type FormProjectTypes = {
  latitude?: number;
  longitude?: number;
  target_operational_date?: Moment;
  country_name?: string;
};

type ProjectFormData = Omit<
  Project,
  "target_operational_date" | "location" | "country_code"
> &
  FormProjectTypes;

const ProjectInformation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const project = useProjectState();
  const { getTokenSilently } = useAuth0();
  const requestState =
    useSelector(
      (state: GlobalState) => state.requests.UPDATE_PROJECT_REQUEST
    ) || {};

  useEffect(() => {
    form.resetFields();
  }, [form, project]);

  const handleUpdateProject = async () => {
    const updatedProject: ProjectFormData = await form.validateFields();
    const updatedProjectCountryCode = countryCodes
      .filter((cc) => cc[0] === updatedProject.country_name)
      .map((cc) => cc[1])[0];
    const location =
      updatedProject.latitude !== undefined &&
      updatedProject.longitude !== undefined
        ? {
            coordinate_system: LocationCoordinateSystem.LAT_LONG,
            datum: LocationDatum.WGS84,
            longitude: updatedProject.longitude,
            latitude: updatedProject.latitude,
          }
        : undefined;
    dispatch(
      updateProject(
        await getTokenSilently(),
        updatedProject.id,
        updatedProject.timezone,
        updatedProjectCountryCode,
        updatedProject.currency_code,
        updatedProject.target_operational_date?.unix(),
        location,
        updatedProject.notes
      )
    );
  };

  /* eslint-disable react/jsx-props-no-spreading */
  const styleProps = {
    formItem: {
      className: "traverse-form-item",
      style: { marginBottom: "4px" },
    },
  };

  let projectFormData: ProjectFormData | undefined;
  if (project !== undefined) {
    projectFormData = {
      ...project,
      latitude: project?.location?.latitude,
      longitude: project?.location?.longitude,
      target_operational_date: project?.target_operational_date
        ? moment(project.target_operational_date, "YYYY-MM-DD")
        : undefined,
    };
  }
  return (
    <WinddeskCard
      title={t("Project Information")}
      buttonText={project && t("Update Project")}
      buttonOnClick={handleUpdateProject}
      loading={requestState.isLoading}
      bodyStyle={{ marginBottom: 0 }}
    >
      <WinddeskSubcard>
        {projectFormData && (
          <Form
            form={form}
            colon={false}
            layout="horizontal"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={projectFormData}
          >
            <FormattedFormItem
              name="name"
              label={t("Project Name")}
              tooltip={t("Name / ID should be unique, with no spaces")}
              rules={[{ required: true, validateTrigger: "onChange" }]}
              {...styleProps.formItem}
            >
              <Input disabled />
            </FormattedFormItem>
            <FormattedFormItem
              name="id"
              label={t("Project number")}
              rules={[{ required: true, validateTrigger: "onChange" }]}
              tooltip={t(
                "Project number is automatically allocated by WindDesk"
              )}
              {...styleProps.formItem}
            >
              <Input disabled />
            </FormattedFormItem>
            <FormattedFormItem
              name="timezone"
              label={t("Project time zone [UTC]")}
              rules={[{ required: true, validateTrigger: "onChange" }]}
              tooltip={t(
                "If the project crosses multiple time zones, please consider the most representative time zone."
              )}
              {...styleProps.formItem}
            >
              <Select
                style={{ minWidth: 200 }}
                showSearch={timezones.length > 6}
                notFoundContent="Not found"
                options={timezones.map((key) => ({ value: key, label: key }))}
              />
            </FormattedFormItem>
            <FormattedFormItem
              name="country_name"
              label={t("Country")}
              tooltip={t(
                "If the project crosses multiple countries, please consider the most representative country."
              )}
              {...styleProps.formItem}
            >
              <Select
                style={{ minWidth: 200 }}
                showSearch={countryCodes.length > 6}
                notFoundContent="Not found"
                options={countryCodes.map(([countryName]) => ({
                  value: countryName,
                  label: countryName,
                }))}
              />
            </FormattedFormItem>
            <FormattedFormItem
              name="currency_code"
              label={t("Input Currency")}
              tooltip={t(
                "All cost type configuration shall be based on this currency selection"
              )}
              {...styleProps.formItem}
            >
              <Select
                style={{ minWidth: 200 }}
                showSearch={currencyCodes.length > 6}
                options={currencyCodes.map((key) => ({
                  value: key,
                  label: key,
                }))}
              />
            </FormattedFormItem>
            <FormattedFormItem
              name="target_operational_date"
              label={t("Target operation date")}
              tooltip={t(
                "All cost type configuration shall be based on this currency selection"
              )}
              {...styleProps.formItem}
            >
              <DatePicker style={{ minWidth: 100 }} />
            </FormattedFormItem>
            <FormattedFormItem
              name="latitude"
              label={t("Project General Location (Latitude)")}
              {...styleProps.formItem}
            >
              <InputNumber style={{ minWidth: 100 }} />
            </FormattedFormItem>
            <FormattedFormItem
              name="longitude"
              label={t("Project General Location (Longitude)")}
              {...styleProps.formItem}
            >
              <InputNumber style={{ minWidth: 100 }} />
            </FormattedFormItem>
          </Form>
        )}
      </WinddeskSubcard>
    </WinddeskCard>
  );
};
export default ProjectInformation;
