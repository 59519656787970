import { Card } from "antd";
import React from "react";

interface Props {
  title?: string;
}

export const WinddeskSubcard: React.FC<Props> = ({ title, children }) => (
  <Card
    title={title}
    headStyle={{
      boxSizing: "border-box",

      fontSize: "14px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",

      paddingLeft: "8px",
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      height: "48px",
      borderRadius: "3px",
      border: "2px solid #e1e5e8",
      fontWeight: 500,
      background: "#e1e5e8",
    }}
    bodyStyle={{
      padding: "8px 0 12px 12px",
      borderLeft: "2px solid #e1e5e8",
      marginLeft: "12px",
    }}
    bordered={false}
    size="small"
  >
    {children}
  </Card>
);
