import { Col, Row } from "antd";
import React from "react";
import ProjectInformation from "src/components/Projects/ProjectInformation";
import ProjectMap from "src/components/Projects/ProjectMap";
import PublishedWindFarmAlternatives from "src/components/Projects/PublishedWindFarmAlternatives";

type Props = {
  projectId: string;
};

const ProjectDashboard: React.FC<Props> = ({ projectId }) => (
  <>
    <Row gutter={14} style={{ display: "flex" }}>
      <Col span={14}>
        <ProjectInformation />
      </Col>
      <Col span={10}>
        <ProjectMap projectId={projectId} />
      </Col>
    </Row>
    <Row gutter={14} style={{ marginTop: "24px" }}>
      <Col span={14}>
        <PublishedWindFarmAlternatives />
      </Col>
    </Row>
  </>
);

export default ProjectDashboard;
