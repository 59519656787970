import { Button, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { countryCodes, timezones } from "src/components/Projects/constants";
import { OptionProps } from "src/components/Sider/Selector";
import SiderCard from "src/components/Sider/SiderCard";
import { ReactComponent as ProjectsIcon } from "src/components/Sider/icons/projects.svg";
import { createProject } from "src/redux/actions/projects";
import { canCreateProjects } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";

interface Props {
  options: OptionProps[];
  onSelectedProject?: (projectId?: string) => void;
  selectedProjectId?: string;
  selectedProjectUrl?: string;
}

const ProjectSider: React.FC<Props> = ({
  options,
  selectedProjectId,
  selectedProjectUrl,
  onSelectedProject,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently().then((token: string) => {
      setAuthToken(token);
    });
  }, [getTokenSilently]);

  const [antdForm] = Form.useForm();

  if (authToken === undefined) {
    return null;
  }

  const form = (dismissModal: () => void) => (
    <Form
      form={antdForm}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={async ({
        projectName,
        projectTimezone,
        projectCountryName,
      }) => {
        const projectCountryCode = countryCodes.find(
          ([countryName, _countryCode]) => countryName === projectCountryName
        )?.[1];
        if (projectCountryCode === undefined) {
          return;
        }

        dispatch(
          createProject(
            projectName,
            projectTimezone,
            projectCountryCode,
            authToken
          )
        );
        dismissModal();
        antdForm.resetFields();
        message.success(t("Project created!"));
      }}
    >
      <Form.Item
        label={t("Project name")}
        labelAlign="right"
        name="projectName"
        rules={[{ required: true, validateTrigger: "onChange" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("Project time zone [UTC]")}
        labelAlign="right"
        name="projectTimezone"
        rules={[{ required: true, validateTrigger: "onChange" }]}
      >
        <Select
          showSearch={timezones.length > 6}
          options={timezones.map((key) => ({ value: key, label: key }))}
        />
      </Form.Item>
      <Form.Item
        label={t("Project country name")}
        labelAlign="right"
        name="projectCountryName"
        rules={[{ required: true, validateTrigger: "onChange" }]}
      >
        <Select
          showSearch={countryCodes.length > 6}
          options={countryCodes.map(([countryName]) => ({
            value: countryName,
            label: countryName,
          }))}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }} labelAlign="right">
        <Button data-cy="projectFormSubmit" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <SiderCard
      data-cy="projectSiderCard"
      title="Project"
      options={options}
      handleChange={(projectId) => {
        history.push(`/predict/projects/${projectId}`);
        onSelectedProject?.(projectId);
      }}
      mainButtonUrl="/predict/projects/"
      mainButtonText="View All Projects"
      mainButtonIcon={<ProjectsIcon />}
      homeUrl={selectedProjectUrl}
      value={selectedProjectId}
      newText="+ Add New Project"
      newForm={form}
      buttonEnabled={canCreateProjects(authToken)}
      buttonDisabledTooltip="Inform company administrator to create projects."
      selectPlaceholder="Select / Add Project"
      selectionDataCy="projectSelection"
      buttonDataCy="addProjectButton"
    />
  );
};

export default ProjectSider;
