import { Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import {
  allProjectsData,
  defaultProjectData,
} from "src/components/Projects/allProjectsData";
import { getCompanyName } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";
import "src/components/Projects/AllProjects.css";

const AllProjects: React.FC = () => {
  const { t } = useTranslation();

  const { getTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently().then((token: string) => {
      setAuthToken(token);
    });
  }, [getTokenSilently]);

  const companyName = authToken && getCompanyName(authToken);

  const createAntdSorter = (columnName: string) => (a: any, b: any) =>
    a[columnName] > b[columnName]
      ? 1
      : a[columnName] === b[columnName]
      ? 0
      : -1;

  const columns: Record<string, any>[] = [
    {
      title: "ID",
      dataIndex: "id",
      align: "left",
      sorter: createAntdSorter("id"),
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      sorter: createAntdSorter("name"),
    },
    {
      title: "Stage",
      dataIndex: "stage",
      align: "center",
      sorter: createAntdSorter("stage"),
    },
    {
      title: "Municipality",
      dataIndex: "municipality",
      align: "center",
      sorter: createAntdSorter("municipality"),
    },
    {
      title: "Province",
      dataIndex: "province",
      align: "center",
      sorter: createAntdSorter("province"),
    },
    {
      title: "Capacity (MW)",
      dataIndex: "capacity",
      align: "center",
      sorter: createAntdSorter("capacity"),
    },
    {
      title: "P90 PLF (%)",
      dataIndex: "p90plf",
      align: "center",
      sorter: createAntdSorter("p90plf"),
    },
    {
      title: "E-IRR (%) (P50)",
      dataIndex: "eirr",
      align: "center",
      sorter: createAntdSorter("eirr"),
    },
    { title: "Report", dataIndex: "report", align: "center" },
  ];

  const dataSource: Record<string, Record<string, any>[]> = allProjectsData;

  const [map, setMap] = useState<any>(undefined);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  const relevantDataSource: Record<string, any>[] =
    companyName === undefined
      ? []
      : dataSource[companyName] === undefined
      ? defaultProjectData
      : dataSource[companyName];
  const selectedLocation: [lat: number, lng: number] | undefined =
    selectedIndex === undefined ||
    relevantDataSource[selectedIndex] === undefined
      ? undefined
      : [
          relevantDataSource[selectedIndex].lat,
          relevantDataSource[selectedIndex].lng,
        ];

  return (
    <Row gutter={14}>
      <Col span={14}>
        <WinddeskCard title={t("All Projects Summary")}>
          <Table
            className="traverse-view-all-projects-table"
            columns={columns}
            dataSource={relevantDataSource}
            pagination={false}
            onRow={(record, idx) => ({
              onClick: () => {
                map?.setView([record.lat, record.lng], 13);
                setSelectedIndex(idx);
              },
            })}
          />
        </WinddeskCard>
      </Col>
      <Col span={10}>
        {selectedLocation && (
          <MapContainer
            style={{ height: "500px" }}
            whenCreated={setMap}
            center={selectedLocation}
            zoom={13}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {selectedLocation && <Marker position={selectedLocation} />}
          </MapContainer>
        )}
      </Col>
    </Row>
  );
};

export default AllProjects;
