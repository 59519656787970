import React from "react";
import selectLogo from "src/components/EmptyPage/select.png";
import styled from "styled-components";

const EmptyPageContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const EmptyPageText = styled.p`
  margin-top: 24px;
  color: #8c8c8c;
`;

const EmptyPage: React.FC = ({ children }) => (
  <EmptyPageContainer>
    <img src={selectLogo} alt="Select Project" />
    <EmptyPageText>{children}</EmptyPageText>
  </EmptyPageContainer>
);

export default EmptyPage;
