import { Empty } from "antd";
import { LatLngExpression } from "leaflet";
import React from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { useSelector } from "react-redux";

import { Location, LocationCoordinateSystem } from "src/networking/types";
import { GlobalState } from "src/redux/reducers";
import { toLatLon } from "utm";

type Props = {
  projectId: string;
};

const getCoordinates = (location: Location) => {
  if (location.coordinate_system === LocationCoordinateSystem.UTM) {
    const ll = toLatLon(
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      location.utm_x!,
      location.utm_y!,
      location.utm_zone!,
      location.utm_zone_lettering!.toUpperCase()
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    );
    return {
      lat: ll.latitude,
      lng: ll.longitude,
    };
  }
  return {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    lat: location.latitude!,
    lng: location.longitude!,
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  };
};

interface LeafletLocationProps {
  center: LatLngExpression;
  zoom: number;
}

const LocationUpdater: React.FC<LeafletLocationProps> = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const ProjectMap: React.FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const projectsState = useSelector((state: GlobalState) => state.projects);
  const project = projectsState.projects[projectId];

  if (project === undefined) {
    return null;
  }

  const { location } = project;
  return (
    <>
      {location ? (
        <MapContainer
          key={project.id}
          style={{ height: "100%" }}
          center={getCoordinates(location)}
          zoom={13}
          scrollWheelZoom={false}
        >
          <LocationUpdater center={getCoordinates(location)} zoom={13} />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={getCoordinates(location)}>
            <Popup>Project: {project.name}</Popup>
          </Marker>
        </MapContainer>
      ) : (
        <Empty description={t("No location defined for project")} />
      )}
    </>
  );
};
export default ProjectMap;
