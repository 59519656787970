import React from "react";
import { pluralise } from "src/utils";
import styled from "styled-components";

const MainItemTitleText = styled.span`
  font-family: Blender Pro;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1;
  margin: 0 24px 12px;
  color: #595959;
`;

interface Props {
  title: string;
}

const SiderCardTitle: React.FC<Props> = ({ title }) => (
  <MainItemTitleText>{pluralise(title)}</MainItemTitleText>
);

export default SiderCardTitle;
