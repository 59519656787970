import queryString from "query-string";
import { TimeSeriesInterval, WorkflowType } from "src/networking/types";
import { SensorsActionTypes } from "src/redux/actionTypes";
import { RequestPayload } from "src/redux/utils";

export interface SensorsAction {
  type: SensorsActionTypes;
  payload: RequestPayload;
  metadata?: any;
}

export function getTimeSeriesForSensor(
  projectId: string,
  siteName: string,
  sensorName: string,
  token: string,
  workflowType?: WorkflowType,
  period?: TimeSeriesInterval
): SensorsAction {
  return {
    type: SensorsActionTypes.GET_TIME_SERIES_FOR_SENSOR_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/sites/${siteName}/sensors/${sensorName}/time_series`,
          query: { workflow_type: workflowType, period },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
    metadata: { projectId, siteName, sensorName },
  };
}

export function getTimeSeriesSamplingRatesForSensor(
  projectId: string,
  siteName: string,
  sensorName: string,
  token: string,
  workflowType?: WorkflowType
): SensorsAction {
  return {
    type: SensorsActionTypes.GET_TIME_SERIES_SAMPLING_RATES_FOR_SENSOR_REQUEST,
    payload: {
      request: {
        url: queryString.stringifyUrl({
          url: `/v1/projects/${projectId}/sites/${siteName}/sensors/${sensorName}/time_series_sampling_rates`,
          query: { workflow_type: workflowType },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
    metadata: { projectId, siteName, sensorName },
  };
}
