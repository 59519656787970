// TODO
/* eslint-disable import/no-cycle */

import * as d3 from "d3";
import * as fc from "d3fc";
import React, { useEffect, useState } from "react";

import MdfState from "../../redux/reducers/mdf_dashboard";
import { MdfViewSetup } from "./MdfDashboard";

const settings: { [k: string]: any } = {
  corr: { divid: "mdf_graph_corrs", title: "corr plots" },
  ratio: { divid: "mdf_graph_ratio", title: "ratio plots" },
};

const drawGraph = (idx: number, type: string) => {
  const vis = d3.select(`#${settings[type].divid}`).append("div");
  vis
    .attr("class", "plot-corr")
    .style("width", `${MdfViewSetup.w}px`)
    .style("height", `${MdfViewSetup.h}px`)
    .style("display", "inline-block");

  const zoom = fc
    .zoom()
    // @ts-ignore TODO
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    .on("zoom", render)
    .extent([
      [0, 0],
      [MdfViewSetup.w, MdfViewSetup.h],
    ]);

  let pts: any = null;
  let xaxis: any = null;
  let yaxis: any = null;
  const render = () => {
    const state = MdfState.getState();
    const plots: any[] = type === "corr" ? state.graphCorrs : state.graphRatio;
    const params = plots[idx];
    if (!params) return;

    const ratioCheck = (d: any) => {
      const cutoff = MdfViewSetup.ratioCutoff || 1;
      return (
        Math.abs(d[4]) > cutoff &&
        Math.abs(d[5]) > cutoff &&
        Number.isFinite(d[1])
      );
    };

    const tseries = state.timeseries;
    if (!pts) {
      if (type === "ratio" && !tseries[params.direction]) return;
      for (const sname of params.sensors) {
        if (!tseries[sname]) return;
      }

      const data1 = tseries[params.sensors[0]][0].timeseries;
      const data2 = tseries[params.sensors[1]][0].timeseries;

      pts = [];
      for (let i = 0; i < data1.length; i += 1) {
        // TODO: assumes that data matches
        // might need to handle case when data is not a good match
        const [x, y] =
          type === "corr"
            ? [data1[i][1], data2[i][1]]
            : [
                tseries[params.direction][0].timeseries[i][1],
                data1[i][1] / data2[i][1],
              ];
        pts[i] = [
          x,
          y,
          data1[i][0],
          Math.max(
            state.flagged[params.sensors[0]]
              ? state.flagged[params.sensors[0]][i]
              : -1,
            state.flagged[params.sensors[1]]
              ? state.flagged[params.sensors[1]][i]
              : -1
          ),
          data1[i][1],
          data2[i][1],
        ];
      }

      xaxis = d3
        .scaleLinear()
        .range([0, MdfViewSetup.w])
        .domain(
          type === "ratio"
            ? d3.extent([0, 360])
            : (d3.extent(pts, (d: any) => d[0]) as any)
        );
      yaxis = d3
        .scaleLinear()
        .range([0, MdfViewSetup.h])
        .domain(d3.extent(pts.filter(ratioCheck), (d: any) => d[1]) as any);
    }

    let temp: any[] = [];
    const t0 = new Date(MdfViewSetup.tstamp0).getTime();
    const t1 = new Date(MdfViewSetup.tstamp1).getTime();
    if (t1 - t0 > 0) {
      const data = tseries[params.sensors[0]][0].timeseries;
      const ts0 = data[0][0];
      const ts1 = data[1][0];

      const s0 = Math.max(Math.floor((t0 - ts0) / (ts1 - ts0)), 0);
      const s1 = Math.max(Math.floor((t1 - ts0) / (ts1 - ts0)), 0);
      temp = pts.slice(s0, s1);
    } else {
      temp = pts;
    }

    if (!MdfViewSetup.plotflagged) {
      temp = temp.filter((d: any) => d[3] < 0);
    }

    if (type === "ratio") {
      temp = temp.filter(ratioCheck);
    }

    const highlight = MdfViewSetup.selRange;
    const area = fc
      .seriesWebglPoint()
      .size(1)
      .mainValue((d: any) => d[1])
      .crossValue((d: any) => d[0])
      .decorate((pg: any) =>
        fc
          .webglFillColor()
          .value((d: any) => {
            if (
              highlight.length === 2 &&
              d[2] > highlight[0] &&
              d[2] < highlight[1]
            ) {
              return MdfViewSetup.clrCorrHlight;
            }

            return MdfViewSetup.clrCorrs[d[3] + 1];
          })
          .data(temp)(pg)
      );

    const chart = fc
      .chartCartesian(xaxis, yaxis)
      .chartLabel(
        type === "corr"
          ? `${params.sensors[0]} VS ${params.sensors[1]}`
          : `${params.direction} VS ${params.sensors[0]}/${params.sensors[1]}`
      )
      .webglPlotArea(area)
      .decorate((sel: any) => {
        sel.enter().selectAll(".plot-area").call(zoom, xaxis, yaxis);
        sel.enter().selectAll(".x-axis").call(zoom, xaxis, null);
        sel.enter().selectAll(".y-axis").call(zoom, null, yaxis);
      });

    vis.datum(temp).call(chart);
  };
  render();

  return render;
};

const MdfGraphPlot: React.FC<{ type: string }> = (props: any) => {
  const { type } = props;
  const { divid, title } = settings[type];

  const fnsUnsub: any[] = [];
  const callback = (fn: any) => {
    fnsUnsub.push(MdfState.subscribe(fn));
  };
  const clearListeners = () => {
    while (fnsUnsub.length) fnsUnsub.pop()();
  };

  let lineCurr = "";
  const redrawLine = () => {
    const state = MdfState.getState();
    const plots = type === "corr" ? state.graphCorrs : state.graphRatio;
    const lineNew = plots.map((x: any) => x.sensors).join("---___---");
    if (lineNew === lineCurr) return;
    lineCurr = lineNew;

    clearListeners();
    const maindiv = document.getElementById(divid);
    if (maindiv) {
      maindiv.innerHTML = "";
    }

    for (let idx = 0; idx < plots.length; idx += 1) {
      callback(drawGraph(idx, type));
    }
  };
  const mainListener = MdfState.subscribe(redrawLine);

  useEffect(() => {
    const setupTimer = setTimeout(redrawLine, 1000 / MdfViewSetup.fps);

    return () => {
      mainListener();
      clearTimeout(setupTimer);
      for (const fn of fnsUnsub) fn();
    };
  });

  // NOTE: because of this, auto refresh
  const [cutoff, setCutoff] = useState(MdfViewSetup.ratioCutoff);

  return (
    <>
      <h1>{title}</h1>
      {type === "ratio" && (
        <>
          <input
            id="cutoff"
            type="number"
            value={cutoff}
            onChange={(e: any) => {
              setCutoff(e.target.value);
              MdfViewSetup.ratioCutoff = e.target.value;
            }}
          />{" "}
          m/s
          <br />
          <label htmlFor="cutoff">cutoff</label>
        </>
      )}
      <div id={divid} />
    </>
  );
};

export default MdfGraphPlot;
