import { RightOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import color from "src/colors";
import { OptionProps } from "src/components/Sider/Selector";
import styled from "styled-components";

const SiderMenuItem = styled(Menu.Item)`
  padding-left: 36px !important;
  margin: 0 !important;

  &:not(:hover) {
    color: #262626 !important;
  }

  &.ant-menu-item-selected {
    font-weight: 600;
  }

  & > .ant-menu-title-content {
    display: flex;
  }

  & > .ant-menu-title-content:hover svg > path {
    fill: ${color.hover};
    transition: all 0.3s;
  }
`;

const SiderMenuContents = styled.span`
  flex: 1;
`;

const SiderMenuRightArrow = styled.div`
  margin-right: 8px;
`;

interface Props {
  options: OptionProps[];
  onClick: (key: string) => void;
  selectedKeys: string[];
}

const SiderMenu: React.FC<Props> = ({ options, onClick, selectedKeys }) => (
  <Menu
    selectedKeys={selectedKeys}
    onClick={({ key }) => onClick(key)}
    mode="inline"
  >
    {options.map((opt) => (
      <SiderMenuItem key={opt.value}>
        <SiderMenuContents>{opt.label}</SiderMenuContents>
        <SiderMenuRightArrow>
          <RightOutlined />
        </SiderMenuRightArrow>
      </SiderMenuItem>
    ))}
  </Menu>
);

export default SiderMenu;
