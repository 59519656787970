import { Select } from "antd";
import React, { ReactNode } from "react";
import styled from "styled-components";
import "src/components/Sider/Selector.css";

const SelectorComponent = styled(Select)`
  flex: 1;
  display: flex;
  max-width: 252px;
  overflow: hidden;

  & > * {
    align-items: center;
  }

  & .ant-select-selection-item {
    font-weight: 500;
    height: 30px;
    max-width: 206px;
  }
`;

export type OptionProps = {
  value: string;
  label: ReactNode;
  description: string;
};

type SelectorProps = {
  options: OptionProps[];
  handleChange: (optionValue: OptionProps["value"]) => void;
  [selectProp: string]: any;
};

const Selector: React.FC<SelectorProps> = ({
  options,
  handleChange,
  ...selectProps
}) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <SelectorComponent
    dropdownClassName="sider-selector-dropdown"
    showSearch
    notFoundContent="Not found"
    // @ts-ignore some antd problem
    onSelect={handleChange}
    optionFilterProp="description"
    options={options}
    {...selectProps}
  />
  /* eslint-enable react/jsx-props-no-spreading */
);

export default Selector;
