// TODO
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { MdfViewSetup } from "./MdfDashboard";

const MAX_POINTS_PLOTTED = 1000;

export const getTimeIndexScale = (
  scales: any[],
  xaxis: any
): [number, number, number] => {
  const [t0, t1] = [
    xaxis.invert(0).getTime(),
    xaxis.invert(MdfViewSetup.w).getTime(),
  ];

  // NOTE: get scale
  const full = scales[0].timeseries;
  const tstampFull = full[full.length - 1][0] - full[0][0];
  const totalCount = scales[0].full_count * ((t1 - t0) / tstampFull);
  const scale = Math.max(Math.floor(totalCount / MAX_POINTS_PLOTTED), 1);

  const [finalIdx, finalScale] = scales.reduce(
    ([accFinalIdx, accFinalScale], curr, idx) =>
      curr.scale < scale && curr.scale > accFinalScale
        ? [idx, curr.scale]
        : [accFinalIdx, accFinalScale],
    [0, 1]
  );

  // NOTE: get idx (assume same spacing)
  const ts0 = full[0][0];
  const ts1 = full[full.length - 1][0];
  const tdiff = full[1][0] - ts0;
  const i0 = Math.max(Math.ceil((t0 - ts0) / tdiff), 0);
  const i1 = full.length - Math.max(Math.floor((ts1 - t1) / tdiff), 0);

  return [finalIdx, Math.floor(i0 / finalScale), Math.ceil(i1 / finalScale)];
};
