// TODO
/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { rescaleTimeSeries, uploadFlagData } from "../../redux/actions/mdf";
import { updateView } from "../../redux/actions/mdf_dashboard";
import MdfState from "../../redux/reducers/mdf_dashboard";
import { useAuth0 } from "../../utils/react-auth0-spa";
import { MdfViewSetup } from "./MdfDashboard";

const MdfConfig: React.FC = () => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const empty = new File([""], "");
  const [datafile, setDatafile] = useState(empty);

  const [linkzoom, setLinkzoom] = useState(true);
  MdfViewSetup.zoomall = linkzoom;

  const [plotflagged, setPlotflagged] = useState(true);
  MdfViewSetup.plotflagged = plotflagged;

  const loadDatafile = () => {
    if (datafile.name !== "") {
      getTokenSilently().then((tkn: string) => {
        dispatch(rescaleTimeSeries(tkn, datafile));
      });
    }
  };

  const loadFlagfile = () => {
    if (datafile.name !== "") {
      getTokenSilently().then((tkn: string) => {
        dispatch(uploadFlagData(tkn, datafile));
      });
    }
  };

  return (
    <>
      <h1>upload data</h1>
      <input
        type="file"
        onChange={(evt: any) => setDatafile(evt.target.files[0] || empty)}
      />
      <br />
      <button type="button" onClick={loadDatafile}>
        load datafile
      </button>
      <button type="button" onClick={loadFlagfile}>
        load flagfile
      </button>
      <br />
      <br />

      <input
        id="zoomall"
        type="checkbox"
        checked={linkzoom}
        onChange={() => {
          setLinkzoom(!linkzoom);
          MdfViewSetup.zoomall = !linkzoom;
          MdfState.dispatch(updateView());
        }}
      />
      <label htmlFor="zoomall">&nbsp; link zoom</label>
      <br />

      <input
        id="plotflag"
        type="checkbox"
        checked={plotflagged}
        onChange={() => {
          setPlotflagged(!plotflagged);
          MdfViewSetup.plotflagged = !plotflagged;
          MdfState.dispatch(updateView());
        }}
      />
      <label htmlFor="plotflag">&nbsp; plot flagged</label>
      <br />
      <br />
    </>
  );
};

export default MdfConfig;
