import { Col, Row } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WinddeskCol from "src/components/Common/WinddeskCol";
import SensorTimeSeriesCard from "src/components/Monitor/Summary/SensorTimeSeriesCard";
import SiteSummaryCard from "src/components/Monitor/Summary/SiteSummaryCard";
import { ReactComponent as BatteryIcon } from "src/components/Monitor/Summary/icons/battery.svg";
import { ReactComponent as BellIcon } from "src/components/Monitor/Summary/icons/bell.svg";
import { ReactComponent as MeterIcon } from "src/components/Monitor/Summary/icons/meter.svg";
import { ReactComponent as WindIcon } from "src/components/Monitor/Summary/icons/wind.svg";
import AlertsCard, {
  AlertsCardButton,
} from "src/components/Monitor/shared/AlertsCard";
import DataCoverageCard from "src/components/Monitor/shared/DataCoverageCard";
import InfoCard from "src/components/Monitor/shared/InfoCard";
import MeasurementSiteImageCard from "src/components/Monitor/shared/MeasurementSiteImageCard";
import SiteConfigurationImageCard from "src/components/Monitor/shared/SiteConfigurationImageCard";
import { Site, WorkflowType } from "src/networking/types";
import {
  getDataCoverageAverage,
  getSiteDataCoverage,
} from "src/networking/utils";
import { getAlertsForSite } from "src/redux/actions/alerts";
import { getPreQcSensorsForSite } from "src/redux/actions/sites";
import { GlobalState } from "src/redux/reducers";
import { useAuth0 } from "src/utils/react-auth0-spa";
import {
  getFilteredSensorTypeForDataCoverageHeatmap,
  getSortedSensorBySensorTypePriority,
} from "../shared/utils";

interface Props {
  site: Site;
  projectTimezone: string;
}

const SiteSummary: React.FC<Props> = ({ site, projectTimezone }) => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const getAlertsForSiteState = useSelector(
    (state: GlobalState) => state.requests.GET_ALERTS_FOR_SITE_REQUEST
  );
  const getPreQcSensorsForSiteState = useSelector(
    (state: GlobalState) => state.requests.GET_PRE_QC_SENSORS_FOR_SITE_REQUEST
  );

  const siteName = site.name;
  const projectId = site.project;

  useEffect(() => {
    getTokenSilently().then((token: string) => {
      // Do not get alerts resolved from one week ago
      dispatch(
        getAlertsForSite(
          projectId,
          siteName,
          token,
          Math.floor(Date.now() / 1000) - 604800
        )
      );
      dispatch(
        getPreQcSensorsForSite(
          projectId,
          siteName,
          token,
          WorkflowType.MonitorFull
        )
      );
    });
  }, [dispatch, projectId, siteName, getTokenSilently]);

  const alertsState = useSelector((state: GlobalState) => state.alerts);
  const alerts = Object.values(alertsState.alerts).filter(
    (alert) => alert.site_name === site.name
  );

  const siteDataCoverage = getSiteDataCoverage(site);
  const preQcSensorsDataCoverage =
    getPreQcSensorsForSiteState &&
    getPreQcSensorsForSiteState.data &&
    getDataCoverageAverage(getPreQcSensorsForSiteState.data);

  const filteredSiteSensors = getFilteredSensorTypeForDataCoverageHeatmap(
    site.sensors
  );
  const sortedSiteSensors =
    getSortedSensorBySensorTypePriority(filteredSiteSensors);

  const dataCoverageIndices = [
    ...new Set(
      sortedSiteSensors?.flatMap((sensor) =>
        sensor.data_coverage_timeseries.map(([dt, _v]) => dt)
      ) ?? []
    ),
  ].sort();

  const aggregatedCoverage =
    sortedSiteSensors?.map(({ data_coverage_timeseries: c }) =>
      Object.fromEntries(c)
    ) ?? [];

  const [lastMonthlyMeanDate, lastMonthlyMeanWS] =
    site.representative_sensor_monthly_mws.length === 0
      ? [undefined, undefined]
      : site.representative_sensor_monthly_mws[
          site.representative_sensor_monthly_mws.length - 1
        ];

  return (
    <Row gutter={16}>
      <Col span={6}>
        <InfoCard
          avatar={<WindIcon />}
          title={`MWS @${site.representative_sensor_height}m`}
          description={[
            {
              title: site.representative_sensor_deseasoned_mws
                ? `${site.representative_sensor_deseasoned_mws.toFixed(2)} m/s`
                : "-",
              subtitle: "(Deseasoned)",
            },
            {
              title: `${lastMonthlyMeanWS?.toFixed(2) ?? "-"} m/s`,
              subtitle: `(${moment(lastMonthlyMeanDate).format("MMM YYYY")})`,
            },
          ]}
        />
      </Col>
      <Col span={6}>
        <InfoCard
          avatar={<BellIcon />}
          title="Alerts"
          description={[
            {
              title: alerts.length.toString(),
              subtitle: "Total",
              emphasis: true,
            },
            {
              title: alerts
                .filter(({ priority }) => priority === "HIGH")
                .length.toString(),
              subtitle: "Major",
            },
            {
              title: alerts
                .filter(({ priority }) => priority === "MEDIUM")
                .length.toString(),
              subtitle: "Minor",
            },
            {
              title: alerts
                .filter(({ priority }) => priority === "LOW")
                .length.toString(),
              subtitle: "Warnings",
            },
          ]}
        />
      </Col>
      <Col span={6}>
        <InfoCard
          avatar={<MeterIcon />}
          title="Data Coverage"
          description={[
            {
              title: preQcSensorsDataCoverage
                ? `${(preQcSensorsDataCoverage * 100).toFixed(1)}%`
                : "-",
              subtitle: "Pre-QC",
            },
            {
              title: siteDataCoverage
                ? `${(siteDataCoverage * 100).toFixed(1)}%`
                : "-",
              subtitle: "Post-QC",
            },
          ]}
        />
      </Col>
      <Col span={6}>
        <InfoCard
          avatar={<BatteryIcon />}
          title="Battery Status"
          description={[
            {
              title: "✔",
              subtitle: "",
              emphasis: true,
            },
          ]}
        />
      </Col>
      <WinddeskCol span={8}>
        <SiteSummaryCard site={site} loading={false} />
        <Row gutter={16}>
          <WinddeskCol span={12}>
            <MeasurementSiteImageCard
              projectId={projectId}
              siteName={siteName}
            />
          </WinddeskCol>
          <WinddeskCol span={12}>
            <SiteConfigurationImageCard
              projectId={projectId}
              siteName={siteName}
            />
          </WinddeskCol>
        </Row>
      </WinddeskCol>
      <WinddeskCol span={16}>
        <SensorTimeSeriesCard
          site={site}
          title="Time Series of Wind Speed Sensor"
          sensorFilter={({ type }) => type === "Wind Speed"}
        />
        <SensorTimeSeriesCard
          site={site}
          title="Time Series of Wind Direction Sensor"
          sensorFilter={({ type }) => type === "Wind Direction"}
        />
        <Row gutter={16}>
          <WinddeskCol span={12}>
            <DataCoverageCard
              loading={!site.sensors}
              x={dataCoverageIndices}
              y={
                sortedSiteSensors?.map(
                  ({ sensor_weak_name }) => sensor_weak_name
                ) ?? []
              }
              z={aggregatedCoverage.map((sensor) =>
                dataCoverageIndices.map((k) => sensor[k])
              )}
            />
          </WinddeskCol>
          <WinddeskCol span={12}>
            <AlertsCard
              title="All Active Alerts Summary"
              alerts={alerts.filter(({ resolved_by_id }) => !resolved_by_id)}
              loading={getAlertsForSiteState.isLoading}
              error={getAlertsForSiteState.error}
              tableFilters={false}
              startingShownColumns={[
                "sensorId",
                "sensorType",
                "priority",
                "nature",
                "message",
                "activationTime",
                "status",
              ]}
              noSelect
              buttonsToShow={[
                AlertsCardButton.SNOOZE,
                AlertsCardButton.RESOLVE,
              ]}
              timeZoneOffset={projectTimezone}
            />
          </WinddeskCol>
        </Row>
      </WinddeskCol>
    </Row>
  );
};

export default SiteSummary;
