import { keyBy } from "lodash";

import { Site } from "src/networking/types";
import { ProjectsActionTypes, SitesActionTypes } from "src/redux/actionTypes";
import { getActionTypeForSuccess } from "src/redux/utils";

export interface SitesState {
  sites: Record<string, Site>;
}

const initialState = {
  sites: {},
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const reducer = (state: SitesState = initialState, action: any): SitesState => {
  switch (action.type) {
    case getActionTypeForSuccess(SitesActionTypes.GET_SITE_DETAILS_REQUEST):
      return {
        ...state,
        sites: {
          ...state.sites,
          [action.payload.data.name]: action.payload.data,
        },
      };
    case getActionTypeForSuccess(
      ProjectsActionTypes.GET_SITES_FOR_PROJECT_REQUEST
    ):
      return {
        ...state,
        sites: { ...state.sites, ...keyBy(action.payload.data, "name") },
      };
    default:
      return state;
  }
};

export default reducer;
