import React from "react";

export default [
  {
    key: "TT-FT-001",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-001",
    name: "Maestrazgo",
    stage: "Permitted",
    municipality: "Mirambel",
    province: "Teruel",
    capacity: 49.5,
    p90plf: 29.3,
    eirr: 11.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-002",
    lat: 40.557921,
    lng: -0.587214,
    id: "TT-FT-002",
    name: "Las Majas VI-A",
    stage: "Pre-Permit",
    municipality: "Granada",
    province: "Barcelona",
    capacity: 88.5,
    p90plf: 32.5,
    eirr: 15.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-003",
    lat: 40.657694,
    lng: -1.174994,
    id: "TT-FT-003",
    name: "Picasso III-B",
    stage: "Operational",
    municipality: "Castellon",
    province: "Alicante",
    capacity: 149.5,
    p90plf: 33.1,
    eirr: 16.1,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-004",
    lat: 40.633516,
    lng: -2.902086,
    id: "TT-FT-004",
    name: "Nadal Stage-1",
    stage: "Permitted",
    municipality: "Jaen",
    province: "Valencia",
    capacity: 75,
    p90plf: 43.1,
    eirr: 22.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-005",
    lat: 39.037514,
    lng: -0.627001,
    id: "TT-FT-005",
    name: "Nadal Stage-2",
    stage: "Permitted",
    municipality: "Leon",
    province: "Malaga",
    capacity: 121,
    p90plf: 32.5,
    eirr: 12.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-006",
    lat: 38.972907,
    lng: -0.317962,
    id: "TT-FT-006",
    name: "Alonso Extension",
    stage: "Permitted",
    municipality: "Toledo",
    province: "Biscay",
    capacity: 92,
    p90plf: 17.3,
    eirr: 2.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-007",
    lat: 39.28527,
    lng: -2.273383,
    id: "TT-FT-007",
    name: "Castile",
    stage: "Permitted",
    municipality: "Girona",
    province: "Alava",
    capacity: 42.5,
    p90plf: 32.5,
    eirr: 12.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-008",
    lat: 39.29399,
    lng: -3.127189,
    id: "TT-FT-008",
    name: "Cervantes",
    stage: "Survey Permit",
    municipality: "Cuenca",
    province: "Coruna",
    capacity: 600,
    p90plf: 29.5,
    eirr: 12.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-009",
    lat: 38.95283,
    lng: -3.497019,
    id: "TT-FT-009",
    name: "Armas I",
    stage: "Survey Permit",
    municipality: "Soria",
    province: "Ameria",
    capacity: 245,
    p90plf: 37.5,
    eirr: 25.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-010",
    lat: 40.003723,
    lng: -4.662709,
    id: "TT-FT-010",
    name: "Armas II",
    stage: "Construction",
    municipality: "Badajoz",
    province: "Seville",
    capacity: 420,
    p90plf: 32,
    eirr: 15.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-011",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-011",
    name: "Armas III",
    stage: "Construction",
    municipality: "Cordoba",
    province: "Burgos",
    capacity: 75,
    p90plf: 32.5,
    eirr: 14.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-012",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-012",
    name: "Maestrazgo II",
    stage: "Auctioned",
    municipality: "Ourense",
    province: "La Mirama",
    capacity: 127,
    p90plf: 15.5,
    eirr: 0,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-013",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-013",
    name: "Lleida",
    stage: "Auctioned",
    municipality: "Mirambel",
    province: "Guadalajara",
    capacity: 49.5,
    p90plf: 25.5,
    eirr: 10.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-014",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-014",
    name: "Segovia",
    stage: "Prospecting",
    municipality: "Guadalajara",
    province: "Teruel",
    capacity: 332.5,
    p90plf: 32.5,
    eirr: 17.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-015",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-015",
    name: "Palencia",
    stage: "Mast Permit",
    municipality: "Majas",
    province: "Vegas",
    capacity: 920,
    p90plf: 32.5,
    eirr: 19.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-016",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-016",
    name: "Zamora",
    stage: "Mast Permit",
    municipality: "Toledo",
    province: "Cuenca",
    capacity: 349.5,
    p90plf: 55.5,
    eirr: 30.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-017",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-017",
    name: "Albacete",
    stage: "Prospecting",
    municipality: "Alovera",
    province: "Mancha",
    capacity: 49.5,
    p90plf: 23.5,
    eirr: 18.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-018",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-018",
    name: "Avila",
    stage: "Prospecting",
    municipality: "Mirambel",
    province: "Teruel",
    capacity: 46,
    p90plf: 28.5,
    eirr: 19.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-019",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-019",
    name: "Lugo",
    stage: "Prospecting",
    municipality: "Mirambel",
    province: "Teruel",
    capacity: 149.5,
    p90plf: 32.5,
    eirr: 19.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-020",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-020",
    name: "Zaragoza",
    stage: "Prospecting",
    municipality: "Mirambel",
    province: "Teruel",
    capacity: 1045,
    p90plf: 28.5,
    eirr: 12.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
  {
    key: "TT-FT-021",
    lat: 40.567767,
    lng: -0.386262,
    id: "TT-FT-021",
    name: "Murcia",
    stage: "Auctioned",
    municipality: "Mirambel",
    province: "Teruel",
    capacity: 42.5,
    p90plf: 27.5,
    eirr: 11.4,
    report: (
      <a
        href="https://drive.google.com/file/d/1imZaeAMi8xTRUMpzlSUhtSrx3OopTmXF/view?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
    ),
  },
];
