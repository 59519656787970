import { cloneDeep, isEqual, isPlainObject } from "lodash";
import Moment from "moment";
import { Accessor, Diff, Model, isEnum } from "src/networking/types";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getDiffs = (
  currentValue: any,
  previousValue: any,
  accessor: (string | number)[] = []
): Diff[] => {
  /* eslint-enable @typescript-eslint/explicit-module-boundary-types */
  if (Array.isArray(currentValue)) {
    const lengthToConsider = Array.isArray(previousValue)
      ? Math.max(currentValue.length, previousValue.length)
      : currentValue.length;
    return [...Array(lengthToConsider).keys()].flatMap((i) =>
      getDiffs(
        currentValue[i],
        Array.isArray(previousValue) ? previousValue[i] : undefined,
        accessor.concat([i])
      )
    );
  }

  if (isEnum(currentValue)) {
    if (isEnum(previousValue)) {
      return getDiffs(currentValue.value, previousValue.value, accessor);
    }

    return [{ accessor, previousValue, currentValue }];
  }

  if (isPlainObject(currentValue)) {
    const allKeys = new Set(
      isPlainObject(previousValue)
        ? Object.keys(currentValue).concat(Object.keys(previousValue))
        : Object.keys(currentValue)
    );
    return Array.from(allKeys).flatMap((k) =>
      getDiffs(
        currentValue[k],
        isPlainObject(previousValue) ? previousValue[k] : undefined,
        accessor.concat([k])
      )
    );
  }

  return currentValue === previousValue
    ? []
    : [{ accessor, previousValue, currentValue }];
};

export const modifyNestedStructure = (
  model: Model,
  accessor: Accessor,
  action: (innerObject: any, lastField: string | number) => void
): void => {
  let val: any = model;

  for (const [i, f] of accessor.slice(0, -1).entries()) {
    if (val[f] === null || val[f] === undefined) {
      val[f] = typeof accessor[i + 1] === "number" ? [] : {};
    }
    val = val[f];
  }

  const lastField = accessor[accessor.length - 1];
  action(val, lastField);
};

export const applyDiffs = (model: Model, diffs: Diff[]): Model => {
  const newModel = cloneDeep(model);
  for (const { accessor, currentValue } of diffs) {
    modifyNestedStructure(newModel, accessor, (innerObject, lastField) => {
      /* eslint-disable no-param-reassign */
      if (isEnum(innerObject[lastField])) {
        innerObject[lastField].value = currentValue;
      } else if (Moment.isMoment(currentValue)) {
        innerObject[lastField] = currentValue.unix();
      } else if (
        Array.isArray(currentValue) &&
        currentValue.length === 2 &&
        currentValue.every(Moment.isMoment)
      ) {
        innerObject[lastField] = currentValue.map((v) => v.unix());
      } else {
        innerObject[lastField] = currentValue;
      }
      /* eslint-enable no-param-reassign */
    });
  }

  return newModel;
};

export interface OverlappingDiffs {
  overlappingDiffs: [diffFromLeft: Diff, diffFromRight: Diff][];
  nonOverlappingDiffs: [
    remainingDiffsFromLeft: Diff[],
    remainingDiffsFromRight: Diff[]
  ];
}

export const getOverlappingDiffs = (
  diffs: Diff[],
  diffs2: Diff[]
): OverlappingDiffs => {
  const overlappingDiffs = [];
  const nonOverlappingDiffs = [];

  for (const diff of diffs) {
    let overlap = false;

    for (const diff2 of diffs2) {
      if (isEqual(diff.accessor, diff2.accessor)) {
        overlappingDiffs.push([diff, diff2] as [Diff, Diff]);
        overlap = true;
        break;
      }
    }

    if (!overlap) {
      nonOverlappingDiffs.push(diff);
    }
  }

  const nonOverlappingDiffs2 = diffs2.filter((diff2) =>
    diffs.every(({ accessor }) => !isEqual(accessor, diff2.accessor))
  );

  return {
    overlappingDiffs,
    nonOverlappingDiffs: [nonOverlappingDiffs, nonOverlappingDiffs2],
  };
};
