import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Typography,
  Upload,
  message,
} from "antd";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { WinddeskCard } from "src/components/Common/WinddeskCard";
import { hydraWinddeskClient } from "src/networking/index";

import {
  getDocumentByProjectId,
  getDocumentByWorkflowId,
  uploadDocument,
} from "src/redux/actions/documents";
import { GlobalState } from "src/redux/reducers";
import { canUploadDocuments } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";

const { Text } = Typography;

type Props = {
  projectId: string;
  simulationId?: string;
};

const SimulationReport: React.FC<Props> = ({ projectId, simulationId }) => {
  const { t } = useTranslation();

  const { getTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently().then((token: string) => {
      setAuthToken(token);
    });
  }, [getTokenSilently]);

  const columns: any = [
    {
      title: "Name",
      dataIndex: "documentTitle",
      render: (documentTitle: string) => <Text>{documentTitle}</Text>,
      align: "left",
    },
    {
      title: "Issued",
      dataIndex: "issueDate",
      render: (issueDate: Date) => (
        // eslint-disable-next-line react/destructuring-assignment
        <Text>{issueDate.toLocaleDateString()}</Text>
      ),
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "documentType",
      render: (documentType: string) => <Text>{documentType}</Text>,
      align: "center",
    },
    {
      title: "Revision",
      dataIndex: "revisionNumber",
      render: (revisionNumber: string) => <Text>{revisionNumber}</Text>,
      align: "center",
    },
    {
      title: "ID",
      dataIndex: "documentNumber",
      render: (documentType: string) => <Text>{documentType}</Text>,
      align: "center",
    },
    {
      title: "Download",
      render: ({
        documentId,
        fileName,
      }: {
        documentId: number;
        fileName: string;
      }) => (
        <Button
          onClick={async () => {
            hydraWinddeskClient
              .get(`/v1/documents/${documentId}/data`, {
                headers: { Authorization: `Bearer ${authToken}` },
                responseType: "blob",
              })
              .then((resp) => {
                saveAs(resp.data, fileName);
              });
          }}
        >
          Download
        </Button>
      ),
      align: "center",
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    if (authToken) {
      dispatch(
        simulationId === undefined
          ? getDocumentByProjectId(projectId, authToken)
          : getDocumentByWorkflowId(projectId, simulationId, authToken)
      );
    }
  }, [dispatch, authToken, simulationId, projectId]);

  const documentsState = useSelector(
    (state: GlobalState) => state.documents.documents
  );
  const getDocumentsByProjectIdState = useSelector(
    (state: GlobalState) => state.requests.GET_DOCUMENTS_BY_PROJECT_ID_REQUEST
  );
  const getDocumentsByWorkflowIdState = useSelector(
    (state: GlobalState) => state.requests.GET_DOCUMENTS_BY_WORKFLOW_ID_REQUEST
  );
  const getDocumentsState =
    simulationId === undefined
      ? getDocumentsByProjectIdState
      : getDocumentsByWorkflowIdState;

  const data = Object.values(documentsState).flatMap((doc) =>
    doc.project_id === Number(projectId) &&
    (simulationId === undefined || doc.workflow_id === Number(simulationId))
      ? [
          {
            key: doc.id,
            fileName: doc.file_name,
            documentId: doc.id,
            documentType: doc.type,
            documentTitle: doc.title,
            documentNumber: doc.traverse_document_id,
            revisionNumber: doc.revision,
            issueDate: new Date(doc.issue_time * 1000),
          },
        ]
      : []
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<any>(undefined);
  const [antdForm] = Form.useForm();
  const formModal = (
    <Modal
      title="Upload file"
      visible={showForm}
      footer={null}
      onCancel={() => setShowForm(false)}
      maskClosable={false}
    >
      <Form
        form={antdForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={({
          revision,
          issueTime,
          documentTitle,
          documentType,
          traverseDocumentId,
        }) => {
          if (simulationId && authToken && currentFile) {
            dispatch(
              uploadDocument(
                projectId,
                simulationId,
                currentFile,
                revision,
                issueTime.unix(),
                documentType,
                documentTitle,
                traverseDocumentId,
                authToken
              )
            );
            setShowForm(false);
            setCurrentFile(undefined);
            antdForm.resetFields();
            message.success(t("File uploaded!"));
          }
        }}
      >
        <Form.Item
          label={t("Name")}
          labelAlign="right"
          name="documentTitle"
          rules={[{ required: true, validateTrigger: "onChange" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("Issued")} labelAlign="right" name="issueTime">
          <DatePicker showTime />
        </Form.Item>
        <Form.Item
          label={t("Type")}
          labelAlign="right"
          name="documentType"
          rules={[{ required: true, validateTrigger: "onChange" }]}
        >
          <Select
            options={[
              { value: "report", label: "Report" },
              { value: "kmz", label: "Google Earth (KMZ)" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={t("Revision")}
          labelAlign="right"
          name="revision"
          rules={[{ required: true, validateTrigger: "onChange" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("ID")}
          labelAlign="right"
          name="traverseDocumentId"
          rules={[{ required: true, validateTrigger: "onChange" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("Upload")} labelAlign="right">
          <Upload
            fileList={currentFile ? [currentFile] : []}
            beforeUpload={(file) => {
              setCurrentFile(file);
              return false;
            }}
          >
            <Button>Select File</Button>
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }} labelAlign="right">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  return (
    <>
      <WinddeskCard
        title={`${t("Completed Reports")}`}
        buttonText={
          authToken && canUploadDocuments(authToken) ? "Upload File" : undefined
        }
        buttonOnClick={() => setShowForm(true)}
      >
        <Table
          columns={columns}
          dataSource={data}
          loading={getDocumentsState.isLoading}
        />
      </WinddeskCard>
      {formModal}
    </>
  );
};
export default SimulationReport;
