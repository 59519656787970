import { Button, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { OptionProps } from "src/components/Sider/Selector";
import SiderCard from "src/components/Sider/SiderCard";
import { createWorkflow } from "src/redux/actions/workflows";
import { canCreateWorkflows } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";

interface Props {
  options: OptionProps[];
  projectId?: string;
  onSelectedSimulation?: (simulationId?: string) => void;
  selectedSimulationId?: string;
  selectedSimulationUrl?: string;
}

const SimulationSider: React.FC<Props> = ({
  options,
  projectId,
  onSelectedSimulation,
  selectedSimulationId,
  selectedSimulationUrl,
}) => {
  const history = useHistory();
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [antdForm] = Form.useForm();

  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    getTokenSilently().then((token: string) => {
      setAuthToken(token);
    });
  }, [getTokenSilently]);

  if (authToken === undefined) {
    return null;
  }

  const form = (dismissModal: () => void) => (
    <Form
      form={antdForm}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={async ({ workflowName, workflowType }) => {
        if (projectId) {
          dispatch(
            createWorkflow(projectId, workflowName, workflowType, authToken)
          );
        }
        dismissModal();
        antdForm.resetFields();
        message.success("Workflow created!");
      }}
    >
      <Form.Item
        label={t("Workflow name")}
        labelAlign="right"
        name="workflowName"
        rules={[{ required: true, validateTrigger: "onChange" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("Workflow type")}
        labelAlign="right"
        name="workflowType"
        rules={[{ required: true, validateTrigger: "onChange" }]}
      >
        <Select
          options={[
            { value: "prospect", label: t("Prospect") },
            { value: "predict", label: t("Predict") },
          ]}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }} labelAlign="right">
        <Button data-cy="simulationFormSubmit" type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <SiderCard
      data-cy="simulationSiderCard"
      title="Workflow"
      options={options}
      handleChange={(simulationId) => {
        if (projectId) {
          history.push(
            `/predict/projects/${projectId}/simulations/${simulationId}`
          );
        }
        onSelectedSimulation?.(simulationId);
      }}
      homeUrl={selectedSimulationUrl}
      showContents={!!projectId}
      noContentsText="Select a project to view workflows"
      newText="+ Add New Workflow"
      newForm={form}
      value={selectedSimulationId}
      buttonEnabled={canCreateWorkflows(authToken)}
      buttonDisabledTooltip="Inform company administrator to create workflows."
      selectPlaceholder="Select / Add Workflow"
      selectionDataCy="simulationSelection"
      buttonDataCy="addSimulationButton"
    />
  );
};

export default SimulationSider;
