import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { MatchProps, matchUrls } from "src/components/Common/routes";
import { Project } from "src/networking/types";
import { GlobalState } from "src/redux/reducers";

export const useProjectSelectionState = (): [
  string | undefined,
  Dispatch<SetStateAction<string | undefined>>
] => {
  const [selectedProject, setSelectedProject] = useState<string | undefined>();

  const projectsState = useSelector(
    (state: GlobalState) => state.projects.projects
  );
  const projectIds = Object.values(projectsState).map((value) => value.id);

  const projectMatch =
    useRouteMatch<MatchProps>(matchUrls.project)?.params || {};
  const { projectId } = projectMatch;
  useEffect(() => {
    if (projectId && projectIds.find((id) => id === projectId))
      setSelectedProject(projectId);
  }, [projectId, projectIds]);

  return [selectedProject, setSelectedProject];
};
export const useProjectState = (): Project | undefined => {
  const projectsState = useSelector(
    (state: GlobalState) => state.projects.projects
  );
  const selectedProject = useProjectSelectionState()[0];
  return selectedProject && selectedProject in projectsState
    ? projectsState[selectedProject]
    : undefined;
};
