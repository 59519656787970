import { Col, Space } from "antd";
import React from "react";
import styled from "styled-components";

const FullWidthSpace = styled(Space)`
  display: flex !important;
  width: 100% !important;
`;

const WinddeskCol: React.FC<Record<string, unknown>> = ({
  children,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Col {...props}>
    <FullWidthSpace size={16} direction="vertical">
      {children}
    </FullWidthSpace>
  </Col>
);

export default WinddeskCol;
