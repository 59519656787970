import { Menu, Popconfirm } from "antd";
import { Header } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Brand } from "src/components/NavHeader/Brand";
import { WinddeskBreadcrumb } from "src/components/NavHeader/Breadcrumb";
import NavItem from "src/components/NavHeader/NavItem";
import {
  HelpIcon,
  LogoutIcon,
  MonitorIcon,
  PredictIcon,
} from "src/components/NavHeader/icons";
import { canManageAllAlerts } from "src/utils/authz";
import { useAuth0 } from "src/utils/react-auth0-spa";
import styled from "styled-components";

const Text = styled.span`
  font-family: Blender Pro;
  font-size: 16px;
  color: inherit;

  margin-left: 6px;
`;

const HeaderDiv = styled.div`
  display: flex;
  width: 100%;
`;

const HeaderRightDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 44px;
`;

const TraverseHeader = styled(Header)`
  padding: 0;
  height: 60px;
  line-height: 60px;
`;

const TraverseMenu = styled(Menu)`
  & > .ant-menu-item {
    height: 60px;
  }
`;

interface Props {
  brandWidth: string;
}

export const NavHeader: React.FC<Props> = ({ brandWidth }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const { getTokenSilently } = useAuth0();

  const [manageAllAlertsPerm, setManageAllAlertsPerm] = useState(false);
  getTokenSilently().then((token: string) => {
    setManageAllAlertsPerm(canManageAllAlerts(token));
  });

  return (
    <TraverseHeader>
      <HeaderDiv>
        <Brand width={brandWidth} />
        <HeaderRightDiv>
          <WinddeskBreadcrumb />
          <TraverseMenu
            theme="dark"
            mode="horizontal"
            selectedKeys={[
              location.pathname.startsWith("/predict")
                ? "predict"
                : location.pathname.startsWith("/alerts")
                ? "alerts"
                : "monitor",
            ]}
            disabledOverflow
          >
            {manageAllAlertsPerm && (
              <Menu.Item key="alerts">
                <Link to="/alerts">
                  <NavItem centered>
                    <Text>ALERTS</Text>
                  </NavItem>
                </Link>
              </Menu.Item>
            )}

            <Menu.Item key="predict">
              <Link to="/predict">
                <NavItem centered>
                  <PredictIcon />
                  <Text>PREDICT / PROSPECT</Text>
                </NavItem>
              </Link>
            </Menu.Item>

            <Menu.Item key="monitor">
              <Link to="/monitor">
                <NavItem centered>
                  <MonitorIcon />
                  <Text>MONITOR</Text>
                </NavItem>
              </Link>
            </Menu.Item>

            <Menu.Item key="help">
              <a href="mailto:help@traverse.ai">
                <NavItem centered>
                  <HelpIcon />
                  <Text>HELP</Text>
                </NavItem>
              </a>
            </Menu.Item>

            <Menu.Item key="logout">
              <NavItem centered>
                <Popconfirm
                  title="Confirm logout?"
                  onConfirm={() => history.push("/logout")}
                >
                  <LogoutIcon />
                  <Text>LOGOUT</Text>
                </Popconfirm>
              </NavItem>
            </Menu.Item>
          </TraverseMenu>
        </HeaderRightDiv>
      </HeaderDiv>
    </TraverseHeader>
  );
};
