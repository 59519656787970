/* eslint-disable no-underscore-dangle */
import { cloneDeep } from "lodash";
import {
  applyDiffs,
  modifyNestedStructure,
} from "src/components/SimulationConfigs/utils/diffs";
import {
  BaseValue,
  Data,
  Dataclass,
  Dict,
  DiffResult,
  List,
  Model,
  PresentationConfig,
  WorkflowConfig,
  isData,
} from "src/networking/types";
import { ConfigsActionTypes } from "src/redux/actionTypes";
import { getActionTypeForSuccess } from "src/redux/utils";

export interface ConfigsState {
  configs: Record<string, WorkflowConfig>;
  presentationConfig?: PresentationConfig;
  showOnlyPrimaryFields: boolean;
  promptOnLeave: boolean;
  configCommitDiffs: Record<number, DiffResult>;
  formWorkflowId?: number;
  formValues: any;
  validationErrors: any;
  debug: boolean;
}

const initialState = {
  configs: {},
  presentationConfig: undefined,
  showOnlyPrimaryFields: true,
  promptOnLeave: false,
  configCommitDiffs: {},
  formWorkflowId: undefined,
  formValues: {},
  validationErrors: {},
  debug: false,
};

const serializeTypes = new Set();

const serialize = (data: Data): Model | Model[] | undefined => {
  serializeTypes.add(data.__type__);
  switch (data.__type__) {
    case "file":
      return data.__payload__ as Model;
    case "dict":
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return serializeDict(data.__payload__ as Dict);
    case "dataclass":
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return serializeDict(
        Object.entries(data.__payload__ as Dataclass) as Dict
      );
    case "list":
    case "set":
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return serializeList(data.__payload__ as List);
    case "enum":
      return {
        keys: data.__keys__ ?? [],
        value: data.__payload__ as BaseValue,
      };
    case "path":
    case "datetime":
      return data.__payload__ as BaseValue;
    case "unfilled":
      return undefined;
    default:
      return {};
  }
};

const serializeDict = (dict: Dict): Model => {
  const model: Model = {};
  dict.forEach(([key, value]) => {
    if (isData(value)) {
      const serializedValue = serialize(value) as Model;
      if (serializedValue !== undefined) {
        model[String(key)] = serializedValue;
      }
    } else {
      model[String(key)] = value;
    }
  });
  return model;
};

const serializeList = (list: List): Model[] =>
  list.map((elem) => {
    if (isData(elem)) {
      return serialize(elem) as Model;
    }
    return elem;
  });

const reducer = (
  state: ConfigsState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action: any
): ConfigsState => {
  switch (action.type) {
    case getActionTypeForSuccess(
      ConfigsActionTypes.GET_CONFIG_BY_WORKFLOW_ID_REQUEST
    ):
    case getActionTypeForSuccess(
      ConfigsActionTypes.SAVE_CONFIG_BY_WORKFLOW_ID_REQUEST
    ):
    case getActionTypeForSuccess(
      ConfigsActionTypes.NOTIFY_RUN_WORKFLOW_REQUEST
    ):
    case getActionTypeForSuccess(ConfigsActionTypes.UNLOCK_WORKFLOW_REQUEST): {
      const {
        config_id: configId,
        config_commit_id: configCommitId,
        config,
        locked,
        dynamic_options: dynamicOptions,
      } = action.payload.data;
      return {
        ...state,
        configs: {
          ...state.configs,
          [action.meta.previousAction.metadata.workflowId]: {
            configId,
            configCommitId,
            config: serialize(config),
            locked,
            dynamicOptions,
          },
        },
      };
    }

    case getActionTypeForSuccess(
      ConfigsActionTypes.GET_PRESENTATION_CONFIG_REQUEST
    ):
      return {
        ...state,
        presentationConfig: action.payload.data,
      };
    case getActionTypeForSuccess(
      ConfigsActionTypes.GET_DIFF_TO_LATEST_COMMIT_REQUEST
    ):
      return {
        ...state,
        configCommitDiffs: {
          ...state.configCommitDiffs,
          [action.meta.previousAction.metadata.configCommitId]:
            action.payload.data,
        },
      };

    case ConfigsActionTypes.SET_FORM_STATE_FROM_CONFIG:
      return {
        ...state,
        formWorkflowId: action.payload.workflowId,
        formValues: state.configs[action.payload.workflowId]?.config,
      };
    case ConfigsActionTypes.UPDATE_FORM_STATE:
      return {
        ...state,
        formValues: applyDiffs(state.formValues, [action.payload]),
        promptOnLeave: true,
      };
    case ConfigsActionTypes.APPLY_DIFFS: {
      const { formWorkflowId } = state;
      if (formWorkflowId === undefined) {
        return state;
      }

      const config = state.configs[formWorkflowId];
      if (config === undefined) {
        return state;
      }

      const { combinedDiffs, newDiffs, newConfigCommitId } = action.payload;

      return {
        ...state,
        configs: {
          ...state.configs,
          [formWorkflowId]: {
            ...config,
            configCommitId: newConfigCommitId,
            config: applyDiffs(config.config, newDiffs),
          },
        },
        formValues: applyDiffs(config.config, combinedDiffs),
        promptOnLeave: true,
      };
    }
    case ConfigsActionTypes.RESET_FORM_STATE: {
      const { formWorkflowId } = state;
      if (formWorkflowId === undefined) {
        return state;
      }
      return {
        ...state,
        formValues: state.configs[formWorkflowId]?.config,
      };
    }
    case ConfigsActionTypes.ADD_FORM_LIST: {
      const formValues = cloneDeep(state.formValues);
      const { accessor } = action.payload;
      modifyNestedStructure(formValues, accessor, (innerObject, lastField) => {
        if (Array.isArray(innerObject[lastField])) {
          innerObject[lastField].push({});
        } else {
          // eslint-disable-next-line no-param-reassign
          innerObject[lastField] = [{}];
        }
      });

      return {
        ...state,
        formValues,
        promptOnLeave: true,
      };
    }
    case ConfigsActionTypes.REMOVE_FORM_LIST: {
      const formValues = cloneDeep(state.formValues);
      const { accessor, index } = action.payload;
      modifyNestedStructure(formValues, accessor, (innerObject, lastField) => {
        if (Array.isArray(innerObject[lastField])) {
          // eslint-disable-next-line no-param-reassign
          innerObject[lastField] = innerObject[lastField]
            .slice(0, index)
            .concat(innerObject[lastField].slice(index + 1));
        } else {
          // eslint-disable-next-line no-param-reassign
          innerObject[lastField] = [];
        }
      });

      return {
        ...state,
        formValues,
        promptOnLeave: true,
      };
    }

    case ConfigsActionTypes.MODIFY_VALIDATION_ERRORS: {
      const validationErrors = cloneDeep(state.validationErrors);
      const { accessor, message } = action.payload;
      modifyNestedStructure(
        validationErrors,
        accessor,
        (innerObject, lastField) => {
          // eslint-disable-next-line no-param-reassign
          innerObject[lastField] = message;
        }
      );

      return {
        ...state,
        validationErrors,
      };
    }

    case ConfigsActionTypes.SET_SHOW_PRIMARY:
      return {
        ...state,
        showOnlyPrimaryFields: action.payload,
      };
    case ConfigsActionTypes.SET_DEBUG: {
      return {
        ...state,
        debug: action.payload,
      };
    }
    case ConfigsActionTypes.SET_PROMPT_ON_LEAVE: {
      return {
        ...state,
        promptOnLeave: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
