import { DocumentsActionTypes } from "src/redux/actionTypes";
import { RequestPayload } from "src/redux/utils";

export interface ConfigsAction {
  type: DocumentsActionTypes;
  payload: RequestPayload;
  metadata?: any;
}

export function getDocumentByWorkflowId(
  projectId: string,
  workflowId: string,
  token: string
): ConfigsAction {
  return {
    type: DocumentsActionTypes.GET_DOCUMENTS_BY_WORKFLOW_ID_REQUEST,
    payload: {
      request: {
        url: `/v1/projects/${projectId}/workflows/${workflowId}/documents/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function getDocumentByProjectId(
  projectId: string,
  token: string
): ConfigsAction {
  return {
    type: DocumentsActionTypes.GET_DOCUMENTS_BY_PROJECT_ID_REQUEST,
    payload: {
      request: {
        url: `/v1/projects/${projectId}/documents/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}

export function uploadDocument(
  projectId: string,
  workflowId: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  file: any,
  revision: string,
  issueTime: number,
  documentType: string,
  documentTitle: string,
  traverseDocumentId: string,
  token: string
): ConfigsAction {
  const formData = new FormData();
  formData.set("revision", revision);
  formData.set("issue_time", issueTime.toString());
  formData.set("doc_type", documentType);
  formData.set("doc_title", documentTitle);
  formData.set("traverse_doc_id", traverseDocumentId);
  formData.set("file", file);

  return {
    type: DocumentsActionTypes.UPLOAD_DOCUMENT_REQUEST,
    payload: {
      request: {
        method: "post",
        url: `/v1/projects/${projectId}/workflows/${workflowId}/upload_document`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}
