import { LatLngBoundsLiteral } from "leaflet";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { BasicSite } from "src/networking/types";
import styled from "styled-components";

interface Props {
  sites: BasicSite[];
}

const MapComponent = styled(MapContainer)`
  margin-top: 16px;
`;

const Map: React.FC<Props> = ({ sites }) => {
  if (sites.length === 0) {
    return <div>no sites to display</div>;
  }

  const latitudes = sites.map((site) => site.position.coordinates.lat).sort();
  const longitudes = sites.map((site) => site.position.coordinates.lng).sort();

  const maxLat = latitudes[latitudes.length - 1];
  const minLat = latitudes[0];
  const maxLng = longitudes[longitudes.length - 1];
  const minLng = longitudes[0];

  const centerLat = latitudes.reduce((a, b) => a + b, 0) / sites.length;
  const centerLng = longitudes.reduce((a, b) => a + b, 0) / sites.length;

  const bounds: LatLngBoundsLiteral = [
    [maxLat, maxLng],
    [minLat, minLng],
  ];

  return (
    <MapComponent
      style={{ height: "500px" }}
      center={[centerLat, centerLng]}
      zoom={14}
      bounds={sites.length === 1 ? undefined : bounds}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetLeafletMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {sites.map(({ name, position }) => (
        <Marker
          key={`${position.coordinates.lat} ${position.coordinates.lng}`}
          position={[position.coordinates.lat, position.coordinates.lng]}
        >
          <Popup>{name}</Popup>
        </Marker>
      ))}
    </MapComponent>
  );
};

export default Map;
