export interface MatchProps {
  projectId?: string;
  simulationId?: string;
  configurationId?: string;
}

export const matchUrls = {
  project: "/predict/projects/:projectId",
  simulation: "/predict/projects/:projectId/simulations/:simulationId",
  configuration:
    "/predict/projects/:projectId/simulations/:simulationId/configurations/:configurationId",
};
