import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeSeriesCard from "src/components/Monitor/Summary/TimeSeriesCard";
import {
  Sensor,
  Site,
  TimeSeriesInterval,
  WorkflowType,
} from "src/networking/types";
import {
  getTimeSeriesForSensor,
  getTimeSeriesSamplingRatesForSensor,
} from "src/redux/actions/sensors";
import { GlobalState } from "src/redux/reducers";
import { useAuth0 } from "src/utils/react-auth0-spa";

interface Props {
  site: Site;
  sensorFilter?: (sensor: Sensor) => boolean;
  title: string;
}

const SiteSummary: React.FC<Props> = ({ site, sensorFilter, title }) => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  const getTimeSeriesForSensorState = useSelector(
    (state: GlobalState) => state.requests.GET_TIME_SERIES_FOR_SENSOR_REQUEST
  );
  const getTimeSeriesSamplingRatesForSensorState = useSelector(
    (state: GlobalState) =>
      state.requests.GET_TIME_SERIES_SAMPLING_RATES_FOR_SENSOR_REQUEST
  );

  const siteName = site.name;
  const projectId = site.project;

  const [windSpeedSensorName, setSensorName] = useState<string | undefined>();
  const [windSpeedSensorPeriod, setSensorPeriod] = useState<
    TimeSeriesInterval | undefined
  >();

  useEffect(() => {
    if (
      windSpeedSensorName === undefined ||
      windSpeedSensorPeriod === undefined
    ) {
      return;
    }

    getTokenSilently().then((token: string) => {
      dispatch(
        getTimeSeriesForSensor(
          projectId,
          siteName,
          windSpeedSensorName,
          token,
          WorkflowType.MonitorFull,
          windSpeedSensorPeriod
        )
      );
    });
  }, [
    dispatch,
    projectId,
    siteName,
    windSpeedSensorName,
    windSpeedSensorPeriod,
    getTokenSilently,
  ]);

  useEffect(() => {
    if (windSpeedSensorName === undefined) {
      return;
    }

    getTokenSilently().then((token: string) => {
      dispatch(
        getTimeSeriesSamplingRatesForSensor(
          projectId,
          siteName,
          windSpeedSensorName,
          token,
          WorkflowType.MonitorFull
        )
      );
    });
  }, [dispatch, projectId, siteName, windSpeedSensorName, getTokenSilently]);

  const intervalData = useSelector((state: GlobalState) =>
    windSpeedSensorName !== undefined
      ? state.sensors.timeSeriesIntervals[projectId]?.[siteName]?.[
          windSpeedSensorName
        ]
      : undefined
  );
  const data = useSelector((state: GlobalState) =>
    windSpeedSensorName !== undefined && windSpeedSensorPeriod !== undefined
      ? state.sensors.timeSeries[projectId]?.[siteName]?.[windSpeedSensorName]
      : undefined
  );

  return (
    <TimeSeriesCard
      loading={
        getTimeSeriesForSensorState.isLoading ||
        getTimeSeriesSamplingRatesForSensorState.isLoading
      }
      error={getTimeSeriesForSensorState.error}
      title={title}
      nameSelection={
        site?.sensors
          ?.filter(sensorFilter ?? (() => true))
          ?.map(({ id, sensor_weak_name }) => [id, sensor_weak_name]) ?? []
      }
      name={windSpeedSensorName}
      onChangeName={setSensorName}
      periodSelection={intervalData}
      period={windSpeedSensorPeriod}
      onChangePeriod={setSensorPeriod}
      data={data}
    />
  );
};

export default SiteSummary;
